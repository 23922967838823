import React, {useEffect, useState} from 'react'
import {SettingPageTitle} from '../components/header/SettingPageTitle'
import {collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useAuth} from '../../../modules/auth'

const SettingFramework = () => {
  const [activeFrameworks, setActiveFrameworks] = useState<string[]>([])
  const [snoozedFrameworks, setSnoozedFrameworks] = useState<string[]>([])
  const {currentUser} = useAuth()
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)

  useEffect(() => {
    getActiveFrameworks()
  }, [])

  const getActiveFrameworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting frameworks:', error)
    }
  }

  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77'

      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true

      script.onload = () => {
        setIsCrispLoaded(true)
        const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
        if (wasChatOpen) {
          window.$crisp.push(['do', 'chat:show'])
          window.$crisp.push(['do', 'chat:open'])
          setIsChatOpen(true)
        } else {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
        }
      }

      document.head.appendChild(script)
    } else {
      setIsCrispLoaded(true)
      const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
      if (wasChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        setIsChatOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isCrispLoaded) {
      if (isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
      } else {
        window.$crisp.push(['do', 'chat:hide'])
      }
    }
  }, [isChatOpen, isCrispLoaded])

  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
          localStorage.setItem('isChatOpen', 'false')
        },
      ])
    }
  }, [isCrispLoaded])

  const handleCrispChat = () => {
    if (isCrispLoaded) {
      if (!isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        window.$crisp.push(['do', 'session:reset'])
        setIsChatOpen(false)
        localStorage.setItem('isChatOpen', 'false')
      }
    } else {
      console.log('Crisp is not loaded yet. Please wait a moment and try again.')
    }
  }

  const toggleSnoozeFramework = async (framework) => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const updatedFrameworks = activeFrameworks.includes(framework)
      ? activeFrameworks.filter((fw) => fw !== framework)
      : [...activeFrameworks, framework]

    await updateDoc(doc(tenantRef, 'active_frameworks', 'txMY8H6hn0kF5mX0lsit'), {
      frameworks: updatedFrameworks,
    })

    if (activeFrameworks.includes(framework)) {
      setActiveFrameworks(activeFrameworks.filter((fw) => fw !== framework))
      setSnoozedFrameworks([...snoozedFrameworks, framework])
    } else {
      setSnoozedFrameworks(snoozedFrameworks.filter((fw) => fw !== framework))
      setActiveFrameworks([...activeFrameworks, framework])
    }
  }

  return (
    <>
      <SettingPageTitle icon='Settings.svg' title='Settings' desc='Manage your settings'>
        Personnel Management
      </SettingPageTitle>
      <div className='card py-3'>
        <div className='card-body'>
          {activeFrameworks.length > 0 ? (
            <div className='table-responsive' style={{height: '200px', overflowY: 'auto'}}>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                    <th className='min-w-200px '>FRAMEWORK</th>
                    <th className='min-w-100px'>STATUS</th>
                    <th className='min-w-50px'></th>
                  </tr>
                </thead>
                <tbody>
                  {activeFrameworks.map((framework) => (
                    <tr key={framework}>
                      <td>
                        <div className='d-flex align-items-center fs-6'>{framework}</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <a
                            href='#'
                            className='badge fs-7'
                            style={{
                              color: '#1CBB8D',
                              background: '#E8F8F4',
                            }}
                          >
                            Active
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              className='d-flex flex-column align-items-center justify-content-center'
              style={{height: '200px'}}
            >
              <div className='text-center mb-4'>
                <p className='fs-4 fw-bold text-gray-900 mb-3'>No Active Frameworks Found</p>
                <p className='text-gray-500 mb-4'>
                  It seems you haven't set up any frameworks yet.
                </p>
                <button
                  className='btn btn-sm fs-6'
                  style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  onClick={(e) => handleCrispChat()}
                >
                  Set Up Framework
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SettingFramework
