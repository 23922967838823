import React, {useEffect, useState} from 'react'
import db from '../../../../../services/firebase'
import {collection, doc, onSnapshot, updateDoc, addDoc, getDoc, serverTimestamp} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import {toast} from 'react-toastify'
import {ExportVendor} from '../utils/ExportVendor'
import {ExportAsset} from '../utils/ExportAsset'
import {ExportRisk} from '../utils/ExportRisk'
import {ExportTest} from '../utils/ExportTest'
import {ExportControl} from '../utils/ExportControl'
import {ExportEmployee} from '../utils/ExportEmployees'
import {ExportZipPolicy} from '../utils/ExportZipPolicy'
import {ExportZipTest} from '../utils/ExportZipTest'
import Drawer from 'react-modern-drawer'

const AddAudit = ({isOpen, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const [frameworks, setFrameworks] = useState<string[]>([])
  const [owner, setOwner] = useState('')
  const [owners, setOwners] = useState<any[]>([])
  const [organization, setOrganization] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    const unsubscribe = getActiveFramworks()
    return () => unsubscribe && unsubscribe()
  }, [currentUser])

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (currentUser?.tenantId) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId)
        const companyRef = doc(tenantRef, 'settings', 'company')
        const docSnap = await getDoc(companyRef)

        if (docSnap.exists()) {
          const companyData = docSnap.data()
          setOrganization(companyData.legalName || '')
        }
      }
    }

    fetchCompanyData()
  }, [currentUser])

  const getActiveFramworks = () => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const testsCollectionRef = collection(tenantRef, 'active_frameworks')

    const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
      const frameworksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        frameworks: doc.data().frameworks,
      }))
      const frameworks = frameworksData[0]?.frameworks || []
      setActiveFrameworks(frameworks)
    })

    return unsubscribe
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const data = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          role: doc.data().role,
          availability: doc.data().availability,
        }))
        .filter((item) => item.role === 'Auditor')
      setOwners(data)
    })

    return unsubscribe
  }, [])

  const handleFrameworkChange = (frameworks: string[]) => {
    setFrameworks(frameworks)

    if (frameworks.length === 0) {
      setFormErrors((prev) => ({...prev, frameworks: 'Please select at least one framework.'}))
    } else {
      setFormErrors((prev) => ({...prev, frameworks: ''}))
    }
  }

  const handleExportToDataroom = async () => {
    let errors = {...formErrors} 
    let hasError = false

    if (frameworks.length === 0) {
      errors.frameworks = 'Please select a framework.'
      hasError = true
    } else {
      errors.frameworks = ''
    }

    if (!owner.trim()) {
      errors.owner = 'Please select an Auditor.'
      hasError = true
    } else {
      errors.owner = ''
    }

    if (!organization.trim()) {
      errors.organization =
        'If no URL is available, please navigate to the settings page to upload the company details, which will then be displayed here.'
      hasError = true
    } else {
      errors.organization = ''
    }
    if (!startDate.trim()) {
      errors.startDate = 'Please select an Auditor.'
      hasError = true
    } else {
      errors.startDate = ''
    }

    if (!endDate.trim()) {
      errors.endDate = 'Please select an Auditor.'
      hasError = true
    } else {
      errors.endDate = ''
    }

    setFormErrors(errors)

    if (hasError) {
      return
    }

    setIsLoading(true)
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const dataRoomRef = collection(tenantRef, 'dataroom')

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      const todaysDate =  `${month}/${day}/${year}` 

      const selectedOwner = owners.find((o) => o.uid === owner)

      for (const framework of frameworks) {
        const auditFolderName = `${formattedDate}-${framework}-Audit`

        try {
          const auditFolderDoc = await addDoc(dataRoomRef, {
            fName: auditFolderName,
            folder_name: auditFolderName,
            name: auditFolderName,
            subFolder: `tenants/${tenantId}/dataroom/${auditFolderName}/`,
            time_created: formattedDate,
            tenantId: tenantId,
            uid: currentUser?.uid,
            is_folder: true,
            uploadedBy: currentUser?.userName,
            contentType: 'folder',
            fileId: '',
          })

          const folderId = auditFolderDoc.id
          
          const tenantRef2 = doc(db, 'tenants', tenantId)
          const policyRef = collection(tenantRef2, 'audits')

          const audits = await addDoc(policyRef, {
            createdAt: new Date().toISOString(),
            auditStartDate: formattedDate,
            framework: [framework],
            tenantId: tenantId,
            uid: currentUser?.uid,
            start_date: startDate,
            end_date: endDate,
            uploadedBy: currentUser?.userName,
            auditor: {
              fullName: selectedOwner?.full_name,
              email: selectedOwner?.email,
              id: selectedOwner?.uid,
            },
            status: 'Ongoing',
            organization: organization,
            dataroomFolderID: folderId,
            auditEvidenceFolderName: auditFolderName,
          })

          const uniqueID = audits.id

          await updateDoc(audits, {auditId: uniqueID})
          await updateDoc(auditFolderDoc, {fileId: folderId, auditID: audits.id})

          const employeeDocRef = doc(tenantRef2, 'employees', selectedOwner?.id)
          await updateDoc(employeeDocRef, {availability: false})

          try {
            await ExportEmployee(currentUser, auditFolderName, folderId, formattedDate, uniqueID)

            await ExportRisk(currentUser, auditFolderName, folderId, formattedDate, uniqueID)

            await ExportVendor(currentUser, auditFolderName, folderId, formattedDate, uniqueID)

            await ExportAsset(currentUser, auditFolderName, folderId, formattedDate, uniqueID)

            await ExportZipTest(
              currentUser,
              [framework],
              auditFolderName,
              folderId,
              formattedDate,
              uniqueID
            )

            await ExportControl(
              currentUser,
              framework,
              auditFolderName,
              folderId,
              formattedDate,
              uniqueID
            )

            await ExportTest(
              currentUser,
              framework,
              auditFolderName,
              folderId,
              formattedDate,
              uniqueID
            )

            await ExportZipPolicy(
              currentUser,
              framework,
              auditFolderName,
              folderId,
              formattedDate,
              uniqueID
            )
          } catch (exportError) {
            throw exportError
          }
        } catch (frameworkError) {
          throw frameworkError
        }
      }

      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} created a new Audit for ${frameworks} framework and assigned to auditor ${selectedOwner?.full_name}`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        // email: email,
        uid: currentUser?.uid,
        username: currentUser?.userName,
        action: 'Audit created',
        target: 'Audits',
        email: currentUser?.email,
        date: todaysDate,
      })

      toast.success('Successfully exported to Dataroom', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      onClose()
    } catch (error) {
      toast.error('Error exporting to dataroom', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          color: 'black',
        },
        autoClose: 3000,
      })
    } finally {
      setIsLoading(false)
      setFrameworks([])
      setOwner('')
      setStartDate('')
      setEndDate('')
      setFormErrors({})
    }
  }

  const handleOwnerChange = (selectedOwnerId: string) => {
    const selectedOwner = owners.find((owner) => owner.uid === selectedOwnerId)

    if (selectedOwner) {
      if (!selectedOwner.availability) {
        setFormErrors((prev) => ({
          ...prev,
          owner: 'This auditor is already assigned to an audit and is not available',
        }))
        setOwner('')
      } else {
        setOwner(selectedOwnerId)
        setFormErrors((prev) => ({...prev, owner: ''}))
      }
    } else {
      setFormErrors((prev) => ({...prev, owner: 'Please select an Auditor.'}))
      setOwner('')
    }
  }

  return (
    <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
      <div
        className=' modal-dialog-centered border-bottom border-gray-300'
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          padding: '14px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingLeft: '15px',
          paddingRight: '12px',
        }}
      >
        <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
          Add Audit
        </div>

        <div style={{display: 'flex', gap: '16px'}}>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary '
            onClick={onClose}
            aria-label='Close'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#5A5A5A'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-x'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M18 6l-12 12' />
              <path d='M6 6l12 12' />
            </svg>
          </div>
        </div>
      </div>
      <div
        className='p-6 scroll-y overflow-auto  '
        tabIndex={-1}
        style={{height: 'calc(100vh - 50px)'}}
      >
        <div className='mb-8'>
          <p className='fs-5 fw-bold p-0 m-0'>Invite your auditor and add an audit</p>
          <p className='fs-7'>
            Add an audit to your framework when you're ready to share your SecureSlate data with the
            auditors.
          </p>
        </div>
        <div className='form-group w-100'>
          <div className='my-2'>
            <label className='text-dark fw-semibold fs-6  required'>Frameworks</label>
            {/* <div className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
              Select all options that apply
            </div> */}
          </div>

          <select
            className={`form-select mb-2 ${formErrors.frameworks ? 'is-invalid' : ''}`}
            value={frameworks}
            onChange={(e) => {
              const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value)
              handleFrameworkChange(selectedOptions)
            }}
          >
            <option value='' selected>
              Select framework
            </option>
            {activeFrameworks.map((framework) => (
              <option key={framework} value={framework}>
                {framework}
              </option>
            ))}
          </select>
          {formErrors.frameworks && <span className='text-danger'>{formErrors.frameworks}</span>}
          {/* <div
            className='fv-row mb-4 fv-plugins-icon-container'
            data-kt-buttons='true'
            data-kt-initialized='1'
          >
            {activeFrameworks.map((framework) => (
              <label
                key={framework}
                className={`form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6 ${
                  frameworks.includes(framework) ? 'active' : ''
                }`}
                style={{
                  backgroundColor: frameworks.includes(framework) ? '#E8F8F4' : 'transparent',
                  transition: 'background-color 0.3s',
                }}
                onMouseEnter={(e) =>
                  ((e.currentTarget as HTMLElement).style.backgroundColor = '#E8F8F4')
                }
                onMouseLeave={(e) =>
                  ((e.currentTarget as HTMLElement).style.backgroundColor = frameworks.includes(
                    framework
                  )
                    ? '#E8F8F4'
                    : 'transparent')
                }
              >
                <input
                  type='checkbox'
                  checked={frameworks.includes(framework)}
                  onChange={() => handleFrameworkChange(framework)}
                  className='form-check-input me-2'
                />
                <span className='d-flex align-items-center'>
                  <span className='ms-4'>
                    <span className='fs-6 fw-bold text-gray-900 d-block'>{framework}</span>
                  </span>
                </span>
              </label>
            ))}

            <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
              {formErrors.frameworks && <div>{formErrors.frameworks}</div>}
            </div>
          </div> */}
        </div>
        <div className='form-group mb-4'>
          <label className='text-dark fw-semibold my-2 fs-6  required'>Auditor Name</label>

          <select
            name='group'
            className={`form-select mb-2 ${formErrors.owner ? 'is-invalid' : ''}`}
            data-control='select2'
            data-placeholder='Select Owner'
            value={owner}
            onChange={(e) => handleOwnerChange(e.target.value)}
          >
            <option value='' selected>
              Select Auditor
            </option>
            {owners.map((owner) => (
              <option key={owner.id} value={owner.uid}>
                {owner.full_name}
              </option>
            ))}
          </select>
          {formErrors.owner && <span className='text-danger'>{formErrors.owner}</span>}
        </div>

        <div className='form-group mb-4'>
          <label className='text-dark fw-semibold my-2 fs-6  required'>Organization</label>

          <input
            type='text'
            className={`form-control ${formErrors.organization ? 'is-invalid' : ''}`}
            placeholder='Organization url'
            value={organization}
            onChange={(e) => {
              setOrganization(e.target.value)
              setFormErrors((prev) => ({...prev, organization: ''}))
            }}
            disabled
          />
          {formErrors.organization && (
            <span className='text-danger'>{formErrors.organization}</span>
          )}
        </div>
        <div className='fv-row mb-4'>
          {/* begin::Label */}
          <label className='text-dark fw-semibold my-2 fs-6  required'>Start Date</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='Start Date'
            className={`form-control ${formErrors.startDate ? 'is-invalid' : ''}`}
            type='date'
            name='startDate'
            autoComplete='off'
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value)
              setFormErrors((prev) => ({...prev, startDate: ''}))
            }}
          />

          {/* end::Input */}
          {formErrors.startDate && (
            <div className='invalid-feedback d-block'>{formErrors.startDate}</div>
          )}
        </div>
        <div className='fv-row mb-4'>
          {/* begin::Label */}
          <label className='text-dark fw-semibold my-2 fs-6  required'>End Date</label>
          {/* end::Label */}

          {/* begin::Input */}
          <input
            placeholder='End Date'
            className={`form-control ${formErrors.endDate ? 'is-invalid' : ''}`}
            type='date'
            name='enddate'
            autoComplete='off'
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value)
              setFormErrors((prev) => ({...prev, endDate: ''}))
            }}
          />

          {/* end::Input */}
          {formErrors.endDate && (
            <div className='invalid-feedback d-block mb-20'>{formErrors.endDate}</div>
          )}
        </div>
      </div>
      <div
        className='offcanvas-footer d-flex justify-content-end  border-top py-4 px-6'
        style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
      >
        <button
          className='btn btn-sm btn-light fs-6 me-4'
          onClick={() => {
            setFrameworks([])
            onClose()
            setFormErrors({})
          }}
          style={{
            height: '40px',
            borderRadius: '4px',
            transition: 'background-color 0.3s',
          }}
        >
          Cancel
        </button>
        {isLoading ? (
          <button
            className='btn btn-sm fs-6'
            disabled
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Exporting... <span className='spinner-border spinner-border-sm align-middle'></span>
          </button>
        ) : (
          <button
            className='btn btn-sm fs-6'
            onClick={handleExportToDataroom}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Export to dataroom
          </button>
        )}
      </div>
    </Drawer>
  )
}

export default AddAudit
