import ExcelJS from 'exceljs'
import {collection, doc, getDocs, addDoc, updateDoc, getFirestore} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL, getMetadata} from 'firebase/storage'
import {storage} from 'services/firebase'
export const ExportVendor = async (currentUser, auditFolderName, folderId, formattedDate, auditID) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId
  const policyRef = collection(doc(db, 'tenants', tenantId!), 'vendors')
  const querySnapshot = await getDocs(policyRef)

const data = querySnapshot.docs.map((doc) => doc.data()).filter((doc) => !doc.archived)
  if (data.length > 0) {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Vendor Risk')

    worksheet.columns = [
      {header: 'Vendor Name', key: 'vendor_name', width: 30},
      {header: 'Category', key: 'category', width: 20},
      {header: 'Website URL', key: 'website_url', width: 30},
      {header: 'Policy URL', key: 'policy_url', width: 30},
      {header: 'Terms URL', key: 'terms_url', width: 30},
      {header: 'Reliance', key: 'reliance', width: 15},
      {header: 'Risk Level', key: 'risk_level', width: 15},
      {header: 'Authentication Type', key: 'authentication_type', width: 20},
      {header: 'Diligence File Name', key: 'diligence_file_name', width: 30},
      {header: 'Due Diligence Status', key: 'due_diligence_status', width: 20},
      {header: 'Due Diligence Date', key: 'due_diligence_date', width: 20},
      {header: 'Skip Next Due Diligence', key: 'skip_next_due_diligence', width: 25},
      {header: 'Two Factor Authentication', key: 'two_factor', width: 25},
      {header: 'Impact Assessment', key: 'impact_assessment', width: 50},
      {header: 'Owner', key: 'owner', width: 20},
    ]

    const headerRow = worksheet.getRow(1)
    headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FF000000'},
    }
    headerRow.height = 30
    headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    const exportData = data.map((row) => ({
      vendor_name: row.vendor_name || '',
      category: row.category || '',
      website_url: row.website_url || '',
      policy_url: row.policy_url || '',
      terms_url: row.terms_url || '',
      reliance: row.reliance || '',
      risk_level: row.risk_level || '',
      authentication_type: row.authentication_type || '',
      diligence_file_name: row.diligence?.[0]?.diligence_file_name || '',
      due_diligence_status: row.due_diligence_status || '',
      due_diligence_date: row.due_diligence_date || '',
      skip_next_due_diligence: row.skip_next_due_diligence ? 'Yes' : 'No',
      two_factor: row.two_factor ? 'Yes' : 'No',
      impact_assessment: row.impact_assessment?.join(', ') || '',
      owner: row.owner || '',
    }))

    exportData.forEach((item) => {
      const row = worksheet.addRow(item)
      row.height = 20
      row.font = {size: 10}

      if (item.reliance === 'High') {
        row.getCell('F').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'F4CCCC'},
        }
      } else if (item.reliance === 'Medium') {
        row.getCell('F').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FFF2CC'},
        }
      } else if (item.reliance === 'Low') {
        row.getCell('F').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'D9EAD3'},
        }
      }

      // Adjusting the fill color for risk level in column G
      if (item.risk_level === 'High') {
        row.getCell('G').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'F4CCCC'},
        }
      } else if (item.risk_level === 'Medium') {
        row.getCell('G').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FFF2CC'},
        }
      } else if (item.risk_level === 'Low') {
        row.getCell('G').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'D9EAD3'},
        }
      }

      if (item.due_diligence_status === 'Incomplete') {
        row.getCell('J').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'F4CCCC'},
        }
      } else if (item.due_diligence_status === 'Complete') {
        row.getCell('J').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'D9EAD3'},
        }
      }
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const fileName = `${formattedDate} - vendor risk.xlsx`
    const storageRef = ref(storage, `tenants/${tenantId}/dataroom/${auditFolderName}/${fileName}`)
    await uploadBytes(storageRef, buffer)
    const metadata = await getMetadata(storageRef)
    const downloadURL = await getDownloadURL(storageRef)
    const tenantRef = doc(db, 'tenants', tenantId!)
    const dataRoomRef = collection(tenantRef, 'dataroom')

    const fileDoc = {
      createdAt: new Date().toISOString(),
      name: fileName,
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      downloadUrl: downloadURL,
      fileSize: metadata.size,
      subFolder: storageRef.fullPath,
      time_created: formattedDate,
      tenantId: tenantId,
      uid: currentUser?.uid,
      is_folder: false,
      uploadedBy: currentUser?.userName,
      parentFolder: folderId,
      framework: '',
      fileId: '',
    }
    const docRef = await addDoc(dataRoomRef, fileDoc)
    await updateDoc(docRef, {fileId: docRef.id})

    await updateAuditDocument(currentUser, auditID, data.length)
  }
}

const updateAuditDocument = async (currentUser, auditId, totalLength) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId || ''
  const tenantRef2 = doc(db, 'tenants', tenantId)
  const policyRef = doc(tenantRef2, 'audits', auditId)

  await updateDoc(policyRef, {TotalVendors: totalLength})
}