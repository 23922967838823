import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {GoogleAuthProvider, User, UserCredential, onAuthStateChanged, signInWithPopup} from 'firebase/auth'
import db,{auth1, googleProvider} from '../../../../services/firebase'
import posthog from 'posthog-js';

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  googleSignIn: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  googleSignIn: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(() => {
    const storedAuth = localStorage.getItem('auth')
    return storedAuth ? JSON.parse(storedAuth) : authHelper.getAuth()
  })
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(() => {
    const storedUser = localStorage.getItem('currentUser')
    return storedUser ? JSON.parse(storedUser) : undefined
  })


  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      const authToStore = {
        uid: auth?.uid,
        userName: auth?.userName,
        api_token: auth?.api_token,
        email: auth?.email,
        tenantId: auth?.tenantId,
        role: auth?.role
        // ... other properties
      }
      authHelper.setAuth(auth)
      localStorage.setItem('auth', JSON.stringify(authToStore))
    } else {
      authHelper.removeAuth()
      localStorage.removeItem('auth')
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    localStorage.removeItem('currentUser')
    if (auth?.email) {
      posthog.identify(auth?.email, {
        email: ''
      });
    }
  }
  const googleSignIn = async () => {

    return await signInWithPopup(auth1, googleProvider)
  }
  // useEffect(() => {
    
  //   const unsubscribe = onAuthStateChanged(auth1, (currentUser: any) => {
  //     setCurrentUser(currentUser)
  //     console.log('User', currentUser)
     

      
  //   })
  //   return () => {
  //     unsubscribe()
  //   }
  // }, [])
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth1, (user) => {
  //     // Make sure to update the tenantId in your state or context when the user changes
  //     setCurrentUser({
  //       uid: user?.uid,
  //       userName: user?.displayName,
  //       api_token: user?.refreshToken,
  //       // email: user?.email,
  //       tenantId: user?.tenantId, // Check if tenantId is available
  //     });
  //   });
  
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);



  useEffect(() => {
    const userToStore = {
      uid: currentUser?.uid,
      userName: currentUser?.userName,
      api_token: currentUser?.api_token,
      email: currentUser?.email,
      tenantId: currentUser?.tenantId,
      role: currentUser?.role,
      account_type:currentUser?.account_type
      // ... other properties
    }
    localStorage.setItem('currentUser', JSON.stringify(userToStore))
  }, [currentUser])

  return (
    <AuthContext.Provider
      value={{auth, saveAuth, googleSignIn, currentUser, setCurrentUser, logout}}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  interface ExtendedUser extends User {
    password?: string
    first_name?: string
    last_name?: string
    // tenantId: string
  }
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {

    async function fetchPersonalDataFromFirestore(tenantId: string, userId: string) {
      try {
        const personalDocSnapshot = db.collection('tenants').doc(tenantId).collection('employees').where('uid', '==', userId).get();
        if ((await personalDocSnapshot).docs.length >0 ) {
          return (await personalDocSnapshot).docs[0].data();
        } else {
          console.error('User data not found in Firestore')
          return {}
        }
      } catch (error) {
        console.error('Error fetching user data from Firestore:', error)
        throw error
      }
    }


    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = (await getUserByToken()) as ExtendedUser

          if (user) {
            const userPersonaData = await fetchPersonalDataFromFirestore(user.tenantId||"", user.uid);
            setCurrentUser({
              uid: user.uid,
              userName: user.displayName,
              api_token: await user.getIdToken(),
              email: user.email,
              tenantId: user.tenantId,
              role:userPersonaData.role
              // password: user.password || '',
              // first_name: user.first_name || '',
              // last_name: user.last_name || '',
              // ... other properties
            })
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => {
        didRequest.current = true;
      };
    }

    if (auth && auth.api_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
