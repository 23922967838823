import {saveAs} from 'file-saver'
import {collection, doc, getDocs, getFirestore} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import {toast} from 'react-toastify'
import ExcelJS from 'exceljs'
import {useState} from 'react'
import AddAsset from '../AddAsset'
import AddAssetCSV from '../AddAssetCSV'
const AssetListToolbar = () => {
  const {currentUser} = useAuth()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCSV, setIsCSV] = useState(false)

  const exportData = async () => {
    const db = getFirestore()
    const tenantId = currentUser?.tenantId
    const tenantRef = doc(db, 'tenants', tenantId!)
    const PolicyRef = collection(tenantRef, 'assets')
    const querySnapshot = await getDocs(PolicyRef)

    const data = querySnapshot.docs.map((doc) => doc.data()).filter((doc) => !doc.is_repo)

    if (data.length > 0) {
      const fields = [
        {key: 'serial_number', header: 'Serial Number', width: 20},
        {key: 'name', header: 'Name', width: 15},
        {key: 'model', header: 'Model', width: 15},
        {key: 'os_version', header: 'OS Version', width: 20},
        {key: 'owner', header: 'Owner', width: 20},
        {key: 'hd_encryption', header: 'HD Encryption', width: 15},
        {key: 'anti_virus', header: 'Anti Virus', width: 15},
        {key: 'password_policy', header: 'Password Policy', width: 15},
        {key: 'screen_policy', header: 'Screen Policy', width: 15},
        {key: 'firewall', header: 'Firewall', width: 15},
        {key: 'audit_scope', header: 'Audit Scope', width: 15},
      ]

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Assets')

      worksheet.columns = fields.map((field) => ({
        header: field.header,
        key: field.key,
        width: field.width,
      }))

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center'}

      worksheet.views = [{state: 'frozen', ySplit: 1}]

      for (let i = 1; i <= 11; i++) {
        const cell = headerRow.getCell(i)
        cell.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 11}
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'FF000000'},
        }
        cell.alignment = {horizontal: 'center', vertical: 'middle'}
      }

      data.forEach((row) => {
        const rowData = fields.reduce((acc, field) => {
          let value = row[field.key] || row[field.key.toLowerCase()] || ''

          if (field.key === 'last_checkedin' && value) {
            const date = new Date(value)
            acc[field.key] = date.toLocaleDateString()
          } else {
            acc[field.key] = value
          }

          return acc
        }, {})

        const addedRow = worksheet.addRow(rowData)
        addedRow.height = 20
        addedRow.font = {size: 11}

        fields.forEach((field) => {
          const cell = addedRow.getCell(field.key)
          if (
            [
              'anti_virus',
              'audit_scope',
              'firewall',
              'hd_encryption',
              'password_policy',
              'screen_policy',
            ].includes(field.key)
          ) {
            if (cell.value === 'No') {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'F4CCCC'},
              }
            } else if (cell.value === 'Yes') {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'D9EAD3'},
              }
            }
          }
        })
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})
      saveAs(excelBlob, 'Assets.xlsx')

      toast.success('Assets data exported successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    } else {
      console.log('No data to export')
    }
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseModal2 = () => {
    setIsCSV(!isCSV)
  }

  const handleOpenModal2 = () => {
    setIsCSV(true)
  }

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <button
          type='button'
          className=' btn btn-sm btn-light me-4 fs-6'
          onClick={exportData}
          style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#5A5A5A'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
            style={{marginRight: '4px'}}
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
            <path d='M7 11l5 5l5 -5' />
            <path d='M12 4l0 12' />
          </svg>
          Export
        </button>

        {currentUser?.role !== 'Auditor' && (
          <div className='dropdown'>
            <button
              className='btn btn-sm fs-6 rotate'
              style={{background: '#1CBB8D', height: '40px', color: '#fff'}}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              onClick={toggleDropdown}
              id='dropdownMenu2'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <span className='mx-2'>Add Assets</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down rotate-180'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M6 9l6 6l6 -6' />
              </svg>
            </button>
            <ul
              className='dropdown-menu mt-2'
              aria-labelledby='dropdownMenu2'
              style={{width: '160px'}}
            >
              <li>
                <button
                  className='dropdown-item text-hover-primary button-with-icon'
                  type='button'
                  onClick={handleOpenModal}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-pencil mb-1 mx-1 '
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4' />
                    <path d='M13.5 6.5l4 4' />
                  </svg>{' '}
                  Add manually
                </button>
              </li>

              <li>
                <button
                  className='dropdown-item text-hover-primary button-with-icon'
                  type='button'
                  onClick={handleOpenModal2}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-file-import mb-1 mx-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                    <path d='M5 13v-8a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5.5m-9.5 -2h7m-3 -3l3 3l-3 3' />
                  </svg>{' '}
                  Import
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
      <AddAssetCSV isOpen={isCSV} onClose={handleCloseModal2} />
      <AddAsset isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  )
}

export {AssetListToolbar}
