/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar'
import {useAuth} from '../../../../modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../../services/firebase'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
})

const PageDataProvider: FC<WithChildren> = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])

  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  title?: string
  icon?: string
  desc?: string
}

const ReportPageTitle: FC<Props & WithChildren> = ({
  children,
  description,
  breadcrumbs,
  title,
  icon,
  desc,
}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs} = usePageData()
  const {currentUser} = useAuth()
  const [totalControl, setTotalcontrols] = useState(0)
  const [totalISO, setTotalISO] = useState(0)
  const [totalSOC, setTotalSOC] = useState(0)
  const [unhealthyControlsCount, setUnhealthyControlsCount] = useState(0)
  const [healthyControlsCount, setHealthyControlsCount] = useState(0)
  const [healthyISOControlsCount, setHealthyISOControlsCount] = useState(0)
  const [healthySOCControlsCount, setHealthySOCControlsCount] = useState(0)
  const [controls, setControls] = useState<any[]>([])
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    getControls()
  }, [activeFrameworks])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getActiveFramworks = () => {
    try {
      if (currentUser?.role !== 'Auditor') {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)

        const testsCollectionRef = collection(tenantRef, 'active_frameworks')

        const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
          const frameworksData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            frameworks: doc.data().frameworks,
          }))
          const frameworks = frameworksData[0]?.frameworks || []
          setActiveFrameworks(frameworks)
        })

        return unsubscribe // Return the unsubscribe function
      } else {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)

        const testsCollectionRef = collection(tenantRef, 'audits')

        const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
          const frameworksData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            frameworks: doc.data().framework,
            auditor:doc.data().auditor,
            status:doc.data().status
          })).filter((item)=>item.auditor?.id === currentUser?.uid && item.status !== 'Completed')
          const frameworks = frameworksData[0]?.frameworks || []
          setActiveFrameworks(frameworks)
        })

        return unsubscribe // Return the unsubscribe function
      }
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        setControls(filteredControls)

        setTotalcontrols(filteredControls.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    const unhealthyControls = controls.filter((doc) => {
      const status = doc.control_evidence_status
      return status && status === 'Unhealthy'
    })

    // Count the number of documents with status "Unhealthy"
    const unhealthyControlsCount = unhealthyControls.length

    // Update state with the count of "Unhealthy" controls
    setUnhealthyControlsCount(unhealthyControlsCount)

    const iso27001Controls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      return framework && framework.includes('ISO')
    })

    // Count the number of documents that start with "ISO 27001"
    const iso27001ControlsCount = iso27001Controls.length
    setTotalISO(iso27001ControlsCount)

    const SOC2Controls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      return framework && framework.startsWith('SOC')
    })

    // Count the number of documents that start with "ISO 27001"
    const SOC2ControlsCount = SOC2Controls.length
    setTotalSOC(SOC2ControlsCount)
  }, [controls])

  useEffect(() => {
    if (!controls || controls.length === 0) {
      return
    }

    const HealthyControls = controls.filter((doc) => {
      const status = doc.control_evidence_status || ''
      return status.toLowerCase() === 'ok'
    })
    const HealthyControlsCount = HealthyControls.length
    setHealthyControlsCount(HealthyControlsCount)

    // Filter documents with framework containing "ISO" and status "Ok"
    const isoHealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings || ''
      const status = doc.control_evidence_status || ''
      return framework.includes('ISO') && status.toLowerCase() === 'ok'
    })

    // Count the number of documents with framework containing "ISO" and status "Ok"
    const isoHealthyControlsCount = isoHealthyControls.length

    // Update state with the count of "ISO" controls with status "Ok"
    setHealthyISOControlsCount(isoHealthyControlsCount)

    // Filter documents with framework containing "SOC" and status "OK"
    const socHealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings || ''
      const status = doc.control_evidence_status || ''
      return framework.includes('SOC') && status.toLowerCase() === 'ok'
    })

    // Count the number of documents with framework containing "SOC" and status "OK"
    const socHealthyControlsCount = socHealthyControls.length

    // Update state with the count of "SOC" controls with status "OK"
    setHealthySOCControlsCount(socHealthyControlsCount)
  }, [controls])

  return (
    <>
      {icon ? (
        <div className={`card mb-5 mb-xl-8`}>
          <div className='card-header border-0 pt-5 mb-5'>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5 '>
                <img src={toAbsoluteUrl(`/media/logos/${icon}`)} alt='' />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <span className='card-label fw-bold fs-3'>{title}</span>
                <span className='text-muted fw-semibold fs-7'>{desc}</span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <div className='col-auto  p-0 mb-3 mb-lg-0'>
                <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                  <CircularProgressbar
                    value={
                      title === 'SOC 2'
                        ? (healthySOCControlsCount / totalSOC) * 100
                        : (healthyISOControlsCount / totalISO) * 100
                    }
                    // text={`${percentage}%`}
                    strokeWidth={15}
                    styles={buildStyles({
                      strokeLinecap: 'round',
                      pathTransitionDuration: 0.5,
                      pathColor: `#44dbae`,
                      textColor: '#000000',
                      trailColor: '#d6d6d6',
                      backgroundColor: '#3e98c7',
                    })}
                  />
                </div>
              </div>
              <div className='mx-2 fw-semibold' style={{color: '#171717'}}>
                {' '}
                Controls OK :{' '}
                {title === 'SOC 2' ? (
                  // <>{((healthySOCControlsCount / totalSOC) * 100).toFixed(0)}% </>
                  <>
                    {totalSOC !== 0 ? (
                      `${((healthySOCControlsCount / totalSOC) * 100).toFixed(0)}%`
                    ) : (
                      <span className='fs-7 fw-semibold' style={{color: '5A5A5A'}}>
                        Loading...
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {totalISO !== 0 ? (
                      `${((healthyISOControlsCount / totalISO) * 100).toFixed(0)}%`
                    ) : (
                      <span className='fs-7 fw-semibold' style={{color: '5A5A5A'}}>
                        Loading...
                      </span>
                    )}
                  </>
                )}
              </div>
              {/* <button
                className='btn btn-sm text-white ms-3'
                style={{background: '#1CBB8D', height: '40px'}}
              >
                Export
              </button> */}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

const PageDescription: FC<WithChildren> = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, ReportPageTitle, PageDataProvider, usePageData}
