import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../../_metronic/helpers'
import {collection, doc, getDoc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {useAuth} from '../../../../modules/auth'

type Props = {
  id: string
  isOpen: boolean
  onClose: () => void
}

interface Control {
  id: string
  control_category: string
  control_description: string
  control_document: string
  control_evidence_status: string
  control_framework_mappings: string
  control_id: string
  control_owner: string
  control_test: string
  control_title: string
  control_uid: string
  document_id: string[]
  test_id: string[]
}

const getCodeFromFrameworkMapping = (mapping: string) => {
  // Extract the code portion, e.g., "C.4.1" from "ISO 27001:2022 ‧ C.4.1"
  const parts = mapping.split('‧')
  return parts.length > 1 ? parts[1].trim() : ''
}

const getMainCode = (mapping: string) => {
  const parts = mapping.split('‧')
  if (parts.length > 1) {
    return parts[1].trim().split('.').slice(0, 2).join('.') // Return full code, e.g., "A.4"
  }
  return ''
}

// Function to get the letter prefix
const getLetterPrefix = (code: string) => {
  return code.match(/[A-Z]/)?.[0] || ''
}

// Function to get the numeric part
const getNumericPart = (code: string) => {
  return parseInt(code.replace(/[A-Z]\./i, '')) || 0
}

const AddFramework: React.FC<Props> = ({id, isOpen, onClose}) => {
  const [risk, setRisk] = useState([] as any[])
  const [control, setControl] = useState<any>([])
  const {currentUser} = useAuth()
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [showRecommendedOnly, setShowRecommendedOnly] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedFrameworks, setSelectedFrameworks] = useState<string[]>([])
  const [controls, setControls] = useState<Record<string, Control[]>>({})
  const [selectedControls, setSelectedControls] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getRisk()
  }, [showRecommendedOnly])

  useEffect(() => {
    getControl()
    getControls()
  }, [activeFrameworks])

  useEffect(() => {
    // Ensure the first framework is selected by default
    if (activeFrameworks.length > 0) {
      setSelectedFrameworks([activeFrameworks[0]])
    }
  }, [activeFrameworks])

  const handleFrameworkSelection = (framework: string) => {
    setSelectedFrameworks([framework]) // Only one framework should be selected at a time
  }

  const getRisk = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'risks')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .filter((data) => data.id === id)
        setRisk(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }
  const getControl = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        setControl(filteredControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getControls = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData: Control[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          control_category: doc.data().control_category || '',
          control_description: doc.data().control_description || '',
          control_document: doc.data().control_document || '',
          control_evidence_status: doc.data().control_evidence_status || '',
          control_framework_mappings: doc.data().control_framework_mappings || '',
          control_id: doc.data().control_id || '',
          control_owner: doc.data().control_owner || '',
          control_test: doc.data().control_test || '',
          control_title: doc.data().control_title || '',
          control_uid: doc.data().control_uid || '',
          document_id: doc.data().document_id || [],
          test_id: doc.data().test_id || [],
        }))
        // setControls(controlsData)

        // Filter to get only ISO framework controls
        // const filteredControls = controlsData.filter(
        //   (control) =>
        //     control.control_framework_mappings.startsWith(`${activeFrameworks[0]}`) &&
        //     !control.control_framework_mappings.startsWith('ISO 27001:2013')
        // )

        const filteredControls = controlsData.filter(
          (control) =>
            activeFrameworks.some((framework) =>
              control.control_framework_mappings.startsWith(framework)
            ) && !control.control_framework_mappings.startsWith('ISO 27001:2013')
        )

        const groupedControls = filteredControls.reduce((acc, control) => {
          const mainCode = getMainCode(control.control_framework_mappings)
          if (!acc[mainCode]) {
            acc[mainCode] = []
          }
          acc[mainCode].push(control)
          return acc
        }, {} as Record<string, Control[]>)

        // Sort each group by the full code portion
        Object.keys(groupedControls).forEach((mainCode) => {
          groupedControls[mainCode].sort((a, b) => {
            const codeA = getCodeFromFrameworkMapping(a.control_framework_mappings)
            const codeB = getCodeFromFrameworkMapping(b.control_framework_mappings)
            return codeA.localeCompare(codeB)
          })
        })

        const sortedGroupedControls = Object.entries(groupedControls)
          .sort(([keyA], [keyB]) => {
            const prefixA = getLetterPrefix(keyA)
            const prefixB = getLetterPrefix(keyB)
            if (prefixA !== prefixB) {
              return prefixA.localeCompare(prefixB)
            }
            return getNumericPart(keyA) - getNumericPart(keyB)
          })
          .reduce((acc, [key, value]) => {
            acc[key] = value.sort((a, b) => {
              const codeA = getCodeFromFrameworkMapping(a.control_framework_mappings)
              const codeB = getCodeFromFrameworkMapping(b.control_framework_mappings)
              return getNumericPart(codeA) - getNumericPart(codeB)
            })
            return acc
          }, {} as Record<string, Control[]>)

        setControls(sortedGroupedControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    if (control.length > 0 && risk.length > 0) {
      logMatchingRecommendedControls()
    }
  }, [control, risk])

  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77'

      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true

      script.onload = () => {
        setIsCrispLoaded(true)
        const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
        if (wasChatOpen) {
          window.$crisp.push(['do', 'chat:show'])
          window.$crisp.push(['do', 'chat:open'])
          setIsChatOpen(true)
        } else {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
        }
      }

      document.head.appendChild(script)
    } else {
      setIsCrispLoaded(true)
      const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
      if (wasChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        setIsChatOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isCrispLoaded) {
      if (isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
      } else {
        window.$crisp.push(['do', 'chat:hide'])
      }
    }
  }, [isChatOpen, isCrispLoaded])

  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
          localStorage.setItem('isChatOpen', 'false')
        },
      ])
    }
  }, [isCrispLoaded])

  const handleCrispChat = () => {
    if (isCrispLoaded) {
      if (!isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        window.$crisp.push(['do', 'session:reset'])
        setIsChatOpen(false)
        localStorage.setItem('isChatOpen', 'false')
      }
    } else {
      console.log('Crisp is not loaded yet. Please wait a moment and try again.')
    }
  }

  const logMatchingRecommendedControls = () => {
    const matchingControls = control.filter((item) => {
      return Array.from(recommendedControlsSet as Set<string>).some((rec: string) =>
        item.control_title.toLowerCase().includes(rec.toLowerCase())
      )
    })
  }

  const handleToggleRecommended = () => {
    setShowRecommendedOnly(!showRecommendedOnly)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const recommendedControlsSet = useMemo(() => {
    return new Set(risk[0]?.recommendedControls || [])
  }, [risk])

  const filteredControls = useMemo(() => {
    return Object.entries(controls).filter(([mainCode, controlGroup]) => {
      const matchesSearch = controlGroup[0].control_category
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
      const matchesSelectedFrameworks =
        selectedFrameworks.length === 0 ||
        selectedFrameworks.some((framework) =>
          controlGroup[0].control_framework_mappings.includes(framework)
        )

      return matchesSearch && matchesSelectedFrameworks
    })
  }, [controls, searchTerm, selectedFrameworks])

  const resetSelectedFrameworks = () => {
    setSelectedFrameworks([])
    setSearchTerm('')
  }

  const updateControlFrameworks = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'controls')

      const controlRef = doc(controlsCollectionRef, id)
      const controlDoc = await getDoc(controlRef)

      if (controlDoc.exists()) {
        const controlData = controlDoc.data()

        const framework = selectedFrameworks[0]

        // Find the main code (e.g., A.5) from the selected control
        const selectedControl = selectedControls[0]
        const mainCode = Object.keys(controls).find((key) =>
          controls[key].some((control) => control.id === selectedControl)
        )
        setIsLoading(true)
        if (mainCode) {
          // Count existing controls for this main code
          const existingControlsCount = controls[mainCode].length
          const specificCategory = filteredControls.find(
            ([code, controlGroup]) => code === mainCode
          )?.[1][0].control_category

          // Create new mapping
          const newMapping = `${framework} ‧ ${mainCode}.${existingControlsCount + 1}`

          setIsLoading(true)
          await updateDoc(controlRef, {
            control_framework_mappings: newMapping,
            control_uid: `${mainCode}.${existingControlsCount + 1}`,
            control_category: specificCategory,
          })
        }
      }
      setIsLoading(false)
      getControls()
      setSelectedControls([])

      // Close modal
      onClose()
    } catch (error) {
      console.error('Error updating control frameworks:', error)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => {
        resetSelectedFrameworks()
        onClose()
      }}
      backdropClassName='bg-opacity-1'
      centered
      size='lg'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      dialogClassName='modal-90w'
    >
      <Modal.Header
        closeButton
        style={{borderTopLeftRadius: '0.475rem', borderTopRightRadius: '0.475rem'}}
      >
        <Modal.Title>Map Framework</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='p-0 mt-4'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(80vh - 60px)',
          borderBottomLeftRadius: '0.475rem',
          borderBottomRightRadius: '0.475rem',
          overflow: 'hidden',
        }}
      >
        <div className='px-6'>
          {activeFrameworks.length > 0 ? (
            activeFrameworks.map((framework, index) => (
              <span
                key={index}
                className={`badge px-4 py-2 rounded-pill me-4 fs-7 cursor-pointer ${
                  selectedFrameworks.includes(framework) ? 'bg-light-primary' : ''
                }`}
                style={{
                  border: selectedFrameworks.includes(framework)
                    ? '2px solid #1CBB8D'
                    : '2px solid #E1E1E1',
                  color: selectedFrameworks.includes(framework) ? '#1CBB8D' : '',
                  cursor: 'pointer',
                }}
                onClick={() => handleFrameworkSelection(framework)}
              >
                {framework}
              </span>
            ))
          ) : (
            <>
              <div className='card p-4 fs-6 fw-semibold cursor-pointer bg-light-danger'>
                <p className='p-0 m-0 px-3'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-shield-question me-2 mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M15.065 19.732c-.95 .557 -1.98 .986 -3.065 1.268a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3c.51 1.738 .617 3.55 .333 5.303' />
                    <path d='M19 22v.01' />
                    <path d='M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483' />
                  </svg>
                  <span className='mt-2'>Framework not found!!</span>
                </p>
              </div>
            </>
          )}
        </div>
        <div className='static-content'>
          <div className='d-flex align-items-center position-relative my-8 px-6 '>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className='table-container' style={{overflowY: 'auto', flexGrow: 1}}>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-top-2 border-gray-200'>
                <th className='min-w-50px'></th>
                <th className='min-w-500px'>REQUIREMENTS</th>
                {/* <th className='min-w-200px'>MAPPED FRAMEWORKS</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredControls.length > 0 ? (
                filteredControls.map(([mainCode, controlGroup]) => (
                  <tr key={mainCode} className='px-6'>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid ps-6'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value={controlGroup[0].id}
                          checked={selectedControls.includes(controlGroup[0].id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedControls([...selectedControls, controlGroup[0].id])
                            } else {
                              setSelectedControls(
                                selectedControls.filter((id) => id !== controlGroup[0].id)
                              )
                            }
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='fw-semibold p-0'>
                        <a href='#' className='fs-6 fw-bold text-gray-900 text-hover-primary'>
                          {mainCode.split(',')[0].trim()}
                        </a>
                        <div className='fs-7'>{controlGroup[0].control_category}</div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <td colSpan={10} className='text-center'>
                  <div className=''>
                    <img
                      src='/media/buttons/emptystate.svg'
                      width={100}
                      height={100}
                      alt='Empty State'
                    />
                    <div className='m-5 fs-6'>
                      <p style={{fontWeight: 'bold'}}>No Framework Assigned</p>
                    </div>
                    <p>
                      A framework is not assigned to this account. Check our{' '}
                      <a
                        href='https://getsecureslate.com/pricing'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-primary ms-1 fw-semibold'
                      >
                        Pricing Page
                      </a>{' '}
                      or {' '}
                      <a
                        href='#'
                        onClick={() => handleCrispChat()}
                        className='text-primary ms-1 fw-semibold'
                      >
                        Contact sales
                      </a>{' '}<br/>
                      to get customized package.
                    </p>
                  </div>
                </td>
              )}
            </tbody>
          </table>
        </div>

        <div className='modal-footer p-0 my-4 pt-4 px-6'>
          <button
            className='btn btn-sm btn-light fs-6'
            onClick={() => {
              resetSelectedFrameworks()
              onClose()
            }}
            style={{
              width: '80px',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Cancel
          </button>

          {isLoading ? (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '200px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
            >
              Map Frameworks...{' '}
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </button>
          ) : (
            <button
              className='btn btn-sm fs-6'
              type='submit'
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                width: '150px',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
              onClick={updateControlFrameworks}
            >
              Map Frameworks
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddFramework
