import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {collection, doc, onSnapshot, serverTimestamp, updateDoc} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import DiligenceDrawer from './DiligenceDrawer'

const Diligence = () => {
  var {id} = useParams()
  const [vendors, setVendors] = useState([] as any[])
  const {currentUser} = useAuth()
  const [activeTab, setActiveTab] = useState('due')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [skip, setSkip] = useState(false)
  const [dueDiligenceDate, setDueDiligenceDate] = useState('')

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    getVendors()
  }, [])

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const getVendors = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'vendors')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setVendors(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting vendors:', error)
    }
  }

  useEffect(() => {
    if (vendors.length > 0) {
      const vendor = vendors.find((v) => v.id === id)
      if (vendor) {
        setDueDiligenceDate(vendor.due_diligence_date || '')
        setSkip(vendor.skip_next_deu_diligence || '')
      }
    }
  }, [vendors, id])

  useEffect(() => {
    const checkDueDiligenceStatus = async () => {
      const vendor = vendors.find((v) => v.id === id)
      if (vendor && vendor.due_diligence_date) {
        const dueDate = new Date(vendor.due_diligence_date)
        const today = new Date()

        if (today > dueDate && vendor.due_deligence_status === 'Complete') {
          try {
            const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
            const vendorsRef = doc(tenantRef, 'vendors', id || '')
            await updateDoc(vendorsRef, {
              due_diligence_status: 'Incomplete',
              updated_at: serverTimestamp(),
            })
            // await updateDoc(vendorRef, { dueDeligenceStatus: 'Incomplete' })
          } catch (error) {
            console.error('Error updating due diligence status:', error)
          }
        }
      }
    }

    checkDueDiligenceStatus()
  }, [vendors, id, currentUser])

  const handleDateChange = async (e) => {
    const newDate = e.target.value
    setDueDiligenceDate(newDate)

    try {
      // const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const vendorsRef = doc(tenantRef, 'vendors', id || '')
      await updateDoc(vendorsRef, {
        due_diligence_date: newDate,
        updated_at: serverTimestamp(),
      })
      // await updateDoc(vendorRef, { dueDiligenceDate: newDate });
    } catch (error) {
      console.error('Error updating due diligence date:', error)
    }
  }

  const handleSkipNextDiligence = async (e) => {
    const newskip = e.target.checked
    setSkip(newskip)

    try {
      // const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const vendorsRef = doc(tenantRef, 'vendors', id || '')
      await updateDoc(vendorsRef, {
        skip_next_deu_diligence: newskip,
        updated_at: serverTimestamp(),
      })
      // await updateDoc(vendorRef, { dueDiligenceDate: newDate });
    } catch (error) {
      console.error('Error updating due diligence date:', error)
    }
  }

  return (
    <>
      {vendors.length > 0 &&
        vendors
          .filter((vendor) => vendor.id === id)
          .map((vendor) => (
            <>
              <div className='card px-6 pt-6 mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px me-5 '>
                    {/* <img src={toAbsoluteUrl(vendor.logo[0].url)} alt='' /> */}
                    {vendor.logo && vendor.logo.length > 0 ? (
                          <img src={toAbsoluteUrl(vendor.logo[0]?.url)} alt='' loading='lazy' />
                        ) : (
                          <img src={toAbsoluteUrl(vendor.logoUrl)} alt='' loading='lazy' />
                        )}
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='card-label fw-bold fs-5'>{vendor.vendor_name}</span>
                    <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                      {vendor.category}
                    </span>
                  </div>
                </div>

                <div className='px-2  mt-4 '>
                  <div className='d-flex  h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
                      <li className='nav-item'>
                        <Link
                          to={`/vendor-risk/details/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'overview' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('overview')}
                        >
                          Overview
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link
                          to={`/vendor-risk/details/diligence/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'due' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('due')}
                        >
                          Due Diligence
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <DiligenceDrawer isOpen={isModalOpen} onClose={handleCloseModal} id={id || ''} />
              <div className='card mb-5 mb-xl-8 mt-8 '>
                <div className=' card-header text-grey-800 py-8  fw-bold fs-5'>Due Diligence</div>
                <div className='card-body'>
                  {/* {vendor.due_diligence_status === 'Complete' ? (
                    <span
                      className='badge rounded-pill  p-1'
                      style={{border: '1px solid #C5C5C5', width: '110px'}}
                    >
                      <span className='mx-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#1CBB8D'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                          <path d='M9 12l2 2l4 -4' />
                        </svg>
                      </span>
                      <span className='fw-semibold fs-7 '>Complete</span>
                    </span>
                  ) : (
                    <>
                      <span
                        className='badge rounded-pill  p-1'
                        style={{border: '1px solid #C5C5C5', width: '110px'}}
                      >
                        <span className='mx-2'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#EE3043'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                            <path d='M9 8l6 8' />
                            <path d='M15 8l-6 8' />
                          </svg>
                        </span>
                        <span className='fw-semibold fs-7 '>Incomplete</span>
                      </span>
                    </>
                  )} */}
                     <span
                            className={`badge fs-7 ${
                              !vendor.due_diligence_status ||
                              vendor.due_diligence_status === 'Incomplete'
                                ? 'badge-danger'
                                : 'badge-primary'
                            }`}
                            style={{
                              color:
                                !vendor.due_diligence_status ||
                                vendor.due_diligence_status === 'Incomplete'
                                  ? '#EE3043'
                                  : '#1CBB8D',
                              background:
                                !vendor.due_diligence_status ||
                                vendor.due_diligence_status === 'Incomplete'
                                  ? '#FFF5F8'
                                  : '#E8F8F4',
                            }}
                          >
                            {vendor.due_diligence_status ? vendor.due_diligence_status : 'Incomplete'}
                          </span>
                  <div className='fs-6  fw-semibold my-4' style={{color: '#5A5A5A'}}>
                    Evaluate your vendor’s security posture and practices by uploading documents
                    list SOC 2 report, ISO report, etc. <strong>Note:</strong> Due Diligence is
                    optional for this vendor as it is a medium risk level vendor.
                  </div>

                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid my-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='skip'
                      checked={skip}
                      onChange={handleSkipNextDiligence}
                      disabled={currentUser?.role === 'Auditor'}
                    />
                    <label className='fw-semibold mx-4 fs-6'>Skip next due diligence</label>
                  </div>

                  {!skip &&
                    (vendor.diligence &&
                    vendor.due_diligence_status === 'Complete' &&
                    vendor.diligence.length > 0 ? (
                      <>
                        <div>
                          <div className='fs-6 fw-bold'>Next due diligence on</div>
                          <input
                            placeholder='Due Date'
                            className='form-control  mb-3 mb-lg-0'
                            type='date'
                            name='dueDiligenceDate'
                            autoComplete='off'
                            value={dueDiligenceDate}
                            onChange={handleDateChange}
                            disabled={currentUser?.role === 'Auditor'}
                          />
                          <div className='fs-7' style={{color: '#828282'}}>
                            High risk level vendor requires due diligence every 12 months. The above
                            date was set based on your previous due diligence date, you can always
                            change it based on your requirements.
                          </div>
                        </div>
                      </>
                    ) : (
                      <button
                        className='btn btn-sm fs-6'
                        onClick={handleOpenModal}
                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#148363')
                        }
                        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#1CBB8D')
                        }
                        style={{
                          backgroundColor: '#1CBB8D',
                          color: '#fff',
                          width: '170px',
                          height: '40px',
                          borderRadius: '4px',

                          transition: 'background-color 0.3s',
                        }}
                        disabled={currentUser?.role === 'Auditor'}
                      >
                        Start Due Diligence
                      </button>
                    ))}
                </div>
              </div>

              <div className='card mb-5 mb-xl-8 mt-8 '>
                <div className=' card-header text-grey-800 py-8  fw-bold fs-5'>Documents</div>
                <div className='card-body p-0 px-6'>
                  {vendor.diligence && vendor.diligence.length > 0 ? (
                    <>
                      {vendor.diligence.map((diligenceItem, index) => (
                        <>
                          <div
                            key={index}
                            className='d-flex justify-content-between mb-4 mt-4 py-2 ms-2'
                          >
                            <div className='d-flex align-items-center'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-file-text mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                                <path d='M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z' />
                                <path d='M9 9l1 0' />
                                <path d='M9 13l6 0' />
                                <path d='M9 17l6 0' />
                              </svg>
                              <div className='ms-2'>
                                <span className='fs-6 fw-bold'>
                                  <a
                                    href={diligenceItem.diligence_url}
                                    target='blank'
                                    className='text-dark text-hover-primary'
                                  >
                                    {diligenceItem.diligence_file_name}
                                  </a>
                                </span>
                                {/* <span className='d-block fs-7 fw-normal' style={{color: '#5A5A5A'}}>
              Uploaded by: {diligenceItem.uploadedBy}
            </span> */}
                              </div>
                            </div>
                            <div className='text-end'>
                              <div className='fs-6 fw-semibold' style={{color: '#5A5A5A'}}>
                                {diligenceItem.upload_time}
                              </div>
                            </div>
                          </div>
                          <div className='separator'></div>
                        </>
                      ))}
                    </>
                  ) : (
                    <div className='text-center mt-2 mb-8'>
                      <img src='/media/icon/document.svg' alt='' />
                      <div className='fs-6 mt-4' style={{color: '#5A5A5A'}}>
                        No documents added for this vendor
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
    </>
  )
}

export default Diligence
