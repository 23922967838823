import {useLocation} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon} from '_metronic/helpers'
import useCrispChat from 'app/Hooks/useCrispChat'
import {useEffect, useState} from 'react'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {doc, collection, onSnapshot} from 'firebase/firestore'
import db from 'services/firebase'

const SidebarFooter = () => {
  const location = useLocation()
  const {currentUser} = useAuth()
  const {toggleCrispChat} = useCrispChat()
  const [personnels, setPersonnels] = useState<any[]>([])
  const [daysLeft, setDaysLeft] = useState(7)

  const isDashboard =
    location.pathname === '/dashboard' ||
    location.pathname.startsWith('/policy-library') ||
    location.pathname.startsWith('/vendor-risk') ||
    location.pathname.startsWith('/personnel-management') ||
    location.pathname.startsWith('/data-room') ||
    location.pathname.startsWith('/asset-management') ||
    location.pathname.startsWith('/risk-management') ||
    location.pathname.startsWith('/integrations') ||
    location.pathname.startsWith('/reports') ||
    location.pathname.startsWith('/tests') ||
    location.pathname.startsWith('/controls') ||
    location.pathname.startsWith('/frameworks') ||
    location.pathname.startsWith('/employees')

  const isOnboarding =
    location.pathname.startsWith('/training') ||
    location.pathname === '/policies' ||
    location.pathname === '/backgroundCheck'

  useEffect(() => {
    const checkTrialStatus = async () => {
      if (!currentUser?.tenantId || !currentUser?.uid) {
        return
      }

      try {
        const functions = getFunctions()
        const checkUserTrialStatus = httpsCallable(functions, 'checkUserTrialStatus')

        const result = await checkUserTrialStatus({
          tenantId: currentUser.tenantId,
          userId: personnels[0] ? personnels[0].id : currentUser.uid,
        })

        const {daysLeft: remainingDays} = result.data as any
        setDaysLeft(remainingDays)
      } catch (error) {
        return
      }
    }

    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }

    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        uid: doc.data().uid,
        start_date: doc.data().start_date,
      }))
      const filter = risksData.filter((f) => f.uid === currentUser?.uid)
      setPersonnels(filter)
      if (filter.length > 0) {
        checkTrialStatus()
      }
    })

    // Initial check
    checkTrialStatus()

    // Set up periodic check (e.g., every hour)
    const intervalId = setInterval(checkTrialStatus, 60 * 60 * 1000)

    return () => {
      unsubscribe()
      clearInterval(intervalId)
    }
  }, [currentUser])

  return (
    <div className='app-sidebar-footer flex-column-auto px-6 mb-4' id='kt_app_sidebar_footer'>
      {isDashboard && currentUser?.account_type === 'Trial' && (
        <>
          <p className='p-0 m-0 mb-1 text-white fs-7 text-center'>
            <span className='text-primary overflow-hidden text-nowrap'>{daysLeft}</span> days left in your trial.
          </p>
          <button
            className='btn btn-sm btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 w-100'
            title='Upgrade'
            onClick={toggleCrispChat}
          >
            <span className='btn-label'>Upgrade</span>
            <KTIcon iconName='triangle' className='btn-icon fs-2 m-0' />
          </button>
        </>
      )}
      {isOnboarding && (currentUser?.role === 'Admin' || currentUser?.role === 'Owner') && (
        <a href='/dashboard'>
          <button
            className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Exit'
          >
            <span className='btn-label'>Exit Onboarding</span>
          </button>
        </a>
      )}
    </div>
  )
}

export {SidebarFooter}
