import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {SettingPageTitle} from '../components/header/SettingPageTitle'
import {AddAuditor} from './AddAuditor'
import {collection, onSnapshot, doc, deleteDoc} from '@firebase/firestore'
import {useAuth} from 'app/modules/auth'
import db from 'services/firebase'
import {KTIcon} from '_metronic/helpers'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'

const UserAuditors = () => {
  const [activeTab, setActiveTab] = useState('roles')
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [users, setUsers] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [activeFrameworks, setActiveFrameworks] = useState<string[]>([])

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('auditors')) {
      setActiveTab('auditors')
    } else if (location.pathname.includes('msp')) {
      setActiveTab('msp')
    } else {
      setActiveTab('users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getPersonnel()
  }, [])

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            role: doc.data().role,
          }))
          .filter((item) => item.role === 'Auditor')

        setUsers(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  useEffect(() => {
    getActiveFrameworks()
  }, [])

  const getActiveFrameworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting frameworks:', error)
    }
  }

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const closeDrawer = () => {
    setIsOpen(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase())
  }

  const filteredUsers = users.filter((user) => user.full_name.toLowerCase().includes(searchTerm))

  const handleDelete = async (item) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${item.full_name}`,
        text: `Are you sure you want to remove auditor ${item.full_name} from the organization? This action is permanent and cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const employeeRef = collection(tenantRef, 'employees')
        const docRef = doc(employeeRef, item.id)
        await deleteDoc(docRef)

        const userRef = collection(db, 'users')
        const docsRef = doc(userRef, item.uid)
        await deleteDoc(docsRef)

        toast.success(`${item.full_name} has been removed from the organization successfully!`, {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${item.full_name}" has been removed.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing an employee.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  return (
    <>
      <SettingPageTitle
        icon='Settings.svg'
        title='Company Settings'
        desc=' Manage your company settings'
        // breadcrumbs={usersBreadcrumbs}
      >
        Personnel Management
      </SettingPageTitle>
      <AddAuditor isOpen={isOpen} onClose={closeDrawer} />
      <div className={`card mb-5 mb-xl-8  `}>
        <div className='px-10 '>
          <div className='d-flex  h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
              <li className='nav-item'>
                <Link
                  to={`/company-settings/users`}
                  className={`nav-link me-6 ${
                    activeTab === 'users' ? 'text-active-primary active' : ''
                  }`}
                >
                  Your organization
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  to={'/company-settings/users/auditors'}
                  className={`nav-link me-6 ${
                    activeTab === 'auditors' ? 'text-active-primary active' : ''
                  }`}
                >
                  Auditors
                </Link>
              </li>

              {/* <li className='nav-item'>
                <Link
                  to={'/company-settings/users/msp'}
                  className={`nav-link me-6 ${
                    activeTab === 'msp' ? 'text-active-primary active' : ''
                  }`}
                >
                  Managed Service Providers
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className='card-body'>
          <div className='d-flex align-items-center justify-content-between position-relative my-1 mx-'>
            {/* Search Input Section */}
            <div className='d-flex align-items-center'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Auditors'
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <button
                type='button'
                className='btn btn-sm fs-6'
                onClick={toggleDrawer}
                style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                disabled={activeFrameworks.length <= 0}
              >
                Add Auditor
              </button>
            </div>
          </div>
          {filteredUsers.length > 0 ? (
            <div className='table-responsive' style={{height: '500px', overflowY: 'auto'}}>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                    <th className='min-w-300px '>NAME</th>
                    <th className='min-w-300px'>EMAIL</th>
                    <th className='min-w-200px '>ROLE</th>
                    <th className='min-w-200px '>AVAILABILITY</th>
                    <th className='min-w-100px '></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}

                <tbody>
                  {filteredUsers.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <div className='d-flex align-items-center  fs-6 fw-bold'>
                          {user.full_name}
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center fw-bold'>{user.email}</div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center fw-bold'>{user.role}</div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center fw-bold'>
                          <span
                            className='badge fs-7'
                            style={{
                              color: user.availability ? '#1CBB8D' : '#FFA500',
                              background: user.availability ? '#E8F8F4' : '#FFF5E5',
                            }}
                          >
                            {user.availability ? 'Open' : 'Engaged'}
                          </span>
                        </div>
                      </td>
                      <td className=''>
                        {/* <div className='dropdown mx-2'> */}
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                          onClick={(e) => handleDelete(user)}
                          disabled={currentUser?.role === 'Auditor'}
                        >
                          <KTIcon iconName='trash' className='fs-3 text-danger' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>

                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          ) : (
            <div className='p-3'>
              <div className='card mb-1 mb-xl-8 mt-4 bg-light-primary' style={{background: ''}}>
                <div className='card-header border-0 pt-5 mb-1'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <span className='card-label fw-bold fs-2'>No Auditor Found</span>
                      <p className='text-muted fs-7'>
                        Auditors have the capability to review your controls, including their
                        relevant supporting evidence like tests, documents, and policies, which are
                        tailored to the audited framework and restricted to the data within the
                        audit window. However, auditors will not have access to sensitive employee
                        data or the ability to alter user access privileges.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='row mx-5'>
                  <div className='form-group mb-5'>
                    <button
                      type='button'
                      className='btn btn-sm fs-6'
                      onClick={toggleDrawer}
                      style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      disabled={activeFrameworks.length <= 0}
                    >
                      Grant access to your auditor
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default UserAuditors
