import React, {useEffect, useState} from 'react'
import {PersonnelPageTitle} from '../../../../_metronic/layout/core/PersonnelPageData'
import {useAuth} from '../../../modules/auth'
import db from '../../../../services/firebase'
import {PageLink} from '../../../../_metronic/layout/core'
import {KTCard, KTIcon} from '../../../../_metronic/helpers'
import {AddPersonnelChecklistModal} from '../components/AddPersonnelChecklist'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  serverTimestamp,
} from 'firebase/firestore'
import Swal from 'sweetalert2'
import {EditPersonnelChecklistModal} from '../components/EditPersonnelChecklist'
import {format, parse, parseISO} from 'date-fns'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type UserData = {
  uid: string
  id: string
  fullName: string
  role: string
  tenantId: string
  policiesAccepted?: boolean
  checklist_name: string
  createdon: string
  file_url: string
}

interface Group {
  id: string
  checklist: string
  // Add any other properties of the group object here
}

const PersonnelChecklist = () => {
  const [users, setUsers] = useState<UserData[]>([])
  const [totalPersonnel, setTotalPersonnel] = useState(0)
  const {currentUser} = useAuth()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<UserData[]>([])
  const [checklistCounts, setChecklistCounts] = useState<{[key: string]: number}>({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState('')
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [totalChecklist, setTotalChecklist] = useState(0)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)

    // Clear the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    setFilteredItems(users)
  }, [users])

  useEffect(() => {
    getPersonnelChecklist()
  }, [])

  useEffect(() => {
    if (!currentUser?.tenantId) {
      return
    }

    const fetchGroups = async () => {
      const tenantId = currentUser.tenantId
      const tenantRef = doc(db, 'tenants', tenantId!)
      const policyRef = collection(tenantRef, 'personnel_group')

      const unsubscribe = onSnapshot(policyRef, (snapshot) => {
        const groupsData = snapshot.docs.map((doc) => ({
          ...(doc.data() as Group),
          id: doc.id,
        }))

        const groupCounts = groupsData.reduce((counts, group) => {
          const checklistName = group.checklist || '[Default]'
          counts[checklistName] = (counts[checklistName] || 0) + 1
          return counts
        }, {})

        setChecklistCounts(groupCounts)
      })

      // Cleanup subscription on unmount
      return () => unsubscribe()
    }

    fetchGroups()
  }, [currentUser?.tenantId])

  const getPersonnelChecklist = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'personnel_checklist')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...(doc.data() as UserData),
          id: doc.id,
        }))
        setUsers(risksData)
        setTotalPersonnel(risksData.length)
        setTotalChecklist(risksData.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      let date
      if (typeof timestamp === 'string') {
        if (timestamp.includes('-')) {
          date = parseISO(timestamp)
        } else if (timestamp.includes('/')) {
          const parts = timestamp.split('/')
          const month = +parts[0]
          const day = +parts[1]
          const year = +parts[2]
          date = new Date(year, month - 1, day)
        }
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000)
      } else {
        date = new Date(timestamp)
      }

      if (isNaN(date.getTime())) {
        return 'Invalid Timestamp'
      }

      return format(date, 'd MMMM, yyyy')
    }
    return '--'
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(users)
      setSearchLength(false)
    } else {
      const filteredItems = users.filter(
        (user) => user.checklist_name && user.checklist_name.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)))
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(e.target.checked)
    if (e.target.checked) {
      setCheckedItems(filteredItems.map((item) => item.id))
    } else {
      setCheckedItems([])
    }
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleCheckedRemoveClick = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const policyRef = collection(tenantRef, 'personnel_checklist')

      // Get all the checklist documents
      const querySnapshot = await getDocs(policyRef)

      // Filter out the "Default" checklist
      const documentsToDelete = querySnapshot.docs.filter(
        (doc) => doc.data().checklistName !== '[Default]'
      )

      if (documentsToDelete.length > 0) {
        const result = await Swal.fire({
          icon: 'warning',
          title: `Remove all Checklist except "Default"?`,
          text: `Are you sure you want to remove all checklists from the organization? This action cannot be undone.`,
          showCancelButton: true,
          confirmButtonText: 'Yes, Remove',
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-secondary',
          },
          buttonsStyling: false,
        })

        if (result.isConfirmed) {
          // User confirmed, proceed with the deletion
          const deletePromises = documentsToDelete.map((doc) => deleteDoc(doc.ref))
          await Promise.all(deletePromises)

          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: `All checklists except "Default" have been deleted.`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
          setCheckedItems([])
        }
      } else {
        Swal.fire({
          icon: 'info',
          title: 'No checklists to delete',
          text: 'There are no checklists to delete except the "Default" checklist.',
          customClass: {
            confirmButton: 'btn btn-info',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting checklist:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: (error as Error).message || 'An error occurred while deleting the checklist.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }
  const handleDeleteClick = async (item: UserData) => {
    try {
      if (totalChecklist <= 1) {
        throw new Error('Cannot delete the last checklist. At least one checklist must remain.')
      }
      const result = await Swal.fire({
        icon: 'warning',
        title: `Delete checklist '${item?.checklist_name}' ?`,
        text: `Are you sure you want to delete the checklist '${item?.checklist_name}'? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // User confirmed, proceed with the deletion
        // await personnelChecklistServices.deletePersonnel(item.id)
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'personnel_checklist')
        const docRef = doc(policyRef, item.id)
        await deleteDoc(docRef)

        const currentDate = new Date() // No need to import the Firestore module
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1 // Note: The month is zero-based
        const day = currentDate.getDate()

        const todaysDate = month + '/' + day + '/' + year

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `Checklist '${item?.checklist_name}' has been deleted by ${currentUser?.userName}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          // PersonnelManagement: fullName,
          action: 'Checklist deleted',
          target: 'Employees',
          date: todaysDate,
        })
        // getPolicy()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${item?.checklist_name}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting policy:', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: (error as Error).message || 'An error occurred while deleting the policy.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }
  const handleEditClick = (id) => {
    // console.log('Edit button clicked for item with id: ', id)
    setSelectedData(id)
    setIsModalOpen(true)
  }

  return (
    <>
      <PersonnelPageTitle
        icon='Icon.svg'
        title='Employees'
        // desc={totalPersonnel.toString() + ' Checklist'}
        desc='Manage your employees to ensure compliance and simplify audits'
        breadcrumbs={usersBreadcrumbs}
      >
        Personnel Management
      </PersonnelPageTitle>
      <EditPersonnelChecklistModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        id={selectedData}
      />
      <KTCard>
        <div>
          <div className='card-header border-0 pt-6'>
            <div className='card-title'>
              {/* begin::Search */}
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control  w-250px ps-14'
                  placeholder='Search checklist..'
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>

              {/* end::Search */}
            </div>
            <div className='card-toolbar'>
              {/* begin::Group actions */}
              {checkedItems.length > 0 ? (
                <>
                  {checkedItems.length > 0 ? (
                    <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                      <span>
                        <p className='pt-4'>{checkedItems.length} Selected</p>
                      </span>
                    </div>
                  ) : null}

                  <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                    <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                  </div>

                  <div
                    className='m-1'
                    style={{cursor: 'pointer'}}
                    onClick={handleCheckedRemoveClick}
                  >
                    <button
                      type='button'
                      className='btn btn-primary'
                      // data-bs-toggle='modal'
                      data-bs-target='#kt_add_personnel'
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                      Remove Checklist
                    </button>
                  </div>
                </>
              ) : (
                <AddPersonnelChecklistModal />
              )}

              {/* end::Group actions */}
            </div>
          </div>
          {/* begin::Body */}
          <div className='card-body py-3 '>
            {/* begin::Table container */}
            <div className='table-responsive ' style={{height: '55vh', overflowY: 'auto'}}>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 '>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value='selectAll'
                          data-kt-check='true'
                          data-kt-check-target='.widget-9-check'
                          onChange={handleSelectAllChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-300px'>CHECKLIST</th>
                    <th className='min-w-150px'>NO. OF CHECKLISTS</th>
                    <th className='min-w-150px'>CREATED ON</th>
                    <th className='min-w-150px'>CREATED BY</th>
                    <th className='min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}

                {filteredItems && filteredItems.length > 0 ? (
                  filteredItems
                    .filter((user) => user.tenantId === currentUser?.tenantId)
                    .map((item) => (
                      <tbody>
                        <tr>
                          <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input widget-9-check'
                                type='checkbox'
                                value={item.id}
                                checked={selectAll || checkedItems.includes(item.id)}
                                onChange={handleCheckChange}
                              />
                            </div>
                          </td>
                          {/* <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={item.avatarUrl} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.fullName}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {item.role}
                          </span>
                        </div>
                      </div>
                    </td> */}

                          <td>
                            <div className='d-flex align-items-center'>
                              {/* <div className='symbol symbol-40px me-5 '>
                                <img src='/media/avatars/300-3.jpg' alt='' />
                              </div> */}
                              <div className='d-flex align-items-center fw-bold'>
                                <div className='symbol symbol-20px me-15'>
                                  <div className=' fs-6'>
                                    <div>{item.checklist_name}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center justify-content-center fw-bold'>
                              <div className='symbol symbol-20px me-15'>
                                <div className=' fs-6'>
                                  <div>{checklistCounts[item.checklist_name] || '-'}</div>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className='d-flex align-items-center fw-bold'>
                              <div
                                className='symbol symbol-20px me-15'
                              >
                                <div className=' fs-6'>
                                  <div>
                                    {item.createdon ? formatTimestamp(item.createdon) : '--'}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center fw-bold'>
                              <div className='symbol symbol-20px me-15'>
                                <div className=' fs-6'>
                                  <div>{currentUser?.userName}</div>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className=''>
                            <div className='dropdown mx-2 '>
                              <button
                                className='btn btn-secondary border-0 p-0 m-2'
                                type='button'
                                id='dropdownMenu2'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <img
                                  src='/media/buttons/Options.svg'
                                  alt='Options'
                                  onClick={toggleDropdown}
                                />
                              </button>
                              <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                                <li>
                                  <button
                                    className='dropdown-item text-muted text-hover-primary'
                                    type='button'
                                    onClick={() => {
                                      handleEditClick(item.id)
                                    }}
                                    disabled={currentUser?.role === 'Auditor'}
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='18'
                                      height='18'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      stroke='currentColor'
                                      stroke-width='2'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'
                                      className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-2 mb-1'
                                    >
                                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                      <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                      <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                      <path d='M16 5l3 3' />
                                    </svg>
                                    Edit Checklist
                                  </button>
                                </li>

                                <li>
                                  <button
                                    className='dropdown-item '
                                    type='button'
                                    style={{color: '#F15363'}}
                                    onClick={(e) => handleDeleteClick(item)}
                                    disabled={currentUser?.role === 'Auditor'}
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='18'
                                      height='18'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      stroke='currentColor'
                                      stroke-width='2'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'
                                      className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                    >
                                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                      <path d='M4 7l16 0' />
                                      <path d='M10 11l0 6' />
                                      <path d='M14 11l0 6' />
                                      <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                      <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                    </svg>{' '}
                                    Delete Checklist
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ))
                ) : (
                  <tbody>
                    <tr style={{height: '100%'}}>
                      {searchLength ? (
                        <td colSpan={6} className='text-center'>
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No personnel Found</p>
                            </div>
                            <p className=''>
                              We couldn't find any checklist that match your search. Maybe try a
                              different search!
                            </p>
                          </div>
                        </td>
                      ) : (
                        <td colSpan={6} className='text-center'>
                          {showEmptyState && (
                            <div className='m-20'>
                              <img src='/media/buttons/emptystate.svg' alt='' />
                              <div className='m-5 fs-5'>
                                <p style={{fontWeight: 'bold'}}>No personnel Added</p>
                              </div>
                              <p>All checklist are required to adhere to.</p>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  </tbody>
                )}

                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      </KTCard>
    </>
  )
}

export default PersonnelChecklist
