import {useState} from 'react'
import {storage} from '../../../../../services/firebase'
import db from '../../../../../services/firebase'
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {addDoc, collection, updateDoc} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import {toast} from 'react-toastify'
const DataRoomToolbar = ({
  onHeaderAction,
  subFolderClicked,
  folderPath,
  folderId,
  fileFolderCreated,
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const {currentUser} = useAuth()

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const f = e.target.files[0]
      if (f) {
        handleUpload(f)
      }
    }
  }
  const handleCustomUpload = () => {
    const hiddenFileInput: HTMLElement | null = document.getElementById('hiddenFileInput')
    hiddenFileInput?.click()
  }

  const handleUpload = async (file: File) => {
    if (file || folderId) {
      const storageRef = ref(storage, `${folderPath}/${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, file)
      setIsUploading(true)
      uploadTask.on(
        'state_changed',
        (snapshot) => {},
        (error) => {
          setIsUploading(false)
        },
        async () => {
          // Get the download URL of the uploaded file
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
          const currentDate = new Date()
          const year = currentDate.getFullYear()
          const month = String(currentDate.getMonth() + 1).padStart(2, '0')
          const day = String(currentDate.getDate()).padStart(2, '0')

          const formattedDate = `${year}-${month}-${day}`
          // Generate a unique document ID
          const tenantId = currentUser?.tenantId || ''
          const fileCollectionRef = collection(db, 'tenants', tenantId, 'dataroom')
          const newFile = {
            subFolder: `${folderPath}${file.name}`,
            parentFolder:folderId ? folderId : '',
            name: file.name,
            fileSize: file.size,
            contentType: file.type,
            downloadUrl: downloadURL,
            uploadedBy: currentUser?.userName, 
            time_created: formattedDate,
            tenantId: currentUser?.tenantId,
            uid: currentUser?.uid,
            is_folder: false,
          }
          const newDocRef = await addDoc(fileCollectionRef, newFile)
          if (folderPath === `/tenants/${currentUser?.tenantId}/dataroom/`) {
            await updateDoc(newDocRef, {fileId: newDocRef.id, is_file: true})
          } else {
            await updateDoc(newDocRef, {fileId: newDocRef.id})
          }
          await updateDoc(newDocRef, {fileId: newDocRef.id})
          toast.success('file uploaded successfully', {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              border: '1px solid #e0e0e0',
              color: 'black',
            },
            progressStyle: {
              background: '#1CBB8D',
            },
            icon: ({theme, type}) => (
              <svg
                width='26'
                height='26'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{color: '#1CBB8D'}}
              >
                <path
                  d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                  fill='currentColor'
                />
              </svg>
            ),
            autoClose: 3000,
          })

          fileFolderCreated(true)
          setIsUploading(false)
        }
      )
    } else {
      toast.error('Please select a file first.', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          color: 'black',
        },
        autoClose: 3000,
      })
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* begin::Export */}
      {!folderId ? (
        <button
          type='button'
          className=' btn btn-sm btn-light me-4 fs-6'
          onClick={onHeaderAction}
          style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
        
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#5A5A5A'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-folder-open me-1 mb-1'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M5 19l2.757 -7.351a1 1 0 0 1 .936 -.649h12.307a1 1 0 0 1 .986 1.164l-.996 5.211a2 2 0 0 1 -1.964 1.625h-14.026a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v2' />
          </svg>
          New Folder
        </button>
      ) : (
        <div></div>
      )}
      {/* end::Export */}
      {/* begin::Add user */}
      <input
        type='file'
        id='hiddenFileInput'
        onChange={handleChange}
        accept='.docx,.pdf,.jpg,.png,.xlsx,.csv' 
        style={{display: 'none'}}
      />
      <button
        type='button'
        disabled={isUploading}
        onClick={handleCustomUpload}
        className='btn btn-sm fs-6'
        style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#148363')
        }
        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
          (e.currentTarget.style.backgroundColor = '#1CBB8D')
        }
      >
        {isUploading ? (
          <>
            Uploading... <span className='spinner-border spinner-border-sm align-middle'></span>
          </>
        ) : (
          'Upload'
        )}
      </button>
    </div>
  )
}
export {DataRoomToolbar}
