import ExcelJS from 'exceljs'
import {collection, doc, getDocs, addDoc, updateDoc, getFirestore} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL, getMetadata, getStorage} from 'firebase/storage'
function stripHtmlTags(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, '')
}
export const ExportTest = async (
  currentUser,
  frameworks,
  auditFolderName,
  folderId,
  formattedDate,
  auditID
) => {
  const db = getFirestore()
  const storage = getStorage()
  const tenantId = currentUser?.tenantId
  const tenantRef = doc(db, 'tenants', tenantId!)
  const PolicyRef = collection(tenantRef, 'tests')
  const querySnapshot = await getDocs(PolicyRef)
  const frameworksArray = typeof frameworks === 'string' ? [frameworks] : frameworks
  const data = querySnapshot.docs.map((doc) => doc.data())

  const filteredItems = data.filter((test) => {
    // Check if the test has a framework property and is a string
    if (!test.framework || typeof test.framework !== 'string') {
      return false
    }

    const testFrameworks = test.framework.split(',').map((fw) => fw.trim())

    return frameworksArray.some((activeFramework) =>
      testFrameworks.some((testFramework) => testFramework === activeFramework)
    )
  })

  if (filteredItems.length > 0) {
    const fields = [
      {key: 'name', header: 'Name', width: 40},
      {key: 'description', header: 'Description', width: 50},
      {key: 'category', header: 'Category', width: 20},
      {key: 'type', header: 'Type', width: 15},
      {key: 'framework', header: 'Framework', width: 30},
      {key: 'status', header: 'Status', width: 15},
      {key: 'owner', header: 'Owner', width: 20},
      {key: 'timeframe', header: 'Timeframe', width: 20},
      {key: 'next_review_date', header: 'Next Review Date', width: 20},
    ]

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Tests')

    worksheet.columns = fields.map((field) => ({
      header: field.header,
      key: field.key,
      width: field.width,
    }))

    const headerRow = worksheet.getRow(1)
    headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FF000000'},
    }
    headerRow.height = 30
    headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    const activeFrameworksMap = new Set(
      (Array.isArray(frameworks) ? frameworks : [frameworks]).map((fw) => fw.toLowerCase())
    )

    filteredItems.forEach((row) => {
      row.height = 30
      const rowData = fields.reduce((acc, field) => {
        let value = row[field.key] || row[field.key.toLowerCase()] || ''

        if (field.key === 'description') {
          value = stripHtmlTags(value)
        }

        if (field.key === 'next_review_date' && value) {
          value = value.split('T')[0]
        }

        if (Array.isArray(value)) {
          acc[field.key] = value.join('; ')
        } else if (typeof value === 'object' && value !== null) {
          acc[field.key] = JSON.stringify(value).replace(/"/g, '""')
        } else {
          acc[field.key] = value.toString().replace(/"/g, '""').replace(/\n/g, ' ')
        }

        if (field.key === 'framework') {
          const frameworks = value.split(',').map((fw) => fw.trim().toLowerCase())
          acc[field.key] = frameworks
            .filter((fw) => activeFrameworksMap.has(fw))
            .map((fw) => fw.toUpperCase())
            .join(', ')
        }

        return acc
      }, {})

      const addedRow = worksheet.addRow(rowData)
      addedRow.height = 20
      addedRow.font = {size: 10}

      const statusCell = addedRow.getCell(6)
      if (statusCell.value === 'Pass') {
        statusCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'D9EAD3'},
        }
      } else if (statusCell.value === 'Fail') {
        statusCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'F4CCCC'},
        }
      }
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const fileName = `${formattedDate} - tests: ${frameworks}.xlsx`
    const storageRef = ref(storage, `tenants/${tenantId}/dataroom/${auditFolderName}/${fileName}`)
    await uploadBytes(storageRef, buffer)
    const metadata = await getMetadata(storageRef)
    const downloadURL = await getDownloadURL(storageRef)

    const dataRoomRef = collection(tenantRef, 'dataroom')

    const fileDoc = {
      createdAt: new Date().toISOString(),
      name: fileName,
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      downloadUrl: downloadURL,
      fileSize: metadata.size,
      subFolder: storageRef.fullPath,
      time_created: formattedDate,
      tenantId: tenantId,
      uid: currentUser?.uid,
      is_folder: false,
      uploadedBy: currentUser?.userName,
      parentFolder: folderId,
      framework: '',
      fileId: '',
    }
    const docRef = await addDoc(dataRoomRef, fileDoc)
    await updateDoc(docRef, {fileId: docRef.id})

    await updateAuditDocument(currentUser, auditID, filteredItems.length)
  } else {
    console.log('No data to export')
  }
}

const updateAuditDocument = async (currentUser, auditId, totalLength) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId || ''
  const tenantRef2 = doc(db, 'tenants', tenantId)
  const policyRef = doc(tenantRef2, 'audits', auditId)

  await updateDoc(policyRef, {TotalTests: totalLength})
}
