import axios from 'axios'

async function createLinearOAuthInstance(accessToken: string) {
  return axios.create({
    baseURL: 'https://api.linear.app/graphql',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
}

async function fetchTeamIdByName(accessToken: string, teamName: string) {
  const axiosInstance = await createLinearOAuthInstance(accessToken)

  const query = `
    query Teams {
      teams {
        nodes {
          id
          name
        }
      }
    }
  `

  try {
    const response = await axiosInstance.post('', {query})
    const teams = response.data.data.teams.nodes
    const foundTeam = teams.find((team) => team.name === teamName)

    if (foundTeam) {
      return foundTeam.id
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

async function findUserIdByEmail(accessToken: string, email: string) {
  const axiosInstance = await createLinearOAuthInstance(accessToken)

  const query = `
    query Users {
      users {
        nodes {
          id
          name
          email
        }
      }
    }
  `

  try {
    const response = await axiosInstance.post('', {query})
    const users = response.data.data.users.nodes

    const foundUser = users.find((user) => user.email.toLowerCase() === email.toLowerCase())

    if (foundUser) {
      return foundUser.id
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

async function fetchWorkflowStates(accessToken: string, teamId: string) {
  const axiosInstance = await createLinearOAuthInstance(accessToken)

  const query = `
    query {
      workflowStates(filter: { team: { id: { eq: "${teamId}" } } }) {
        nodes {
          id
          name
          type
        }
      }
    }
  `

  try {
    const response = await axiosInstance.post('', {query})
    const states = response.data.data.workflowStates.nodes

    const todoState = states.find((state) => state.type === 'unstarted')

    if (todoState) {
      return todoState.id
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

async function createTask(
  accessToken: string,
  teamName: string,
  title: string,
  description: string,
  priority: string,
  dueDate: string,
  assigneeEmail?: string
) {
  try {
    const teamId = await fetchTeamIdByName(accessToken, teamName)
    const todoStatusId = await fetchWorkflowStates(accessToken, teamId)

    if (!todoStatusId) {
      return null
    }

    let assigneeId = null
    if (assigneeEmail) {
      assigneeId = await findUserIdByEmail(accessToken, assigneeEmail)
    }

    const priorityMap = {
      Urgent: 1,
      High: 2,
      Medium: 3,
      Low: 4,
    }
    const priorityLevel = priorityMap[priority]

    const axiosInstance = await createLinearOAuthInstance(accessToken)

    const mutation = `
    mutation {
      issueCreate(input: { 
        teamId: "${teamId}",
        title: "${title}",
        description: "${description}",
        priority: ${priorityLevel}, 
        dueDate: "${dueDate}",
        stateId: "${todoStatusId}"
        ${assigneeId ? `, assigneeId: "${assigneeId}"` : ''}
      }) {
        success
        issue {
          id
          title
          description
          priority
          dueDate
          assignee {
            id
            name
            email
          }
          state {
            id
            name
          }
        }
      }
    }
  `

    const response = await axiosInstance.post('', {query: mutation})

    if (
      response.data.data &&
      response.data.data.issueCreate &&
      response.data.data.issueCreate.success
    ) {
      return response.data.data.issueCreate.issue
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

async function fetchTickets(accessToken: string, teamName: string) {
  try {
    const axiosInstance = await createLinearOAuthInstance(accessToken)

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
    const thirtyDaysAgoISO = thirtyDaysAgo.toISOString()

    const query = `
      query {
        issues(filter: { 
          team: { name: { eq: "${teamName}" } }, 
          createdAt: { gte: "${thirtyDaysAgoISO}" } 
        }) {
          nodes {
            id
            title
            assignee {
              id
              name
            }
            createdAt
            description
          }
        }
      }
    `

    const response = await axiosInstance.post('', {query})

    if (response.data.data && response.data.data.issues) {
      return response.data.data.issues.nodes
    } else {
      return []
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error creating tickets:', error.response?.data)
    } else {
      console.error('Error creating tickets:', (error as Error).message)
    }
    return []
  }
}

async function checkTickets(accessToken: string, teamName: string) {
  const tickets = await fetchTickets(accessToken, teamName)

  // for (const ticket of tickets) {
  //   if (!ticket.assignee) {
  //     // console.log(`Ticket "${ticket.title}" (ID: ${ticket.id}) does not have an assignee.`)
  //   } else {
  //     console.log(
  //       `Ticket "${ticket.title}" (ID: ${ticket.id}) is assigned to ${ticket.assignee.name}.`
  //     )
  //   }
  // }
}

const initiateLinearOAuth = () => {
  const clientId = '0600f8a0084e7869b746ceab1bf3e789'
  const redirectUri = encodeURIComponent('http://localhost:3011/integrations/available')
  const scope = encodeURIComponent('read,write')
  const state =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

  sessionStorage.setItem('oauth_state', state)
  const linearAuthUrl = `https://linear.app/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`
  localStorage.setItem('pendingIntegration', 'Linear')
  window.location.href = linearAuthUrl
}

export {checkTickets, createTask, initiateLinearOAuth, createLinearOAuthInstance}
