import {Route, Routes, Outlet, Navigate, useLocation, useParams} from 'react-router-dom'
import {PageLink} from '../../../_metronic/layout/core'
import {PolicyListWrapper} from './PolicyList'
import {useEffect, useState} from 'react'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {KTCard} from '../../../_metronic/helpers'
import {collection, doc, getDocs} from 'firebase/firestore'
import { PolicyPageTitle } from '_metronic/layout/core/PolicyPageData'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const PolicyPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='lists'
          element={
            <>
              <>
                <PolicyPageTitle
                  icon='Policies.svg'
                  title='Policy Library'
                  desc={
                    'Setup polices for your organization. You can upload your existing polices or use from templates and assign owner for approval. '
                  }
                  breadcrumbs={usersBreadcrumbs}
                >
                  Policy Management
                </PolicyPageTitle>

                <KTCard>
                  <PolicyListWrapper />
                </KTCard>
              </>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/policy-library/lists' />} />
    </Routes>
  )
}

export default PolicyPage
