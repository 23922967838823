import React, {useEffect, useRef, useState} from 'react'
import db from '../../../../services/firebase'
import {addDoc, collection, doc, serverTimestamp} from 'firebase/firestore'
import {useAuth} from '../../../modules/auth'
import Drawer from 'react-modern-drawer'
import Dropzone from 'react-dropzone'
import {ToastContainer, toast} from 'react-toastify'
import * as XLSX from 'xlsx'

export interface policy {
  msg: string
  message: string
  fileUrl?: any
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

interface ParsedData {
  [key: string]: string
}

const AddCSV: React.FC<Props> = ({isOpen, onClose}) => {
  const {currentUser} = useAuth()
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File[]>([])
  const drawerRef = useRef<HTMLDivElement>(null)
  const [uploadedFile, setUploadedFile] = useState<{name: string; size: number} | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleUpload = (acceptedFiles: File[]) => {
    const xlsxFile = acceptedFiles.find((file) => file.name.toLowerCase().endsWith('.xlsx'))

    if (xlsxFile) {
      setFile([xlsxFile])
      setUploadedFile({
        name: xlsxFile.name,
        size: xlsxFile.size,
      })
    }
  }

  const fieldMapping = {
    'Control Title': 'control_title',
    'Control Description': 'control_description',
  }
  const handleRemoveFile = () => {
    setUploadedFile(null)
  }

  const currentDate = new Date() // No need to import the Firestore module
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1 // Note: The month is zero-based
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    if (!file || file.length === 0) {
      setMessage({error: true, msg: 'Please select a excel file'})
      setShowAlert(true)
      setIsLoading(false)
      return
    }

    try {
      const xlsxFile = file[0]
      const fileReader = new FileReader()

      fileReader.onload = async (event) => {
        const arrayBuffer = event.target?.result as ArrayBuffer
        const workbook = XLSX.read(arrayBuffer, {type: 'array'})
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const data = XLSX.utils.sheet_to_json(worksheet, {header: 1})

        if (data.length <= 1) {
          toast.error('No data found in the XLSX file', {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              color: 'black',
            },
            autoClose: 3000,
          })
          setIsLoading(false)
          return
        }

        // Validate the headers
        const headers = data[0] as string[]
        const isValid = Object.keys(fieldMapping).every((header) => headers.includes(header))

        if (!isValid) {
          toast.error('Invalid headers in XLSX file', {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              color: 'black',
            },
            autoClose: 3000,
          })
          setIsLoading(false)
          return
        }

        // Process the rows and apply the mapping before sending to Firestore
        const parsedDataArray = data.slice(1).map((row) => {
          const parsedData: ParsedData = headers.reduce(
            (obj: ParsedData, header: string, index: number) => {
              const targetField = fieldMapping[header]
              if (targetField) {
                obj[targetField] = (row as string[])[index] || ''
              }
              return obj
            },
            {}
          )
          return parsedData
        })
        setIsLoading(true)
        // Process the parsed data - e.g., storing it in Firestore
        if (currentUser) {
          const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
          const controlsRef = collection(tenantRef, 'controls')

          for (const parsedData of parsedDataArray) {
            await addDoc(controlsRef, {
              ...parsedData,
              control_evidence_status: 'Unhealthy',
              author: 'Custom Control',
              uploadedBy: currentUser.uid,
              uploadedAt: serverTimestamp(),
            })
            setIsLoading(false)
            const activitiesRef = collection(tenantRef, 'activities')
            await addDoc(activitiesRef, {
              message: `${currentUser?.userName} uploaded a new control through csv '${uploadedFile?.name}'`,
              timestamp: serverTimestamp(),
              tenantId: currentUser?.tenantId,
              uid: currentUser?.uid,
              username: currentUser?.userName,
              date: todaysDate,
              email: currentUser?.email,
              target: 'Contols',
              action: 'Control added',
            })
            toast.success('Control added successfully', {
              position: toast.POSITION.TOP_CENTER,
              className: 'bg-white',
              style: {
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                border: '1px solid #e0e0e0',
                color: 'black',
              },
              progressStyle: {
                background: '#1CBB8D',
              },
              icon: ({theme, type}) => (
                <svg
                  width='26'
                  height='26'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  style={{color: '#1CBB8D'}}
                >
                  <path
                    d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                    fill='currentColor'
                  />
                </svg>
              ),
              autoClose: 3000,
            })
          }
        }
        setFile([])
        setUploadedFile(null)
        setIsLoading(false)
        onClose()
      }

      fileReader.onerror = (error) => {
        setMessage({error: true, msg: 'Error reading XLSX file'})
        setShowAlert(true)
      }

      fileReader.readAsArrayBuffer(xlsxFile)
    } catch (err) {
      console.error('Error in handleSubmit:', err)
      setMessage({error: true, msg: 'Error uploading CSV file'})
      setShowAlert(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {' '}
      <ToastContainer />
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Import Controls
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary'
              aria-label='Close'
              onClick={onClose}
              style={{
                transition: 'all 0.2s ease',
              }}
              onMouseEnter={(e) => {
                if (e.currentTarget) {
                  e.currentTarget.style.backgroundColor = '#fff'
                  const svgElement = e.currentTarget.querySelector('svg')
                  if (svgElement) {
                    svgElement.style.stroke = '#1CBB8D'
                  }
                }
              }}
              onMouseLeave={(e) => {
                if (e.currentTarget) {
                  e.currentTarget.style.backgroundColor = '#fff'
                  const svgElement = e.currentTarget.querySelector('svg')
                  if (svgElement) {
                    svgElement.style.stroke = '#5A5A5A'
                  }
                }
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-7'
          tabIndex={-1}
          style={{height: 'calc(100vh - 20px)'}}
        >
          <div className='fs-6 fw-semibold' style={{color: '#5A5A5A'}}>
            Please download this excel template for controls. Make sure you add all the required
            details and upload it here.
          </div>
          <div className='mt-4'>
            <div className={`card mb-4 mb-xl-8 mt-2`}>
              <div
                className='card-header border-0 pt-6 mb-5'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <img
                    src='/media/icon/file2.svg'
                    alt=''
                    className='img-fluid'
                    style={{width: '50px', height: '50px', marginRight: '10px'}} // Adjust marginRight as needed
                  />
                  <div className='d-flex justify-content-start flex-column fw-bold fs-6'>
                    {/* {file[0].name} */}
                    Control Template.xlsx
                    <span className=' fw-semibold d-block fs-7' style={{color: '#5A5A5A'}}>
                      9 KB
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    transition: 'all 0.2s ease',
                  }}
                  onMouseEnter={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#1CBB8D'
                      }
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#5A5A5A'
                      }
                    }
                  }}
                >
                  <a href='/templates/Control Template.xlsx' download>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5A5A5A'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-cloud-download mx-2 mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4' />
                      <path d='M12 13l0 9' />
                      <path d='M9 19l3 3l3 -3' />
                    </svg>
                    <span className='fw-semibold text-hover-primary' style={{color: '#5A5A5A'}}>
                      Download
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='form'>
            <div className='form-group mb-4'>
              <div className='main-container'>
                <div className='fw-bold fs-6 mb-2 mt-6'> Your Uploads</div>
                {uploadedFile ? (
                  <div className={`card mb-4 mb-xl-8 mt-4`}>
                    <div
                      className='card-header border-0 pt-6 mb-5'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                          src='/media/icon/file2.svg'
                          alt=''
                          className='img-fluid'
                          style={{width: '50px', height: '50px', marginRight: '10px'}}
                        />
                        <div className='d-flex justify-content-start flex-column fw-bold fs-6'>
                          {uploadedFile.name}
                          <span className='fw-semibold d-block fs-6' style={{color: '#5A5A5A'}}>
                            Size: {(uploadedFile.size / 1024).toFixed(2)} KB
                          </span>
                        </div>
                      </div>
                      <div>
                        {' '}
                        <span className='mx-1 fs-7'>Uploaded</span>{' '}
                        <span className='btn btn-icon btn-sm btn-active-light-primary'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='#5A5A5A'
                            className='icon icon-tabler icons-tabler-filled icon-tabler-circle-x'
                            onClick={handleRemoveFile}
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z' />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Dropzone
                    onDrop={handleUpload}
                    minSize={0}
                    accept={{
                      'text/csv': [],
                      'application/vnd.ms-excel': [],
                      'application/csv': [],
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                      '': ['.csv', '.xlsx', '.xls'],
                    }}
                    maxSize={5 * 1024 * 1024}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps({
                          className: 'dropzone',
                          style: {
                            backgroundColor: '#fff', // Change this to your desired color
                            padding: '20px',
                            border: '2px dashed #ccc',
                            borderRadius: '4px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          },
                        })}
                      >
                        <input {...getInputProps()} />
                        <img
                          src='/media/icon/uploadFile.svg'
                          alt=''
                          loading='lazy'
                          className='mb-4 mt-4'
                        />
                        <p className='fw-bold fs-6'>
                          Drag and drop or <a href='#'>select</a> a files
                        </p>
                        <p className='fs-6'>Upload upto 5 mb. Supported formats: xlsx</p>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className='offcanvas-footer d-flex justify-content-end  border-top pt-4 pb-4 px-6'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <button type='button' className='btn btn-light btn-sm mx-2 ' onClick={onClose}>
            Cancel
          </button>
          {isLoading ? (
            <button
              type='submit'
              value='Submit'
              className='btn btn-sm '
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                height: '40px',
                borderRadius: '4px',
                transition: 'background-color 0.3s',
              }}
              disabled
            >
              Adding control...
              <span className='spinner-border spinner-border-sm align-middle m-2'></span>
            </button>
          ) : (
            <button
              className='btn btn-sm '
              onClick={handleSubmit}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              disabled={uploadedFile === null}
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}
            >
              Add control
            </button>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default AddCSV
