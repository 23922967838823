import {collection, doc, getDocs, getFirestore} from 'firebase/firestore'
import {useListView} from '../../../vendor/core/ListViewProvider'
import {AddPersonnelModal} from '../AddPersonnelModal'
import {saveAs} from 'file-saver'
import {useAuth} from '../../../../modules/auth'
import ExcelJS from 'exceljs'
import {toast} from 'react-toastify'
import {KTIcon} from '_metronic/helpers'
import {useState} from 'react'

const PersonnelListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const {currentUser} = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  const exportData = async () => {
    const db = getFirestore()
    const tenantId = currentUser?.tenantId
    const tenantRef = doc(db, 'tenants', tenantId!)
    const PolicyRef = collection(tenantRef, 'employees')
    const querySnapshot = await getDocs(PolicyRef)

    const data = querySnapshot.docs.map((doc) => doc.data()).filter((data)=>data.role !== 'Auditor')

    if (data.length > 0) {
      const fields = [
        {key: 'full_name', header: 'Full Name', width: 30},
        {key: 'email', header: 'Email', width: 30},
        {key: 'role', header: 'Role', width: 15},
        {key: 'group', header: 'Group', width: 20},
        {key: 'start_date', header: 'Start Date', width: 20},
        {key: 'end_date', header: 'End Date', width: 20},
        {key: 'onboarding', header: 'Onboarding', width: 20},
        {key: 'onboardingEnded', header: 'Onboarding Completed Date', width: 30},
      ]

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Employees')

      worksheet.columns = fields.map((field) => ({
        header: field.header,
        key: field.key,
        width: field.width,
      }))

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
      worksheet.views = [{state: 'frozen', ySplit: 1}]

      data.forEach((row) => {
        const rowData = fields.reduce((acc, field) => {
          let value = row[field.key] || row[field.key.toLowerCase()] || ''

          if (field.key === 'onboarding') {
            if (value === '') {
              value = 'Pending'
            }
            acc[field.key] = value
          } else {
            acc[field.key] = value
          }

          return acc
        }, {})

        const addedRow = worksheet.addRow(rowData)
        addedRow.height = 20
        addedRow.font = {size: 10}

        const onboardingCell = addedRow.getCell('onboarding')
        if (onboardingCell.value === 'Completed') {
          onboardingCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        } else if (onboardingCell.value === 'Pending') {
          onboardingCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFF2CC'},
          }
        } else {
          onboardingCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        }

        if (row['end_date']) {
          addedRow.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'F4CCCC'},
            }
          })
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})
      saveAs(excelBlob, 'employees.xlsx')

      toast.success('Employees data exported successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    } else {
      console.log('No data to export')
    }
  }

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const closeDrawer = () => {
    setIsOpen(false)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <AddPersonnelModal isOpen={isOpen} onClose={closeDrawer} />
      {currentUser?.role !== 'Auditor' && (
        <button
          type='button'
          className='btn btn-sm fs-6'
          onClick={toggleDrawer}
          style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
        >
          <KTIcon iconName='plus' className='fs-2 text-white' />
          Add Employee
        </button>
      )}
      {/* end::Add user */}
    </div>
  )
}

export {PersonnelListToolbar}
