import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useAuth} from '../../../modules/auth'
import {UpdateVendor} from './UpdateVendor'
import {Helmet} from 'react-helmet'
const ViewVendor = () => {
  var {id} = useParams()
  const [vendors, setVendors] = useState([] as any[])
  const {currentUser} = useAuth()
  const [activeTab, setActiveTab] = useState('overview')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [vendorID, setVendorID] = useState('')

  useEffect(() => {
    getVendors()
  }, [])

  const handleTabClick = (tab) => {
    setActiveTab(tab)
    // Additional logic if needed when a tab is clicked
  }

  const getVendors = async () => {
    try {
      const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'vendors')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setVendors(controlsData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getBadgeClass = (risk) => {
    if (risk === 'Low') {
      return {backgroundColor: '#1CBB8D'} // Low risk (primary blue)
    } else if (risk === 'Medium') {
      return {backgroundColor: '#ffc107'} // Medium risk (warning yellow)
    } else if (risk === 'High') {
      return {backgroundColor: '#dc3545'} // High risk (danger red)
    }
  }

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    setVendorID(id)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Vendor Risk</title>
      </Helmet>
      {vendors.length > 0 ? (
        vendors
          .filter((vendor) => vendor.id === id)
          .map((vendor) => (
            <React.Fragment key={vendor.id}>
              <div className='card px-6 pt-6 mb-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px me-5 '>
                    {vendor.logo && vendor.logo.length > 0 ? (
                      <img src={toAbsoluteUrl(vendor.logo[0]?.url)} alt='' loading='lazy' />
                    ) : (
                      <img src={toAbsoluteUrl(vendor.logoUrl)} alt='' loading='lazy' />
                    )}
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='card-label fw-bold fs-5'>{vendor.vendor_name}</span>
                    <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                      {vendor.category}
                    </span>
                  </div>
                </div>

                <div className='px-2  mt-4 '>
                  <div className='d-flex  h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
                      <li className='nav-item'>
                        <Link
                          to={`/vendor-risk/details/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'overview' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('overview')}
                        >
                          Overview
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link
                          to={`/vendor-risk/details/diligence/${id}`}
                          className={`nav-link me-6 ${
                            activeTab === 'due' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('due')}
                        >
                          Due Diligence
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <>
                <div className='card mb-5 mb-xl-8 mt-8 '>
                  <div className=' card-header text-grey-800  fw-bold fs-5 d-flex justify-content-between align-items-center'>
                    <div>Main information</div>
                    {currentUser?.role !== 'Auditor' && (
                      <button
                        className='btn'
                        style={{color: '#1CBB8D'}}
                        onClick={() => handleOpenModal(vendor.id)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#1CBB8D'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                          <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                          <path d='M16 5l3 3' />
                        </svg>{' '}
                        Edit
                      </button>
                    )}
                  </div>
                  <div className='card-body'>
                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>VENDOR NAME</label>
                      </div>
                      <div className='col-9'>
                        <span className='text-gray-800 fw-semibold fs-6'>{vendor.vendor_name}</span>
                      </div>
                    </div>
                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>WEBSITE</label>
                      </div>
                      <div className='col-9'>
                        <span className='text-gray-800 fw-semibold fs-6'>{vendor.website_url}</span>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>CATEGORY</label>
                      </div>
                      <div className='col-9'>
                        <span className='text-gray-800 fw-semibold fs-6'>
                          {vendor.category ? vendor.category : '--'}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>
                          PRIVACY POLICY URL
                        </label>
                      </div>
                      <div className='col-9'>
                        <span className='text-gray-800 fw-semibold fs-6'>{vendor.policy_url}</span>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>
                          TERMS & CONDITIONS URL
                        </label>
                      </div>
                      <div className='col-9'>
                        <span className='text-gray-800 fw-semibold fs-6'>{vendor.terms_url}</span>
                      </div>
                    </div>

                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>OWNER</label>
                      </div>
                      <div className='col-9'>
                        <div className='d-flex align-items-center'>
                          {vendor.owner ? (
                            <>
                              <div className='symbol symbol-25px me-2'>
                                <span
                                  className='symbol-label fs-7 fw-bold'
                                  style={{backgroundColor: '#DAF9EF'}}
                                >
                                  {(() => {
                                    const nameParts = vendor.owner.split(' ')
                                    if (nameParts.length > 1) {
                                      return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1]
                                        .charAt(0)
                                        .toUpperCase()}`
                                    } else {
                                      return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[0]
                                        .charAt(1)
                                        .toUpperCase()}`
                                    }
                                  })()}
                                </span>
                              </div>
                              <a href='#' className='text-dark fw-semibold text-hover-primary fs-6'>
                                {vendor.owner}
                              </a>
                            </>
                          ) : (
                            <span>--</span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>
                          AUTHENTICATION TYPE
                        </label>
                      </div>
                      <div className='col-9'>
                        <span className='text-gray-800 fw-semibold fs-6'>
                          {vendor.authentication_type ? vendor.authentication_type : '--'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card mb-5 mb-xl-8 mt-8 '>
                  <div className=' card-header text-grey-800  fw-bold fs-5 d-flex justify-content-between align-items-center'>
                    <div> Impact Assessment</div>
                    {currentUser?.role !== 'Auditor' && (
                      <button
                        className='btn'
                        style={{color: '#1CBB8D'}}
                        onClick={() => handleOpenModal(vendor.id)}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#1CBB8D'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                          <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                          <path d='M16 5l3 3' />
                        </svg>{' '}
                        Edit
                      </button>
                    )}
                  </div>
                  <div className='card-body'>
                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>DATA ACCESSED</label>
                      </div>
                      <div className='col-9'>
                        <span className='text-gray-800 fw-semibold fs-6'>
                          {vendor.impact_assessment ? vendor.impact_assessment.join(', ') : '--'}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-5'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>
                          OPERATIONAL IMPACT
                        </label>
                      </div>
                      <div className='col-9'>
                        <div className='d-flex align-items-center'>
                          {vendor.reliance ? (
                            <span
                              className={`badge fs-7 ${
                                vendor.reliance === 'High'
                                  ? 'badge-danger'
                                  : vendor.reliance === 'Medium'
                                  ? 'badge-warning'
                                  : 'badge-primary'
                              }`}
                              style={{
                                color:
                                  vendor.reliance === 'High'
                                    ? '#EE3043'
                                    : vendor.reliance === 'Medium'
                                    ? '#FFA800'
                                    : '#1CBB8D',
                                background:
                                  vendor.reliance === 'High'
                                    ? '#FFF5F8'
                                    : vendor.reliance === 'Medium'
                                    ? '#FFF8DD'
                                    : '#E8F8F4',
                              }}
                            >
                              {vendor.reliance}
                            </span>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-3'>
                        <label className='text-gray-800 fw-block fw-bold fs-7'>RISK LEVEL</label>
                      </div>
                      <div className='col-9'>
                        <div className='d-flex align-items-center'>
                          {vendor.risk_level ? (
                            <span
                              className={`badge fs-7 ${
                                vendor.risk_level === 'High'
                                  ? 'badge-danger'
                                  : vendor.risk_level === 'Medium'
                                  ? 'badge-warning'
                                  : 'badge-primary'
                              }`}
                              style={{
                                color:
                                  vendor.risk_level === 'High'
                                    ? '#EE3043'
                                    : vendor.risk_level === 'Medium'
                                    ? '#FFA800'
                                    : '#1CBB8D',
                                background:
                                  vendor.risk_level === 'High'
                                    ? '#FFF5F8'
                                    : vendor.risk_level === 'Medium'
                                    ? '#FFF8DD'
                                    : '#E8F8F4',
                              }}
                            >
                              {vendor.risk_level}
                            </span>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </React.Fragment>
          ))
      ) : (
        <>
          <div style={{height: '80vh', display: 'flex', flexDirection: 'column'}}>
            {' '}
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='spinner-border text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}
      <UpdateVendor id={vendorID} isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  )
}

export default ViewVendor
