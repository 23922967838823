import {collection, doc, getDocs, getFirestore, Timestamp} from 'firebase/firestore'
import {useListView} from '../../../vendor/core/ListViewProvider'
import {AddRiskModal} from '../AddRiskModal'
import {saveAs} from 'file-saver'
import {useAuth} from '../../../../modules/auth'
import ExcelJS from 'exceljs'
import { toast } from 'react-toastify'

const RiskListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const {currentUser} = useAuth()

  const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}/${month}/${day}`
  }

  const exportData = async () => {
    const db = getFirestore()
    const tenantId = currentUser?.tenantId
    const policyRef = collection(doc(db, 'tenants', tenantId!), 'risks')
    const querySnapshot = await getDocs(policyRef)

    const data = querySnapshot.docs.map((doc) => doc.data())

    if (data.length > 0) {
      const fields = [
        {key: 'riskSource', header: 'Risk Name', width: 50},
        {key: 'description', header: 'Description', width: 40},
        {key: 'category', header: 'Category', width: 40},
        {key: 'owner', header: 'Risk Owner', width: 20},
        {key: 'CIA', header: 'CIA', width: 30},
        {key: 'inherent_likelihood', header: 'Inherent Likelihood', width: 15},
        {key: 'inherent_impact', header: 'Inherent Impact', width: 15},
        {key: 'residual_impact', header: 'Residual Impact', width: 15},
        {key: 'residual_likelihood', header: 'Residual Likelihood', width: 15},
        {key: 'treatment', header: 'Treatment', width: 15},
        {key: 'risk_status', header: 'Risk Status', width: 15},
        {key: 'control_title', header: 'Mapped Controls', width: 30},
      ]

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Risks')

      worksheet.columns = fields.map((field) => ({
        header: field.header,
        key: field.key,
        width: field.width,
      }))

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center',vertical:'middle'}
      worksheet.views = [{ state: 'frozen', ySplit: 1 }];

      data.forEach((row) => {
        const rowData = fields.reduce((acc, field) => {
          if (field.key === 'CIA') {
            acc[field.key] = (row[field.key] || []).join(', ')
          }else if (field.key === 'category') {
            acc[field.key] = (row[field.key] || []).join(', ')
          } else if (field.key === 'control_title') {
            acc[field.key] = (row.added_controls || [])
              .map((control) => control.control_title)
              .join(', ')
          } else if (field.key === 'updated_at') {
            const timestamp =
              row[field.key] instanceof Timestamp ? row[field.key].toDate() : row[field.key]
            acc[field.key] = timestamp ? formatDate(timestamp) : ''
          } else {
            acc[field.key] = row[field.key] || ''
          }
          return acc
        }, {})

        const addedRow = worksheet.addRow(rowData)
        addedRow.height = 20
        addedRow.font={size:10}

        const riskStatusCell = addedRow.getCell('risk_status')
        switch (riskStatusCell.value) {
          case 'Incomplete':
            riskStatusCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'F4CCCC'}, 
            }
            break
          case 'Needs approval':
            riskStatusCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'FFF2CC'},
            }
            break
          case 'Approved':
            riskStatusCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'D9EAD3'},
            }
            break

          default:
            break
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})
      saveAs(excelBlob, 'risks.xlsx')

      toast.success('Risk data exported successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <VendorListFilter /> */}

      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add user */}
      <AddRiskModal />
      {/* end::Add user */}
    </div>
  )
}

export {RiskListToolbar}
