/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ReportPageTitle} from './PageTitle/ReportPageTitle'
import {useAuth} from '../../../modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {useParams} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {ViewControl} from '../../controls/components/ViewControl'
import {Helmet} from 'react-helmet'
import {TestListLoading} from '../../tests/components/loading/TestListLoading'
import {saveAs} from 'file-saver'
import ExcelJS from 'exceljs'
import {toast, ToastContainer} from 'react-toastify'
import useCrispChat from 'app/Hooks/useCrispChat'
import UpgradePlan from 'app/pages/controls/components/submodal/UpgradePlan'

interface Control {
  id: string
  control_category: string
  control_description: string
  control_document: string
  control_evidence_status: string
  control_framework_mappings: string
  control_id: string
  control_owner: string
  control_test: string
  control_title: string
  control_uid: string
  document_id: string[]
  test_id: string[]
  deactivation_control: string
}

const getCodeFromFrameworkMapping = (mapping: string) => {
  // Extract the code portion, e.g., "C.4.1" from "ISO 27001:2022 ‧ C.4.1"
  const parts = mapping.split('‧')
  return parts.length > 1 ? parts[1].trim() : ''
}

const getMainCode = (mapping: string) => {
  const parts = mapping.split('‧')
  if (parts.length > 1) {
    return parts[1].trim().split('.').slice(0, 2).join('.') // Return full code, e.g., "A.4"
  }
  return ''
}

// Function to get the letter prefix
const getLetterPrefix = (code: string) => {
  return code.match(/[A-Z]/)?.[0] || ''
}

const ViewReports = () => {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const [controls, setControls] = useState<Record<string, Control[]>>({})
  const [filteredControls, setFilteredControls] = useState<Record<string, Control[]>>({})
  const {name} = useParams()
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [controlID, setControlID] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const {toggleCrispChat} = useCrispChat()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getControl()
  }, [])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 8000)
    return () => clearTimeout(timeoutId)
  }, [])

  const toggleAccordion = (id: string) => {
    setOpenAccordion(openAccordion === id ? null : id)
    setOpen(!open)
  }

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    if (!id) {
      return
    }
    setControlID(id)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    const filterControls = () => {
      if (searchTerm.trim() === '') {
        // If there's no search term, display all controls
        setFilteredControls(controls)
        setSearchLength(false)
      } else {
        // Filter controls based on search term
        const newFilteredControls = Object.entries(controls).reduce(
          (acc, [mainCode, groupedControls]) => {
            const filtered = groupedControls.filter((control) =>
              control.control_title.toLowerCase().includes(searchTerm.toLowerCase())
            )
            if (filtered.length > 0) {
              acc[mainCode] = filtered
            }
            return acc
          },
          {} as Record<string, Control[]>
        )
        setSearchLength(Object.keys(newFilteredControls).length === 0)
        setFilteredControls(newFilteredControls)
      }
    }

    filterControls()
  }, [searchTerm, controls])

  const getControl = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        let controlsData: Control[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          control_category: doc.data().control_category || '',
          control_description: doc.data().control_description || '',
          control_document: doc.data().control_document || '',
          control_evidence_status: doc.data().control_evidence_status || '',
          control_framework_mappings: doc.data().control_framework_mappings || '',
          control_id: doc.data().control_id || '',
          control_owner: doc.data().control_owner || '',
          control_test: doc.data().control_test || '',
          control_title: doc.data().control_title || '',
          control_uid: doc.data().control_uid || '',
          document_id: doc.data().document_id || [],
          test_id: doc.data().test_id || [],
          deactivation_control: doc.data().deactivation_control || '',
        }))

        const filteredControls = controlsData.filter(
          (control) =>
            control.control_framework_mappings.startsWith(`${name}`) &&
            !control.control_framework_mappings.startsWith('ISO 27001:2013')
        )

        // Group controls by main code
        const groupedControls = filteredControls.reduce((acc, control) => {
          const mainCode = getMainCode(control.control_framework_mappings)
          if (!acc[mainCode]) {
            acc[mainCode] = []
          }
          acc[mainCode].push(control)
          return acc
        }, {} as Record<string, Control[]>)

        Object.keys(groupedControls).forEach((mainCode) => {
          groupedControls[mainCode].sort((a, b) => {
            const codeA = getCodeFromFrameworkMapping(a.control_framework_mappings)
            const codeB = getCodeFromFrameworkMapping(b.control_framework_mappings)
            return codeA.localeCompare(codeB)
          })
        })

        const getNumericParts = (code: string) => {
          return code.match(/\d+/g)?.map(Number) || []
        }

        const sortedGroupedControls = Object.entries(groupedControls)
          .sort(([keyA], [keyB]) => {
            const prefixA = getLetterPrefix(keyA)
            const prefixB = getLetterPrefix(keyB)
            if (prefixA === 'C' && prefixB !== 'C') {
              return -1
            } else if (prefixB === 'C' && prefixA !== 'C') {
              return 1
            }
            if (prefixA !== prefixB) {
              return prefixA.localeCompare(prefixB)
            }
            const numericPartsA = getNumericParts(keyA)
            const numericPartsB = getNumericParts(keyB)
            for (let i = 0; i < Math.min(numericPartsA.length, numericPartsB.length); i++) {
              if (numericPartsA[i] !== numericPartsB[i]) {
                return numericPartsA[i] - numericPartsB[i]
              }
            }
            return numericPartsA.length - numericPartsB.length
          })
          .reduce((acc, [key, value]) => {
            acc[key] = value.sort((a, b) => {
              const codeA = getCodeFromFrameworkMapping(a.control_framework_mappings)
              const codeB = getCodeFromFrameworkMapping(b.control_framework_mappings)
              const numericPartsA = getNumericParts(codeA)
              const numericPartsB = getNumericParts(codeB)
              for (let i = 0; i < Math.min(numericPartsA.length, numericPartsB.length); i++) {
                if (numericPartsA[i] !== numericPartsB[i]) {
                  return numericPartsA[i] - numericPartsB[i]
                }
              }
              return numericPartsA.length - numericPartsB.length
            })
            return acc
          }, {} as Record<string, Control[]>)

        if (currentUser?.account_type === 'Trial') {
          const allControls = Object.values(sortedGroupedControls).flat()

          allControls.forEach((control, index) => {
            if (index >= 4) {
              control.control_title = maskStringPreservingSpaces(control.control_title)
            }
          })
        }

        setControls(sortedGroupedControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const maskStringPreservingSpaces = (str, visibleStartLength = 4, visibleEndLength = 3) => {
    if (!str || str.length <= visibleStartLength + visibleEndLength) return '****'

    const start = str.slice(0, visibleStartLength)
    const end = str.slice(-visibleEndLength)
    const middle = str.slice(visibleStartLength, -visibleEndLength)

    const maskedMiddle = [...middle].map((char) => (char === ' ' ? ' ' : '*')).join('')

    return `${start}${maskedMiddle}${end}`
  }

  function processControlValues(inputString: string, inputString2: string): string {
    // Concatenate the input strings with a '+' separator
    const concatenatedString = inputString + '+' + inputString2

    // Split the concatenated string by '+' to separate the two parts
    const parts = concatenatedString.split('+')

    let numerator = 0
    let denominator = 0

    // Loop through each part
    parts.forEach((part) => {
      // Split the part by '/' to separate the numerator and the denominator
      const splitPart = part.split('/')
      // Convert the numerator and the denominator to integers
      const num = parseInt(splitPart[0], 10)
      const den = parseInt(splitPart[1], 10)

      // Check if the conversion was successful
      if (isNaN(num) || isNaN(den)) {
        return
      }

      // Add the numerator and denominator to the total
      numerator += num
      denominator += den
    })

    if (denominator === 0) {
      return '0/0'
    }
    let processedValue = ''
    processedValue = currentUser?.account_type === 'Trial' ? '0/0' : `${numerator}/${denominator}`
    return processedValue
  }

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  const exportData = async () => {
    if (Object.keys(filteredControls).length > 0) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Controls')

      // Define header row
      worksheet.columns = [
        {header: 'Control', key: 'controlObjective', width: 50},
        {header: 'Control Description', key: 'controlDescription', width: 60},
        {header: 'Framework', key: 'sec', width: 30},
        {header: 'Category', key: 'clause', width: 30},
        {header: 'Platform', key: 'platform', width: 15},
        {header: 'Document', key: 'document', width: 15},
        {header: 'Status', key: 'status', width: 15},
        {header: 'Justification for exclusion', key: 'justification', width: 50},
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
      worksheet.views = [{state: 'frozen', ySplit: 1}]

      const exportData = Object.values(filteredControls)
        .flatMap((group) => group)
        .map((row) => ({
          controlObjective: row.control_title || '',
          controlDescription: stripHtmlTags(row.control_description) || '',
          sec: row.control_framework_mappings || '',
          clause: row.control_category,
          platform: row.control_test || '',
          document: row.control_document || '',
          status: row.control_evidence_status || '',
          justification: row.deactivation_control || '',
        }))

      exportData.forEach((item) => {
        const row = worksheet.addRow(item)
        row.height = 20
        row.font = {size: 10}

        if (item.status === 'Deactivated') {
          row.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'DDEEFF'}, // Updated to a soft blue color
            }
          })
        }

        if (item.status === 'OK') {
          row.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        } else if (item.status === 'Unhealthy') {
          row.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})

      // Save the file with a name that includes the framework
      saveAs(excelBlob, `controls_${name}.xlsx`)

      toast.success('Controls data exported successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Framework</title>
      </Helmet>
      {name === 'SOC 2' ? (
        <ReportPageTitle
          icon='SOC.svg'
          title='SOC 2'
          desc='Your progress towards SOC 2 compliance based on your controls and tests.'
        ></ReportPageTitle>
      ) : (
        <ReportPageTitle
          icon='ISO.svg'
          title='ISO 27001: 2022'
          desc='Your progress towards ISO 27001:2022 compliance based on your controls and tests.'
        ></ReportPageTitle>
      )}
      {currentUser?.account_type === 'Trial' && (
        <div className='card p-4 fs-6'>
          <div className='ms-4 fw-semibold'>
            This section is accessible with any paid plan.{' '}
            <button
              className='btn btn-sm fs-6 ms-4'
              style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              onClick={toggleCrispChat}
            >
              Contact sales
            </button>
          </div>
        </div>
      )}
      <div>
        <div className='card-header border-0 pt-6 h-100 mb-8'>
          {currentUser?.account_type !== 'Trial' && (
            <div className='d-flex justify-content-between align-items-center w-100'>
              {/* Search on the left side */}
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-3' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control ps-12'
                  placeholder='Search..'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div>
                <button
                  type='button'
                  onClick={exportData}
                  className='btn btn-sm fs-6'
                  style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
                    style={{marginRight: '4px'}}
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                    <path d='M7 11l5 5l5 -5' />
                    <path d='M12 4l0 12' />
                  </svg>
                  Export
                </button>
              </div>
            </div>
          )}
        </div>

        <ViewControl isOpen={isModalOpen} onClose={handleCloseModal} id={controlID} />
        {Object.keys(filteredControls).length > 0 ? (
          Object.keys(filteredControls).map((mainCode, index) => {
            const categoryCount = Object.keys(filteredControls)
              .slice(0, index + 1)
              .filter((code, i, array) => {
                return i === 0 || code !== array[i - 1]
              }).length

            return (
              <div key={mainCode} className='mt-4'>
                <span className='fs-5 fw-bold '>
                  {mainCode.split(',')[0].trim()} {controls[mainCode][0].control_category}
                </span>
                {filteredControls[mainCode].map((control) => (
                  <>
                    <div
                      key={control.id}
                      className={`card p-6 mt-4 mb-4`}
                      style={
                        currentUser?.account_type === 'Trial' && categoryCount > 1
                          ? {filter: 'blur(4px)', pointerEvents: 'none'}
                          : {}
                      }
                    >
                      <div
                        className='d-flex'
                        onClick={() => toggleAccordion(control.id)}
                        style={{cursor: 'pointer'}}
                      >
                        <div className='row w-100'>
                          <div className='col'>
                            <div className='d-flex align-items-center'>
                              <span>
                                {openAccordion === control.id ? (
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#5A5A5A'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M6 9l6 6l6 -6' />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#5A5A5A'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M9 6l6 6l-6 6' />
                                  </svg>
                                )}
                              </span>
                              <span className='fs-6 fw-bold mx-4'>
                                {getCodeFromFrameworkMapping(
                                  control.control_framework_mappings.split(',')[0].trim()
                                )}{' '}
                                {control.control_title}
                              </span>
                            </div>
                          </div>
                          <div className='col-auto d-flex align-items-center ml-auto'>
                            <div className='d-flex justify-content-end'>
                              {control.control_evidence_status === 'Deactivated' ? (
                                <div className='fw-semibold'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#5A5A5A'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-eye-pause mx-1 mb-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                    <path d='M13.022 17.945a9.308 9.308 0 0 1 -1.022 .055c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6c-.195 .325 -.394 .636 -.596 .935' />
                                    <path d='M17 17v5' />
                                    <path d='M21 17v5' />
                                  </svg>{' '}
                                  Deactivated
                                </div>
                              ) : control.control_evidence_status !== 'OK' ? (
                                <div className='fw-semibold'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='16'
                                    height='16'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#EE3043'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle mb-1 mx-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M12 9v4' />
                                    <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                                    <path d='M12 16h.01' />
                                  </svg>{' '}
                                  Controls Unhealthy
                                </div>
                              ) : (
                                <div className='fw-bold'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='18'
                                    height='18'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='#1CBB8D'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check mb-1 mx-1'
                                  >
                                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                    <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                    <path d='M9 12l2 2l4 -4' />
                                  </svg>{' '}
                                  OK
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {openAccordion === control.id && (
                        <div className='table-responsive mt-3'>
                          <table className='table table-hover'>
                            <thead>
                              <tr className='py-4 border-bottom fw-bold' style={{color: '#5A5A5A'}}>
                                <th>CONTROL</th>
                                <th>STATUS</th>
                                <th className='d-flex justify-content-end mx-20'>ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                className='border-bottom my-2 fw-bold'
                                style={
                                  currentUser?.account_type === 'Trial' && categoryCount > 1
                                    ? {filter: 'blur(4px)', pointerEvents: 'none'}
                                    : {color: '#171717'}
                                }
                              >
                                <td className='pt-6 pb-6'>
                                  <a
                                    href='#'
                                    className='text-dark fw-bold text-hover-primary fs-6'
                                    onClick={() =>
                                      currentUser?.account_type === 'Trial'
                                        ? setShowModal(true)
                                        : handleOpenModal(control.id)
                                    }
                                  >
                                    {control.control_title}{' '}
                                  </a>
                                </td>

                                <td className='pt-6 pb-6'>
                                  <div className='d-flex align-items-center'>
                                    {control.control_evidence_status === 'Deactivated' ? (
                                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                      <a
                                        href='#'
                                        className='badge fs-7 text-dark text-bold m-1'
                                        style={{background: '#F6F6F6'}}
                                      >
                                        {control.control_evidence_status}
                                      </a>
                                    ) : (
                                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                      <a
                                        href='#'
                                        className={`badge fs-7 ${
                                          control.control_evidence_status === 'Ok' ||
                                          control.control_evidence_status === 'OK'
                                            ? 'badge-primary'
                                            : 'badge-danger'
                                        }`}
                                        style={{
                                          color:
                                            control.control_evidence_status === 'Ok' ||
                                            control.control_evidence_status === 'OK'
                                              ? '#1CBB8D'
                                              : '#EE3043',
                                          background:
                                            control.control_evidence_status === 'Ok' ||
                                            control.control_evidence_status === 'OK'
                                              ? '#E8F8F4'
                                              : '#FFF5F8',
                                        }}
                                      >
                                        {control.control_evidence_status === 'Ok' ||
                                        control.control_evidence_status === 'OK'
                                          ? 'OK'
                                          : 'Unhealthy'}
                                      </a>
                                    )}
                                    <span className='mx-2'>
                                      {currentUser?.account_type === 'Trial'
                                        ? '0/0'
                                        : processControlValues(
                                            control.control_document,
                                            control.control_test
                                          )}{' '}
                                      Test passing
                                    </span>
                                  </div>
                                </td>
                                <td className='pt-6 pb-6'>
                                  <div className='d-flex justify-content-end'>
                                    {currentUser?.account_type === 'Trial' ? (
                                      <a
                                        href='#'
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setShowModal(true)
                                        }}
                                        rel='noopener noreferrer'
                                        className='d-flex align-items-center'
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='16'
                                          height='16'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='#5A5A5A'
                                          strokeWidth='2'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                          <path d='M11 13l9 -9' />
                                          <path d='M15 4h5v5' />
                                        </svg>
                                        <span style={{color: '#5A5A5A'}}>View in full Screen</span>
                                      </a>
                                    ) : (
                                      <a
                                        href={`/controls/details/${control.id}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='d-flex align-items-center'
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='16'
                                          height='16'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='#5A5A5A'
                                          strokeWidth='2'
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                          <path d='M11 13l9 -9' />
                                          <path d='M15 4h5v5' />
                                        </svg>
                                        <span style={{color: '#5A5A5A'}}>View in full Screen</span>
                                      </a>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            )
          })
        ) : (
          <>
            {searchLength ? (
              <div className='text-center'>
                <div className='m-20'>
                  <img src='/media/buttons/emptystate.svg' alt='' />
                  <div className='m-5 fs-5'>
                    <p style={{fontWeight: 'bold'}}>No controls found</p>
                  </div>
                  <p className=''>
                    We couldn't find any controls that match your search. Maybe try a different
                    search!
                  </p>
                </div>
              </div>
            ) : (
              <div className='text-center '>
                {!showEmptyState ? (
                  <div
                    className='d-flex justify-content-center align-items-center text-center'
                    style={{
                      height: '100vh',
                      width: '100vw',
                      position: 'absolute',
                      top: '20vh',
                      left: '20vh',
                    }}
                  >
                    <TestListLoading />
                  </div>
                ) : (
                  <div className='m-20'>
                    <img src='/media/buttons/emptystate.svg' alt='' />
                    <div className=' fs-5'>
                      <p style={{fontWeight: 'bold'}}>No Controls</p>
                    </div>
                    <p>Connection error</p>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <ToastContainer />
      <UpgradePlan show={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}

export default ViewReports
