/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {SettingPageTitle} from '../components/header/SettingPageTitle'
import {useAuth} from '../../../modules/auth'
import {collection, onSnapshot, doc} from '@firebase/firestore'
import db from 'services/firebase'
import {ToastContainer} from 'react-toastify'
import {KTIcon} from '_metronic/helpers'
import {AddPersonnelModal} from 'app/pages/personnel-management/components/AddPersonnelModal'

const SettingsUserpermissions = () => {
  const [activeTab, setActiveTab] = useState('roles')
  const location = useLocation()
  const {currentUser} = useAuth()
  const [users, setUsers] = useState<any[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const closeDrawer = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    // Fetch control data when the component mounts
    if (location.pathname.includes('auditors')) {
      setActiveTab('auditors')
    } else if (location.pathname.includes('msp')) {
      setActiveTab('msp')
    } else {
      setActiveTab('users')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getPersonnel()
  }, [])

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            role: doc.data().role,
          }))
          .filter((item) => item.role !== 'Auditor')

        setUsers(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase())
  }
  const filteredUsers = users.filter((user) => user.full_name.toLowerCase().includes(searchTerm))

  return (
    <>
      <SettingPageTitle icon='Settings.svg' title='Settings' desc=' Manage your settings'>
        Personnel Management
      </SettingPageTitle>
      <div className={`card mb-5 mb-xl-8`}>
        <div className='px-10 '>
          <div className='d-flex  h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
              <li className='nav-item'>
                <Link
                  to={`/company-settings/users`}
                  className={`nav-link me-6 ${
                    activeTab === 'users' ? 'text-active-primary active' : ''
                  }`}
                >
                  Your organization
                </Link>
              </li>

              <li className='nav-item'>
                <Link
                  to={'/company-settings/users/auditors'}
                  className={`nav-link me-6 ${
                    activeTab === 'auditors' ? 'text-active-primary active' : ''
                  }`}
                >
                  Auditors
                </Link>
              </li>

              {/* <li className='nav-item'>
                <Link
                  to={'/company-settings/users/msp'}
                  className={`nav-link me-6 ${
                    activeTab === 'msp' ? 'text-active-primary active' : ''
                  }`}
                >
                  Managed Service Providers
                </Link>
              </li> */}
            </ul>
          </div>
        </div>

        <div className='card-body'>
          <>
            <div className='d-flex align-items-center justify-content-between position-relative my-1'>
              {/* Search Input Section */}
              <div className='d-flex align-items-center'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder='Search Users'
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <button
                  type='button'
                  className='btn btn-sm fs-6'
                  onClick={toggleDrawer}
                  style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  <KTIcon iconName='plus' className='fs-2 text-white' />
                  Add Employee
                </button>
              </div>
            </div>
            <AddPersonnelModal isOpen={isOpen} onClose={closeDrawer} />
          </>
          {/* begin::Table container */}
          <div className='table-responsive' style={{height: '500px', overflowY: 'auto'}}>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                  <th className='min-w-200px '>NAME</th>
                  <th className='min-w-200px'>EMAIL</th>
                  <th className='min-w-100px '>ROLE</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody>
                {filteredUsers && filteredUsers.length > 0 ? 
                  filteredUsers.map((user) => (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center  fs-6 fw-bold'>
                        {user.full_name}
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center fw-bold'>{user.email}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center fw-bold'>{user.role}</div>
                    </td>
                  </tr>
                )):<>
                  <td colSpan={6} className='text-center'>
                      <div className=''>
                        <img src='/media/buttons/emptystate.svg' alt='' />
                        <div className='m-5 fs-5'>
                          <p style={{fontWeight: 'bold'}}>No Employees Found</p>
                        </div>
                        <p className=''>
                          We couldn't find any personnel that match your search. Maybe try a
                          different search!
                        </p>
                      </div>
                    </td>
                </>}
              </tbody>

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default SettingsUserpermissions
