import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Swal from 'sweetalert2'

interface UserData {
  uid: string
  id: string
  full_name: string
  role: string
  avatarUrl: string
  tenantId: string
  policiesAccepted?: boolean
  start_date: string
  group: string
  end_date: string
  email: string
}

interface OffboardingProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const Offboarding: React.FC<OffboardingProps> = ({isOpen, onClose, id}) => {
  const [users, setUsers] = useState<UserData[]>([])
  const [showModal, setShowModal] = useState(false)
  const {currentUser} = useAuth()
  const [updating, setUpdating] = useState(false)
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setShowModal(false)
    onClose()
  }

  useEffect(() => {
    getPersonnel()
  }, [id])

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...(doc.data() as UserData),
            id: doc.id,
            full_name: doc.data().full_name,
            end_date: doc.data().end_date,
          }))
          .filter((data) => data.id === id)
        setUsers(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  const handleChangeEndDate = (e) => {
    setEndDate(e.target.value)
  }

  // const handleConfirmOffboard = async () => {
  //   const currentDate = new Date()
  //   const year = currentDate.getFullYear()
  //   const month = currentDate.getMonth() + 1
  //   const day = currentDate.getDate()

  //   const todaysDate = month + '/' + day + '/' + year
  //   setUpdating(true)
  //   try {
  //     const tenantId = currentUser?.tenantId || ''
  //     const tenantRef = doc(db, 'tenants', tenantId)

  //     const assetRef = doc(tenantRef, 'employees', id)
  //     await updateDoc(assetRef, {end_date: endDate})

  //     const controlsRef = collection(tenantRef, 'controls')
  //     const q = query(controlsRef, where('control_owner', '==', users[0].full_name))
  //     const querySnapshot = await getDocs(q)
  //     querySnapshot.forEach(async (document) => {
  //       const controlDocRef = doc(tenantRef, 'controls', document.id)
  //       await updateDoc(controlDocRef, {control_owner: ''})
  //     })

  //     const testRef = collection(tenantRef, 'tests')
  //     const t = query(testRef, where('owner', '==', users[0].full_name))
  //     const querySnapshot2 = await getDocs(t)
  //     querySnapshot2.forEach(async (document) => {
  //       const testDocRef = doc(tenantRef, 'tests', document.id)
  //       await updateDoc(testDocRef, {owner: ''})
  //     })

  //     const policyRef = collection(tenantRef, 'policies')
  //     const p = query(policyRef, where('owner', '==', users[0].full_name))
  //     const querySnapshot3 = await getDocs(p)
  //     querySnapshot3.forEach(async (document) => {
  //       const policyDocRef = doc(tenantRef, 'policies', document.id)
  //       await updateDoc(policyDocRef, {owner: ''})
  //     })

  //     const assetsRef = collection(tenantRef, 'assets')
  //     const a = query(assetsRef, where('owner', '==', users[0].full_name))
  //     const querySnapshot4 = await getDocs(a)
  //     querySnapshot4.forEach(async (document) => {
  //       const assetDocRef = doc(tenantRef, 'assets', document.id)
  //       await updateDoc(assetDocRef, {owner: ''})
  //     })

  //     const risksRef = collection(tenantRef, 'risks')
  //     const r = query(risksRef, where('owner', '==', users[0].full_name))
  //     const querySnapshot5 = await getDocs(r)
  //     querySnapshot5.forEach(async (document) => {
  //       const riskDocRef = doc(tenantRef, 'risks', document.id)
  //       await updateDoc(riskDocRef, {owner: ''})
  //     })

  //     const vendorsRef = collection(tenantRef, 'vendors')
  //     const v = query(vendorsRef, where('owner', '==', users[0].full_name))
  //     const querySnapshot6 = await getDocs(v)
  //     querySnapshot6.forEach(async (document) => {
  //       const vendorDocRef = doc(tenantRef, 'vendors', document.id)
  //       await updateDoc(vendorDocRef, {owner: ''})
  //     })

  //     const activitiesRef = collection(tenantRef, 'activities')
  //     await addDoc(activitiesRef, {
  //       message: ` ${users[0].full_name} has been Offboarded by ${currentUser?.userName} `,
  //       timestamp: serverTimestamp(),
  //       tenantId: currentUser?.tenantId,
  //       // email: email,
  //       uid: currentUser?.uid,
  //       username: currentUser?.userName,
  //       action: 'Employee offboarded',
  //       target: 'Employees',
  //       email: currentUser?.email,
  //       date: todaysDate,
  //     })
  //     setUpdating(false)
  //     setShowModal(false)
  //     setEndDate('')
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Offboarded',
  //       text: ` ${users[0].full_name} has been offboarded successfully.`,
  //       customClass: {
  //         confirmButton: 'btn btn-success',
  //       },
  //       buttonsStyling: false,
  //     })
  //   } catch (error) {
  //     console.error('Error updating personnel:')
  //   }
  // }

  const handleConfirmOffboard = async () => {
    const currentDate = new Date();
    const todaysDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
    setUpdating(true);
  
    try {
      const tenantId = currentUser?.tenantId || '';
      const tenantRef = doc(db, 'tenants', tenantId);
  
      const employeeRef = doc(tenantRef, 'employees', id);
      await updateDoc(employeeRef, { end_date: endDate });
  
      const collectionsToUpdate = [
        { name: 'controls', field: 'control_owner' },
        { name: 'tests', field: 'owner' },
        { name: 'policies', field: 'owner' },
        { name: 'assets', field: 'owner' },
        { name: 'risks', field: 'owner' },
        { name: 'vendors', field: 'owner' },
      ];
  
      const updateOwnerField = async (collectionName, ownerField) => {
        const collectionRef = collection(tenantRef, collectionName);
        const q = query(collectionRef, where(ownerField, '==', users[0].full_name));
        const querySnapshot = await getDocs(q);
  
        const updatePromises = querySnapshot.docs.map((docSnapshot) => {
          const docRef = doc(tenantRef, collectionName, docSnapshot.id);
          return updateDoc(docRef, { [ownerField]: '' });
        });
  
        await Promise.all(updatePromises);
      };
  
      for (const { name, field } of collectionsToUpdate) {
        await updateOwnerField(name, field);
      }
  
      const activitiesRef = collection(tenantRef, 'activities');
      await addDoc(activitiesRef, {
        message: `${users[0].full_name} has been offboarded by ${currentUser?.userName}`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        username: currentUser?.userName,
        action: 'Employee offboarded',
        target: 'Employees',
        email: currentUser?.email,
        date: todaysDate,
      });
  
      setUpdating(false);
      setShowModal(false);
      setEndDate('');
      Swal.fire({
        icon: 'success',
        title: 'Offboarded',
        text: `${users[0].full_name} has been offboarded successfully.`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      });
    } catch (error) {
      console.error('Error during offboarding:', error);
      setUpdating(false);
    }
  };
  

  return (
    <Modal show={showModal} onHide={handleClose} backdropClassName='bg-opacity-1' centered>
      <Modal.Header closeButton>
        <Modal.Title>Offboard {users.length > 0 ? users[0].full_name : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='fs-6'>
          Set the employee's last working day below to initiate the offboarding process. Please
          note:
          <ul className='mt-3'>
            <li>
              The employee will be immediately offboarded and their status in the system will be set
              to
              <strong> Ended</strong>.
            </li>
            <li>
              All ownerships, including policies, controls, tests, risks, vendors, and assets
              associated with the employee, will be automatically unassigned.
            </li>
            {/* <li>Relevant teams will be notified to reassign the unowned responsibilities.</li>
            <li>An audit trail of these changes will be maintained for compliance purposes.</li> */}
          </ul>
          Ensure the selected end date is accurate, as these changes will take effect immediately
          after confirmation.
        </div>
        <div className='fv-row mb-2 mt-7'>
          <label className=' fw-bold fs-6 mb-2'>End Date</label>
          <input
            placeholder='Enter Checklist Date'
            type='date'
            className='form-control  mb-3 mb-lg-0 w-100'
            name='endDate'
            value={endDate || users[0]?.end_date}
            onChange={handleChangeEndDate}
            autoComplete='off'
            disabled={users[0]?.end_date === ''}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='p-0 px-6 py-4'>
        <button type='button' className='btn btn-light fs-6 mx-4' onClick={handleClose}>
          Close
        </button>
        {updating ? (
          <button
            className='btn btn-sm fs-6'
            onClick={() => (endDate ? handleConfirmOffboard() : null)}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            // disabled={activities.length === 0}
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',

              transition: 'background-color 0.3s',
            }}
          >
            Offboarding... <span className='spinner-border spinner-border-sm align-middle'></span>
          </button>
        ) : (
          <button
            className='btn btn-sm fs-6'
            onClick={() => (endDate ? handleConfirmOffboard() : null)}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            disabled={users[0]?.end_date === ''}
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',

              transition: 'background-color 0.3s',
            }}
          >
            Offboard Employee
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default Offboarding
