/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import AWSDrawer from './components/drawers/AWSDrawer'
import {ToastContainer} from 'react-toastify'
import {KTIcon, toAbsoluteUrl} from '_metronic/helpers'
import {useAuth} from 'app/modules/auth'
import db from 'services/firebase'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import AsanaDrawer from './components/drawers/AsanaDrawer'
import GithubDrawer from './components/drawers/GithubDrawer'
import LinearDrawer from './components/drawers/LinearDrawer'
import {useLocation} from 'react-router-dom'

type Props = {
  className: string
}

const IntegrationManagement: React.FC<Props> = ({className}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [integration, setIntegration] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [selectedItemId, setSelectedItemId] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredIntegrations, setFilteredIntegrations] = useState<any[]>([])
  const [selectedIntegration, setSelectedIntegration] = useState('')
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const serviceParam = queryParams.get('service')

    if (id && serviceParam === 'Linear') {
      handleOpenModal(id)
    }
  }, [location])

  useEffect(() => {
    getIntegration()
  }, [])

  useEffect(() => {
    setFilteredIntegrations(integration)
  }, [integration])

  const getIntegration = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'integrations')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const integrationData = snapshot.docs.map((item) => ({
          ...item.data(),
          id: item.id,
        }))
        setIntegration(integrationData)
        setShowEmptyState(false)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
      setShowEmptyState(false)
    }
  }

  const handleOpenModal = (itemId: string) => {
    setIsModalOpen(true)
    setSelectedItemId(itemId)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const integrationHandlers = {
    // AWS: (id: string) => handleOpenModal(id),
    // Asana: (id: string) => handleOpenModal(id),
    // GitLab: (id: string) => handleOpenModal(id),
    // Sentry: (id: string) => handleOpenModal(id),
    // GitHub: (id: string) => handleOpenModal(id),
    Linear: (id: string) => handleOpenModal(id),
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    let filteredItems = integration

    if (selectedIntegration) {
      filteredItems = filteredItems.filter((item) => item.group === selectedIntegration)
    }

    if (searchTerm) {
      filteredItems = filteredItems.filter((item) => item.name.toLowerCase().includes(searchTerm))
    } else {
    }

    setFilteredIntegrations(filteredItems)
  }

  const filterIntegrations = (category: string) => {
    if (!category) {
      setFilteredIntegrations(integration) // Show all if no category is selected
      return
    }
    const filteredItems = integration.filter((item) => item.group === category)
    setFilteredIntegrations(filteredItems)
  }

  return (
    <>
      <div className='card-title mb-4'>
        {/* begin::Search */}
        <div
          className='d-flex justify-content-between align-items-center'
          data-kt-user-table-toolbar='base'
        >
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <label htmlFor='Integrations' className='fw-semibold fs-6 me-2'>
              Category
            </label>
            <select
              id='Integrations'
              className='form-select form-control w-auto'
              aria-label='Select Integrations'
              value={selectedIntegration}
              onChange={(e) => {
                const newSelectedIntegration = e.target.value
                setSelectedIntegration(newSelectedIntegration)
                filterIntegrations(newSelectedIntegration)
              }}
            >
              <option value=''>All</option>
              <option value='Cloud Providers'>Cloud Services</option>
              <option value='Business Suite'>Business Suite</option>
              <option value='Background Checks'>Background Checks</option>
              <option value='Device Management'>Device Management</option>
              <option value='Developer Tools'>Developer Tools</option>
              <option value='Endpoint Security'>Endpoint Security</option>
              <option value='Human Resources'>Human Resources</option>
              <option value='Password Management'>Password Management</option>
              <option value='Single Sign-On'>Single Sign On</option>
              <option value='Project Management'>Project Management</option>
              <option value='Vulnerability Management'>Vulnerability Management</option>
              <option value='Other'>Other</option>
            </select>
          </div>
        </div>
      </div>
      {filteredIntegrations && filteredIntegrations.length > 0 ? (
        <>
          {filteredIntegrations.map((item, index) => (
            <div key={index} className={`card mb-5 mb-xl-8`}>
              <div className='card-header border-0 pt-5 mb-5'>
                <div className='d-flex align-items-center'>
                  <div
                    className='d-inline-block position-relative me-5'
                    style={{
                      width: '45px',
                      height: '45px',
                      overflow: 'hidden',
                      backgroundColor: '#F6F6F6',
                      borderRadius: '10px',
                      padding: '6px',
                    }}
                  >
                    <img
                      src={toAbsoluteUrl(`${item.img_path}`)}
                      alt=''
                      loading='lazy'
                      style={{
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>

                  <div className='d-flex justify-content-start flex-column'>
                    <span className='card-label fw-bold fs-5'>{item.name}</span>
                    <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                      {item.group}
                    </span>
                  </div>
                </div>

                <div className='mt-2'>
                  {item.status === 'Connected' ? (
                    <span className='badge border p-2 rounded-pill'>
                      <span
                        className={`badge border rounded-circle mx-2 bg-primary`}
                        style={{
                          width: '12px',
                          height: '12px',
                          padding: '3px',
                        }}
                      >
                        {' '}
                      </span>
                      <span className='text-center'>{item.status}</span>
                    </span>
                  ) : (
                    <span className='badge border p-2 rounded-pill'>
                      <span
                        className={`badge border rounded-circle mx-2 bg-danger`}
                        style={{
                          width: '12px',
                          height: '12px',
                          padding: '3px',
                        }}
                      >
                        {' '}
                      </span>
                      <span className='text-center'>Disconnected</span>
                    </span>
                  )}
                  <button
                    className='btn fw-bold fs-6 mb-1'
                    style={{color: '#5A5A5A'}}
                    onClick={() =>
                      integrationHandlers[item.name]
                        ? integrationHandlers[item.name](item.id)
                        : handleOpenModal(item.id)
                    }
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-settings mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
                      <path d='M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0' />
                    </svg>{' '}
                    Manage
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {' '}
          <div className='container-fluid min-vh-50 d-flex align-items-center justify-content-center'>
            <div className='row' style={{height: '50vh', width: '100%'}}>
              <div className='col d-flex align-items-center justify-content-center'>
                <div className='text-center'>
                  <img
                    src='/media/integrations/Integrations Icon.svg'
                    alt='Integrations Icon'
                    className='mb-4'
                    style={{width: '60px'}}
                  />
                  <h2 className='fs-6 mb-2'>No integrations connected</h2>
                  <p className='fs-6' style={{color: '#5A5A5A'}}>
                    You'll see all your connected services here
                  </p>
                </div>
              </div>
            </div>
          </div>{' '}
        </>
      )}
      <LinearDrawer id={selectedItemId} isOpen={isModalOpen} onClose={handleCloseModal} />
      <ToastContainer />
    </>
  )
}

export {IntegrationManagement}
