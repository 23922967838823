import {useListView} from '../../../vendor/core/ListViewProvider'
import AddPolicyModal from '../AddPolicyModal'
import {useAuth} from '../../../../modules/auth'
import {useEffect, useState} from 'react'
import db from '../../../../../services/firebase'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import UpgradePlan from 'app/pages/controls/components/submodal/UpgradePlan'
const PolicyListToolbar = (props) => {
  const {setItemIdForUpdate} = useListView()
  const {currentUser} = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleExportAll = async () => {
    // Define a mapping of framework names to file URLs
    const frameworkToFileUrl = {
      'SOC 2': '/templates/SOC_2 Policies.zip',
      'ISO 27001:2022': '/templates/ISO 27001 Policies.zip',
    }

    // Check each active framework and download the corresponding file
    activeFrameworks.forEach((framework) => {
      const fileUrl = frameworkToFileUrl[framework] // Ensure the framework name matches the keys in the mapping
      if (fileUrl) {
        // Download the file for this framework
        const link = document.createElement('a')
        link.href = fileUrl
        link.setAttribute('download', '') // This will prompt the file to be downloaded
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <PolicyListFilter /> */}
      {/* <AddTemplates/> */}
      <AddPolicyModal isOpen={isModalOpen} onClose={handleCloseModal} />
      {activeFrameworks.length > 0 && (
        <button
          type='button'
          className=' btn btn-sm btn-light me-4 fs-6'
          // onClick={handleExportAll}
          onClick={() =>
            currentUser?.account_type === 'Trial' ? setShowModal(true) : handleExportAll()
          }
          style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#5A5A5A'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
            style={{marginRight: '4px'}}
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
            <path d='M7 11l5 5l5 -5' />
            <path d='M12 4l0 12' />
          </svg>
          Export
        </button>
      )}
      {currentUser?.role !== 'Auditor' && (
        <button
          className='btn btn-sm fs-6'
          style={{background: '#1CBB8D', height: '40px', color: '#fff'}}
          onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#148363')
          }
          onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
            (e.currentTarget.style.backgroundColor = '#1CBB8D')
          }
          onClick={() =>
            currentUser?.account_type === 'Trial' ? setShowModal(true) : handleOpenModal()
          }
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-plus'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M12 5l0 14' />
            <path d='M5 12l14 0' />
          </svg>
          <span className='mx-2'>Add Policy</span>
        </button>
      )}
      <UpgradePlan show={showModal} onClose={() => setShowModal(false)} />
    </div>
  )
}

export {PolicyListToolbar}
