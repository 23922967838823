import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
  getDocs,
  getDoc,
} from 'firebase/firestore'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Drawer from 'react-modern-drawer'
import db from '../../../../services/firebase'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import OffboardingChecklist from './subcomponents/OffboardingChecklist'

interface ViewPersonnelProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

interface UserData {
  uid: string
  id: string
  full_name: string
  role: string
  avatarUrl: string
  tenantId: string
  policiesAccepted?: boolean
  start_date: string
  group: string
  end_date: string
  email: string
  acceptedPolicies: string
  securityTraining: string
}

interface FormErrors {
  end_date?: string
}

const ViewPersonnel: React.FC<ViewPersonnelProps> = ({isOpen, onClose, id}) => {
  const [users, setUsers] = useState<UserData[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<UserData | null>(null)
  const [groups, setGroups] = useState<any[]>([])
  const [activeTab, setActiveTab] = useState('onboarding')
  const [assets, setAssets] = useState<any[]>([])
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const handleClose = () => setShowModal(false)
  const [end_date, setEndDate] = useState('')
  const [tasks, setTasks] = useState<any[]>([])
  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [isLoading, setIsLoading] = useState(false)
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    getPersonnel()
  }, [id])

  useEffect(() => {
    if (users.length > 0) {
      getTaskLists()
    }
  }, [users])

  const getPersonnel = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...(doc.data() as UserData),
            id: doc.id,
            end_date: doc.data().end_date,
          }))
          .filter((data) => data.id === id)
        setUsers(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'personnel_group')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setGroups(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const PolicyRef = collection(tenantRef, 'assets')

        // const limitedQuery = query(controlsCollectionRef, limit(10))

        const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
          const assetData = snapshot.docs.map((item) => ({
            ...item.data(),
            id: item.id,
          }))
          setAssets(assetData)
        })

        return unsubscribe
      } catch (error) {
        console.error('Error fetching assets:', error)
      }
    }

    fetchData()
  }, [])

  const handleCloseDrawer = () => {
    onClose();
    setActiveTab('onboarding'); 
  }

  const handleMarkAsComplete = async (taskId) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Completed'})
      getTaskLists()
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }
  const handleMarkAsIncomplete = async (taskId) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Incomplete'})
      getTaskLists()
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }

  const getTaskLists = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')

      const unsubscribe = onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          owner: doc.data().owner || '',
        }))

        if (users && users.length > 0 && users[0]?.full_name) {
          setTasks(controlsData.filter((data) => data.owner === users[0].full_name))
        } else {
          console.error('User data is missing or incomplete')
        }
      })
      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const validateForm = () => {
    const errors: FormErrors = {}
    if (!end_date.trim()) {
      errors.end_date = 'End Date is required'
    }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleRemoveTask = async (taskId) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Remove Selected Items',
        text: 'Are you sure you want to remove the selected items? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await deleteDoc(taskRef)
        getTaskLists() // Refresh the tasks list
      }
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Selected items have been removed.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleOffboard = (item) => (event) => {
    setShowModal(true)
    setSelectedItem(item)
  }
  const handleConfirmOffboard = async () => {
    if (!validateForm()) {
      return
    }

    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      setIsLoading(true)
      const assetRef = doc(tenantRef, 'employees', id)
      await updateDoc(assetRef, {end_date: end_date})
      setIsLoading(false)
      setShowModal(false)
      setEndDate('')
      Swal.fire({
        icon: 'success',
        title: 'Offboarded',
        text: ` ${users[0].full_name} has been offboarded successfully.`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      console.error('Error updating personnel:')
      setIsLoading(false)
    }
  }

  const handleChangeEndDate = (e) => {
    setEndDate(e.target.value)

    // Clear the error for this field
    setFormErrors((prev) => ({...prev, endDate: ''}))

    // Validate the changed field
    if (!e.target.value.trim()) {
      setFormErrors((prev) => ({...prev, endDate: 'End Date is required'}))
    }
  }

  const handleChange = async (e, id) => {
    const newStartDate = e.target.value
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)

    const assetRef = doc(tenantRef, 'employees', id)
    await updateDoc(assetRef, {startDate: e.target.value})
    if (selectedItem) {
      setSelectedItem({
        ...selectedItem,
        start_date: newStartDate,
      })
    }
  }

  const handleGroupChange = async (e, id) => {
    const newGroup = e.target.value
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)

    const assetRef = doc(tenantRef, 'employees', id)
    await updateDoc(assetRef, {group: e.target.value})
    if (selectedItem) {
      setSelectedItem({
        ...selectedItem,
        group: newGroup,
      })
    }
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const handleRoleChange = async (e, userId) => {
    const newRole = e.target.value
    let result

    if (newRole === 'Member') {
      result = await Swal.fire({
        icon: 'warning',
        title: 'Change Role',
        text: `Confirm role change to '${newRole}'? This will remove ownership of all associated items: tests, controls, policies, risks, vendors, and more. The user will lose access to the admin dashboard.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Change',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
    } else {
      result = await Swal.fire({
        icon: 'warning',
        title: 'Change Role',
        text: `Confirm role change to '${newRole}'? The user will gain access to the admin dashboard with this change.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Change',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
    }

    if (result.isConfirmed) {
      setUpdating(true)
      try {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const assetRef = doc(tenantRef, 'employees', userId)

        const userDoc = await getDoc(assetRef)
        if (userDoc.exists()) {
          const userData = userDoc.data()
          const currentRole = userData.role

          await updateDoc(assetRef, {role: newRole})

          if (currentRole === 'Admin' && newRole === 'Member') {
            const collectionsToUpdate = [
              'tests',
              'controls',
              'policies',
              'risks',
              'vendors',
              'assets',
              'tasklists',
            ]

            for (let collectionName of collectionsToUpdate) {
              const collectionRef = collection(db, 'tenants', tenantId, collectionName)
              let querySnapshot

              if (collectionName === 'controls') {
                querySnapshot = await getDocs(
                  query(collectionRef, where('control_owner', '==', userData.full_name))
                )
              } else {
                querySnapshot = await getDocs(
                  query(collectionRef, where('owner', '==', userData.full_name))
                )
              }

              querySnapshot.forEach(async (docSnapshot) => {
                const docRef = doc(collectionRef, docSnapshot.id)
                const docData = docSnapshot.data()
                if (
                  docData.owner === userData.full_name ||
                  docData.control_owner === userData.full_name
                ) {
                  const updateField = collectionName === 'controls' ? 'control_owner' : 'owner'
                  await updateDoc(docRef, {[updateField]: ''})
                }
              })
            }
            setUpdating(false)
          }
          setUpdating(false)
          Swal.fire({
            icon: 'success',
            title: 'Role Updated!',
            text: `The role has been successfully changed to ${newRole}.`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'User document not found in Firestore.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          })
        }
      } catch (error) {
        setUpdating(false)
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while updating the role or removing the user from other collections.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      }
    } else {
      // Reset to original role if canceled
      e.target.value = users.find((user) => user.id === userId)?.role || ''
    }
  }

  const setReminder = async (taskId: string, status: boolean) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tasklists/${taskId}`)
      await updateDoc(testDocRef, {
        reminder: status,
      })
      // toast.success('Reminder set successfully')
    } catch (error) {
      console.error('Error setting reminder: ', error)
    }
  }

  return (
    <>
      <Drawer open={isOpen} onClose={handleCloseDrawer} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Employee Details
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className='p-6 scroll-y overflow-auto '
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          {users.map((user) => (
            //   <Modal.Body>
            <>
              <div key={user.id}>
                <div className='modal-body scroll-y '>
                  <div>
                    <div className='d-flex justify-content-between mb-8'>
                      <div className='symbol symbol-40px me-5'>
                        {user.full_name ? (
                          <div className='symbol symbol-25px'>
                            <span
                              className='symbol-label fs-7 fw-bold'
                              style={{backgroundColor: '#DAF9EF'}}
                            >
                              {user.full_name
                                ? (() => {
                                    const nameParts = user.full_name.split(' ')
                                    if (nameParts.length > 1) {
                                      return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1]
                                        .charAt(0)
                                        .toUpperCase()}`
                                    } else {
                                      return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[0]
                                        .charAt(1)
                                        .toUpperCase()}`
                                    }
                                  })()
                                : ''}
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold fs-6'>{user.full_name}</span>
                        <span className=' fw-semibold d-block fs-7' style={{color: '#5A5A5A'}}>
                          {user.email}
                        </span>
                      </div>

                      <div className='ms-auto'>
                        {currentUser?.role === 'Owner' && (
                          <div style={{cursor: 'pointer'}}>
                            <button
                              className='btn btn-sm fs-6'
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                              }}
                              onClick={handleOffboard(user)}
                              disabled={!!user.end_date}
                            >
                              Offboard {user.full_name}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                show={showModal}
                onHide={handleClose}
                backdropClassName='bg-opacity-1'
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Offboard {user.full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='fs-6'>
                    Set the employee's last working day below to initiate the offboarding. Please
                    note that your employee will be noticed to complete the assignment checklist.
                  </div>
                  <div className='fv-row mb-2 mt-7'>
                    <label className=' fw-bold fs-6 mb-2'>End Date</label>
                    <input
                      placeholder='Enter Checklist Date'
                      type='date'
                      className={`form-control mb-3 mb-lg-0 w-100 ${
                        formErrors.end_date ? 'is-invalid' : ''
                      }`}
                      name='end_date'
                      value={end_date || user?.end_date}
                      onChange={handleChangeEndDate}
                      autoComplete='off'
                    />
                    {formErrors.end_date && (
                      <div className='invalid-feedback'>{formErrors.end_date}</div>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button type='button' className='btn btn-light fs-6 mx-4' onClick={handleClose}>
                    Close
                  </button>
                  {isLoading ? (
                    <button
                      className='btn btn-sm fs-6'
                      onClick={handleConfirmOffboard}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      disabled
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        height: '40px',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Offboarding Employee...
                      <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                    </button>
                  ) : (
                    <button
                      className='btn btn-sm fs-6'
                      onClick={handleConfirmOffboard}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        height: '40px',
                        borderRadius: '4px',

                        transition: 'background-color 0.3s',
                      }}
                    >
                      Offboard Employee
                    </button>
                  )}
                </Modal.Footer>
              </Modal>

              {currentUser?.role === 'Owner' && user.id !== currentUser.uid ? (
                <div className='row mb-3'>
                  <div className='col-3 d-flex align-items-center'>
                    <label className='text-gray-800 fw-semibold fs-6 me-4'>Role</label>
                  </div>
                  <div className='col-9'>
                    <div className='fw-normal d-flex align-items-center'>
                      <select
                        name='role'
                        className='form-select w-50 p-2'
                        data-control='select2'
                        data-placeholder='Select Role'
                        value={user.role}
                        onChange={(e) => handleRoleChange(e, user.id)}
                      >
                        <option value='Admin'>Admin</option>
                        <option value='Member'>Member</option>
                      </select>
                      {updating && <span className='spinner-border spinner-border-sm ms-2'></span>}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='row mb-3'>
                  <div className='col-3'>
                    <label className='text-gray-800 fw-semibold me-2 fs-6'>Role:</label>
                  </div>
                  <div className='col-9'>
                    <span className='fw-normal fw-bold'>{user.role}</span>
                  </div>
                </div>
              )}

              <div className='row mb-3'>
                <div className='col-3 d-flex align-items-center'>
                  <label className='text-gray-800 fw-semibold fs-6 '>Start Date:</label>
                </div>
                <div className='col-9'>
                  <span className='fw-normal'>
                    {' '}
                    <input
                      placeholder='Start Date'
                      className='form-control w-50 p-2'
                      type='date'
                      name='startDate'
                      autoComplete='off'
                      onChange={(e) => handleChange(e, user.id)}
                      value={user.start_date}
                      disabled={currentUser?.role === 'Auditor'}
                    />
                  </span>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-3 d-flex align-items-center'>
                  <label className='text-gray-800 fw-semibold fs-6 me-4'>Group</label>
                </div>
                <div className='col-9'>
                  <span className='fw-normal'>
                    {' '}
                    <select
                      name='group'
                      className='form-select  w-50 p-2 '
                      data-control='select2'
                      data-placeholder='Select Group'
                      value={user.group}
                      onChange={(e) => handleGroupChange(e, user.id)}
                      disabled={currentUser?.role === 'Auditor'}
                    >
                      {groups.map((group) => (
                        <option key={group.id} value={group.groupName}>
                          {group.groupName}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              </div>

              <div className='px-2 '>
                <div className='d-flex  h-55px'>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
                    <li className='nav-item'>
                      <Link
                        to={`/employees`}
                        className={`nav-link me-6 ${
                          activeTab === 'onboarding' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('onboarding')}
                      >
                        Onboarding
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        to={``}
                        className={`nav-link me-6 ${
                          activeTab === 'tasks' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('tasks')}
                      >
                        Tasks
                      </Link>
                    </li>
                    <li className='nav-item'>
                      <Link
                        to={'/employees'}
                        className={`nav-link me-6 ${
                          activeTab === 'assets' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('assets')}
                      >
                        Assets
                      </Link>
                    </li>
                    {user.end_date && currentUser?.role === 'Owner' ? (
                      <li className='nav-item'>
                        <Link
                          to={'/employees'}
                          className={`nav-link me-6 ${
                            activeTab === 'offboarding' ? 'text-active-primary active' : ''
                          }`}
                          onClick={() => handleTabClick('offboarding')}
                        >
                          Offboarding
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>

                {activeTab === 'tasks' && (
                  <div className='mt-8 '>
                    <div
                      className='table-responsive'
                      style={{minHeight: '300px', maxHeight: '500px', overflowY: 'auto'}}
                    >
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='w-25px'>
                              {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value='1'
                                  data-kt-check='true'
                                  data-kt-check-target='.widget-9-check'
                                  checked={allTaskMarkAsCompleted}
                                  onChange={handleAllMarkAsCompleteClicked}
                                />
                              </div> */}
                            </th>
                            <th className='min-w-200px'>Task Name</th>
                            <th className='min-w-50px'>Priority</th>
                            <th className='min-w-100px'>Assigned To</th>
                            {/* <th className='min-w-100px'>Due date</th> */}
                            <th className='min-w-50px text-end'></th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {tasks && tasks.length > 0 ? (
                            tasks.map((task) => (
                              <tr key={task.id}>
                                <td>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                      className='form-check-input widget-9-check'
                                      type='checkbox'
                                      checked={task.status === 'Completed'}
                                      onChange={() => {
                                        if (task.status === 'Completed') {
                                          handleMarkAsIncomplete(task.id)
                                        } else {
                                          handleMarkAsComplete(task.id)
                                        }
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                    {task.task_name}
                                  </div>
                                </td>

                                {/* <td className="text-end">
      <div className="d-flex flex-column w-100 me-2">
        <div className="d-flex flex-stack mb-2">
          
          <span className={`badge badge-light-success`}>{task.priority}</span>
        </div>
      </div>
    </td> */}
                                <td>
                                  <span className='badge border p-2 rounded-pill fs-6 fw-semibold'>
                                    <span
                                      className={`badge border rounded-circle mx-2  ${
                                        task.priority === 'High'
                                          ? 'bg-danger'
                                          : task.priority === 'Medium'
                                          ? 'bg-warning'
                                          : 'bg-primary'
                                      }`}
                                      style={{
                                        width: '12px',
                                        height: '12px',
                                        padding: '3px',
                                      }}
                                    >
                                      {' '}
                                    </span>

                                    {task.priority}
                                  </span>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    {task.owner ? (
                                      <>
                                        <div className='symbol symbol-35px me-3'>
                                          <span
                                            className='symbol-label fs-7 fw-bold'
                                            style={{backgroundColor: '#DAF9EF'}}
                                          >
                                            {task.owner
                                              ? (() => {
                                                  const nameParts = task.owner.split(' ')
                                                  if (nameParts.length > 1) {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[1]
                                                      .charAt(0)
                                                      .toUpperCase()}`
                                                  } else {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[0]
                                                      .charAt(1)
                                                      .toUpperCase()}`
                                                  }
                                                })()
                                              : ''}
                                          </span>
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                          <div className='text-dark fw-semibold text-hover-primary fs-6'>
                                            {task.owner}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <span>-</span>
                                      </>
                                    )}
                                  </div>
                                </td>

                                <td className='text-end'>
                                  <div className='dropdown '>
                                    <button
                                      className='btn btn-secondary border-0 p-0 '
                                      type='button'
                                      id='dropdownMenu2'
                                      data-bs-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                    >
                                      <img src='/media/buttons/Options.svg' alt='Options' />
                                    </button>
                                    <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                                      <li>
                                        {task.status === 'Completed' ? (
                                          <button
                                            className='dropdown-item text-hover-primary'
                                            style={{color: '#5A5A5A'}}
                                            type='button'
                                            onClick={() => handleMarkAsIncomplete(task.id)}
                                            disabled={currentUser?.role === 'Auditor'}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-x mx-1 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M18 6l-12 12' />
                                              <path d='M6 6l12 12' />
                                            </svg>{' '}
                                            Mark as Incomplete
                                          </button>
                                        ) : (
                                          <button
                                            className='dropdown-item text-hover-primary'
                                            style={{color: '#5A5A5A'}}
                                            type='button'
                                            onClick={() => handleMarkAsComplete(task.id)}
                                            disabled={currentUser?.role === 'Auditor'}
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='#5E6274'
                                              strokeWidth='2'
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M5 12l5 5l10 -10' />
                                            </svg>{' '}
                                            Mark as complete
                                          </button>
                                        )}
                                      </li>
                                      <li>
                                        <button
                                          className='dropdown-item'
                                          style={{color: '#F15363'}}
                                          type='button'
                                          onClick={() => handleRemoveTask(task.id)}
                                          disabled={currentUser?.role === 'Auditor'}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='#F15363'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M4 7l16 0' />
                                            <path d='M10 11l0 6' />
                                            <path d='M14 11l0 6' />
                                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                          </svg>{' '}
                                          Remove task
                                        </button>
                                      </li>
                                      {/* <li>
                                        <button
                                          className='dropdown-item'
                                          type='button'
                                          style={{color: 'rgb(28, 187, 141)'}}
                                          onClick={() => setReminder(task.id, true)}
                                          disabled={currentUser?.role === 'Auditor'}
                                        >
                                          <svg
                                            width='24'
                                            height='25'
                                            viewBox='0 0 24 25'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                          >
                                            <path
                                              d='M12 10.3252V13.3252H14M7 4.3252L4.25 6.3252M17 4.3252L19.75 6.3252M5 13.3252C5 14.2444 5.18106 15.1547 5.53284 16.004C5.88463 16.8533 6.40024 17.6249 7.05025 18.2749C7.70026 18.925 8.47194 19.4406 9.32122 19.7924C10.1705 20.1441 11.0807 20.3252 12 20.3252C12.9193 20.3252 13.8295 20.1441 14.6788 19.7924C15.5281 19.4406 16.2997 18.925 16.9497 18.2749C17.5998 17.6249 18.1154 16.8533 18.4672 16.004C18.8189 15.1547 19 14.2444 19 13.3252C19 12.4059 18.8189 11.4957 18.4672 10.6464C18.1154 9.79713 17.5998 9.02546 16.9497 8.37545C16.2997 7.72544 15.5281 7.20982 14.6788 6.85804C13.8295 6.50626 12.9193 6.3252 12 6.3252C11.0807 6.3252 10.1705 6.50626 9.32122 6.85804C8.47194 7.20982 7.70026 7.72544 7.05025 8.37545C6.40024 9.02546 5.88463 9.79713 5.53284 10.6464C5.18106 11.4957 5 12.4059 5 13.3252Z'
                                              stroke='rgb(28, 187, 141)'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                            />
                                          </svg>{' '}
                                          Set Reminder
                                        </button>
                                      </li> */}
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <td colSpan={10}>
                              <div
                                className='d-flex justify-content-center align-items-center text-center'
                                style={{height: '100%'}}
                              >
                                <div className='m-8'>
                                  <img src='/media/buttons/emptystate.svg' alt='' />
                                  <div className=' fs-5'>
                                    <p style={{fontWeight: 'bold'}}>No task added</p>
                                  </div>
                                  <p>
                                    Maybe try adding{' '}
                                    <a
                                      href='https://app.getsecureslate.com/dashboard'
                                      style={{color: '#1CBB8D'}}
                                    >
                                      {' '}
                                      new task{' '}
                                    </a>{' '}
                                  </p>
                                </div>
                              </div>
                            </td>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {activeTab === 'onboarding' &&
                  users.map((user) => (
                    <>
                      <div className='mt-8 '>
                        <div className='card mb-5 mb-xl-8'>
                          <div className='card-header border-0 pt-5 mb-5'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-5 '>
                                <img src={toAbsoluteUrl(`/media/logos/policies1.svg`)} alt='' />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='card-label fw-bold fs-6'>Accepted Policies</span>
                                <span
                                  className='fw-semibold fs-7 d-none d-md-block'
                                  style={{color: '#5A5A5A'}}
                                >
                                  Read & accepted all assigned policies
                                </span>
                              </div>
                            </div>

                            {user.acceptedPolicies === 'Complete' ? (
                              <div className='d-flex justify-content-end '>
                                <div className='pt-4 '>
                                  <span
                                    className='badge rounded-pill p-1'
                                    style={{border: '1px solid #C5C5C5'}}
                                  >
                                    <span className='mx-2'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#1CBB8D'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                        <path d='M9 12l2 2l4 -4' />
                                      </svg>
                                    </span>
                                    <span className='fs-7 me-2' style={{color: '#5A5A5A'}}>
                                      Completed
                                    </span>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className='d-flex justify-content-end '>
                                <div className='pt-4 '>
                                  <span
                                    className='badge rounded-pill p-1'
                                    style={{border: '1px solid #C5C5C5'}}
                                  >
                                    <span className='mx-2'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#007bff'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-clock'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                                        <path d='M12 7v5l3 3' />
                                      </svg>
                                    </span>
                                    <span className='fs-7 me-2' style={{color: '#5A5A5A'}}>
                                      Pending
                                    </span>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='card mb-5 mb-xl-8'>
                          <div className='card-header border-0 pt-5 mb-5'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-5 '>
                                <img src={toAbsoluteUrl(`/media/logos/training.svg`)} alt='' />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='card-label fw-bold fs-6'>Security Training</span>
                                <span
                                  className='fw-semibold fs-7 d-none d-md-block'
                                  style={{color: '#5A5A5A'}}
                                >
                                  Completed Security training
                                </span>
                              </div>
                            </div>

                            {user.securityTraining === 'Complete' ? (
                              <div className='d-flex justify-content-end '>
                                <div className='pt-4'>
                                  {/* <img
                                src={toAbsoluteUrl(`/media/buttons/CompletedO.svg`)}
                                style={{maxWidth: '100px', maxHeight: '50px'}}
                                alt=''
                              /> */}

                                  <span
                                    className='badge rounded-pill p-1'
                                    style={{border: '1px solid #C5C5C5'}}
                                  >
                                    <span className='mx-2'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#1CBB8D'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                        <path d='M9 12l2 2l4 -4' />
                                      </svg>
                                    </span>
                                    <span className='fs-7 me-2' style={{color: '#5A5A5A'}}>
                                      Completed
                                    </span>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className='d-flex justify-content-end '>
                                <div className='pt-4'>
                                  {/* <img
                                src={toAbsoluteUrl(`/media/buttons/pendingO.svg`)}
                                style={{maxWidth: '90px', maxHeight: '50px'}}
                                alt=''
                              /> */}
                                  <span
                                    className='badge rounded-pill p-1'
                                    style={{border: '1px solid #C5C5C5'}}
                                  >
                                    <span className='mx-2'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='#007bff'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        className='icon icon-tabler icons-tabler-outline icon-tabler-clock'
                                      >
                                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                        <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
                                        <path d='M12 7v5l3 3' />
                                      </svg>
                                    </span>
                                    <span className='fs-7 me-2' style={{color: '#5A5A5A'}}>
                                      Pending
                                    </span>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className='card mb-5 mb-xl-8'>
                      <div className='card-header border-0 pt-5 mb-5'>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-35px me-5 '>
                            <img src={toAbsoluteUrl(`/media/logos/bacgroundcheck.svg`)} alt='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='card-label fw-bold fs-7'>Backgroung Check</span>
                            <span className='text-muted fw-semibold fs-9 d-none d-md-block'>
                              Submitted all required information
                            </span>
                          </div>
                        </div>
                        {onboardingTotal === 1 ? (
                          <div className='d-flex justify-content-end '>
                            <div className='pt-4 '>
                              <img
                                src={toAbsoluteUrl(`/media/buttons/CompletedO.svg`)}
                                style={{maxWidth: '100px', maxHeight: '50px'}}
                                alt=''
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='d-flex justify-content-end '>
                            <div className='pt-4 '>
                              <img
                                src={toAbsoluteUrl(`/media/buttons/pendingO.svg`)}
                                style={{maxWidth: '90px', maxHeight: '50px'}}
                                alt=''
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div> */}
                      </div>
                    </>
                  ))}

                {activeTab === 'assets' && (
                  <>
                    {assets && assets.length > 0 ? (
                      assets
                        .filter((asset) => asset.is_repo !== true)
                        .map((asset) => (
                          <div className='mt-8'>
                            <div className={`card mb-5 mb-xl-8`}>
                              <div className='card-header border-0 pt-5 mb-5'>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='card-label fw-bold fs-6'>{asset.name}</span>
                                    <span
                                      className='fw-semibold fs-7 d-none d-md-block'
                                      style={{color: '#5A5A5A'}}
                                    >
                                      model: {asset.model} | OS: {asset.os_version}
                                    </span>
                                  </div>
                                </div>

                                <div className='d-flex justify-content-end '>
                                  <div
                                    className='pt-4 fs-7 m-2 '
                                    style={{cursor: 'pointer', color: '#5A5A5A'}}
                                    onClick={() => navigate('/asset-management')}
                                  >
                                    View in Asset Management{' '}
                                    <img
                                      src='/media/buttons/Chevron Button.svg'
                                      alt='>'
                                      className='ms-1'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div
                        className='d-flex justify-content-center align-items-center text-center mt-20'
                        style={{height: '100%'}}
                      >
                        <div className=''>
                          <img
                            src='/media/buttons/emptystate.svg'
                            alt=''
                            className='img-fluid'
                            style={{maxWidth: '100px'}}
                          />

                          <div className=' fs-6'>
                            <p style={{fontWeight: 'bold'}}>No assets added!!</p>
                          </div>
                          <p className='fs-7'>
                            Maybe try adding new assets thorugh{' '}
                            <a href='/asset-management' style={{color: '#1CBB8D'}}>
                              Asset Management
                            </a>
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {activeTab === 'offboarding' &&
                  users.map((user) => (
                    <>
                      <OffboardingChecklist user={user} />
                    </>
                  ))}
              </div>
            </>
            //   </Modal.Body>
          ))}
        </div>
      </Drawer>
      <ToastContainer />
    </>
  )
}

export default ViewPersonnel
