/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {ReportPageTitle} from './PageTitle/ReportPageTitle'
import {useAuth} from '../../../modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../services/firebase'
import {KTIcon} from '../../../../_metronic/helpers'
import {ViewControl} from '../../controls/components/ViewControl'
import {Helmet} from 'react-helmet'
import {saveAs} from 'file-saver'
import ExcelJS from 'exceljs'
import {toast, ToastContainer} from 'react-toastify'
import {TestListLoading} from '../../tests/components/loading/TestListLoading'
import useCrispChat from 'app/Hooks/useCrispChat'
import UpgradePlan from 'app/pages/controls/components/submodal/UpgradePlan'

interface Control {
  id: string
  control_category: string
  control_description: string
  control_document: string
  control_evidence_status: string
  control_framework_mappings: string
  control_id: string
  control_owner: string
  control_test: string
  control_title: string
  control_uid: string
  document_id: string[]
  test_id: string[]
  deactivation_control: string
  category: string
  tag: string
}

interface GroupedControls {
  [category: string]: {
    [tag: string]: Control[]
  }
}

// Constants
const CATEGORY_ORDER = [
  'Control Environment',
  'Communication and Information',
  'Risk Assessment',
  'Monitoring Activities',
  'Control Activities',
  'Logical and Physical Access Controls',
  'System Operations',
  'Change Management',
  'Risk Mitigation',
  'Supporting Compliance Document',
] as const

const CATEGORY_MAPPING: Record<string, string[]> = {
  'Control Environment': ['CC1'],
  'Communication and Information': ['CC2'],
  'Risk Assessment': ['CC3'],
  'Monitoring Activities': ['CC4'],
  'Control Activities': ['CC5'],
  'Logical and Physical Access Controls': ['CC6'],
  'System Operations': ['CC7'],
  'Change Management': ['CC8'],
  'Risk Mitigation': ['CC9'],
  'Supporting Compliance Document': ['SD'],
}

// Utility functions
const extractCCTags = (mappings: string | undefined): string[] => {
  if (!mappings) return []

  // Split by comma and handle both "SOC 2 ‧ CC X.X" and "SOC 2 ‧ SD - SOC 2" formats
  return mappings.split(',').map((mapping) => {
    const trimmed = mapping.trim()
    const ccMatch = trimmed.match(/SOC 2 ‧ (CC \d+\.\d+)/i)
    const sdMatch = trimmed.match(/SOC 2 ‧ (SD.*)/i)
    return ccMatch ? ccMatch[1].trim() : sdMatch ? sdMatch[1].trim() : trimmed
  })
}

// Update the getCategoryFromCCTag function to handle SD tags
const getCategoryFromCCTag = (ccTag: string): string => {
  if (ccTag.startsWith('SD')) {
    return 'Supporting Compliance Document'
  }

  // Extract just the CC number (e.g., "CC 6" from "CC 6.6")
  const ccMatch = ccTag.match(/^CC (\d+)/i)
  if (!ccMatch) return 'Other'

  const ccNumber = `CC${ccMatch[1]}`

  // Find the category that matches this CC number
  const category = Object.entries(CATEGORY_MAPPING).find(([_, prefixes]) =>
    prefixes.includes(ccNumber)
  )?.[0]

  return category || 'Other'
}

// Update the sortCCTags function to handle SD tags
const sortCCTags = (a: string, b: string): number => {
  // Handle case where one or both tags are SD tags
  const isSDa = a.startsWith('SD')
  const isSDb = b.startsWith('SD')

  if (isSDa && !isSDb) return 1 // SD tags come last
  if (!isSDa && isSDb) return -1
  if (isSDa && isSDb) return a.localeCompare(b)

  const aMatch = a.match(/CC (\d+)\.(\d+)/i)
  const bMatch = b.match(/CC (\d+)\.(\d+)/i)

  if (!aMatch || !bMatch) return a.localeCompare(b)

  const [, aMajor, aMinor] = aMatch.map(Number)
  const [, bMajor, bMinor] = bMatch.map(Number)

  return aMajor !== bMajor ? aMajor - bMajor : aMinor - bMinor
}

const SOCReports = () => {
  const {currentUser} = useAuth()
  const [controls, setControls] = useState<GroupedControls>({})
  const [filteredControls, setFilteredControls] = useState<Record<string, Control[]>>({})
  const name = 'SOC 2'
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [controlID, setControlID] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const {toggleCrispChat} = useCrispChat()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getControl()
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowEmptyState(Object.keys(controls).length === 0)
    }, 3000)
    return () => clearTimeout(timeoutId)
  }, [controls])

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    if (!id) {
      return
    }
    setControlID(id)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (searchTerm.trim() === '') {
      getControl()
    } else {
      const lowercasedSearchTerm = searchTerm.toLowerCase()
      const filtered = Object.keys(controls).reduce((acc, category) => {
        const filteredTags = Object.keys(controls[category]).reduce((tagAcc, tag) => {
          const filteredControls = controls[category][tag].filter((control) =>
            control.control_title.toLowerCase().includes(lowercasedSearchTerm)
          )
          if (filteredControls.length > 0) {
            tagAcc[tag] = filteredControls
          }
          return tagAcc
        }, {})

        if (Object.keys(filteredTags).length > 0) {
          acc[category] = filteredTags
        }
        return acc
      }, {})

      setControls(filtered)
    }
  }, [searchTerm, controls])

  // const getControl = async () => {
  //   try {
  //     const tenantId = currentUser?.tenantId || ''
  //     const tenantRef = doc(db, 'tenants', tenantId)
  //     const controlsCollectionRef = collection(tenantRef, 'controls')
  //     setShowEmptyState(true)
  //     return onSnapshot(controlsCollectionRef, (snapshot) => {
  //       const controlsData = snapshot.docs.map((doc) => ({
  //         ...(doc.data() as Control),
  //         id: doc.id,
  //         control_owner: currentUser?.account_type === 'Trial' ? '' : doc.data().control_owner,
  //         control_title:
  //           currentUser?.account_type === 'Trial'
  //             ? maskStringPreservingSpaces(doc.data().control_title || '')
  //             : doc.data().control_title || '',
  //       }))

  //       // Filter SOC 2 controls
  //       const filteredControls = controlsData.filter(
  //         (control) =>
  //           control.control_framework_mappings?.startsWith('SOC 2') &&
  //           !control.control_framework_mappings?.startsWith('ISO 27001:2013')
  //       )
  //       const filteredControlsRecord: Record<string, Control[]> = filteredControls.reduce(
  //         (acc, control) => {
  //           // Use the control's category as the key
  //           const category = control.control_category
  //           if (!acc[category]) {
  //             acc[category] = []
  //           }
  //           acc[category].push(control)
  //           return acc
  //         },
  //         {}
  //       )

  //       setFilteredControls(filteredControlsRecord)
  //       // Initialize the grouping with all categories from CATEGORY_ORDER
  //       const initialGrouping: GroupedControls = CATEGORY_ORDER.reduce((acc, category) => {
  //         acc[category] = {}
  //         return acc
  //       }, {} as GroupedControls)

  //       // Group controls by category and CC tag
  //       const groupedControls = filteredControls.reduce((acc, control) => {
  //         const ccTags = extractCCTags(control.control_framework_mappings)

  //         ccTags.forEach((ccTag) => {
  //           const category = getCategoryFromCCTag(ccTag)
  //           if (!acc[category]) {
  //             acc[category] = {}
  //           }
  //           if (!acc[category][ccTag]) {
  //             acc[category][ccTag] = []
  //           }
  //           // Prevent duplicate controls
  //           if (!acc[category][ccTag].some((c) => c.id === control.id)) {
  //             acc[category][ccTag].push(control)
  //           }
  //         })

  //         return acc
  //       }, initialGrouping)

  //       // Sort CC tags within each category
  //       const orderedControls = CATEGORY_ORDER.reduce((acc, category) => {
  //         if (groupedControls[category]) {
  //           acc[category] = Object.entries(groupedControls[category])
  //             .sort(([a], [b]) => sortCCTags(a, b))
  //             .reduce(
  //               (obj, [key, value]) => ({
  //                 ...obj,
  //                 [key]: value,
  //               }),
  //               {}
  //             )
  //         }
  //         return acc
  //       }, {} as GroupedControls)

  //       setControls(orderedControls)
  //       setShowEmptyState(false)
  //     })
  //   } catch (error) {
  //     toast.error('Failed to fetch controls')
  //   }
  // }

  const getControl = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'controls')
      setShowEmptyState(true)

      return onSnapshot(controlsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...(doc.data() as Control),
          id: doc.id,
          control_owner: currentUser?.account_type === 'Trial' ? '' : doc.data().control_owner,
          control_title: doc.data().control_title || '',
        }))

        const filteredControls = controlsData.filter(
          (control) =>
            control.control_framework_mappings?.startsWith('SOC 2') &&
            !control.control_framework_mappings?.startsWith('ISO 27001:2013')
        )

        const filteredControlsRecord: Record<string, Control[]> = filteredControls.reduce(
          (acc, control) => {
            const category = control.control_category
            if (!acc[category]) {
              acc[category] = []
            }
            acc[category].push(control)
            return acc
          },
          {}
        )

        const initialGrouping: GroupedControls = CATEGORY_ORDER.reduce((acc, category) => {
          acc[category] = {}
          return acc
        }, {} as GroupedControls)

        const groupedControls = filteredControls.reduce((acc, control) => {
          const ccTags = extractCCTags(control.control_framework_mappings)

          ccTags.forEach((ccTag) => {
            const category = getCategoryFromCCTag(ccTag)
            if (!acc[category]) {
              acc[category] = {}
            }
            if (!acc[category][ccTag]) {
              acc[category][ccTag] = []
            }
            // Prevent duplicate controls
            if (!acc[category][ccTag].some((c) => c.id === control.id)) {
              acc[category][ccTag].push(control)
            }
          })

          return acc
        }, initialGrouping)

        const orderedControls = CATEGORY_ORDER.reduce((acc, category) => {
          if (groupedControls[category]) {
            acc[category] = Object.entries(groupedControls[category])
              .sort(([a], [b]) => sortCCTags(a, b))
              .reduce(
                (obj, [key, value]) => ({
                  ...obj,
                  [key]: value,
                }),
                {}
              )
          }
          return acc
        }, {} as GroupedControls)

        if (currentUser?.account_type === 'Trial') {
          let allControls: Control[] = []
          Object.values(orderedControls).forEach((categoryGroup) => {
            Object.values(categoryGroup).forEach((controls) => {
              allControls = allControls.concat(controls)
            })
          })

          const unmaskedControlIds = new Set(allControls.slice(0, 6).map((control) => control.id))

          Object.keys(orderedControls).forEach((category) => {
            Object.keys(orderedControls[category]).forEach((ccTag) => {
              orderedControls[category][ccTag] = orderedControls[category][ccTag].map(
                (control) => ({
                  ...control,
                  control_title: unmaskedControlIds.has(control.id)
                    ? control.control_title
                    : maskStringPreservingSpaces(control.control_title),
                })
              )
            })
          })
        }

        setFilteredControls(filteredControlsRecord)
        setControls(orderedControls)
        setShowEmptyState(false)
      })
    } catch (error) {
      toast.error('Failed to fetch controls')
    }
  }

  const maskStringPreservingSpaces = (str, visibleStartLength = 3, visibleEndLength = 2) => {
    if (!str || str.length <= visibleStartLength + visibleEndLength) return '****'
    const start = str.slice(0, visibleStartLength)
    const end = str.slice(-visibleEndLength)
    const middle = str.slice(visibleStartLength, -visibleEndLength)
    const maskedMiddle = [...middle].map((char) => (char === ' ' ? ' ' : '*')).join('')
    return `${start}${maskedMiddle}${end}`
  }

  function processControlValues(inputString: string, inputString2: string): string {
    // Concatenate the input strings with a '+' separator
    const concatenatedString = inputString + '+' + inputString2

    // Split the concatenated string by '+' to separate the two parts
    const parts = concatenatedString.split('+')

    let numerator = 0
    let denominator = 0

    // Loop through each part
    parts.forEach((part) => {
      // Split the part by '/' to separate the numerator and the denominator
      const splitPart = part.split('/')
      // Convert the numerator and the denominator to integers
      const num = parseInt(splitPart[0], 10)
      const den = parseInt(splitPart[1], 10)

      // Check if the conversion was successful
      if (isNaN(num) || isNaN(den)) {
        return
      }

      // Add the numerator and denominator to the total
      numerator += num
      denominator += den
    })

    if (denominator === 0) {
      return '0/0'
    }

    // Return the result as a string in the format "numerator/denominator"
    const processedValue = `${numerator}/${denominator}`
    return processedValue
  }

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  const getInitials = (name: string) => {
    const nameParts = name.split(' ')
    if (nameParts.length > 1) {
      return `${nameParts[0].charAt(0)}${nameParts[1].charAt(0)}`.toUpperCase()
    }
    return nameParts[0].charAt(0).toUpperCase()
  }

  const exportData = async () => {
    if (Object.keys(filteredControls).length > 0) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Controls')

      // Define header row
      worksheet.columns = [
        {header: 'Control', key: 'controlObjective', width: 30},
        {header: 'Control Description', key: 'controlDescription', width: 50},
        {header: 'Category', key: 'clause', width: 40},
        {header: 'Framework', key: 'sec', width: 40},
        {header: 'Owner', key: 'owner', width: 20},
        {header: 'Platform', key: 'platform', width: 15},
        {header: 'Document', key: 'document', width: 15},
        {header: 'Status', key: 'status', width: 15},
        {header: 'Justification for exclusion', key: 'justification', width: 40},
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
      worksheet.views = [{state: 'frozen', ySplit: 1}]

      const exportData = Object.values(filteredControls)
        .flatMap((group) => group)
        .map((row) => ({
          controlObjective: row.control_title || '',
          controlDescription: stripHtmlTags(row.control_description) || '',
          clause: row.category,
          sec: row.control_framework_mappings || '',
          owner: row.control_owner || '',
          platform: row.control_test || '',
          document: row.control_document || '',
          status: row.control_evidence_status || '',
          justification: row.deactivation_control || '',
        }))

      exportData.forEach((item) => {
        const row = worksheet.addRow(item)
        row.height = 20
        row.font = {size: 10}

        if (item.status === 'Deactivated') {
          row.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'DDEEFF'}, // Updated to a soft blue color
            }
          })
        }

        if (item.status === 'OK') {
          row.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        } else if (item.status === 'Unhealthy') {
          row.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})

      // Save the file with a name that includes the framework
      saveAs(excelBlob, `controls_${name}.xlsx`)

      toast.success('Controls data exported successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
  }

  let controlCounter = 0

  const shouldApplyBlur = (currentCount) => {
    if (currentUser?.account_type !== 'Trial') return false
    return currentCount >= 6
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Framework</title>
      </Helmet>
      {name === 'SOC 2' ? (
        <ReportPageTitle
          icon='SOC.svg'
          title='SOC 2'
          desc='Your progress towards SOC 2 compliance based on your controls and tests.'
        ></ReportPageTitle>
      ) : (
        <ReportPageTitle
          icon='ISO.svg'
          title='ISO 27001: 2022'
          desc='Your progress towards ISO 27001:2022 compliance based on your controls and tests.'
        ></ReportPageTitle>
      )}
      {currentUser?.account_type === 'Trial' && (
        <div className='card p-4 fs-6'>
          <div className='ms-4 fw-semibold'>
            This section is accessible with any paid plan.{' '}
            <button
              className='btn btn-sm fs-6 ms-4'
              style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              onClick={toggleCrispChat}
            >
              Contact sales
            </button>
          </div>
        </div>
      )}
      <div>
        <div className='card-header border-0 pt-6 h-100 mb-8'>
          <div className='d-flex justify-content-between align-items-center w-100'>
            {/* Search on the left side */}
            {currentUser?.account_type !== 'Trial' && (
              <>
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-3' />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control ps-12'
                    placeholder='Search..'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <div>
                  <button
                    type='button'
                    onClick={exportData}
                    className='btn btn-sm fs-6'
                    style={{height: '40px', background: '#1CBB8D', color: '#fff'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
                      style={{marginRight: '4px'}}
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                      <path d='M7 11l5 5l5 -5' />
                      <path d='M12 4l0 12' />
                    </svg>
                    Export
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <ViewControl isOpen={isModalOpen} onClose={handleCloseModal} id={controlID} />

        <div className='space-y-8'>
          {Object.keys(controls) && Object.keys(controls).length > 0 ? (
            Object.keys(controls).map((category) => (
              <div key={category} className='rounded-lg overflow-hidden'>
                <h2 className='text-xl font-bold pt-4'>{category}</h2>

                <div className='space-y-6 mt-4 ml-4'>
                  {Object.keys(controls[category])
                    .sort(sortCCTags)
                    .map((ccTag) => (
                      <div key={ccTag} className='card mb-4 w-full'>
                        <h2 className='card-header text-lg font-semibold text-blue-600 pt-6 px-4'>
                          {ccTag}
                        </h2>
                        <div className='card-body pt-0'>
                          <div className='table-responsive'>
                            <table className='table'>
                              <thead className=' fs-7 fw-semibold' style={{color: '#5A5A5A'}}>
                                <tr>
                                  <th className='min-w-500px py-6'>CONTROL TITLE</th>
                                  <th className='min-w-100px py-6'>STATUS</th>
                                  <th className='min-w-200px py-6'>TEST PROGRESS</th>
                                  <th className='min-w-100px py-6'>OWNER</th>
                                  <th className='min-w-200px py-6'>ACTION</th>
                                </tr>
                              </thead>

                              <tbody className='bg-white divide-y fs-6'>
                                {controls[category][ccTag].map((control) => {
                                  const currentControlIndex = controlCounter++

                                  return (
                                    <tr
                                      key={control.id}
                                      style={
                                        shouldApplyBlur(currentControlIndex)
                                          ? {filter: 'blur(4px)', pointerEvents: 'none'}
                                          : {}
                                      }
                                    >
                                      <td className='py-4 whitespace-nowrap'>
                                        <div className='text-sm text-gray-900'>
                                          <a
                                            href='#'
                                            className='text-dark fw-semibold text-hover-primary fs-6'
                                            // onClick={() => handleOpenModal(control.id)}
                                            onClick={() =>
                                              currentUser?.account_type === 'Trial'
                                                ? setShowModal(true)
                                                : handleOpenModal(control.id)
                                            }
                                          >
                                            {control.control_title}
                                          </a>
                                        </div>
                                      </td>
                                      <td className='py-4 whitespace-nowrap'>
                                        {control.control_evidence_status === 'Deactivated' ? (
                                          <a
                                            href='#'
                                            className='badge fs-7 text-dark text-bold m-1'
                                            style={{background: '#F6F6F6'}}
                                          >
                                            {control.control_evidence_status}
                                          </a>
                                        ) : (
                                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                          <a
                                            href='#'
                                            className={`badge fs-7 ${
                                              control.control_evidence_status === 'Ok' ||
                                              control.control_evidence_status === 'OK'
                                                ? 'badge-primary'
                                                : 'badge-danger'
                                            }`}
                                            style={{
                                              color:
                                                control.control_evidence_status === 'Ok' ||
                                                control.control_evidence_status === 'OK'
                                                  ? '#1CBB8D'
                                                  : '#EE3043',
                                              background:
                                                control.control_evidence_status === 'Ok' ||
                                                control.control_evidence_status === 'OK'
                                                  ? '#E8F8F4'
                                                  : '#FFF5F8',
                                            }}
                                          >
                                            {control.control_evidence_status === 'Ok' ||
                                            control.control_evidence_status === 'OK'
                                              ? 'OK'
                                              : 'Unhealthy'}
                                          </a>
                                        )}
                                      </td>
                                      <td className='py-4 whitespace-nowrap'>
                                        {currentUser?.account_type === 'Trial'
                                          ? '0/0'
                                          : processControlValues(
                                              control.control_test,
                                              control.control_document
                                            )}{' '}
                                        Test passing
                                      </td>
                                      <td className='py-4 whitespace-nowrap'>
                                        {control.control_owner ? (
                                          <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-35px me-5'>
                                              <span
                                                className='symbol-label fs-7 fw-bold'
                                                style={{backgroundColor: '#DAF9EF'}}
                                              >
                                                {getInitials(control.control_owner)}
                                              </span>
                                            </div>
                                            <a
                                              href='#'
                                              className='text-dark fw-bold text-hover-primary fs-7'
                                            >
                                              {control.control_owner}
                                            </a>
                                          </div>
                                        ) : (
                                          <span>-</span>
                                        )}
                                      </td>
                                      <td className='pt-6 pb-6'>
                                        {currentUser?.account_type === 'Trial' ? (
                                          <a
                                            href='#'
                                            rel='noopener noreferrer'
                                            onClick={(e) => {
                                              e.preventDefault()
                                              setShowModal(true)
                                            }}
                                            className='d-flex align-items-center'
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='16'
                                              height='16'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='#5A5A5A'
                                              strokeWidth='2'
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                              <path d='M11 13l9 -9' />
                                              <path d='M15 4h5v5' />
                                            </svg>
                                            <span style={{color: '#5A5A5A'}}>
                                              View in full Screen
                                            </span>
                                          </a>
                                        ) : (
                                          <a
                                            href={`/controls/details/${control.id}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='d-flex align-items-center'
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='16'
                                              height='16'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              strokeWidth='2'
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                              <path d='M11 13l9 -9' />
                                              <path d='M15 4h5v5' />
                                            </svg>
                                            <span style={{color: '#5A5A5A'}}>
                                              View in full Screen
                                            </span>
                                          </a>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))
          ) : (
            <>
              {!showEmptyState ? (
                <div className='text-center'>
                  <div className='m-20'>
                    <img src='/media/buttons/emptystate.svg' alt='' />
                    <div className='m-5 fs-5'>
                      <p style={{fontWeight: 'bold'}}>No controls found</p>
                    </div>
                    <p className=''>
                      We couldn't find any controls that match your search. Maybe try a different
                      search!
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  className='d-flex justify-content-center align-items-center text-center'
                  style={{
                    height: '100vh',
                    width: 'calc(100% - 40vh)',
                    position: 'absolute',
                    top: '20vh',
                    left: '40vh',
                  }}
                >
                  <TestListLoading />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ToastContainer />
      <UpgradePlan show={showModal} onClose={() => setShowModal(false)} />
    </>
  )
}

export default SOCReports
