import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {addDoc, collection, doc, onSnapshot, serverTimestamp} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Drawer from 'react-modern-drawer'
import {ToastContainer, toast} from 'react-toastify'
import {createTask} from 'app/Integrations/Linear'

interface AddProps {
  isOpen: boolean
  onClose: () => void
}

const AddTask: React.FC<AddProps> = ({isOpen, onClose}) => {
  const {currentUser} = useAuth()
  const [dueDate, setDueDate] = useState('')
  const [taskName, setTaskName] = useState('')
  const [priority, setPriority] = useState('')
  const [controlTask, setControlTask] = useState('')
  const [testTask, setTestTask] = useState('')
  const [owners, setOwners] = useState<any[]>([])
  const [owner, setOwner] = useState('')
  const [selectedOwnerEmail, setSelectedOwnerEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const taskNameRef = useRef<HTMLInputElement | null>(null)
  const [sendEmail, setSendEmail] = useState(false)
  const [description, setDescription] = useState('')
  const [integration, setIntegration] = useState<any[]>([])
  const [sendLinearMessage, setSendLinearMessage] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (taskNameRef.current) {
          taskNameRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          uid: doc.data().uid,
          end_date: doc.data().end_date,
        }))
        .filter((data) => !data.end_date)
      setOwners(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    getIntegration()
    getActiveFramworks()
  }, [])

  const getIntegration = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'integrations')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const integrationData = snapshot.docs
          .map((item) => ({
            ...item.data(),
            id: item.id,
            name: item.data().name,
          }))
          .filter((item) => item.name === 'Linear')
        setIntegration(integrationData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!taskName.trim()) {
      errors.taskName = 'Task Name is required'
    }

    if (!priority.trim()) {
      errors.priority = 'Task priority is required'
    }

    if (!owner) {
      errors.owner = 'Task Owner is required'
    }

    if (!dueDate.trim()) {
      errors.dueDate = 'Task DueDate is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    if (!validateForm()) {
      setLoading(false)
      return
    }

    const selectedOwner = owners.find((o) => o.full_name === owner)
    const assigneeId = selectedOwner ? selectedOwner.uid : null

    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()

    const todaysDate = `${month}/${day}/${year}`

    try {
      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const tasksRef = collection(tenantRef, 'tasklists')
        await addDoc(tasksRef, {
          task_name: taskName.trim(),
          description: description,
          priority,
          owner,
          duedate: dueDate,
          control: controlTask,
          test: testTask,
          tenantId: currentUser?.tenantId,
          username: currentUser?.userName,
          date: todaysDate,
          checked: false,
          status: 'Incomplete',
          deliverable: {
            email: sendEmail,
            linear: sendLinearMessage,
          },
          assignee_id: assigneeId,
        })
        if (integration.length > 0 && integration[0].status === 'Connected' && sendLinearMessage) {
          const access_token = integration.length > 0 ? integration[0].access_token : ''
          const teamName = integration.length > 0 ? integration[0].teamName : ''

          createTask(
            access_token,
            teamName,
            taskName,
            description,
            priority,
            dueDate,
            selectedOwnerEmail
          )
            .then((task) => console.log(task))
            .catch((error) => console.error(error))
        }
        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} added a new task "${taskName.trim()}" for ${owner}`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          action: 'Task added',
          target: 'Tasks',
          email: currentUser?.email,
          date: todaysDate,
        })

        toast.success('Task assigned successfully!', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        setTaskName('')
        setPriority('')
        setOwner('')
        setControlTask('')
        setTestTask('')
        setDueDate('')
        setDescription('')
        setSendEmail(false)
        setSendLinearMessage(false)
        setTimeout(() => {
          onClose()
        }, 1500)
      }
    } catch (error) {
      console.error('Error adding task:', error)
    }

    setLoading(false)
  }

  const handleCheckedEmail = () => {
    setSendEmail(!sendEmail)
  }

  const handleTasknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskName(e.target.value)
    setFormErrors((prev) => ({...prev, taskName: ''}))
  }

  const onCancel = () => {
    setFormErrors({})
    onClose()
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onCancel} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Add Task
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onCancel}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className='p-6 scroll-y overflow-auto '
          tabIndex={-1}
          style={{height: 'calc(100vh - 30px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className='fs-6 mb-4'>
                  Delegate tasks which might help you fix your controls and tests to you team
                  members. You can also add due dates to ensure audit readiness.{' '}
                </div>
                <div className='fv-row mb-4 '>
                  <label className=' fw-semibold fs-6 mb-1 required'>Task Name</label>
                  <input
                    placeholder='Enter Task Name'
                    type='text'
                    className={`form-control ${formErrors.taskName ? 'is-invalid' : ''}`}
                    name='taskname'
                    value={taskName}
                    onChange={handleTasknameChange}
                    autoComplete='off'
                    ref={taskNameRef}
                  />
                  {formErrors.taskName && (
                    <div className='invalid-feedback'>{formErrors.taskName}</div>
                  )}
                </div>

                <div className='fv-row mb-4'>
                  <label className='fw-bold fs-6 mb-2'>Description</label>
                  <textarea
                    placeholder='Enter description for task'
                    className='form-control mb-3 mb-lg-0 w-100'
                    name='description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    autoComplete='off'
                  />
                </div>

                {/* <div className='fv-row mb-4 '>
                  <label className=' fw-semibold fs-6 mb-1'>
                    Map to control{' '}
                    <span className='fs-7' style={{color: '#5A5A5A'}}>
                      (Optional)
                    </span>
                  </label>
                  <select
                    className='form-select  mb-3 mb-lg-0 w-100'
                    value={controlTask}
                    onChange={(e) => setControlTask(e.target.value)}
                  >
                    <option selected>Select control</option>
                    <option value='Determining the scope of the information security management system'>
                      Determining the scope of the information security management system
                    </option>
                    <option value='Inventory of information and other associated assets'>
                      Inventory of information and other associated assets
                    </option>
                    <option value='Intellectual property rights'>
                      Intellectual property rights
                    </option>
                    <option value='Security of assets off-premises'>
                      Security of assets off-premises
                    </option>
                    <option value='Storage media'>Storage media</option>
                    <option value='Secure disposal or re-use of equipment'>
                      Secure disposal or re-use of equipment
                    </option>
                  </select>
                </div>

                <div className='fv-row mb-4 '>
                  <label className=' fw-semibold fs-6 mb-1'>
                    Map to test{' '}
                    <span className='fs-7' style={{color: '#5A5A5A'}}>
                      (Optional)
                    </span>
                  </label>
                  <select
                    className='form-select  mb-3 mb-lg-0 w-100'
                    value={testTask}
                    onChange={(e) => setTestTask(e.target.value)}
                  >
                    <option selected>Select Test</option>
                    <option value='A process exists to identify and prioritize security vulnerabilities'>
                      A process exists to identify and prioritize security vulnerabilities
                    </option>
                    <option value='Access request ticket and history'>
                      Access request ticket and history
                    </option>
                    <option value='Company has an approved Asset Management Policy'>
                      Company has an approved Asset Management Policy
                    </option>
                    <option value='Assets returned by employees'>
                      Assets returned by employees
                    </option>
                    <option value='Backups completed for other databases'>
                      Backups completed for other databases
                    </option>
                    <option value='Cloud provider service agreement'>
                      Cloud provider service agreement
                    </option>
                  </select>
                </div> */}

                <div className='fv-row mb-4 '>
                  <label className=' fw-semibold fs-6 mb-1 required'>Priority</label>
                  <select
                    className={`form-select  ${formErrors.priority ? 'is-invalid' : ''}`}
                    value={priority}
                    onChange={(e) => {
                      setPriority(e.target.value)
                      setFormErrors((prev) => ({...prev, priority: ''}))
                    }}
                  >
                    <option selected>Select Priority</option>
                    <option value='Low'>Low</option>
                    <option value='Medium'>Medium</option>
                    <option value='High'>High</option>
                  </select>
                  {formErrors.priority && (
                    <span className='invalid-feedback'>{formErrors.priority}</span>
                  )}
                </div>
                <div className='fv-row  '>
                  <label className=' fw-semibold fs-6 mb-1 required'>Assign to</label>
                  <select
                    data-placeholder='Select Owner'
                    onChange={(e) => {
                      const [selectedFullName, selectedEmail] = e.target.value.split('|')
                      setOwner(selectedFullName)
                      setSelectedOwnerEmail(selectedEmail)
                      setFormErrors((prev) => ({...prev, owner: ''}))
                    }}
                    value={`${owner}|${selectedOwnerEmail}`}
                    className={`form-select  ${formErrors.owner ? 'is-invalid' : ''}`}
                  >
                    <option value='' selected>
                      Select Owner
                    </option>
                    {owners
                      .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                      .map((owner) => (
                        <option key={owner.id} value={`${owner.full_name}|${owner.email}`}>
                          {owner.full_name}
                        </option>
                      ))}
                  </select>
                  {formErrors.owner && <span className='invalid-feedback'>{formErrors.owner}</span>}
                </div>

                <div className='form-group mb-4'>
                  <label className='fw-semibold text-dark mt-5 fs-6 mb-1 required'>Due date</label>
                  <input
                    type='date'
                    className={`form-control   ${formErrors.dueDate ? 'is-invalid ' : ''}`}
                    placeholder='Selcted due date'
                    name='dueDate'
                    value={dueDate}
                    onChange={(e) => {
                      setDueDate(e.target.value)
                      setFormErrors((prev) => ({...prev, dueDate: ''}))
                    }}
                  />
                  {formErrors.dueDate && (
                    <span className='invalid-feedback'>{formErrors.dueDate}</span>
                  )}
                </div>

                <div className='separator my-5'></div>

                <div className='form-group '>
                  <label className='fw-semibold text-dark fs-6 mb-4'>Delivery</label>
                  <div className='checkbox-group '>
                    <div className='form-check mb-4'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='sendEmailCheckbox'
                        checked={sendEmail}
                        onChange={(e) => setSendEmail(e.target.checked)}
                      />
                      <label className='text-dark ms-2 fs-6' htmlFor='sendEmailCheckbox'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='#5A5A5A'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-mail me-1 mb-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                          <path d='M3 7l9 6l9 -6' />
                        </svg>{' '}
                        Send email
                      </label>
                    </div>
                    {activeFrameworks.length > 0 && currentUser?.account_type !== 'Trial' && currentUser?.account_type === 'internal' && (
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='sendLinearMessageCheckbox'
                          checked={sendLinearMessage}
                          onChange={(e) => {
                            setSendLinearMessage(e.target.checked)
                          }}
                          disabled={
                            integration.length === 0 || integration[0].status !== 'Connected'
                          }
                        />
                        <label className='text-dark ms-2 fs-6' htmlFor='sendLinearMessageCheckbox'>
                          <img
                            src='/media/icon/Linear_Symbol_1.png'
                            width={18}
                            className='me-1 mb-1'
                            alt=''
                          />{' '}
                          Create issue on Linear{' '}
                          {integration.length > 0 && integration[0].teamName ? (
                            <span
                              className='badge fs-7'
                              style={{
                                color:
                                  integration[0].status === 'Connected' ? '#1CBB8D' : '#FF0000',
                                background:
                                  integration[0].status === 'Connected' ? '#E8F8F4' : '#FFCCCC',
                              }}
                            >
                              {integration[0].status}
                            </span>
                          ) : integration[0] && !integration[0].teamName ? (
                            <span
                              className='badge fs-7 badge-warning'
                              style={{cursor: 'pointer'}}
                              onClick={() =>
                                (window.location.href = `/integrations?id=${integration[0].id}&service=Linear`)
                              }
                            >
                              Select a team
                            </span>
                          ) : (
                            <span
                              className='badge fs-7 badge-secondary'
                              style={{cursor: 'pointer'}}
                              onClick={() =>
                                (window.location.href = '/integrations/available?service=Linear')
                              }
                            >
                              Connect integration
                            </span>
                          )}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className='offcanvas-footer d-flex justify-content-end  border-top mt-8 py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              <div className='d-flex justify-content-end align-items-center mb-4'>
                <button
                  type='button'
                  className='btn btn-sm btn-light fw-bold fs-6 me-4'
                  style={{height: '40px'}}
                  onClick={onCancel}
                >
                  Cancel
                </button>
                {loading ? (
                  <button
                    type='submit'
                    className='btn  btn-sm fs-6 '
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    disabled
                  >
                    Adding Task...
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                  </button>
                ) : (
                  <button
                    type='submit'
                    onClick={handleSubmit}
                    className='btn  btn-sm fs-6'
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                  >
                    Add Task
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Drawer>
      <ToastContainer />
    </>
  )
}

export default AddTask
