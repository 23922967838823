/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {useAuth} from '../../../modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../services/firebase'

type Props = {
  className: string
}

const Reports: React.FC<Props> = ({className}) => {
  const [isCrispLoaded, setIsCrispLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const {currentUser} = useAuth()
  const [totalControl, setTotalcontrols] = useState(0)
  const [totalISO, setTotalISO] = useState(0)
  const [totalSOC, setTotalSOC] = useState(0)
  const [unhealthyControlsCount, setUnhealthyControlsCount] = useState(0)
  const [healthyControlsCount, setHealthyControlsCount] = useState(0)
  const [healthyISOControlsCount, setHealthyISOControlsCount] = useState(0)
  const [healthySOCControlsCount, setHealthySOCControlsCount] = useState(0)
  const [controls, setControls] = useState<any[]>([])
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])

  useEffect(() => {
    getControls()
  }, [activeFrameworks])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getActiveFramworks = () => {
    try {
      if (currentUser?.role !== 'Auditor') {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)

        const testsCollectionRef = collection(tenantRef, 'active_frameworks')

        const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
          const frameworksData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            frameworks: doc.data().frameworks,
          }))
          const frameworks = frameworksData[0]?.frameworks || []
          setActiveFrameworks(frameworks)
        })

        return unsubscribe // Return the unsubscribe function
      } else {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)

        const testsCollectionRef = collection(tenantRef, 'audits')

        const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
          const frameworksData = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            frameworks: doc.data().framework,
            auditor:doc.data().auditor,
            status:doc.data().status
          })).filter((item)=>item.auditor?.id === currentUser?.uid && item.status !== 'Completed')
          const frameworks = frameworksData[0]?.frameworks || []
          setActiveFrameworks(frameworks)
        })

        return unsubscribe // Return the unsubscribe function
      }
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }
  
  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        setControls(filteredControls)

        setTotalcontrols(filteredControls.length)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77'

      const script = document.createElement('script')
      script.src = 'https://client.crisp.chat/l.js'
      script.async = true

      script.onload = () => {
        setIsCrispLoaded(true)
        const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
        if (wasChatOpen) {
          window.$crisp.push(['do', 'chat:show'])
          window.$crisp.push(['do', 'chat:open'])
          setIsChatOpen(true)
        } else {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
        }
      }

      document.head.appendChild(script)
    } else {
      setIsCrispLoaded(true)
      const wasChatOpen = localStorage.getItem('isChatOpen') === 'true'
      if (wasChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        setIsChatOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isCrispLoaded) {
      if (isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
      } else {
        window.$crisp.push(['do', 'chat:hide'])
      }
    }
  }, [isChatOpen, isCrispLoaded])

  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide'])
          setIsChatOpen(false)
          localStorage.setItem('isChatOpen', 'false')
        },
      ])
    }
  }, [isCrispLoaded])

  const handleCrispChat = () => {
    if (isCrispLoaded) {
      if (!isChatOpen) {
        window.$crisp.push(['do', 'chat:show'])
        window.$crisp.push(['do', 'chat:open'])
        setIsChatOpen(true)
        localStorage.setItem('isChatOpen', 'true')
      } else {
        window.$crisp.push(['do', 'chat:hide'])
        window.$crisp.push(['do', 'session:reset'])
        setIsChatOpen(false)
        localStorage.setItem('isChatOpen', 'false')
      }
    } else {
      console.log('Crisp is not loaded yet. Please wait a moment and try again.')
    }
  }

  useEffect(() => {
    const unhealthyControls = controls.filter((doc) => {
      const status = doc.control_evidence_status
      return status && status === 'Unhealthy'
    })

    // Count the number of documents with status "Unhealthy"
    const unhealthyControlsCount = unhealthyControls.length

    // Update state with the count of "Unhealthy" controls
    setUnhealthyControlsCount(unhealthyControlsCount)

    const iso27001Controls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      return framework && framework.includes('ISO')
    })

    // Count the number of documents that start with "ISO 27001"
    const iso27001ControlsCount = iso27001Controls.length
    setTotalISO(iso27001ControlsCount)

    const SOC2Controls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings
      return framework && framework.startsWith('SOC')
    })

    // Count the number of documents that start with "ISO 27001"
    const SOC2ControlsCount = SOC2Controls.length
    setTotalSOC(SOC2ControlsCount)
  }, [controls])

  useEffect(() => {
    if (!controls || controls.length === 0) {
      return
    }

    const HealthyControls = controls.filter((doc) => {
      const status = doc.control_evidence_status || ''
      return status.toLowerCase() === 'ok'
    })
    const HealthyControlsCount = HealthyControls.length
    setHealthyControlsCount(HealthyControlsCount)

    // Filter documents with framework containing "ISO" and status "Ok"
    const isoHealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings || ''
      const status = doc.control_evidence_status || ''
      return framework.includes('ISO') && status.toLowerCase() === 'ok'
    })

    // Count the number of documents with framework containing "ISO" and status "Ok"
    const isoHealthyControlsCount = isoHealthyControls.length

    // Update state with the count of "ISO" controls with status "Ok"
    setHealthyISOControlsCount(isoHealthyControlsCount)

    // Filter documents with framework containing "SOC" and status "OK"
    const socHealthyControls = controls.filter((doc) => {
      const framework = doc.control_framework_mappings || ''
      const status = doc.control_evidence_status || ''
      return framework.includes('SOC') && status.toLowerCase() === 'ok'
    })

    // Count the number of documents with framework containing "SOC" and status "OK"
    const socHealthyControlsCount = socHealthyControls.length

    // Update state with the count of "SOC" controls with status "OK"
    setHealthySOCControlsCount(socHealthyControlsCount)
  }, [controls])
  return (
    <>
      <Helmet>
        <title>Secureslate | Framework</title>
      </Helmet>
      <div className='row gx-8 gy-1 '>
        {activeFrameworks.includes('ISO 27001:2022') ? (
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className={`card ${className}  h-90 d-flex flex-column mb-0`}>
              <div className='card-body flex-column'>
                <div className='flex-grow-1'>
                  <div className='mixed-widget-4-chart pt-1'>
                    <img src='/media/logos/ISO.svg' className='img-fluid' alt='' />
                  </div>
                </div>

                <div className='mt-4'>
                  <h2 className=''>ISO 27001:2022</h2>
                  <p
                    className='fs-6 p-0 text-wrap mb-4'
                    style={{color: '#5A5A5A', maxHeight: '3rem'}}
                  >
                    Your progress towards ISO 27001:2022 compliance based on your controls and
                    tests.
                  </p>

                  <div className='row align-items-center p-0 my-4'>
                    <div className='col-auto mx-3 p-0 mb-3 mb-lg-0 mt-8'>
                      <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                        <CircularProgressbar
                          value={(healthyISOControlsCount / totalISO) * 100}
                          // text={`${percentage}%`}
                          strokeWidth={15}
                          styles={buildStyles({
                            strokeLinecap: 'round',
                            pathTransitionDuration: 0.5,
                            pathColor: `#44dbae`,
                            textColor: '#000000',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#3e98c7',
                          })}
                        />
                      </div>
                    </div>

                    <div
                      className='col fw-semibold p-0 mb-3 mb-lg-0 mt-8'
                      style={{color: '#171717'}}
                    >
                      {' '}
                      Controls OK:{' '}
                      {totalISO !== 0 ? (
                        `${((healthyISOControlsCount / totalISO) * 100).toFixed(0)}%`
                      ) : (
                        <span className='fs-7 fw-semibold' style={{color: '5A5A5A'}}>
                          Loading...
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div style={{height: '40px'}}>
                  <a
                    href='/frameworks/details/ISO 27001:2022'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={`btn w-100 `}
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF'}}
                  >
                    View Framework
                  </a>
                </div> */}
                <div style={{height: '40px'}}>
                  <a
                    href='/frameworks/details/ISO 27001:2022'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={`btn w-100 `}
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                  >
                    View Framework
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className={`card ${className}  h-90 d-flex flex-column mb-0`}>
              <div className='card-body flex-column'>
                <div className='flex-grow-1'>
                  <div className='mixed-widget-4-chart pt-1'>
                    <img src='/media/logos/ISO.svg' className='img-fluid' alt='' />
                  </div>
                </div>

                <div className='mt-4'>
                  <h2 className=''>ISO 27001:2022</h2>
                  <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                    Your progress towards ISO 27001:2022 compliance based on your controls and
                    tests.
                  </p>
                  <div className='row align-items-center p-0 my-4'>
                    <div
                      className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                      style={{color: '#171717'}}
                    >
                      Contact sales to enable this framework
                    </div>
                  </div>
                </div>
                <div style={{height: '40px'}}>
                  <a
                    href='#'
                    className={`btn w-100`}
                    style={{border: 'solid 1.5px #C5C5C5'}}
                    onClick={() => handleCrispChat()}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5A5A5A'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                      <path d='M3 7l9 6l9 -6' />
                    </svg>{' '}
                    Contact Sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeFrameworks.includes('SOC 2') ? (
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className={`card ${className}  h-90 d-flex flex-column`}>
              <div className='card-body flex-column'>
                <div className='flex-grow-1'>
                  <div className='mixed-widget-4-chart pt-1'>
                    <img src='/media/logos/SOC.svg' className='img-fluid' alt='' />
                  </div>
                </div>

                <div className='mt-4 '>
                  <h2 className=''>SOC 2</h2>
                  <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                    Your progress towards SOC 2 compliance based on your controls and tests.
                  </p>

                  <div className='row align-items-center p-0  my-4'>
                    <div className='col-auto mx-3 p-0 mb-3 mb-lg-0 mt-8'>
                      <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                        <CircularProgressbar
                          value={(healthySOCControlsCount / totalSOC) * 100}
                          // text={`${percentage}%`}
                          strokeWidth={15}
                          styles={buildStyles({
                            strokeLinecap: 'round',
                            pathTransitionDuration: 0.5,
                            pathColor: `#44dbae`,
                            textColor: '#000000',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#3e98c7',
                          })}
                        />
                      </div>
                    </div>

                    <div
                      className='col fw-semibold p-0 mb-4 mt-4 mb-lg-0 mt-8'
                      style={{color: '#171717'}}
                    >
                      {' '}
                      Controls OK:{' '}
                      {totalSOC !== 0
                        ? `${((healthySOCControlsCount / totalSOC) * 100).toFixed(0)}%`
                        : 'Loading...'}
                    </div>
                  </div>
                </div>
                <div style={{height: '40px'}}>
                  <a
                    href='/frameworks/details/SOC_2'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={`btn w-100 `}
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                  >
                    View Framework
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className={`card ${className}  h-90 d-flex flex-column`}>
              <div className='card-body flex-column'>
                <div className='flex-grow-1'>
                  <div className='mixed-widget-4-chart pt-1'>
                    <img src='/media/logos/SOC.svg' className='img-fluid' alt='' />
                  </div>
                </div>

                <div className='mt-4'>
                  <h2 className=''>SOC 2</h2>
                  <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                    Your progress towards SOC 2 compliance based on your controls and tests.
                  </p>
                  <div className='row align-items-center p-0 my-4'>
                    <div
                      className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                      style={{color: '#171717'}}
                    >
                      Contact sales to enable this framework
                    </div>
                  </div>
                </div>
                <div style={{height: '40px'}}>
                  <a
                    href='#'
                    className={`btn w-100`}
                    style={{border: 'solid 1.5px #C5C5C5'}}
                    onClick={() => handleCrispChat()}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5A5A5A'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                      <path d='M3 7l9 6l9 -6' />
                    </svg>{' '}
                    Contact Sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <img src='/media/logos/ISO.svg' className='img-fluid' alt='' />
                </div>
              </div>

              <div className='mt-4'>
                <h2 className=''>ISO 9001</h2>
                <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  Your progress towards ISO 9001 compliance based on your controls and tests.
                </p>
                <div className='row align-items-center p-0 my-4'>
                  {/* <div className='col-auto mx-2 p-0 mb-3 mb-lg-0'>
                    <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                      <CircularProgressbar
                        value={percentage}
                        // text={`${percentage}%`}
                        strokeWidth={15}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          pathTransitionDuration: 0.5,
                          pathColor: `#44dbae`,
                          textColor: '#000000',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#3e98c7',
                        })}
                      />
                    </div>
                  </div>

                  <div className='col text-muted p-0 mb-3 mb-lg-0'> Controls Ok: {percentage}%</div>
                </div> */}

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this framework
                  </div>
                </div>
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100`}
                  onClick={() => handleCrispChat()}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        {activeFrameworks.includes('GDPR') ? (
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className={`card ${className}  h-90 d-flex flex-column`}>
              <div className='card-body flex-column'>
                <div className='flex-grow-1'>
                  <div className='mixed-widget-4-chart pt-1'>
                    <img src='/media/logos/GDPRFramework.svg' className='img-fluid' alt='' />
                  </div>
                </div>

                <div className='mt-4 '>
                  <h2 className=''>GDPR</h2>
                  <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                    Your progress towards GDPR compliance based on your controls and tests.
                  </p>

                  <div className='row align-items-center p-0  my-4'>
                    <div className='col-auto mx-3 p-0 mb-3 mb-lg-0 mt-8'>
                      <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                        <CircularProgressbar
                          value={(healthySOCControlsCount / totalSOC) * 100}
                          // text={`${percentage}%`}
                          strokeWidth={15}
                          styles={buildStyles({
                            strokeLinecap: 'round',
                            pathTransitionDuration: 0.5,
                            pathColor: `#44dbae`,
                            textColor: '#000000',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#3e98c7',
                          })}
                        />
                      </div>
                    </div>

                    <div
                      className='col fw-semibold p-0 mb-4 mt-4 mb-lg-0 mt-8'
                      style={{color: '#171717'}}
                    >
                      {' '}
                      Controls OK:{' '}
                      {totalSOC !== 0
                        ? `${((healthySOCControlsCount / totalSOC) * 100).toFixed(0)}%`
                        : 'Loading...'}
                    </div>
                  </div>
                </div>
                <div style={{height: '40px'}}>
                  <a
                    href='/frameworks/details/SOC_2'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={`btn w-100 `}
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                  >
                    View Framework
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className={`card ${className}  h-90 d-flex flex-column`}>
              <div className='card-body flex-column'>
                <div className='flex-grow-1'>
                  <div className='mixed-widget-4-chart pt-1'>
                    <img src='/media/logos/GDPRFramework.svg' className='img-fluid' alt='' />
                  </div>
                </div>

                <div className='mt-4'>
                  <h2 className=''>GDPR</h2>
                  <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                    Your progress towards GDPR compliance based on your controls and tests.
                  </p>
                  <div className='row align-items-center p-0 my-4'>
                    <div
                      className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                      style={{color: '#171717'}}
                    >
                      Contact sales to enable this framework
                    </div>
                  </div>
                </div>
                <div style={{height: '40px'}}>
                  <a
                    href='#'
                    className={`btn w-100`}
                    style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#C5C5C5')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#ffffff')
                    }
                    onClick={() => handleCrispChat()}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5A5A5A'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                      <path d='M3 7l9 6l9 -6' />
                    </svg>{' '}
                    Contact Sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <img src='/media/logos/HIPPAFramework.svg' className='img-fluid' alt='' />
                </div>
              </div>

              <div className='mt-4'>
                <h2 className=''>HIPAA</h2>
                <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  Your progress towards HIPAA compliance based on your controls and tests.
                </p>
                <div className='row align-items-center p-0 my-4'>
                  {/* <div className='col-auto mx-2 p-0 mb-3 mb-lg-0'>
                    <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                      <CircularProgressbar
                        value={percentage}
                        // text={`${percentage}%`}
                        strokeWidth={15}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          pathTransitionDuration: 0.5,
                          pathColor: `#44dbae`,
                          textColor: '#000000',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#3e98c7',
                        })}
                      />
                    </div>
                  </div>

                  <div className='col text-muted p-0 mb-3 mb-lg-0'> Controls Ok: {percentage}%</div>
                </div> */}

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this framework
                  </div>
                </div>
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100`}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <img src='/media/logos/PCIDSS.svg' className='img-fluid' alt='' />
                </div>
              </div>

              <div className='mt-4'>
                <h2 className=''>PCI DSS</h2>
                <p className='fs-6 p-0 text-wrap' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  Your progress towards PCI DSS compliance based on your controls and tests.
                </p>
                <div className='row align-items-center p-0 my-4'>
                  {/* <div className='col-auto mx-2 p-0 mb-3 mb-lg-0'>
                    <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                      <CircularProgressbar
                        value={percentage}
                        // text={`${percentage}%`}
                        strokeWidth={15}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          pathTransitionDuration: 0.5,
                          pathColor: `#44dbae`,
                          textColor: '#000000',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#3e98c7',
                        })}
                      />
                    </div>
                  </div>

                  <div className='col text-muted p-0 mb-3 mb-lg-0'> Controls Ok: {percentage}%</div>
                </div> */}

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this framework
                  </div>
                </div>
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100`}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
          <div className={`card ${className}  h-90 d-flex flex-column`}>
            <div className='card-body flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart pt-1'>
                  <img src='/media/logos/CCPAFramework.svg' className='img-fluid' alt='' />
                </div>
              </div>

              <div className='mt-4'>
                <h2 className=''>CCPA</h2>
                <p className='fs-6 p-0 text-wrap ' style={{color: '#5A5A5A', maxHeight: '3rem'}}>
                  Your progress towards CCPA compliance based on your controls and tests.
                </p>
                <div className='row align-items-center p-0 my-4'>
                  {/* <div className='col-auto mx-2 p-0 mb-3 mb-lg-0'>
                    <div style={{maxWidth: '20px', maxHeight: '100px'}}>
                      <CircularProgressbar
                        value={percentage}
                        // text={`${percentage}%`}
                        strokeWidth={15}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          pathTransitionDuration: 0.5,
                          pathColor: `#44dbae`,
                          textColor: '#000000',
                          trailColor: '#d6d6d6',
                          backgroundColor: '#3e98c7',
                        })}
                      />
                    </div>
                  </div>

                  <div className='col text-muted p-0 mb-3 mb-lg-0'> Controls Ok: {percentage}%</div>
                </div> */}

                  <div
                    className='col p-0 mb-3 mx-3 mb-lg-0 fw-semibold mt-8'
                    style={{color: '#171717'}}
                  >
                    Contact sales to enable this framework
                  </div>
                </div>
              </div>
              <div style={{height: '40px'}}>
                <a
                  href='#'
                  className={`btn w-100 `}
                  style={{border: 'solid 1.5px #C5C5C5', backgroundColor: '#fffff'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#C5C5C5')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#ffffff')
                  }
                  onClick={() => handleCrispChat()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-mail mb-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
                    <path d='M3 7l9 6l9 -6' />
                  </svg>{' '}
                  Contact Sales
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='col-xl-4'>
          <div className={`card ${className}`}>
            <div className='card-body d-flex justify-content-center text-center flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart'>
                  <img src='/media/logos/ISO 27001.svg' alt='' />
                </div>
              </div>

              <div className='pt-5'>
                <h3 className='text-center fs-6 pb-5 '>ISO 27001 Readiness Assessment</h3>

                <a
                  href='#'
                  className={`btn w-100 py-3`}
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className='col-xl-4'>
          <div className={`card ${className}`}>
            <div className='card-body d-flex justify-content-center text-center flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart'>
                  <img src='/media/logos/HIPAA.svg'alt='' />
                </div>
              </div>

              <div className='pt-5'>
                <h3 className='text-center fs-6 pb-5 '>HIPAA Readiness Assessment</h3>

                <a
                  href='#'
                  className={`btn w-100 py-3`}
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-4'>
          <div className={`card ${className}`}>
            <div className='card-body d-flex justify-content-center text-center flex-column'>
              <div className='flex-grow-1'>
                <div className='mixed-widget-4-chart'>
                  <img src='/media/logos/GDPR.svg' alt='' />
                </div>
              </div>

              <div className='pt-5'>
                <h3 className='text-center fs-6 pb-5 '>GDPR Readiness Assessment</h3>

                <a
                  href='#'
                  className={`btn w-100 py-3`}
                  style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                >
                  View Report
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export {Reports}
