import {getFirestore, doc, collection, getDocs, addDoc, updateDoc} from 'firebase/firestore'
import {getStorage, ref, getDownloadURL} from 'firebase/storage'
import JSZip from 'jszip'
import {uploadBytes} from 'firebase/storage'

export const ExportZipPolicy = async (
  currentUser,
  frameworks,
  auditFolderName,
  folderId,
  formattedDate,
  auditID
) => {
  const frameworksArray = typeof frameworks === 'string' ? [frameworks] : frameworks
  if (currentUser) {
    const db = getFirestore()
    const storage = getStorage()
    const tenantId = currentUser?.tenantId
    const tenantRef = doc(db, 'tenants', tenantId!)
    const PolicyRef = collection(tenantRef, 'policies')
    const querySnapshot = await getDocs(PolicyRef)

    const data = querySnapshot.docs.map((doc) => doc.data())

    // Initialize the ZIP object
    const zip = new JSZip()

    let frameworkFileCount = 0;

    for (const framework of frameworksArray) {
      const filteredPolicies = data.filter((policy) => {
        if (policy.author === 'Custom Policy') return true
        if (!policy.frameworks) return false
        return policy.frameworks.includes(framework)
      })

      if (filteredPolicies.length > 0) {
        // Iterate over each policy and download its custom files
        for (const policy of filteredPolicies) {
          const customFiles = policy.customfile || []

          for (const file of customFiles) {
            const fileUrl = file.url
            const CfileName = file.name
            if (currentUser?.uid) {
              try {
                // Fetch the file using the URL
                const response = await fetch(
                  'https://us-central1-slate-134a0.cloudfunctions.net/generateSignedUrl',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      filePath: `tenants/${currentUser?.tenantId}/policy/policies_documents/${CfileName}`
                    }),
                  }
                );
                const data: { signedUrl?: string } = await response.json();

                if (!data.signedUrl) {
                  throw new Error('No signed URL in response');
                }
                const fileBlob = await downloadFileWithRetry(data.signedUrl);
                if (!fileBlob || fileBlob.size === 0) return;
    
                // Add to zip with proper file name handling
                const fileName = sanitizeFileName(file.name || `file_${frameworkFileCount + 1}`);
                zip.file(fileName, fileBlob);

                // Add the file to the zip
                zip.file(fileName, fileBlob);

                // Increment the file count
                frameworkFileCount++;
              } catch (error) {
                console.error(`Error fetching file: ${fileUrl}`, error);
              }

            }
          }
        }
        const zipBuffer = await zip.generateAsync({type: 'blob'})

        // Define the zip file name
        const zipFileName = `${formattedDate} - policies evidence- ${framework}.zip`

        // Upload the zip file to Firebase Storage
        const storageRef = ref(
          storage,
          `tenants/${tenantId}/dataroom/${auditFolderName}/${zipFileName}`
        )
        await uploadBytes(storageRef, zipBuffer)

        // Get the download URL of the uploaded zip file
        const downloadURL = await getDownloadURL(storageRef)

        const dataRoomRef = collection(tenantRef, 'dataroom')

        const fileDoc = {
          createdAt: new Date().toISOString(),
          name: zipFileName,
          contentType: 'application/zip',
          downloadUrl: downloadURL,
          fileSize: zipBuffer.size,
          subFolder: storageRef.fullPath,
          time_created: formattedDate,
          tenantId: tenantId,
          uid: currentUser?.uid,
          is_folder: false,
          uploadedBy: currentUser?.userName,
          parentFolder: folderId,
          framework: '',
          fileId: '',
        }

        const docRef = await addDoc(dataRoomRef, fileDoc)
        await updateDoc(docRef, {fileId: docRef.id})

        await updateAuditDocument(currentUser, auditID, frameworkFileCount)
      }
    }
  }
}

const downloadFileWithRetry = async (url, maxRetries = 3) => {
  const timeout = 30000;
  
  for (let i = 0; i < maxRetries; i++) {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);
      
      const response = await fetch(url, { signal: controller.signal });
      clearTimeout(timeoutId);
      
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const blob = await response.blob();
      if (blob.size === 0) throw new Error('Empty file received');
      
      return blob; 
    } catch (error) {
      if (i === maxRetries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
    }
  }
};


const sanitizeFileName = (fileName) => {
  return fileName
    .replace(/[^a-zA-Z0-9.-]/g, '_')
    .replace(/_{2,}/g, '_')
    .trim();
};



const updateAuditDocument = async (currentUser, auditId, fileCount) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId || ''
  const tenantRef2 = doc(db, 'tenants', tenantId)
  const policyRef = doc(tenantRef2, 'audits', auditId)

  await updateDoc(policyRef, {TotalPoLicyFiles: fileCount})
}