import React, {useEffect, useState} from 'react'
import Drawer from 'react-modern-drawer'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useAuth} from 'app/modules/auth'
import db from 'services/firebase'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {createLinearOAuthInstance, initiateLinearOAuth} from '../../../../Integrations/Linear'
import useCrispChat from 'app/Hooks/useCrispChat'

interface AddLinearProps {
  id?: string
  isOpen: boolean
  onClose: () => void
}
const LinearDrawer: React.FC<AddLinearProps> = ({id, isOpen, onClose}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [openAccordion, setOpenAccordion] = useState(false)
  const [openAccordion2, setOpenAccordion2] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [apiKey, setApiKey] = useState('')
  const [teamName, setTeamName] = useState('')
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const {currentUser} = useAuth()
  const [integration, setIntegration] = useState<any[]>([])
  const [showPassword, setShowPassword] = useState(false)
  const [teams, setTeams] = useState<any[]>([])
  const [controls, setControls] = useState<any[]>([])
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const {toggleCrispChat} = useCrispChat()

  useEffect(() => {
    if (isOpen && id) {
      getIntegration()
    }
  }, [id, isOpen])

  useEffect(() => {
    if (isOpen && integration.length > 0 && activeFrameworks) {
      getControls()
    }
  }, [isOpen, integration, activeFrameworks])

  const getIntegration = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!id) {
        return
      }
      const integrationRef = doc(db, 'tenants', tenantId, 'integrations', id)

      const unsubscribe = onSnapshot(integrationRef, (doc) => {
        if (doc.exists()) {
          const integrationData = {...doc.data(), id: doc.id}
          setIntegration([integrationData])
        }
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting integration:', error)
    }
  }

  useEffect(() => {
    const unsubscribe = getControls()

    return () => unsubscribe && unsubscribe()
  }, [activeFrameworks])

  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    const setupSubscription = async () => {
      try {
        const tenantId = currentUser?.tenantId

        if (!tenantId) {
          setActiveFrameworks([])
          return
        }

        const tenantRef = doc(db, 'tenants', tenantId)
        const frameworksCollectionRef = collection(tenantRef, 'active_frameworks')

        unsubscribe = onSnapshot(
          frameworksCollectionRef,
          (snapshot) => {
            const frameworksData = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
              frameworks: doc.data().frameworks,
            }))

            const frameworks = frameworksData[0]?.frameworks || []
            setActiveFrameworks(frameworks)
          },
          (error) => {
            console.error('Firestore subscription error:', error)
          }
        )
      } catch (error) {
        console.error('Setup subscription error:', error)
      }
    }

    setupSubscription()

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [currentUser?.tenantId])

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
          author: doc.data().author,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (control.author === 'Custom Control') return true

          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })

        const filteredIntegrationControls = filteredControls.filter((control) =>
          integration[0]?.control_id?.includes(control.id)
        )

        setControls(filteredIntegrationControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  useEffect(() => {
    if (integration.length > 0) {
      fetchTeamIdByName(integration[0].access_token)
    }
  }, [integration])

  const handleClose = () => {
    setCompleted(false)
  }

  const handleConnects = async () => {
    setCompleted(true)
    const {checkTickets} = await import('../../../../Integrations/Linear')
    checkTickets(apiKey, teamName)
  }

  const validateForm = () => {
    const errors: Record<string, string> = {}

    // if (!apiKey.trim()) {
    //   errors.apiKey = 'API key is required'
    // }

    if (!teamName.trim()) {
      errors.teamName = 'Team Name is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()

    if (!id && !validateForm()) {
      setIsLoading(false)
      return
    }

    if (currentUser) {
      const tenantRef = doc(db, 'tenants', currentUser.tenantId!)

      if (id) {
        const tenantId = currentUser.tenantId || ''
        const docRef = doc(db, 'tenants', tenantId, 'integrations', id || '')

        await updateDoc(docRef, {teamName: teamName, status: 'Connected'})
      }

      setCompleted(true)
      toast.success('Linear integration connected successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      window.scrollTo(0, 0)
    }
    setTimeout(() => {
      onClose()
    }, 1000)
    setIsLoading(false)
  }

  const handleTeamName = async (teamName) => {
    setIsLoading(true)
    if (!id && !validateForm()) {
      setIsLoading(false)
      return
    }

    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()

    const todaysDate = month + '/' + day + '/' + year

    if (currentUser) {
      if (id) {
        const tenantId = currentUser.tenantId || ''
        const docRef = doc(db, 'tenants', tenantId, 'integrations', id || '')

        await updateDoc(docRef, {teamName: teamName})
      }
      const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} has updated the ${teamName} team in the Linear integration.`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Linear update',
        target: 'Integration',
        email: currentUser?.email,
        username: currentUser?.userName,
        date: todaysDate,
      })

      setCompleted(true)
      toast.success('Linear team name updated successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      window.scrollTo(0, 0)
    }
    setTimeout(() => {
      onClose()
    }, 1000)
    setIsLoading(false)
  }

  const handleDisconnect = async () => {
    if (currentUser) {
      const tenantId = currentUser.tenantId || ''
      const docRef = doc(db, 'tenants', tenantId, 'integrations', id || '')

      await updateDoc(docRef, {status: 'Disconnected'})
      setCompleted(true)
      toast.success('Linear integration disconnected successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
  }

  async function fetchTeamIdByName(accessToken: string) {
    const axiosInstance = await createLinearOAuthInstance(accessToken)

    const query = `
      query Teams {
        teams {
          nodes {
            id
            name
          }
        }
      }
    `

    try {
      const response = await axiosInstance.post('', {query})
      const teams = response.data.data.teams.nodes

      if (teams.length > 0) {
        setTeams(teams)
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  }

  const handleDeleteIntegration = async (item) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${item.name} integration`,
        text: `Are you sure you want to remove ${item.name} integration? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'integrations')
        const docRef = doc(policyRef, item.id)
        await deleteDoc(docRef)
        onClose()
        toast.success(`${item.name} integration has been deleted successfully!`, {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the integration.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Linear
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 40px)'}}
        >
          {integration.length > 0 ? (
            integration.map((item) => (
              <div className='form'>
                <div className='card-header border-0  mb-4'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/integrations/Linear.svg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='card-label fw-bold fs-5'>Linear</span>
                        <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                          Project Management
                        </span>
                      </div>
                    </div>
                    {item.status === 'Connected' ? (
                      <span className='badge border p-2 rounded-pill'>
                        <span
                          className={`badge border rounded-circle mx-2 bg-primary`}
                          style={{
                            width: '12px',
                            height: '12px',
                            padding: '3px',
                          }}
                        >
                          {' '}
                        </span>
                        <span className='text-center'>Connected</span>
                      </span>
                    ) : (
                      <span className='badge border p-2 rounded-pill'>
                        <span
                          className={`badge border rounded-circle mx-2 bg-danger`}
                          style={{
                            width: '12px',
                            height: '12px',
                            padding: '3px',
                          }}
                        >
                          {' '}
                        </span>
                        <span className='text-center'>Not Connected</span>
                      </span>
                    )}
                  </div>
                </div>
                <label className='text-dark fw-bold text-primary my-2 fs-6 mb-4'>
                  Mapped Controls
                </label>
                <div className={`card  mb-4 ${openAccordion ? 'px-6 pt-6' : 'p-6'}`}>
                  <div
                    className='d-flex'
                    onClick={() => setOpenAccordion(!openAccordion)}
                    style={{cursor: 'pointer'}}
                  >
                    <div className='row w-100'>
                      <div className='col'>
                        <div className='d-flex align-items-center'>
                          <span>
                            {openAccordion ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M6 9l6 6l6 -6' />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M9 6l6 6l-6 6' />
                              </svg>
                            )}
                          </span>
                          <span className='fs-6 fw-bold mx-4'>Controls</span>
                        </div>
                      </div>

                      <div className='col-auto d-flex align-items-center ml-auto'>
                        <div className='d-flex justify-content-end'>
                          <div className='fw-semibold'>{controls.length} controls</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {openAccordion && (
                    <table className='mt-3 '>
                      <thead>
                        <tr className='py-4 border-bottom fw-bold' style={{color: '#5A5A5A'}}>
                          <th className='min-w-250px'></th>
                          <th className='min-w-100px'></th>
                          <th className='min-w-100px'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {controls.map((control) => (
                          <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                            <td className='pt-6 pb-4'>
                              {' '}
                              <a
                                href={`/controls/details/${control.id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='d-flex align-items-center text-dark'
                              >
                                {control.control_title}
                              </a>
                            </td>
                            <td className='pt-6 pb-4'>
                              {control.control_evidence_status === 'Deactivated' ? (
                                <span
                                  className='badge fs-7 text-dark text-bold m-1'
                                  style={{background: '#F6F6F6'}}
                                >
                                  {control.control_evidence_status}
                                </span>
                              ) : (
                                <span
                                  className={`badge fs-7 ${
                                    control.control_evidence_status === 'Ok' ||
                                    control.control_evidence_status === 'OK'
                                      ? 'badge-primary'
                                      : 'badge-danger'
                                  }`}
                                  style={{
                                    color:
                                      control.control_evidence_status === 'Ok' ||
                                      control.control_evidence_status === 'OK'
                                        ? '#1CBB8D'
                                        : '#EE3043',
                                    background:
                                      control.control_evidence_status === 'Ok' ||
                                      control.control_evidence_status === 'OK'
                                        ? '#E8F8F4'
                                        : '#FFF5F8',
                                  }}
                                >
                                  {control.control_evidence_status === 'Ok' ||
                                  control.control_evidence_status === 'OK'
                                    ? 'OK'
                                    : 'Unhealthy'}
                                </span>
                              )}
                            </td>
                            <td className='pt-6 pb-4'>
                              <span className='badge border p-2 rounded-pill me-2'>
                                <span className='text-center'>
                                  {control.control_framework_mappings}
                                </span>
                              </span>{' '}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
               
                <div className='separator my-6'></div>

                <div className='card mb-4 p-4' style={{background: '#F6F6F6'}}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-key mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z' />
                      <path d='M15 9h.01' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>Permissions</span>
                  </div>

                  <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                    SecureSlate will be granted read and write access to your Linear account to
                    access and add data.
                  </div>
                </div>

                <div className='card p-4' style={{background: '#F6F6F6'}}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-user mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
                      <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>Access Type</span>
                  </div>

                  <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                    You need to have access to an Linear admin account to complete the integration
                    process.
                  </div>
                </div>

                <div className='separator  my-6'></div>

                <>
                  <div className='mb-8'>
                    <div className='fs-6 fw-bold text-bold mb-4'>
                      {' '}
                      Connect Linear to SecureSlate
                    </div>
                    {/* <div className='mb-4 position-relative'>
                      <label className='fw-semibold mb-2 fs-6'>API key</label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className='form-control'
                        placeholder='Enter API'
                        value={apiKey || item.apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                      />
                      <span
                        className='position-absolute top-50 translate-middle-y end-0 mt-4 me-3'
                        style={{cursor: 'pointer'}}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-eye'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                            <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-eye-off'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M10.585 10.587a2 2 0 0 0 2.829 2.828' />
                            <path d='M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87' />
                            <path d='M3 3l18 18' />
                          </svg>
                        )}
                      </span>
                    </div> */}
                    <div className='mb-4'>
                      <label className='fw-semibold mb-2 fs-6'>Team Name</label>
                      <select
                        className={`form-select ${formErrors.teamName ? 'is-invalid' : ''}`}
                        value={teamName || item.teamName}
                        onChange={(e) => {
                          setTeamName(e.target.value)
                          if (formErrors.teamName && e.target.value.trim() !== '') {
                            const updatedErrors = {...formErrors}
                            delete updatedErrors.teamName
                            setFormErrors(updatedErrors)
                          }
                          handleTeamName(e.target.value)
                        }}
                      >
                        <option value=''>Select Team</option>
                        {teams.map((team) => (
                          <option key={team.id} value={team.name}>
                            {team.name}
                          </option>
                        ))}
                      </select>
                      {formErrors.teamName && (
                        <span className='text-danger'>{formErrors.teamName}</span>
                      )}
                    </div>

                    <div className='card mb-8 p-4' style={{background: '#F6F6F6'}}>
                      <div className='mt-1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-help mb-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                          <path d='M12 17l0 .01' />
                          <path d='M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4' />
                        </svg>
                        <span className='fw-bold fs-6 mx-2'>
                          Need help connecting Linear to SecureSlate?
                        </span>
                      </div>

                      <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                        Take a look at <a href='#'> our guide </a> for Linear integration with
                        SecureSlate. Need further assistance?{' '}
                        <a href='#' onClick={() => toggleCrispChat()}>
                          {' '}
                          Send us a chat.{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                </>
                <div className='separator my-5'></div>
                <>
                  <div className='mb-4'>
                    <div className='fs-6 fw-bold text-bold mb-4'>Remove integration</div>
                  </div>

                  <div className='card mb-8 p-4' style={{background: '#F6F6F6'}}>
                    <div className='mt-1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-plug-x mb-1'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M13.55 17.733a5.806 5.806 0 0 1 -7.356 -4.052a5.81 5.81 0 0 1 1.537 -5.627l2.054 -2.054l7.165 7.165' />
                        <path d='M4 20l3.5 -3.5' />
                        <path d='M15 4l-3.5 3.5' />
                        <path d='M20 9l-3.5 3.5' />
                        <path d='M16 16l4 4' />
                        <path d='M20 16l-4 4' />
                      </svg>
                      <span className='fw-bold fs-6 mx-2'>
                        Are you sure you want to remove Linear?
                      </span>
                    </div>

                    <div className='fs-6 mt-2 ms-7' style={{color: '#5A5A5A'}}>
                      Disconnecting Linear integration will halt all synced data.
                    </div>
                    <button
                      className='fs-6 ms-7 fw-semibold rounded border-0 mt-4 mx-1'
                      onClick={() => handleDeleteIntegration(item)}
                      style={{
                        background: '#FDEAEC',
                        color: '#EE3043',
                        height: '40px',
                        width: '80px',
                        top: '20px',
                        left: '223px',
                      }}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                        e.currentTarget.style.backgroundColor = '#C72C41'
                        e.currentTarget.style.color = '#FFFFFF'
                      }}
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) => {
                        e.currentTarget.style.backgroundColor = '#FDEAEC'
                        e.currentTarget.style.color = '#EE3043'
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </>

                <div
                  className='offcanvas-footer d-flex justify-content-end  border-top py-4'
                  style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
                >
                  {item.status === 'Connected' ? (
                    <button
                      type='submit'
                      value='Submit'
                      className='btn btn-sm fs-6 '
                      onClick={handleDisconnect}
                      style={{backgroundColor: '#EE3043', color: '#FFFFFF', height: '40px'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#C72C41')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#EE3043')
                      }
                    >
                      Disconnect
                    </button>
                  ) : isLoading ? (
                    <button
                      type='submit'
                      value='Submit'
                      className='btn btn-sm fs-6'
                      style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                    >
                      Connecting...{' '}
                      <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                    </button>
                  ) : (
                    <button
                      type='submit'
                      value='Submit'
                      className='btn btn-sm fs-6 '
                      onClick={handleSubmit}
                      // onClick={handleConnects}
                      style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                    >
                      Connect
                    </button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <>
              <div className='form'>
                <div className='card-header border-0  mb-4'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/integrations/Linear.svg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='card-label fw-bold fs-5'>Linear</span>
                        <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                          Project Management
                        </span>
                      </div>
                    </div>
                    <span className='badge border p-2 rounded-pill'>
                      <span
                        className={`badge border rounded-circle mx-2 bg-danger`}
                        style={{
                          width: '12px',
                          height: '12px',
                          padding: '3px',
                        }}
                      >
                        {' '}
                      </span>
                      <span className='text-center'>Not Connected</span>
                    </span>
                  </div>
                </div>
                <label className='text-dark fw-bold text-primary my-2 fs-6 mb-4'>
                  Mapped Controls & Tests
                </label>
                <div className={`card  mb-4 ${openAccordion ? 'px-6 pt-6' : 'p-6'}`}>
                  <div
                    className='d-flex'
                    onClick={() => setOpenAccordion(!openAccordion)}
                    style={{cursor: 'pointer'}}
                  >
                    <div className='row w-100'>
                      <div className='col'>
                        <div className='d-flex align-items-center'>
                          <span>
                            {openAccordion ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M6 9l6 6l6 -6' />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M9 6l6 6l-6 6' />
                              </svg>
                            )}
                          </span>
                          <span className='fs-6 fw-bold mx-4'>Controls</span>
                        </div>
                      </div>

                      <div className='col-auto d-flex align-items-center ml-auto'>
                        <div className='d-flex justify-content-end'>
                          {/* {control.control_evidence_status !== 'OK' ? ( */}
                          <div className='fw-semibold'>
                            {/* <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#EE3043'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 9v4' />
                                <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                                <path d='M12 16h.01' />
                              </svg>{' '} */}
                            {/* {processControlValues(control.control_document, control.control_test)}{' '} */}
                            2 Controls
                          </div>
                          {/* ) : (
                            <>
                              <div className='fw-bold'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#1CBB8D'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                  <path d='M9 12l2 2l4 -4' />
                                </svg>{' '}
                                OK
                              </div>
                            </>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {openAccordion && (
                    <div className=' table  mt-3 '>
                      <thead>
                        <tr className='py-4 border-bottom fw-bold' style={{color: '#5A5A5A'}}>
                          <th className='min-w-450px'></th>
                          <th className='min-w-100px'></th>
                          {/* <th className='min-w-250px'>ACTION</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                          <td className='pt-6 pb-6'>Segregation of networks</td>
                          <td className='pt-6 pb-6'>
                            <span className='badge border p-2 rounded-pill mx-2'>
                              {/* <span
                                className={`badge border rounded-circle mx-2 ${getBadgeClass(
                                  processControlValues(
                                    control.control_document,
                                    control.control_test
                                  )
                                )}`}
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  padding: '3px',
                                }}
                              >
                                {' '}
                              </span> */}
                              <span className='text-center'>ISO 27001:2022 ‧ A.8.22</span>
                            </span>{' '}
                          </td>
                          {/* <td className='pt-6 pb-6'>
                            <a
                              href={`/controls/details/${control.id}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='d-flex align-items-center'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                <path d='M11 13l9 -9' />
                                <path d='M15 4h5v5' />
                              </svg>
                              <span className='' style={{color: '#5A5A5A'}}>
                                View in full Screen
                              </span>
                            </a>
                          </td> */}
                        </tr>
                        <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                          <td className='pt-6 '>Change management</td>
                          <td className='pt-6 '>
                            <span className='badge border p-2 rounded-pill mx-2'>
                              {/* <span
                                className={`badge border rounded-circle mx-2 ${getBadgeClass(
                                  processControlValues(
                                    control.control_document,
                                    control.control_test
                                  )
                                )}`}
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  padding: '3px',
                                }}
                              >
                                {' '}
                              </span> */}
                              <span className='text-center'>ISO 27001:2022 ‧ A.8.32</span>
                            </span>{' '}
                          </td>
                          {/* <td className='pt-6 pb-6'>
                            <a
                              href={`/controls/details/${control.id}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='d-flex align-items-center'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                <path d='M11 13l9 -9' />
                                <path d='M15 4h5v5' />
                              </svg>
                              <span className='' style={{color: '#5A5A5A'}}>
                                View in full Screen
                              </span>
                            </a>
                          </td> */}
                        </tr>
                      </tbody>
                    </div>
                  )}
                </div>

                <div className={`card  mb-4 ${openAccordion2 ? 'px-6 pt-6' : 'p-6'}`}>
                  <div
                    className='d-flex'
                    onClick={() => setOpenAccordion2(!openAccordion2)}
                    style={{cursor: 'pointer'}}
                  >
                    <div className='row w-100'>
                      <div className='col'>
                        <div className='d-flex align-items-center'>
                          <span>
                            {openAccordion2 ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M6 9l6 6l6 -6' />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M9 6l6 6l-6 6' />
                              </svg>
                            )}
                          </span>
                          <span className='fs-6 fw-bold mx-4'>Tests</span>
                        </div>
                      </div>
                      <div className='col-auto d-flex align-items-center ml-auto'>
                        <div className='d-flex justify-content-end'>
                          {/* {control.control_evidence_status !== 'OK' ? ( */}
                          <div className='fw-semibold'>
                            {/* <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#EE3043'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 9v4' />
                                <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                                <path d='M12 16h.01' />
                              </svg>{' '} */}
                            {/* {processControlValues(control.control_document, control.control_test)}{' '} */}
                            2 Tests
                          </div>
                          {/* ) : (
                            <>
                              <div className='fw-bold'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#1CBB8D'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                  <path d='M9 12l2 2l4 -4' />
                                </svg>{' '}
                                OK
                              </div>
                            </>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {openAccordion2 && (
                    <div className=' table  mt-3 '>
                      <thead>
                        <tr className='py-4 border-bottom fw-bold' style={{color: '#5A5A5A'}}>
                          <th className='min-w-450px'></th>
                          <th className='min-w-100px'></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                          <td className='pt-6 pb-6'>Network Segmentation</td>
                          <td className='pt-6 pb-6'>
                            <span className='badge border p-2 rounded-pill mx-2'>
                              <span className='text-center'>ISO 27001:2022, SOC 2</span>
                            </span>{' '}
                          </td>
                        </tr>
                        <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                          <td className='pt-6 '>Appication session timeout</td>
                          <td className='pt-6 '>
                            <span className='badge border p-2 rounded-pill mx-2'>
                              <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                            </span>{' '}
                          </td>
                        </tr>
                      </tbody>
                    </div>
                  )}
                </div>

                <div className='separator my-6'></div>

                <div className='card mb-4 p-4' style={{background: '#F6F6F6'}}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-key mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z' />
                      <path d='M15 9h.01' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>Permissions</span>
                  </div>

                  <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                    SecureSlate will be granted read-only permissions to access and view the data in
                    your Linear account.
                  </div>
                </div>

                <div className='card p-4' style={{background: '#F6F6F6'}}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-user mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
                      <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>Access Type</span>
                  </div>

                  <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                    You need to have access to an Linear admin account to complete the integration
                    process.
                  </div>
                </div>

                <div className='separator  my-6'></div>

                <>
                  <div className='mb-8'>
                    <div className='fs-6 fw-bold text-bold mb-4'>
                      {' '}
                      Connect Linear to SecureSlate
                    </div>
                    <div className='mb-4'>
                      <label className='fw-semibold mb-2 fs-6'>Team Name</label>
                      <select
                        className={`form-select ${formErrors.teamName ? 'is-invalid' : ''}`}
                        // value={teamName || item.teamName}
                        onChange={(e) => {
                          setTeamName(e.target.value)
                          if (formErrors.teamName && e.target.value.trim() !== '') {
                            const updatedErrors = {...formErrors}
                            delete updatedErrors.teamName
                            setFormErrors(updatedErrors)
                          }
                        }}
                        disabled
                      >
                        <option value=''>Select Team</option>
                      </select>
                      <p className='fs-7 mt-1' style={{color: '#5A5A5A'}}>
                        Once connected to Linear, the team names will be displayed here.
                      </p>
                      {formErrors.teamName && (
                        <span className='text-danger'>{formErrors.teamName}</span>
                      )}
                    </div>

                    <div className='card mb-8 p-4' style={{background: '#F6F6F6'}}>
                      <div className='mt-1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'
                          stroke='currentColor'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          className='icon icon-tabler icons-tabler-outline icon-tabler-help mb-1'
                        >
                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                          <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                          <path d='M12 17l0 .01' />
                          <path d='M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4' />
                        </svg>
                        <span className='fw-bold fs-6 mx-2'>
                          Need help connecting Linear to SecureSlate?
                        </span>
                      </div>

                      <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                        Take a look at <a href='#'> our guide </a> for Linear integration with
                        SecureSlate. Need further assistance?{' '}
                        <a href='#' onClick={() => toggleCrispChat()}>
                          {' '}
                          Send us a chat.{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                </>

                <div
                  className='offcanvas-footer d-flex justify-content-end  border-top py-4'
                  style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
                >
                  {isLoading ? (
                    <button
                      type='submit'
                      value='Submit'
                      className='btn btn-sm fs-6'
                      style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                    >
                      Connecting...{' '}
                      <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                    </button>
                  ) : (
                    <button
                      type='submit'
                      value='Submit'
                      className='btn btn-sm fs-6 '
                      onClick={initiateLinearOAuth}
                      style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                    >
                      Connect
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default LinearDrawer
