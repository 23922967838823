// // import React from 'react'
// // import {Modal} from 'react-bootstrap'
// // import useCrispChat from 'app/Hooks/useCrispChat'

// // interface UpgradeModalProps {
// //   show: boolean
// //   onClose: () => void
// // }

// // const UpgradePlan: React.FC<UpgradeModalProps> = ({show, onClose}) => {
// //   const {toggleCrispChat} = useCrispChat()
// //   if (!show) {
// //     return null
// //   }

// //   return (
// //     <Modal show={show} onHide={onClose} centered>
// //       <Modal.Header closeButton className='border-0'>
// //         <Modal.Title as='h5'>Upgrade to paid plan</Modal.Title>
// //       </Modal.Header>
// //       <Modal.Body className='pt-0'>
// //         <h2 className='fs-4 mb-3'>Unlock this Feature with a Paid Plan</h2>{' '}
// //         <p className='mb-6 fs-6'>
// //           {' '}
// //           Upgrade to any paid plan to continue enjoying this feature. Check out the{' '}
// //           <a
// //             href='https://getsecureslate.com/pricing'
// //             className='text-primary text-decoration-none'
// //             target='_blank'
// //             rel='noreferrer'
// //           >
// //             {' '}
// //             pricing details{' '}
// //           </a>{' '}
// //           for more information.{' '}
// //         </p>
// //         <div className='d-flex gap-3'>
// //           <button
// //             onClick={toggleCrispChat}
// //             className='btn btn-sm fs-6'
// //             onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
// //               (e.currentTarget.style.backgroundColor = '#148363')
// //             }
// //             onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
// //               (e.currentTarget.style.backgroundColor = '#1CBB8D')
// //             }
// //             style={{
// //               backgroundColor: '#1CBB8D',
// //               color: '#fff',
// //               height: '40px',
// //               borderRadius: '4px',
// //               transition: 'background-color 0.3s',
// //             }}
// //           >
// //             Contact sales
// //           </button>
// //           {/* <Button variant="outline-secondary" className="flex-grow-1">
// //             Talk To A Human
// //           </Button> */}
// //         </div>
// //       </Modal.Body>
// //     </Modal>
// //   )
// // }

// // export default UpgradePlan

// import React from 'react';
// import { Modal } from 'react-bootstrap';
// import useCrispChat from 'app/Hooks/useCrispChat';

// interface UpgradeModalProps {
//   show: boolean;
//   onClose: () => void;
// }

// const UpgradePlan: React.FC<UpgradeModalProps> = ({ show, onClose }) => {
//   const { toggleCrispChat } = useCrispChat();

//   if (!show) {
//     return null;
//   }

//   return (
//     <Modal show={show} onHide={onClose} centered size="lg">
//       <Modal.Header closeButton className="border-0">
//         <Modal.Title as="h5">
//           Upgrade to paid plan
//         </Modal.Title>
//       </Modal.Header>

//       <Modal.Body className="pt-0">
//       <h2 className='fs-4 mb-3'>Unlock this Feature with a Paid Plan</h2>{' '}
//         <p className="text-muted mb-4">
//           Join hundreds of teams who have enhanced their productivity
//         </p>

//         {/* Benefits Section */}
//         <div className="mb-4">

//           <div className="d-flex align-items-start gap-3 mb-3">
//             <i className="bi bi-check-circle-fill text-success fs-5"></i>
//             <div>
//               <h6 className="mb-1">Save $20,000 on average</h6>
//               <p className="text-muted small mb-0">
//                 Reduce operational costs and improve efficiency
//               </p>
//             </div>
//           </div>

//           <div className="d-flex align-items-start gap-3 mb-3">
//             <i className="bi bi-check-circle-fill text-success fs-5"></i>
//             <div>
//               <h6 className="mb-1">Save 80 hours per month</h6>
//               <p className="text-muted small mb-0">
//                 Automate manual tasks and streamline workflows
//               </p>
//             </div>
//           </div>

//           <div className="d-flex align-items-start gap-3 mb-4">
//             <i className="bi bi-check-circle-fill text-success fs-5"></i>
//             <div>
//               <h6 className="mb-1">Enterprise-grade security</h6>
//               <p className="text-muted small mb-0">
//                 Advanced features to protect your sensitive data
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* CTA Section */}
//         <div className="d-flex gap-3">
//           <button
//             onClick={toggleCrispChat}
//             className="btn btn-success flex-grow-1"
//             style={{
//               backgroundColor: '#1CBB8D',
//               borderColor: '#1CBB8D',
//               transition: 'background-color 0.3s',
//             }}
//             onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
//               (e.currentTarget.style.backgroundColor = '#148363')
//             }
//             onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
//               (e.currentTarget.style.backgroundColor = '#1CBB8D')
//             }
//           >
//             Talk to Sales
//           </button>
//           <a
//             href="https://getsecureslate.com/pricing"
//             className="btn btn-outline-secondary flex-grow-1"
//             target="_blank"
//             rel="noreferrer"
//           >
//             View Pricing
//           </a>
//         </div>

//         <p className="text-muted small text-center mt-3">
//           Have questions? Our team is here to help you choose the right plan
//         </p>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default UpgradePlan;

import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import useCrispChat from 'app/Hooks/useCrispChat'

interface UpgradeModalProps {
  show: boolean
  onClose: () => void
}

const UpgradePlan: React.FC<UpgradeModalProps> = ({show, onClose}) => {
  const {toggleCrispChat} = useCrispChat()

  if (!show) {
    return null
  }

  const benefits = [
    {
      title: 'Save $20,000 on average',
      description: 'Reduce operational costs and improve efficiency',
    },
    {
      title: 'Save 80 hours per month',
      description: 'Automate manual tasks and streamline workflows',
    },
    {
      title: 'Enterprise-grade security',
      description: 'Advanced features to protect your sensitive data',
    },
  ]

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title as='h5'>Upgrade to Paid Plan</Modal.Title>
      </Modal.Header>

      <Modal.Body className='pt-0 pb-2'>
        <h2 className='fs-4 mb-3'>Unlock Premium Features</h2>
        <p className='mb-4 fs-7' style={{color:'#5A5A5A'}}>
          Join hundreds of teams who have enhanced their productivity and security
        </p>

        {/* Benefits Section */}
        <div className='mb-4'>
          {benefits.map((benefit, index) => (
            <div key={index} className='d-flex align-items-start gap-3 mb-3'>
              <i
                className='bi bi-check-circle-fill fs-5 mt-1'
                style={{
                  color: '#1CBB8D',
                }}
              ></i>
              <div>
                <h6 className='mb-1'>{benefit.title}</h6>
                <p className='small mb-0' style={{color:'#5A5A5A'}}>{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className='d-flex justify-content-center'>
          <button
            onClick={toggleCrispChat}
            className='btn btn-sm fs-6 rotate'
            style={{background: '#1CBB8D', height: '40px', color: '#fff'}}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
          >
            Contact Sales
          </button>
        </div>

        <p className='small text-center mt-3' style={{color:'#5A5A5A'}}>
          Have questions? Our team is here to help you choose the right plan for your needs.
        </p>
      </Modal.Body>
    </Modal>
  )
}

export default UpgradePlan
