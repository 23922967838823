import React, { useEffect, useRef, useState } from 'react'
import Drawer from 'react-modern-drawer'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import createGitHubIntegration from '../../../../Integrations/Github'
import { useAuth } from '../../../../modules/auth'
import { addDoc, collection, doc, getDocs, onSnapshot, query, serverTimestamp, where } from 'firebase/firestore'
import db from '../../../../../services/firebase'
import { ToastContainer, toast } from 'react-toastify'

interface AddGitHubProps {
  isOpen: boolean
  onClose: () => void
}
const GithubDrawer: React.FC<AddGitHubProps> = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [openAccordion, setOpenAccordion] = useState(false)
  const [openAccordion2, setOpenAccordion2] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [githubAPIKey, setGithubAPIKey] = useState('')
  const [githubOrgName, setGithubOrgName] = useState('')
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const { currentUser } = useAuth()
  const [showAlert, setShowAlert] = useState(false)
  const drawerTopRef = useRef(null)

  if (showAlert && drawerTopRef.current) {
    ; (drawerTopRef.current as HTMLElement).scrollIntoView({ behavior: 'smooth' })
  }
  const handleConnect = async () => {
    let errors: Record<string, string> = {}
    if (!githubAPIKey || !githubOrgName) {
      errors.error = 'Please enter API Key and Organization Name'
      setFormErrors(errors)
      return;
    }
    try {
      const githubIntegration = createGitHubIntegration({
        apiKey: githubAPIKey,
        orgName: githubOrgName,
      });
      githubIntegration.getUserOrganizations().then((res) => {
        if (res.status === 200) {
          handleSubmit(res.data);
        }
      })
    }
    catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }
  const handleClose = () => {
    setCompleted(false)
  }

  const handleSubmit = async (repos) => {
    setIsLoading(true);
    setFormErrors({});
    let isAnyRepoAdded = false; // Flag to track if any repo was added

    for (const repo of repos) {
      const newAsset = {
        is_repo: true,
        repo_name: repo.name,
        repo_link: repo.html_url,
        platform: 'GitHub',
        visibility: repo.private ? 'Private' : 'Public',
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
      };

      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!);
        const policyRef = collection(tenantRef, 'assets');

        // Check if the repoLink already exists
        const querySnapshot = await getDocs(query(policyRef, where("repo_link", "==", repo.html_url)));

        if (querySnapshot.empty) {
          // If no existing repoLink, add the new asset
          await addDoc(policyRef, newAsset);
          isAnyRepoAdded = true; // Set flag to true

          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = currentDate.getMonth() + 1;
          const day = currentDate.getDate();
          const todaysDate = month + '/' + day + '/' + year;

          const activitiesRef = collection(tenantRef, 'activities');
          await addDoc(activitiesRef, {
            message: `${currentUser?.userName} added a new asset '${repo.name}'`,
            timestamp: serverTimestamp(),
            tenantId: currentUser?.tenantId,
            uid: currentUser?.uid,
            action: 'Asset added',
            target: 'Asset Management',
            email: currentUser?.email,
            username: currentUser?.userName,
            date: todaysDate,
          });
        } else {
          console.log(`Asset with repoLink ${repo.html_url} already exists. Skipping...`);
          toast.error(`Asset with repoLink ${repo.html_url} already exists. Skipping...`, {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              color: 'black',
            },
            autoClose: 3000,
          });
        }
      }
    }

    // Show success toast only if at least one repo was added
    if (isAnyRepoAdded) {
      toast.success('All new unique repos added successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({ theme, type }) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ color: '#1CBB8D' }}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      });
    }

    setIsLoading(false);
    onClose();
    setShowAlert(false);
    setGithubAPIKey('');
    setGithubOrgName('');
  };



  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
      <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            GitHub
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{ height: 'calc(100vh - 40px)' }}
        >
          <div className='form'>
            <div className='card-header border-0  mb-4'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px me-5'>
                    <img src={toAbsoluteUrl('/media/integrations/github.svg')} alt='' />
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='card-label fw-bold fs-5'>GitHub</span>
                    <span className='fw-semibold fs-6' style={{ color: '#5A5A5A' }}>
                      Code Collaboration & Version Control Platform
                    </span>
                  </div>
                </div>
                <span className='badge border p-2 rounded-pill'>
                  <span
                    className={`badge border rounded-circle mx-2 bg-danger`}
                    style={{
                      width: '12px',
                      height: '12px',
                      padding: '3px',
                    }}
                  >
                    {' '}
                  </span>
                  <span className='text-center'>Not Connected</span>
                </span>
              </div>
            </div>
            <label className='text-dark fw-bold text-primary my-2 fs-6 mb-4'>
              Mapped Controls & Tests
            </label>
            <div className={`card  mb-4 ${openAccordion ? 'px-6 pt-6' : 'p-6'}`}>
              <div
                className='d-flex'
                onClick={() => setOpenAccordion(!openAccordion)}
                style={{ cursor: 'pointer' }}
              >
                <div className='row w-100'>
                  <div className='col'>
                    <div className='d-flex align-items-center'>
                      <span>
                        {openAccordion ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M6 9l6 6l6 -6' />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M9 6l6 6l-6 6' />
                          </svg>
                        )}
                      </span>
                      <span className='fs-6 fw-bold mx-4'>
                        Controls
                      </span>
                    </div>
                  </div>

                  <div className='col-auto d-flex align-items-center ml-auto'>
                    <div className='d-flex justify-content-end'>
                      <div className='fw-semibold'>
                        2 Controls
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openAccordion && (
                <table className=' table  mt-3 '>
                  <thead>
                    <tr className='py-4 border-bottom fw-bold' style={{ color: '#5A5A5A' }}>
                      <th className='min-w-350px'></th>
                      <th className='min-w-100px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-bottom my-2 fw-bold' style={{ color: '#171717' }}>
                      <td className='pt-6 pb-6'>Inventory of assets</td>
                      <td className='pt-6 pb-6'>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                    </tr>
                    <tr className='border-bottom my-2 fw-bold' style={{ color: '#171717' }}>
                      <td className='pt-6 '>Ownership of assets</td>
                      <td className='pt-6 '>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>

            <div className={`card  mb-4 ${openAccordion2 ? 'px-6 pt-6' : 'p-6'}`}>
              <div
                className='d-flex'
                onClick={() => setOpenAccordion2(!openAccordion2)}
                style={{ cursor: 'pointer' }}
              >
                <div className='row w-100'>
                  <div className='col'>
                    <div className='d-flex align-items-center'>
                      <span>
                        {openAccordion2 ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M6 9l6 6l6 -6' />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M9 6l6 6l-6 6' />
                          </svg>
                        )}
                      </span>
                      <span className='fs-6 fw-bold mx-4'>
                        Tests
                      </span>
                    </div>
                  </div>
                  <div className='col-auto d-flex align-items-center ml-auto'>
                    <div className='d-flex justify-content-end'>
                      <div className='fw-semibold'>
                        2 Tests
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openAccordion2 && (
                <table className=' table  mt-3 '>
                  <thead>
                    <tr className='py-4 border-bottom fw-bold' style={{ color: '#5A5A5A' }}>
                      <th className='min-w-350px'></th>
                      <th className='min-w-100px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-bottom my-2 fw-bold' style={{ color: '#171717' }}>
                      <td className='pt-6 pb-6'>Access request ticket and history</td>
                      <td className='pt-6 pb-6'>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                    </tr>
                    <tr className='border-bottom my-2 fw-bold' style={{ color: '#171717' }}>
                      <td className='pt-6 '>Appication session timeout</td>
                      <td className='pt-6 '>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>

            <div className='separator my-6'></div>

            <div className='card mb-4 p-4' style={{ background: '#F6F6F6' }}>
              <div className='mt-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-key mb-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z' />
                  <path d='M15 9h.01' />
                </svg>
                <span className='fw-bold fs-6 mx-2'>Permissions</span>
              </div>

              <div className='fs-6 fw-semibold mt-2' style={{ color: '#5A5A5A' }}>
                SecureSlate will be granted read-only permissions to access and view the data in
                your GitHub account.
              </div>
            </div>

            <div className='card p-4' style={{ background: '#F6F6F6' }}>
              <div className='mt-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-user mb-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
                  <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
                </svg>
                <span className='fw-bold fs-6 mx-2'>Access Type</span>
              </div>

              <div className='fs-6 fw-semibold mt-2' style={{ color: '#5A5A5A' }}>
                You need to have access to an GitHub admin account to complete the integration
                process.
              </div>
            </div>

            <div className='separator  my-6'></div>

            {!completed ? (
              <>
                <div className='mb-8'>
                  <div className='fs-6 fw-bold text-bold mb-4'> Connect GitHub to SecureSlate</div>
                </div>
                <div className='card mb-8 p-4' style={{ background: '#F6F6F6' }}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-help mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                      <path d='M12 17l0 .01' />
                      <path d='M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>
                      How to connect GitHub with SecureSlate?
                    </span>
                  </div>

                  <div className='fs-6 fw-semibold mt-2' style={{ color: '#5A5A5A' }}>
                    Click on the ‘Connect’ button below. Using the API key and Organization name provided, you will be
                    Connected to GitHub.
                  </div>
                  <div className='d-flex flex-row mt-3'>
                    <div className='flex-fill'>
                      <label className='fs-6 fw-bold text-dark'>API Key</label>
                      <input
                        type='text'
                        className={`form-control ${formErrors.serialNumber ? 'is-invalid' : ''}`}
                        placeholder='API Key'
                        name='apiKey'
                        value={githubAPIKey}
                        onChange={(e) => {
                          setGithubAPIKey(e.target.value)
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                      <label className='fs-6 fw-bold text-dark'>Organization Name</label>
                      <input
                        type='text'
                        className={`form-control ${formErrors.serialNumber ? 'is-invalid' : ''}`}
                        placeholder='Organization Name'
                        name='orgName'
                        value={githubOrgName}
                        onChange={(e) => {
                          setGithubOrgName(e.target.value)
                        }}
                      />
                    </div>

                  </div>
                  {formErrors.error && (
                    <span className='text-danger'>{formErrors.error}</span>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className='mb-6'>
                  <div className='fs-6 fw-bold text-bold mb-4'>Disconnect GitHub</div>
                </div>

                <div className='card mb-8 p-4' style={{ background: '#F6F6F6' }}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-plug-x mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M13.55 17.733a5.806 5.806 0 0 1 -7.356 -4.052a5.81 5.81 0 0 1 1.537 -5.627l2.054 -2.054l7.165 7.165' />
                      <path d='M4 20l3.5 -3.5' />
                      <path d='M15 4l-3.5 3.5' />
                      <path d='M20 9l-3.5 3.5' />
                      <path d='M16 16l4 4' />
                      <path d='M20 16l-4 4' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>
                      Are you sure you want to disconnect GitHub?
                    </span>
                  </div>

                  <div className='fs-6 mt-2' style={{ color: '#5A5A5A' }}>
                    Disconnecting GitHub integration will halt all synced data and affect mapped
                    controls and test.
                  </div>
                  <button
                    className='fs-6 fw-semibold rounded border-0 mt-4 mx-1'
                    onClick={handleClose}
                    style={{
                      background: '#FDEAEC',
                      color: '#EE3043',
                      height: '40px',
                      width: '92px',
                      top: '20px',
                      left: '223px',
                    }}
                  >
                    Disconnect
                  </button>
                </div>
              </>
            )}

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}
            >
              {isLoading ? (
                <button
                  type='submit'
                  value='Submit'
                  className='btn btn-sm fs-6'
                  style={{ backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px' }}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                  Connect
                </button>
              ) : (
                <button
                  type='submit'
                  value='Submit'
                  className='btn btn-sm fs-6 '
                  onClick={handleConnect}
                  style={{ backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px' }}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Connect
                </button>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default GithubDrawer
