/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import React from 'react'
import {useAuth} from '../../../modules/auth'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
  serverTimestamp,
  addDoc,
} from 'firebase/firestore'
import db, {storage} from '../../../../services/firebase'
import UploadFile from './UploadFile'
import {deleteObject, ref} from 'firebase/storage'
import {Link, useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import 'react-loading-skeleton/dist/skeleton.css'
import AddTask from '../../controls/components/submodal/AddTask'
import {TestListLoading} from './loading/TestListLoading'
import {ToastContainer, toast} from 'react-toastify'
import {format, parseISO} from 'date-fns'

interface TestControlProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

const ViewTests: React.FC<TestControlProps> = ({isOpen, onClose, id}) => {
  const [personnels, setPersonnels] = useState<any[]>([])
  const [Tests, setTests] = useState([] as any[])
  const {currentUser} = useAuth()
  const [fileData, setFileData] = useState<File[]>([])
  const [newOwner, setNewOwner] = useState('')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const TestId = searchParams.get('id') || id
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [showUploadFile, setShowUploadFile] = useState(false)
  const [activeTab, setActiveTab] = useState('overview')
  const [policies, setPolicies] = useState([] as any[])
  const [selectedTest, setSelectedTest] = useState([] as any[])
  const [noteInput, setNoteInput] = useState('')
  const [tasks, setTasks] = useState<any[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [updatePolicy, setUpdatePolicy] = useState<any[]>([])
  const [isTestLoading, setIsTestLoading] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [editingNoteIndex, setEditingNoteIndex] = useState(null)
  const [editedNoteContent, setEditedNoteContent] = useState('')
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [timeframe, setTimeframe] = useState('')
  const [noteSaving, setNoteSaving] = useState(false)

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      // Logout()
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        end_date:doc.data().end_date
      })).filter((data)=>!data.end_date)
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    setIsTestLoading(true)
    setTasks([])
    getActivities()
  }, [id])

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const controlsCollectionRef = collection(tenantRef, 'tasklists')
      var activeTaskListsQuery = query(controlsCollectionRef, where('test_id', '==', id))

      const unsubscribe = onSnapshot(activeTaskListsQuery, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setTasks(controlsData)
      })
      setIsTestLoading(false)
      return unsubscribe
    } catch (error) {
      setIsTestLoading(false)
      console.error('Error getting controls:', error)
    }
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    const updateDocument = async () => {
      if (!currentUser || !id || updatePolicy.length === 0) return

      const tenantId = currentUser?.tenantId || ''
      try {
        const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)
        const testDocSnapshot = await getDoc(testDocRef)

        if (testDocSnapshot.exists()) {
          const newStatus = updatePolicy[0].status === 'Approved' ? 'Pass' : 'Fail'
          await updateDoc(testDocRef, {
            status: newStatus,
          })
          setUpdatePolicy([])
        }
      } catch (error) {
        console.error('Error updating document status: ', error)
      }
    }

    updateDocument()
  }, [currentUser, id, updatePolicy])

  useEffect(() => {
    getTests()
    getPolicy()
  }, [id])

  useEffect(() => {
    if (selectedTest && selectedTest.length > 0 && selectedTest[0] && selectedTest[0].files) {
      setShowUploadFile(true)
    } else {
      setShowUploadFile(false)
    }
  }, [])

  const getPolicy = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'policies')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const policyData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          policy: doc.data().policy,
          status: doc.data().status,
        }))
        setPolicies(policyData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const getTests = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'tests')
      if (!id) {
        return
      }
      const testDocRef = doc(testsCollectionRef, id)

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            policies: doc.data().policies,
            policyId: doc.data().policyId,
            name: doc.data().name,
          }))
          .filter((test) => test.id === id)
        setTests(testsData)

        const filteredEvidences = testsData.filter((evidence) => evidence.id === TestId)
        if (filteredEvidences.length > 0) {
          const selectedEvidence = filteredEvidences[0]

          if (selectedEvidence.policyId) {
            if (Array.isArray(selectedEvidence.policyId) && selectedEvidence.policyId.length > 0) {
              const updatedPolicy = policies.filter(
                (doc) => doc.id === selectedEvidence.policyId[0]
              )
              setUpdatePolicy(updatedPolicy)
            } else {
              setUpdatePolicy([])
            }
          } else {
            setUpdatePolicy([])
          }
        } else {
          setUpdatePolicy([])
        }
        setSelectedTest(filteredEvidences)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleFileUpload = (files: File[] | null) => {
    if (files && files.length > 0) {
      setFileData((prevFiles) => [...prevFiles, ...files])
    }
  }

  const handleOwner = async (event) => {
    const selectedOwner = event.target.value
    setNewOwner(selectedOwner)
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!id) {
        return
      }
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)
      await updateDoc(testDocRef, {
        owner: selectedOwner,
      })
      setNewOwner('')
    } catch (error) {
      console.error('Error assigning owner: ', error)
    }
  }

  const handleTimeframe = async (event) => {
    const selectedTimeframe = event.target.value
    setTimeframe(selectedTimeframe)

    // Calculate the next review date
    const today = new Date()
    let nextReviewDate

    switch (selectedTimeframe) {
      case 'Annually':
        nextReviewDate = new Date(today.setFullYear(today.getFullYear() + 1))
        break
      case 'Bi-annually':
        nextReviewDate = new Date(today.setMonth(today.getMonth() + 6))
        break
      case 'Quarterly':
        nextReviewDate = new Date(today.setMonth(today.getMonth() + 3))
        break
      case 'Monthly':
        nextReviewDate = new Date(today.setMonth(today.getMonth() + 1))
        break
      case 'Weekly':
        nextReviewDate = new Date(today.setDate(today.getDate() + 7))
        break
      case 'Never':
        nextReviewDate = null
        break
      default:
        nextReviewDate = null
    }

    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)
      await updateDoc(testDocRef, {
        timeframe: selectedTimeframe,
        next_review_date: nextReviewDate ? nextReviewDate.toISOString() : null,
      })
      setTimeframe('')
    } catch (error) {
      console.error('Error updating timeframe and next review date: ', error)
    }
  }

  const setReminder = async (taskId: string, status: boolean) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tasklists/${taskId}`)
      await updateDoc(testDocRef, {
        reminder: status,
      })
      toast.success('Reminder sent successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    } catch (error) {
      console.error('Error setting reminder: ', error)
    }
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleEditNote = (index, currentContent) => {
    setEditingNoteIndex(index)
    setEditedNoteContent(currentContent)
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const handleSaveEditedNote = async () => {
    try {
      setNoteSaving(true)
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      const updatedNotes = currentNotes.map((note, index) =>
        index === editingNoteIndex ? {...note, note: editedNoteContent} : note
      )

      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })

      setEditingNoteIndex(null)
      setEditedNoteContent('')
      setNoteSaving(false)
    } catch (error) {
      console.error('Error editing note: ', error)
    }
  }

  const handleViewClick = (customfileUrl) => {
    const fileUrl = customfileUrl.url
    const fileName = customfileUrl.name
    const fileExtension = fileName.split('.').pop().toLowerCase()

    const link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'

    if (fileExtension === 'pdf') {
      link.click()
    } else if (fileExtension === 'docx') {
      const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
        fileUrl
      )}&embedded=true`
      window.open(googleDocsUrl, '_blank')
    } else {
      link.download = fileName
      link.click()
    }

    setDropdownVisible(false)
  }

  const handleDeleteClick = async (file) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${file?.name}`,
        text: 'Are you sure you want to remove this file? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

        const docSnapshot = await getDoc(testDocRef)

        if (docSnapshot.exists()) {
          const data = docSnapshot.data()

          // Filter out the file to be deleted from the files array
          const updatedFiles = data.files.filter((f) => f.name !== file.name)

          // Reference to the file in Firebase Storage
          const fileRef = ref(storage, file.url)

          // Delete the file from Firebase Storage
          await deleteObject(fileRef)

          // Update the document with the new files array
          await updateDoc(testDocRef, {files: updatedFiles, status: 'Fail'})

          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: `"${file?.name}" has been deleted.`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the file.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }

  const handleAddFileClick = () => {
    setShowUploadFile(true)
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const handleNotes = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!noteInput.trim()) {
      return
    }

    const currentDate = new Date()

    const day = currentDate.getDate()
    const month = currentDate.toLocaleString('default', {month: 'long'})
    const year = currentDate.getFullYear()
    const formattedDate = `${day} ${month}, ${year}`

    let hours = currentDate.getHours()
    let minutes = currentDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'

    // Round minutes to nearest 12
    minutes = Math.round(minutes / 12) * 12
    if (minutes === 60) {
      minutes = 0
      hours += 1
    }

    hours = hours % 12
    hours = hours ? hours : 12

    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`

    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      // Create a new note object
      const newNoteObject = {
        addedUser: currentUser?.userName || 'Unknown User',
        addedDate: formattedDate,
        addedTime: formattedTime,
        note: noteInput.trim(),
      }

      const updatedNotes = [newNoteObject, ...currentNotes]
      setIsAdding(true)
      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })
      setIsAdding(false)
      setNoteInput('')
    } catch (error) {
      setIsAdding(false)
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  function bytesToKB(bytes) {
    return (bytes / 1024).toFixed(2)
  }

  const handleRemoveTask = async (taskId) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: 'Remove Selected Items',
        text: 'Are you sure you want to remove the selected items? This action cannot be undone.',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
        await deleteDoc(taskRef)
        getActivities() // Refresh the tasks list
      }
      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Selected items have been removed.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while removing the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleMarkAsComplete = async (taskId, taskName) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Completed'})
      getActivities()

      const updatedTasks = tasks.filter((task) => task.id !== taskId)
      setTasks(updatedTasks)

      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} has completed the task '${taskName}'`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Control added',
        target: 'Controls',
        email: currentUser?.email,
        username: currentUser?.userName,
        date: todaysDate,
      })

      toast.success('Task has been marked as completed!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }
  const handleMarkAsIncomplete = async (taskId, taskName) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const taskRef = doc(db, 'tenants', tenantId, 'tasklists', taskId)
      await updateDoc(taskRef, {status: 'Incomplete'})
      getActivities()

      const tenantRef = doc(db, 'tenants', currentUser?.tenantId!)
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} has marked the task '${taskName}' as Incomplete `,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Control added',
        target: 'Controls',
        email: currentUser?.email,
        username: currentUser?.userName,
        date: todaysDate,
      })

      toast.error('Task has been marked as incomplete!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          // border: '1px solid #e0e0e0',
          color: 'black',
        },

        autoClose: 3000,
      })
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }

  const handleDeleteNote = async (index) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${id}`)

      // Get the current notes array
      const docSnap = await getDoc(testDocRef)
      const currentNotes = docSnap.data()?.notes || []

      // Remove the note at the specified index
      const updatedNotes = currentNotes.filter((_, i) => i !== index)

      // Update the document
      await updateDoc(testDocRef, {
        notes: updatedNotes,
      })
    } catch (error) {
      console.error('Error deleting note: ', error)
    }
  }

  const formatFileDate = (dateString: string): string => {
    if (dateString) {
      try {
        // Parse the ISO date string
        const date = parseISO(dateString)

        // Format the date
        return format(date, 'd MMMM, yyyy')
      } catch (error) {
        console.error('Error parsing date:', error)
        return 'Invalid Date'
      }
    } else {
      return 'Invalid Date'
    }
  }

  const handleDownloadClick2 = async (customfileUrl) => {
    try {
      const fileUrl = customfileUrl.url

      const response = await fetch(fileUrl, {mode: 'no-cors'})
      const fileContent = await response.blob()

      const blob = new Blob([fileContent], {type: 'application/octet-stream'})

      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = `${customfileUrl.name}`

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(blobUrl)

      setDropdownVisible(false)
    } catch (error) {
      console.error('Error downloading policy file:', error)
    }
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className='border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='d-flex justify-content-between w-100'>
            {Tests.filter((control) => control.id === id).map((control) => (
              <h3 className='modal-title mt-2' key={control.id}>
                {control.name}
              </h3>
            ))}

            <div className='d-flex align-items-center'>
              {currentUser?.role !== 'Auditor' && (
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary'
                  style={{
                    transition: 'all 0.2s ease',
                  }}
                  onMouseEnter={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#1CBB8D'
                      }
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (e.currentTarget) {
                      e.currentTarget.style.backgroundColor = '#fff'
                      const svgElement = e.currentTarget.querySelector('svg')
                      if (svgElement) {
                        svgElement.style.stroke = '#5A5A5A'
                      }
                    }
                  }}
                >
                  <a href={`/tests/details/${id}`} target='_blank' rel='noopener noreferrer'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-external-link'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                      <path d='M11 13l9 -9' />
                      <path d='M15 4h5v5' />
                    </svg>
                  </a>
                </div>
              )}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary'
                aria-label='Close'
                onClick={onClose}
                style={{
                  transition: 'all 0.2s ease',
                }}
                onMouseEnter={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#1CBB8D'
                    }
                  }
                }}
                onMouseLeave={(e) => {
                  if (e.currentTarget) {
                    e.currentTarget.style.backgroundColor = '#fff'
                    const svgElement = e.currentTarget.querySelector('svg')
                    if (svgElement) {
                      svgElement.style.stroke = '#5A5A5A'
                    }
                  }
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='#5E6274'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M18 6l-12 12' />
                  <path d='M6 6l12 12' />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div
          className='p-6 scroll-y overflow-auto'
          tabIndex={-1}
          style={{height: 'calc(100vh - 56px)'}}
        >
          {Tests.map((test) => (
            <div key={test.id}>
              <div className='modal-body'>
                <div
                  className='d-flex flex-column scroll-y pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='fv-row mb-4 text-gray-800 fs-6'>
                    {/* Evidence that reputable external sources are used to identify current and
                      emergingthreats and that vulnerabilities are tracked and remediated */}
                    {/* {test.description && test.description.includes('Guidance') ? (
                      <>
                        {test.description.split('Guidance')[0]} <br />
                        <br /> Guidance {test.description.split('Guidance')[1]}
                      </>
                    ) : (
                      <>
                        {test.description.split('\n').map((line, index) => (
                          <>
                            {line}
                            <br />
                          </>
                        ))}
                      </>
                    )} */}
                    <div
                      className='fv-row mb-4 text-gray-800 fs-6'
                      dangerouslySetInnerHTML={{__html: test.description}}
                    />
                  </div>

                  <div className='row mb-5'>
                    <div className='col-3'>
                      <label className='text-gray-800 fw-semibold me-2 fs-6 '>Status</label>
                    </div>
                    <div className='col-9'>
                      <span className='fw-normal fw-bold fs-6'>
                        {/* <span className='badge border p-2 rounded-pill'>
                          <span
                            className={`badge border rounded-circle mx-1 ${
                              test.status === 'Pass' ? 'bg-primary' : 'bg-danger'
                            }`}
                            style={{
                              width: '12px',
                              height: '12px',
                              padding: '3px',
                            }}
                          >
                            {' '}
                          </span>
                          <span className='text-center'>
                            <span className='text-center'>
 
                              {test.status === 'Pass' ? 'OK' : 'Failing'}
                            </span>
                          </span>
                        </span>{' '} */}
                        <a
                          href='#'
                          className='badge fs-7'
                          style={{
                            color: test.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                            background: test.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                          }}
                        >
                          {' '}
                          {test.status === 'Pass' ? 'Passing' : 'Failing'}
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-3'>
                      <label className='text-gray-800 fw-semibold me-2 fs-6 '>Category</label>
                    </div>
                    <div className='col-9'>
                      <span className='text-normal fs-6'>{test.category}</span>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-3'>
                      <label className='text-gray-800 fw-semibold me-2 fs-6 '>Type</label>
                    </div>
                    <div className='col-9'>
                      <span className='fw-normal fs-6'>{test.type}</span>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-3'>
                      <label className='text-gray-800 fw-semibold me-2 fs-6 '>Framework</label>
                    </div>
                    <div className='col-9'>
                      {test.framework
                        .split(', ')
                        .filter((framework) =>
                          activeFrameworks.some((item) => framework.startsWith(item))
                        )
                        .map((framework, index) => (
                          <span
                            key={index}
                            className='badge px-2 py-1 rounded-pill mb-1 fs-7 me-1'
                            style={{border: '2px solid #E1E1E1'}}
                          >
                            {framework}
                          </span>
                        ))}
                    </div>
                  </div>

                  <div className='row mb-3'>
                    <div className='col-3 d-flex align-items-center'>
                      <label className='text-gray-800 fw-semibold me-2 fs-6 '>Test interval</label>
                    </div>
                    <div className='col-9'>
                      <span className='fw-normal'>
                        {' '}
                        <select
                          name='timeframe'
                          className='form-select py-2 px-4'
                          data-control='select2'
                          data-placeholder='Select time interval'
                          value={test.timeframe || timeframe}
                          onChange={handleTimeframe}
                          disabled={currentUser?.role === 'Auditor'}
                        >
                          <option value='' selected>
                            Select time interval
                          </option>
                          <option value='Annually'>Annually (Recommended)</option>
                          <option value='Bi-annually'>Bi-annually</option>
                          <option value='Quarterly'>Quarterly</option>
                          <option value='Monthly'>Monthly</option>
                          <option value='Weekly'>Weekly</option>
                          <option value='Never'>Never</option>
                        </select>
                      </span>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-3 d-flex align-items-center'>
                      <label className='text-gray-800 fw-semibold me-2 fs-6 '>Owner</label>
                    </div>
                    <div className='col-9'>
                      <span className='fw-normal'>
                        {' '}
                        <select
                          name='owner'
                          className='form-select py-2 px-4'
                          data-control='select2'
                          data-placeholder='Select Owner'
                          value={newOwner || test.owner}
                          onChange={handleOwner}
                          disabled={currentUser?.role === 'Auditor'}
                        >
                          <option value=''>Assign Owner</option>
                          {personnels
                            .filter((user) => user.role === 'Admin' || user.role === 'Owner')
                            .map((group) => (
                              <option key={group.id} value={group.full_name}>
                                {group.full_name}
                              </option>
                            ))}
                        </select>
                      </span>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </div>
              {/* <div className='separator border-3 my-5'></div> */}

              <div className='  mt-4'>
                <div className='d-flex  h-55px'>
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap '>
                    <li className='nav-item'>
                      <Link
                        to={`/tests`}
                        className={`nav-link me-6 ${
                          activeTab === 'overview' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('overview')}
                      >
                        Overview
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to={'/tests'}
                        className={`nav-link me-6 ${
                          activeTab === 'notes' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('notes')}
                      >
                        Notes
                      </Link>
                    </li>

                    <li className='nav-item'>
                      <Link
                        to={'/tests'}
                        className={`nav-link me-6 ${
                          activeTab === 'tasks' ? 'text-active-primary active' : ''
                        }`}
                        onClick={() => handleTabClick('tasks')}
                      >
                        Tasks
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <AddTask isOpen={isModalOpen} onClose={handleCloseModal} test_id={id} />
              {activeTab === 'overview' && (
                <div className='modal-footer'>
                  <div className='mt-2 w-100'>
                    {test.type === 'Document' ? (
                      <>
                        {!test.files || test.files.length === 0 ? (
                          <>
                            <div className='text-gray-800 fw-semibold me-2 fs-6 mb-2 mt-4'>
                              Evidences
                            </div>
                            <UploadFile onFileUpload={handleFileUpload} fid={test.id} />
                          </>
                        ) : (
                          <>
                            <div className='d-flex justify-content-end mb-2'>
                              <button
                                onClick={handleAddFileClick}
                                className='btn btn-sm fs-6 fw-bold px-6'
                                data-kt-menu-dismiss='true'
                                data-kt-user-table-filter='filter'
                                style={{
                                  backgroundColor: '#1CBB8D',
                                  color: '#FFFFFF',
                                  height: '40px',
                                }}
                                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                  (e.currentTarget.style.backgroundColor = '#148363')
                                }
                                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                }
                                disabled={currentUser?.role === 'Auditor'}
                              >
                                Add File
                              </button>
                            </div>

                            {showUploadFile && currentUser?.role !== 'Auditor' && (
                              <UploadFile onFileUpload={handleFileUpload} fid={test.id} />
                            )}

                            <div>
                              <div className='fw-bold fs-6 my-2'>Uploads</div>
                              {test.files &&
                                test.files.length > 0 &&
                                test.files.map((file, fileIndex) => (
                                  <>
                                    <div className={`card mb-1 mt-4`}>
                                      <div
                                        className='card-header border-0 pt-6 mb-5'
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <a
                                          href='#'
                                          className='text-dark text-hover-primary'
                                          onClick={() => handleViewClick(file)}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              flex: 1,
                                              minWidth: 0,
                                            }}
                                          >
                                            <img
                                              src='/media/icon/file2.svg'
                                              alt=''
                                              className='img-fluid'
                                              style={{
                                                width: '50px',
                                                height: '50px',
                                                marginRight: '10px',
                                              }}
                                            />
                                            <div
                                              className='d-flex justify-content-start flex-column fw-bold fs-6'
                                              style={{flexGrow: 1, minWidth: 0}}
                                            >
                                              <span
                                                style={{
                                                  wordBreak: 'break-word',
                                                  whiteSpace: 'normal',
                                                }}
                                              >
                                                {file.name}
                                              </span>
                                              <span
                                                className='fw-semibold d-block fs-6'
                                                style={{color: '#5A5A5A'}}
                                              >
                                                Size: {bytesToKB(file.size)} KB
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                        <div className='dropdown'>
                                          <button
                                            className='btn btn-secondary border-0 p-0 '
                                            type='button'
                                            id='dropdownMenu2'
                                            data-bs-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                          >
                                            <img
                                              src='/media/buttons/Options.svg'
                                              alt='Options'
                                              onClick={toggleDropdown}
                                            />
                                          </button>
                                          <ul
                                            className='dropdown-menu'
                                            aria-labelledby='dropdownMenu2'
                                          >
                                            <li>
                                              <button
                                                className='dropdown-item text-muted text-hover-primary'
                                                type='button'
                                                onClick={() => handleViewClick(file)}
                                              >
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  width='18'
                                                  height='18'
                                                  viewBox='0 0 24 24'
                                                  fill='none'
                                                  stroke='currentColor'
                                                  stroke-width='2'
                                                  stroke-linecap='round'
                                                  stroke-linejoin='round'
                                                  className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-1'
                                                >
                                                  <path
                                                    stroke='none'
                                                    d='M0 0h24v24H0z'
                                                    fill='none'
                                                  />
                                                  <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                                  <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                                </svg>{' '}
                                                View
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                className='dropdown-item text-muted text-hover-primary'
                                                type='button'
                                                onClick={(e) => handleDownloadClick2(file)}
                                              >
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  width='18'
                                                  height='18'
                                                  viewBox='0 0 24 24'
                                                  fill='none'
                                                  stroke='currentColor'
                                                  stroke-width='2'
                                                  stroke-linecap='round'
                                                  stroke-linejoin='round'
                                                  className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                                                >
                                                  <path
                                                    stroke='none'
                                                    d='M0 0h24v24H0z'
                                                    fill='none'
                                                  />
                                                  <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                                                  <path d='M7 11l5 5l5 -5' />
                                                  <path d='M12 4l0 12' />
                                                </svg>{' '}
                                                Download
                                              </button>
                                            </li>

                                            <li>
                                              <button
                                                className='dropdown-item'
                                                type='button'
                                                style={{color: '#F15363'}}
                                                onClick={(e) => handleDeleteClick(file)}
                                                disabled={currentUser?.role === 'Auditor'}
                                              >
                                                <svg
                                                  xmlns='http://www.w3.org/2000/svg'
                                                  width='18'
                                                  height='18'
                                                  viewBox='0 0 24 24'
                                                  fill='none'
                                                  stroke='#F15363'
                                                  strokeWidth='2'
                                                  strokeLinecap='round'
                                                  strokeLinejoin='round'
                                                  className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                                >
                                                  <path
                                                    stroke='none'
                                                    d='M0 0h24v24H0z'
                                                    fill='none'
                                                  />
                                                  <path d='M4 7l16 0' />
                                                  <path d='M10 11l0 6' />
                                                  <path d='M14 11l0 6' />
                                                  <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                                  <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                                </svg>{' '}
                                                Remove
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    {fileIndex === test.files.length - 1 && (
                                      <div
                                        className='fs-7 fw-semibold mb-4 ms-2 mb-xl-8'
                                        style={{color: '#5A5A5A'}}
                                      >
                                        <strong>Next review:</strong>{' '}
                                        {test.next_review_date
                                          ? formatFileDate(test.next_review_date)
                                          : '--'}
                                      </div>
                                    )}
                                  </>
                                ))}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <a
                        href={`https://app.gtsecureslate.com/policy-library/PolicyTemplates?policies=${test.policies}`}
                        target='_blank'
                        rel='noreferrer'
                      > */}
                        {test.fix ? (
                          <div className={`card mb-5 mb-xl-8 mt-6`}>
                            <div
                              className='card-header border-0 pt-6 mb-5'
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='card-label fw-bold fs-6'>How to fix</span>
                                    <div
                                      className='fw-semibold fs-6 d-none d-md-block my-2 text-muted'
                                      dangerouslySetInnerHTML={{__html: test.fix}}
                                    ></div>
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        ) : (
                          <a
                            href={`/policy-library/lists?policies=${
                              test.policyId && test.policyId.length > 0 ? test.policyId[0] : ''
                            }`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <div className={`card mb-5 mb-xl-8 mt-6`}>
                              <div
                                className='card-header border-0 pt-6 mb-5'
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex justify-content-start flex-column'>
                                      <span className='card-label fw-bold fs-6'>How to fix</span>
                                      <span className='text-muted fw-semibold fs-7 d-none d-md-block my-2'>
                                        Create and approve a {test.name} for your business using the
                                        templates included in our{' '}
                                        <a
                                          href={`/policy-library/lists?policies=${
                                            test.policyId && test.policyId.length > 0
                                              ? test.policyId[0]
                                              : ''
                                          }`}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          policy library
                                        </a>
                                        .
                                      </span>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </a>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 'notes' && (
                <>
                  <div className='mb-20 mt-8'>
                    {Tests && Tests.length > 0 ? (
                      Tests.filter((control) => control.id === id).map((control) =>
                        control.notes && control.notes.length > 0 ? (
                          control.notes.map((note, index) => (
                            <div className='mt-4 mb-8' key={index}>
                              <div className='d-flex align-items-center justify-content-between mb-2'>
                                <div className='d-flex align-items-center'>
                                  <div className='symbol symbol-40px me-3'>
                                    {note.addedUser ? (
                                      <span
                                        className='symbol-label fs-7 fw-bold'
                                        style={{
                                          backgroundColor: '#DAF9EF',
                                          borderRadius: '50%',
                                        }}
                                      >
                                        {note.addedUser
                                          ? (() => {
                                              const nameParts = note.addedUser.split(' ')
                                              if (nameParts.length > 1) {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[1]
                                                  .charAt(0)
                                                  .toUpperCase()}`
                                              } else {
                                                return `${nameParts[0]
                                                  .charAt(0)
                                                  .toUpperCase()}${nameParts[0]
                                                  .charAt(1)
                                                  .toUpperCase()}`
                                              }
                                            })()
                                          : ''}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </div>

                                  <div className='d-flex flex-column'>
                                    <span className='text-dark fw-bold fs-6'>{note.addedUser}</span>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                      {note.addedDate} {note.addedTime}
                                    </span>
                                  </div>
                                </div>

                                <div className='dropdown'>
                                  <button
                                    className='btn btn-secondary border-0 p-0 m-2'
                                    type='button'
                                    id='dropdownMenu2'
                                    data-bs-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                  >
                                    <img
                                      src='/media/buttons/Options.svg'
                                      alt='Options'
                                      onClick={toggleDropdown}
                                    />
                                  </button>
                                  <ul
                                    className='dropdown-menu dropdown-menu-end'
                                    aria-labelledby='dropdownMenu2'
                                  >
                                    <li>
                                      <button
                                        className='dropdown-item text-muted text-hover-primary '
                                        type='button'
                                        onClick={() => handleEditNote(index, note.note)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='currentColor'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-2 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                          <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                          <path d='M16 5l3 3' />
                                        </svg>
                                        Edit
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className='dropdown-item text-danger'
                                        type='button'
                                        onClick={() => handleDeleteNote(index)}
                                      >
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='18'
                                          height='18'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          stroke='currentColor'
                                          stroke-width='2'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                          className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-2 mb-1'
                                        >
                                          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                          <path d='M4 7l16 0' />
                                          <path d='M10 11l0 6' />
                                          <path d='M14 11l0 6' />
                                          <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                          <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                        </svg>
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              {editingNoteIndex === index ? (
                                <div>
                                  <textarea
                                    value={editedNoteContent}
                                    onChange={(e) => setEditedNoteContent(e.target.value)}
                                    className='form-control'
                                  />
                                  <button
                                    onClick={handleSaveEditedNote}
                                    className='btn btn-sm fs-6 mt-2'
                                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                      (e.currentTarget.style.backgroundColor = '#148363')
                                    }
                                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                                    }
                                    // disabled={activities.length === 0}
                                    style={{
                                      backgroundColor: '#1CBB8D',
                                      color: '#fff',
                                      width: '80px',
                                      height: '40px',
                                      borderRadius: '4px',
                                      marginLeft: '4px',
                                      transition: 'background-color 0.3s',
                                    }}
                                  >
                                    {noteSaving ? (
                                      <>
                                        Saving...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </>
                                    ) : (
                                      'Save'
                                    )}
                                  </button>
                                </div>
                              ) : (
                                <div className='card p-4 mt-2 mb-4' style={{background: '#F6F6F6'}}>
                                  <span className='d-block fs-6' style={{color: '#5A5A5A'}}>
                                    <div>{note.note}</div>
                                  </span>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div
                            className='d-flex justify-content-center align-items-center text-center'
                            style={{height: '100%'}}
                          >
                            <div className=''>
                              <img
                                src='/media/buttons/emptystate.svg'
                                alt=''
                                className='img-fluid'
                                style={{maxWidth: '100px'}}
                              />

                              <div className=' fs-6'>
                                <p style={{fontWeight: 'bold'}}>No notes added yet!!</p>
                              </div>
                              <p className='fs-7'>Maybe try adding new note for this test</p>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              )}

              {activeTab === 'tasks' && (
                <>
                  <div className='card px-6 mt-4'>
                    {/* begin::Table container */}

                    <div className='my-4 d-flex justify-content-between'>
                      <div className='my-4'>
                        <div className='fs-6 fw-bold mb-2'>Task List</div>
                        <span>Assign tasks that might fix this test to team members.</span>
                      </div>
                      <button
                        onClick={handleOpenModal}
                        className='btn btn-sm fs-6'
                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#148363')
                        }
                        onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                          (e.currentTarget.style.backgroundColor = '#1CBB8D')
                        }
                        disabled={currentUser?.role === 'Auditor'}
                        style={{
                          backgroundColor: '#1CBB8D',
                          color: '#fff',
                          height: '40px',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        Add Task
                      </button>
                    </div>
                    <div
                      className='table-responsive'
                      style={{minHeight: '300px', maxHeight: '500px', overflowY: 'auto'}}
                    >
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='w-25px'>
                              {/* <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value='1'
                                  data-kt-check='true'
                                  data-kt-check-target='.widget-9-check'
                                  checked={allTaskMarkAsCompleted}
                                  onChange={handleAllMarkAsCompleteClicked}
                                />
                              </div> */}
                            </th>
                            <th className='min-w-200px'>Task Name</th>
                            <th className='min-w-50px'>Priority</th>
                            <th className='min-w-100px'>Assigned To</th>
                            {/* <th className='min-w-100px'>Due date</th> */}
                            <th className='min-w-50px text-end'></th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {isTestLoading ? (
                            <TestListLoading />
                          ) : tasks && tasks.length > 0 ? (
                            tasks.map((task) => (
                              <tr key={task.id}>
                                <td>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                      className='form-check-input widget-9-check'
                                      type='checkbox'
                                      // checked={task.checked}
                                      // onChange={() =>
                                      //   handleMarkAsCompleteClicked(task.id, !task.checked)
                                      // }
                                      checked={task.status === 'Completed'}
                                      onChange={() => {
                                        if (task.status === 'Completed') {
                                          handleMarkAsIncomplete(task.id, task.task_name)
                                        } else {
                                          handleMarkAsComplete(task.id, task.task_name)
                                        }
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                    {task.task_name}
                                  </div>
                                </td>

                                <td>
                                  {/* <span className='badge border p-2 rounded-pill fs-6 fw-semibold'>
                                    <span
                                      className={`badge border rounded-circle mx-2  ${
                                        task.priority === 'High'
                                          ? 'bg-danger'
                                          : task.priority === 'Medium'
                                          ? 'bg-warning'
                                          : 'bg-primary'
                                      }
                           `}
                                      style={{
                                        width: '12px',
                                        height: '12px',
                                        padding: '3px',
                                      }}
                                    >
                                      {' '}
                                    </span>

                                    {task.priority}
                                  </span> */}
                                  <a
                                    href='#'
                                    className={`badge fs-7 ${
                                      task.priority === 'High'
                                        ? 'badge-danger'
                                        : task.priority === 'Medium'
                                        ? 'badge-warning'
                                        : 'badge-primary'
                                    }`}
                                    style={{
                                      color:
                                        task.priority === 'High'
                                          ? '#EE3043'
                                          : task.priority === 'Medium'
                                          ? '#FFA800'
                                          : '#1CBB8D',
                                      background:
                                        task.priority === 'High'
                                          ? '#FFF5F8'
                                          : task.priority === 'Medium'
                                          ? '#FFF8DD'
                                          : '#E8F8F4',
                                    }}
                                  >
                                    {task.priority}
                                  </a>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    {task.owner ? (
                                      <>
                                        <div className='symbol symbol-35px me-3'>
                                          <span
                                            className='symbol-label fs-7 fw-bold'
                                            style={{backgroundColor: '#DAF9EF'}}
                                          >
                                            {task.owner
                                              ? (() => {
                                                  const nameParts = task.owner.split(' ')
                                                  if (nameParts.length > 1) {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[1]
                                                      .charAt(0)
                                                      .toUpperCase()}`
                                                  } else {
                                                    return `${nameParts[0]
                                                      .charAt(0)
                                                      .toUpperCase()}${nameParts[0]
                                                      .charAt(1)
                                                      .toUpperCase()}`
                                                  }
                                                })()
                                              : ''}
                                          </span>
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                          <div className='text-dark fw-semibold text-hover-primary fs-6'>
                                            {task.owner}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <span>-</span>
                                      </>
                                    )}
                                  </div>
                                </td>

                                {/* <td className='fs-6 fw-semibold'>{task.duedate}</td> */}
                                <td className='text-end'>
                                  <div className='dropdown '>
                                    {currentUser?.role !== 'Auditor' && (
                                      <button
                                        className='btn btn-secondary border-0 p-0 '
                                        type='button'
                                        id='dropdownMenu2'
                                        data-bs-toggle='dropdown'
                                        aria-haspopup='true'
                                        aria-expanded='false'
                                      >
                                        <img src='/media/buttons/Options.svg' alt='Options' />
                                      </button>
                                    )}
                                    <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                                      <li>
                                        {task.status === 'Completed' ? (
                                          <button
                                            className='dropdown-item text-hover-primary'
                                            style={{color: '#5A5A5A'}}
                                            type='button'
                                            onClick={() =>
                                              handleMarkAsIncomplete(task.id, task.task_name)
                                            }
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='currentColor'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-x mx-1 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M18 6l-12 12' />
                                              <path d='M6 6l12 12' />
                                            </svg>{' '}
                                            Mark as Incomplete
                                          </button>
                                        ) : (
                                          <button
                                            className='dropdown-item text-hover-primary'
                                            style={{color: '#5A5A5A'}}
                                            type='button'
                                            onClick={() =>
                                              handleMarkAsComplete(task.id, task.task_name)
                                            }
                                          >
                                            <svg
                                              xmlns='http://www.w3.org/2000/svg'
                                              width='18'
                                              height='18'
                                              viewBox='0 0 24 24'
                                              fill='none'
                                              stroke='#5E6274'
                                              strokeWidth='2'
                                              strokeLinecap='round'
                                              strokeLinejoin='round'
                                              className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                                            >
                                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                              <path d='M5 12l5 5l10 -10' />
                                            </svg>{' '}
                                            Mark as complete
                                          </button>
                                        )}
                                      </li>
                                      <li>
                                        <button
                                          className='dropdown-item'
                                          style={{color: '#F15363'}}
                                          type='button'
                                          onClick={() => handleRemoveTask(task.id)}
                                        >
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18'
                                            height='18'
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='#F15363'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                                          >
                                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                            <path d='M4 7l16 0' />
                                            <path d='M10 11l0 6' />
                                            <path d='M14 11l0 6' />
                                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                                          </svg>{' '}
                                          Remove task
                                        </button>
                                      </li>
                                      {/* <li>
                                        <button
                                          className='dropdown-item'
                                          type='button'
                                          style={{color: 'rgb(28, 187, 141)'}}
                                          onClick={() => setReminder(task.id, true)}
                                        >
                                          <svg
                                            width='24'
                                            height='25'
                                            viewBox='0 0 24 25'
                                            fill='none'
                                            xmlns='http://www.w3.org/2000/svg'
                                          >
                                            <path
                                              d='M12 10.3252V13.3252H14M7 4.3252L4.25 6.3252M17 4.3252L19.75 6.3252M5 13.3252C5 14.2444 5.18106 15.1547 5.53284 16.004C5.88463 16.8533 6.40024 17.6249 7.05025 18.2749C7.70026 18.925 8.47194 19.4406 9.32122 19.7924C10.1705 20.1441 11.0807 20.3252 12 20.3252C12.9193 20.3252 13.8295 20.1441 14.6788 19.7924C15.5281 19.4406 16.2997 18.925 16.9497 18.2749C17.5998 17.6249 18.1154 16.8533 18.4672 16.004C18.8189 15.1547 19 14.2444 19 13.3252C19 12.4059 18.8189 11.4957 18.4672 10.6464C18.1154 9.79713 17.5998 9.02546 16.9497 8.37545C16.2997 7.72544 15.5281 7.20982 14.6788 6.85804C13.8295 6.50626 12.9193 6.3252 12 6.3252C11.0807 6.3252 10.1705 6.50626 9.32122 6.85804C8.47194 7.20982 7.70026 7.72544 7.05025 8.37545C6.40024 9.02546 5.88463 9.79713 5.53284 10.6464C5.18106 11.4957 5 12.4059 5 13.3252Z'
                                              stroke='rgb(28, 187, 141)'
                                              stroke-width='2'
                                              stroke-linecap='round'
                                              stroke-linejoin='round'
                                            />
                                          </svg>{' '}
                                          Set Reminder
                                        </button>
                                      </li> */}
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                              {' '}
                              <tr>
                                <td colSpan={5}>
                                  <div
                                    className='d-flex justify-content-center align-items-center text-center'
                                    style={{height: '100%'}}
                                  >
                                    <div className=''>
                                      <img
                                        src='/media/buttons/emptystate.svg'
                                        alt=''
                                        className='img-fluid'
                                        style={{maxWidth: '100px'}}
                                      />

                                      <div className=' fs-6'>
                                        <p style={{fontWeight: 'bold'}}>No tasks added</p>
                                      </div>
                                      <p className='fs-7'>
                                        Maybe try assigning task to employee on this tests.
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        {activeTab === 'notes' && (
          <div
            className='offcanvas-footer  border-top pt-4 pb-4  px-6'
            style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
          >
            <div className='d-flex align-items-center'>
              <input
                type='text'
                className='form-control me-2'
                placeholder='Leave a note'
                name='notes'
                value={noteInput}
                onChange={(e) => setNoteInput(e.target.value)}
              />
              {isAdding ? (
                <button
                  className='btn btn-sm fs-6'
                  // onClick={handleNotes}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  disabled
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '150px',
                    height: '40px',
                    borderRadius: '4px',
                    marginLeft: '4px',
                    transition: 'background-color 0.3s',
                  }}
                >
                  Adding...{' '}
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                </button>
              ) : (
                <button
                  className='btn btn-sm fs-6'
                  onClick={handleNotes}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                  // disabled={activities.length === 0}
                  style={{
                    backgroundColor: '#1CBB8D',
                    color: '#fff',
                    width: '120px',
                    height: '40px',
                    borderRadius: '4px',
                    marginLeft: '4px',
                    transition: 'background-color 0.3s',
                  }}
                >
                  Add Note
                </button>
              )}
            </div>
          </div>
        )}
        <ToastContainer />
      </Drawer>
    </>
  )
}

export {ViewTests}
