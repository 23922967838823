import React, {useEffect, useRef, useState} from 'react'

import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {addDoc, arrayUnion, collection, doc, serverTimestamp, updateDoc} from 'firebase/firestore'

import Drawer from 'react-modern-drawer'
import Dropzone from 'react-dropzone'
import {ToastContainer, toast} from 'react-toastify'
import {useAuth} from '../../../../modules/auth'
import db, {storage} from '../../../../../services/firebase'

export interface policy {
  msg: string
  message: string
  fileUrl?: any
}

type Props = {
  isOpen: boolean
  onClose: () => void
  id: string
}

export interface DiligenceFile {
  diligence_filename: string
  diligence_url: string
  upload_time: string
  uploaded_by: string
  created_at: any
  updated_at: any
  due_diligence_date: string
}

const DiligenceDrawer: React.FC<Props> = ({isOpen, onClose, id}) => {
  const {currentUser} = useAuth()
  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File[]>([])
  const drawerRef = useRef<HTMLDivElement>(null)
  const [uploadedFile, setUploadedFile] = useState<{
    name: string
    size: number
    upload_time: string
  } | null>(null)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleUpload = (acceptedFiles: File[]) => {
    const validFile = acceptedFiles.find(
      (file) =>
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
    if (validFile) {
      setFile([validFile])
      const now = new Date()
      const formattedUploadTime = now.toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
      setUploadedFile({
        name: validFile.name,
        size: validFile.size,
        upload_time: formattedUploadTime,
      })
    } else {
      setErrorMessage('Please upload a valid PDF or DOCX file.')
    }
  }

  const handleRemoveFile = () => {
    setUploadedFile(null)
  }

  const currentDate = new Date() // No need to import the Firestore module
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1 // Note: The month is zero-based
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString)
    return date.toISOString().split('T')[0]
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    if (!file || file.length === 0) {
      setMessage({error: true, msg: 'Please select a file'})
      setShowAlert(true)
      setIsLoading(false)
      return
    }

    try {
      const uploadedFile = file[0]
      const diligence_file_name = uploadedFile.name
      const fileRef = ref(
        storage,
        `tenants/${currentUser?.tenantId}/vendors/diligence/${diligence_file_name}`
      )
      const uploadTask = uploadBytesResumable(fileRef, uploadedFile)

      const snapshot = await uploadTask
      const downloadURL = await getDownloadURL(snapshot.ref)

      if (currentUser) {
        const uploadTime = new Date()
        const formattedUploadTime = uploadTime.toLocaleString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })

        const dueDiligenceDate = new Date(uploadTime)
        dueDiligenceDate.setFullYear(dueDiligenceDate.getFullYear() + 1)

        const formattedDueDiligenceDate = formatDateForInput(dueDiligenceDate)

        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const vendorsRef = doc(tenantRef, 'vendors', id)

        const newDiligenceEntry = {
          diligence_file_name,
          diligence_url: downloadURL,
          upload_time: formattedUploadTime,
          uploaded_by: currentUser.uid || '',
        }

        await updateDoc(vendorsRef, {
          diligence: arrayUnion(newDiligenceEntry),
          due_diligence_status: 'Complete',
          due_diligence_date: formattedDueDiligenceDate,
        })

        await updateDoc(vendorsRef, {
          due_deligence_status: 'Complete',
          due_diligence_date: formattedDueDiligenceDate,
          last_updated: serverTimestamp(),
        })

        // Add activity log
        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `Due diligence updated for ${diligence_file_name}`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          date: todaysDate,
          email: currentUser?.email,
          target: 'Vendor Risk',
          action: 'Due diligence updated',
        })

        toast.success('Due Diligence updated successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        onClose()
        setFile([])
        setUploadedFile(null)
      } else {
        throw new Error('Current user is not available')
      }
    } catch (err) {
      toast.error(`Error uploading due diligence`, {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          color: 'black',
        },
        autoClose: 3000,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {' '}
      <ToastContainer />
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Due Diligence
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary'
              aria-label='Close'
              onClick={onClose}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-7'
          tabIndex={-1}
          style={{height: 'calc(100vh - 20px)'}}
        >
          <div className='fs-6 fw-bold mb-4'>Documents</div>
          <div className='fs-6 fw-semibold mb-4' style={{color: '#5A5A5A'}}>
            Please review your vendors compliance documents like ISO report, SOC 2 report, etc and
            upload to SecureSlate.
          </div>
          <div className='fs-6 fw-semibold' style={{color: '#5A5A5A'}}>
            <strong>Note:</strong> Ensure they are the latest valid documents before reviewing.
            You’ll find your uploads in the due diligence documents section.
          </div>

          <div className='form'>
            <div className='form-group mb-4'>
              <div className='main-container'>
                <div className='fw-bold fs-6 mb-4 mt-6'></div>
                {uploadedFile ? (
                  <>
                    <div className='fs-6 fw-semibold my-2'> {uploadedFile.upload_time} </div>
                    <div className={`card mb-4 mb-xl-8 mt-4`}>
                      <div
                        className='card-header border-0 pt-6 mb-5'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <img
                            src='/media/icon/file2.svg'
                            alt=''
                            className='img-fluid'
                            style={{width: '50px', height: '50px', marginRight: '10px'}}
                          />
                          <div
                            className='d-flex justify-content-start flex-column fw-bold fs-6'
                            style={{
                              flexGrow: 1,
                              minWidth: 0,
                              maxWidth: '400px', 
                              whiteSpace: 'normal', 
                              wordWrap: 'break-word', 
                              overflow: 'hidden', 
                            }}
                          >
                            {uploadedFile.name}
                            <span className='fw-semibold d-block fs-6' style={{color: '#5A5A5A'}}>
                              Size: {(uploadedFile.size / 1024).toFixed(2)} KB
                            </span>
                          </div>
                        </div>
                        <div>
                          {' '}
                          <span className='mx-1 fs-7'>Uploaded</span>{' '}
                          <span className='btn btn-icon btn-sm btn-active-light-primary'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20'
                              height='20'
                              viewBox='0 0 24 24'
                              fill='#5A5A5A'
                              className='icon icon-tabler icons-tabler-filled icon-tabler-circle-x'
                              onClick={handleRemoveFile}
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z' />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Dropzone
                    onDrop={handleUpload}
                    minSize={1024}
                    accept={{
                      'application/pdf': ['.pdf'],
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                        '.docx',
                      ],
                    }}
                    maxSize={5 * 1024 * 1024}
                  >
                    {({getRootProps, getInputProps}) => (
                      <div
                        {...getRootProps({
                          className: 'dropzone',
                          style: {
                            backgroundColor: '#fff', // Change this to your desired color
                            padding: '20px',
                            border: '2px dashed #ccc',
                            borderRadius: '4px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          },
                        })}
                      >
                        <input {...getInputProps()} />
                        <img
                          src='/media/icon/uploadFile.svg'
                          alt=''
                          loading='lazy'
                          className='mb-4 mt-4'
                        />
                        <p className='fw-bold fs-6'>
                          Drag and drop or <a href='#'>select</a> a files
                        </p>
                        <p className='fs-6'>Upload up to 5 MB. Supported formats: PDF, DOCX</p>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className='offcanvas-footer d-flex justify-content-end  border-top pt-4 pb-4 px-6'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <button type='button' className='btn btn-light btn-sm mx-2 ' onClick={onClose}>
            Cancel
          </button>
          {isLoading ? (
            <button
              type='submit'
              value='Submit'
              className='btn btn-sm fs-6 '
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}
              disabled
            >
              <span className='spinner-border spinner-border-sm align-middle m-2'></span>
              Complete Due Diligence
            </button>
          ) : (
            <button
              className='btn btn-sm fs-6'
              onClick={handleSubmit}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#148363')
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = '#1CBB8D')
              }
              disabled={uploadedFile === null}
              style={{
                backgroundColor: '#1CBB8D',
                color: '#fff',
                height: '40px',
                borderRadius: '4px',

                transition: 'background-color 0.3s',
              }}
            >
              Complete Due Diligence
            </button>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default DiligenceDrawer
