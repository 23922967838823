import React, {FC, useEffect, useState} from 'react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import {Reports} from './table/Reports'
import {useAuth} from 'app/modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from 'services/firebase'
import {useLocation} from 'react-router-dom'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface LocationState {
  auditCompleted?: boolean
}

const ReportsWrapper: FC = () => {
  const {currentUser} = useAuth()
  const [audits, setAudits] = useState<any[]>([])
  const location = useLocation()
  const {auditCompleted} = (location.state as LocationState) || {auditCompleted: false}

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'audits')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      let Data = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          status: doc.data()?.status,
          createdAt: doc.data().createdAt,
          auditor: doc.data().auditor,
        }))
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

      if (currentUser?.role === 'Auditor') {
        Data = Data.filter((audit) => audit.auditor?.id === currentUser?.uid)
      }
      setAudits(Data)
    })

    return unsubscribe
  }, [])

  return (
    <>
      {audits.length <= 0 && currentUser?.role === 'Auditor' && (
        <label
          className='form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6'
          style={{
            background: '#F5F9FF',
            border: '1px solid #D1E3FF',
            borderRadius: '8px',
            color: '#3B4A67',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
          }}
        >
          {/* Begin::Label */}
          <span className='d-flex align-items-center'>
            {/* Begin::Info */}
            <span className='ms-4'>
              <span className='fs-5 fw-bold text-primary mb-2 d-block'>No Audits Assigned</span>
              <span className='fw-semibold fs-6 text-gray-700'>
                There are currently no audits assigned to your account. Once your organization
                assigns an audit, it will appear here for your review.
              </span>
            </span>
            {/* End::Info */}
          </span>
          {/* End::Label */}
        </label>
      )}

      {auditCompleted && (
        <label
          className='form-check form-check-custom mb-6 btn btn-outline btn-outline-dashed d-flex text-start p-6'
          style={{
            background: '#F5F9FF',
            border: '1px solid #D1E3FF',
            borderRadius: '8px',
            color: '#3B4A67',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
          }}
        >
          {/* Begin::Label */}
          <span className='d-flex align-items-center'>
            {/* Begin::Info */}
            <span className='ms-4'>
              <span className='fs-5 fw-bold text-primary mb-2 d-block'>
                Audit Completed Successfully
              </span>
              <span className='fw-semibold fs-6 text-gray-700'>
                The audit process has been successfully completed, and your access to the data has
                been revoked in accordance with our security and compliance policies. There are
                currently no active audits assigned to your account. If your organization assigns a
                new audit in the future, it will appear here for your review. Please contact your
                organization's administrator if you have any questions or require further
                assistance.
              </span>
            </span>
            {/* End::Info */}
          </span>
          {/* End::Label */}
        </label>
      )}

      <PageTitle
        icon='Framework.svg'
        title='Frameworks'
        desc='View all your compliance and security frameworks'
        breadcrumbs={usersBreadcrumbs}
      >
        Frameworks
      </PageTitle>
      {/* <KTCard> */}
      {/* <ReportsListHeader /> */}
      <Reports className='card-l-stretch mb-xl-8 ' />
      {/* <VendorReports className='card-xl-stretch mb-xl-8' /> */}
      {/* </KTCard> */}
    </>
  )
}

export default ReportsWrapper
