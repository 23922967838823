import React, {useEffect, useState} from 'react'
import Drawer from 'react-modern-drawer'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

interface AddGitLabProps {
  isOpen: boolean
  onClose: () => void
}
const GitlabDrawer: React.FC<AddGitLabProps> = ({isOpen, onClose}) => {
  // const [message, setMessage] = useState({error: false, msg: ''})
  // const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openAccordion, setOpenAccordion] = useState(false)
  const [openAccordion2, setOpenAccordion2] = useState(false)
  const [completed, setCompleted] = useState(false)

  const handleConnect = () => {
    setCompleted(true)
  }
  const handleClose = () => {
    setCompleted(false)
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
      <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            GitLab
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>

        <div
          className=' scroll-y overflow-auto p-6 mb-4'
          tabIndex={-1}
          style={{height: 'calc(100vh - 40px)'}}
        >
          <div className='form'>
            <div className='card-header border-0  mb-4'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-45px me-5'>
                    <img src={toAbsoluteUrl('/media/integrations/GitLab.svg')} alt='' />
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='card-label fw-bold fs-5'>GitLab</span>
                    <span className='fw-semibold fs-6' style={{color: '#5A5A5A'}}>
                      Code Collaboration & Version Control Platform
                    </span>
                  </div>
                </div>
                <span className='badge border p-2 rounded-pill'>
                  <span
                    className={`badge border rounded-circle mx-2 bg-danger`}
                    style={{
                      width: '12px',
                      height: '12px',
                      padding: '3px',
                    }}
                  >
                    {' '}
                  </span>
                  <span className='text-center'>Not Connected</span>
                </span>
              </div>
            </div>
            <label className='text-dark fw-bold text-primary my-2 fs-6 mb-4'>
              Mapped Controls & Tests
            </label>
            <div className={`card  mb-4 ${openAccordion ? 'px-6 pt-6' : 'p-6'}`}>
              <div
                className='d-flex'
                onClick={() => setOpenAccordion(!openAccordion)}
                style={{cursor: 'pointer'}}
              >
                <div className='row w-100'>
                  <div className='col'>
                    <div className='d-flex align-items-center'>
                      <span>
                        {openAccordion ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M6 9l6 6l6 -6' />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M9 6l6 6l-6 6' />
                          </svg>
                        )}
                      </span>
                      <span className='fs-6 fw-bold mx-4'>
                        {/* {getCodeFromFrameworkMapping(
                              control.control_framework_mappings.split(',')[0].trim()
                            )}{' '}
                            {control.control_title} */}
                        Controls
                      </span>
                    </div>
                  </div>

                  <div className='col-auto d-flex align-items-center ml-auto'>
                    <div className='d-flex justify-content-end'>
                      {/* {control.control_evidence_status !== 'OK' ? ( */}
                      <div className='fw-semibold'>
                        {/* <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#EE3043'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 9v4' />
                                <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                                <path d='M12 16h.01' />
                              </svg>{' '} */}
                        {/* {processControlValues(control.control_document, control.control_test)}{' '} */}
                        2 Controls
                      </div>
                      {/* ) : (
                            <>
                              <div className='fw-bold'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#1CBB8D'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                  <path d='M9 12l2 2l4 -4' />
                                </svg>{' '}
                                OK
                              </div>
                            </>
                          )} */}
                    </div>
                  </div>
                </div>
              </div>
              {openAccordion && (
                <table className=' table  mt-3 '>
                  <thead>
                    <tr className='py-4 border-bottom fw-bold' style={{color: '#5A5A5A'}}>
                      <th className='min-w-350px'></th>
                      <th className='min-w-100px'></th>
                      {/* <th className='min-w-250px'>ACTION</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                      <td className='pt-6 pb-6'>Inventory of assets</td>
                      <td className='pt-6 pb-6'>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          {/* <span
                                className={`badge border rounded-circle mx-2 ${getBadgeClass(
                                  processControlValues(
                                    control.control_document,
                                    control.control_test
                                  )
                                )}`}
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  padding: '3px',
                                }}
                              >
                                {' '}
                              </span> */}
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                      {/* <td className='pt-6 pb-6'>
                            <a
                              href={`/controls/details/${control.id}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='d-flex align-items-center'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                <path d='M11 13l9 -9' />
                                <path d='M15 4h5v5' />
                              </svg>
                              <span className='' style={{color: '#5A5A5A'}}>
                                View in full Screen
                              </span>
                            </a>
                          </td> */}
                    </tr>
                    <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                      <td className='pt-6 '>Ownership of assets</td>
                      <td className='pt-6 '>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          {/* <span
                                className={`badge border rounded-circle mx-2 ${getBadgeClass(
                                  processControlValues(
                                    control.control_document,
                                    control.control_test
                                  )
                                )}`}
                                style={{
                                  width: '12px',
                                  height: '12px',
                                  padding: '3px',
                                }}
                              >
                                {' '}
                              </span> */}
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                      {/* <td className='pt-6 pb-6'>
                            <a
                              href={`/controls/details/${control.id}`}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='d-flex align-items-center'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#5A5A5A'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-external-link mb-1 mx-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6' />
                                <path d='M11 13l9 -9' />
                                <path d='M15 4h5v5' />
                              </svg>
                              <span className='' style={{color: '#5A5A5A'}}>
                                View in full Screen
                              </span>
                            </a>
                          </td> */}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>

            <div className={`card  mb-4 ${openAccordion2 ? 'px-6 pt-6' : 'p-6'}`}>
              <div
                className='d-flex'
                onClick={() => setOpenAccordion2(!openAccordion2)}
                style={{cursor: 'pointer'}}
              >
                <div className='row w-100'>
                  <div className='col'>
                    <div className='d-flex align-items-center'>
                      <span>
                        {openAccordion2 ? (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M6 9l6 6l6 -6' />
                          </svg>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#5A5A5A'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-right'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M9 6l6 6l-6 6' />
                          </svg>
                        )}
                      </span>
                      <span className='fs-6 fw-bold mx-4'>
                        {/* {getCodeFromFrameworkMapping(
                              control.control_framework_mappings.split(',')[0].trim()
                            )}{' '}
                            {control.control_title} */}
                        Tests
                      </span>
                    </div>
                  </div>
                  <div className='col-auto d-flex align-items-center ml-auto'>
                    <div className='d-flex justify-content-end'>
                      {/* {control.control_evidence_status !== 'OK' ? ( */}
                      <div className='fw-semibold'>
                        {/* <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#EE3043'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M12 9v4' />
                                <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                                <path d='M12 16h.01' />
                              </svg>{' '} */}
                        {/* {processControlValues(control.control_document, control.control_test)}{' '} */}
                        2 Tests
                      </div>
                      {/* ) : (
                            <>
                              <div className='fw-bold'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='#1CBB8D'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-circle-check'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                                  <path d='M9 12l2 2l4 -4' />
                                </svg>{' '}
                                OK
                              </div>
                            </>
                          )} */}
                    </div>
                  </div>
                </div>
              </div>
              {openAccordion2 && (
                <table className=' table  mt-3 '>
                  <thead>
                    <tr className='py-4 border-bottom fw-bold' style={{color: '#5A5A5A'}}>
                      <th className='min-w-350px'></th>
                      <th className='min-w-100px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                      <td className='pt-6 pb-6'>Access request ticket and history</td>
                      <td className='pt-6 pb-6'>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                    </tr>
                    <tr className='border-bottom my-2 fw-bold' style={{color: '#171717'}}>
                      <td className='pt-6 '>Appication session timeout</td>
                      <td className='pt-6 '>
                        <span className='badge border p-2 rounded-pill mx-8'>
                          <span className='text-center'>SOC 2 ‧ CC 6.5</span>
                        </span>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>

            <div className='separator my-6'></div>

            <div className='card mb-4 p-4' style={{background: '#F6F6F6'}}>
              <div className='mt-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-key mb-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z' />
                  <path d='M15 9h.01' />
                </svg>
                <span className='fw-bold fs-6 mx-2'>Permissions</span>
              </div>

              <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                SecureSlate will be granted read-only permissions to access and view the data in
                your GitLab account.
              </div>
            </div>

            <div className='card p-4' style={{background: '#F6F6F6'}}>
              <div className='mt-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='icon icon-tabler icons-tabler-outline icon-tabler-user mb-1'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0' />
                  <path d='M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
                </svg>
                <span className='fw-bold fs-6 mx-2'>Access Type</span>
              </div>

              <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                You need to have access to an GitLab admin account to complete the integration
                process.
              </div>
            </div>

            <div className='separator  my-6'></div>

            {!completed ? (
              <>
                <div className='mb-8'>
                  <div className='fs-6 fw-bold text-bold mb-4'> Connect GitLab to SecureSlate</div>
                </div>
                <div className='card mb-8 p-4' style={{background: '#F6F6F6'}}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-help mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
                      <path d='M12 17l0 .01' />
                      <path d='M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>
                      How to connect GitLab with SecureSlate?
                    </span>
                  </div>

                  <div className='fs-6 fw-semibold mt-2' style={{color: '#5A5A5A'}}>
                    Click on the ‘Connect’ button below. You’ll be redirected to GitLab. Please
                    login and grant access to connect with SecureSlate.
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='mb-6'>
                  <div className='fs-6 fw-bold text-bold mb-4'>Disconnect GitLab</div>
                </div>

                <div className='card mb-8 p-4' style={{background: '#F6F6F6'}}>
                  <div className='mt-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-plug-x mb-1'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M13.55 17.733a5.806 5.806 0 0 1 -7.356 -4.052a5.81 5.81 0 0 1 1.537 -5.627l2.054 -2.054l7.165 7.165' />
                      <path d='M4 20l3.5 -3.5' />
                      <path d='M15 4l-3.5 3.5' />
                      <path d='M20 9l-3.5 3.5' />
                      <path d='M16 16l4 4' />
                      <path d='M20 16l-4 4' />
                    </svg>
                    <span className='fw-bold fs-6 mx-2'>
                      Are you sure you want to disconnect GitLab?
                    </span>
                  </div>

                  <div className='fs-6 mt-2' style={{color: '#5A5A5A'}}>
                    Disconnecting GitLab integration will halt all synced data and affect mapped
                    controls and test.
                  </div>
                  <button
                    className='fs-6 fw-semibold rounded border-0 mt-4 mx-1'
                    onClick={handleClose}
                    style={{
                      background: '#FDEAEC',
                      color: '#EE3043',
                      height: '40px',
                      width: '92px',
                      top: '20px',
                      left: '223px',
                    }}
                  >
                    Disconnect
                  </button>
                </div>
              </>
            )}

            <div
              className='offcanvas-footer d-flex justify-content-end  border-top py-4'
              style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
            >
              {/* <button type='button' className='btn btn-light mx-2' onClick={onClose}>
                Close
              </button> */}
              {isLoading ? (
                <button
                  type='submit'
                  value='Submit'
                  className='btn btn-sm fs-6'
                  style={{ backgroundColor: '#1CBB8D', color: '#FFFFFF',height:'40px' }}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                  Connect
                </button>
              ) : (
                <button
                  type='submit'
                  value='Submit'
                  className='btn btn-sm fs-6 '
                  onClick={handleConnect}
                  style={{ backgroundColor: '#1CBB8D', color: '#FFFFFF',height:'40px' }}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Connect
                </button>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default GitlabDrawer
