import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import SignupReferral from '../../../../modules/auth/modals/SignupReferal'
import useCrispChat from 'app/Hooks/useCrispChat'

const DashboardHeader = () => {
  const {currentUser} = useAuth()
  const [totalTest, setTotalTests] = useState(0)
  const [passingTests, setPassingTests] = useState(0)
  const [failingTests, setFailingTests] = useState(0)
  const [totalISO, setTotalISO] = useState(0)
  const [totalSOC, setTotalSOC] = useState(0)
  const [unhealthyISOControlsCount, setUnhealthyISOControlsCount] = useState(0)
  const [unhealthySOCControlsCount, setUnhealthySOCControlsCount] = useState(0)
  const [healthyControlsCount, setHealthyControlsCount] = useState(0)
  const [healthyISOControlsCount, setHealthyISOControlsCount] = useState(0)
  const [healthySOCControlsCount, setHealthySOCControlsCount] = useState(0)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [showSignupReferral, setShowSignupReferral] = useState(false)
  const {toggleCrispChat} = useCrispChat()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 5000) 
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const isNewUser = localStorage.getItem('isNewUser')
    if (isNewUser === 'true') {
      setShowSignupReferral(true)
      // Clear the flag to ensure the modal is only shown once
      localStorage.removeItem('isNewUser')
    }
  }, [])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getTests()
  }, [activeFrameworks])

  const getTests = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'tests')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const testsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().framework,
          status: doc.data().status,
        }))

        const filteredTests = testsData.filter((test) =>
          activeFrameworks.some((framework) => test.framework && test.framework.includes(framework))
        )
        setTotalTests(filteredTests.length)

        const passingCount = filteredTests.filter((test) => test.status === 'Pass').length
        const failingCount = filteredTests.filter((test) => test.status === 'Fail').length

        setPassingTests(passingCount)
        setFailingTests(failingCount)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)

    const testsCollectionRef = collection(tenantRef, 'tests')

    const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
      const isoTests = snapshot.docs.filter((doc) => {
        const framework = doc.data().framework
        return framework && framework.includes('ISO 27001:2022')
      })

      // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
      const isoControlsCount = isoTests.length

      // Update state with the count of "ISO" controls with status "Unhealthy"
      setTotalISO(isoControlsCount)

      const socTests = snapshot.docs.filter((doc) => {
        const framework = doc.data().framework
        return framework && framework.includes('SOC 2')
      })

      // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
      const socCount = socTests.length

      // Update state with the count of "ISO" controls with status "Unhealthy"
      setTotalSOC(socCount)
    })

    return () => unsubscribe() // Clean up the subscription when the component unmounts
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)

    const testsCollectionRef = collection(tenantRef, 'tests')

    const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
      // Filter documents with framework starting with "ISO" and status "Unhealthy"
      const isoFailingTests = snapshot.docs.filter((doc) => {
        const framework = doc.data().framework
        const status = doc.data().status
        return framework && framework.includes('ISO 27001:2022') && status === 'Fail'
      })

      // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
      const isoUnhealthyControlsCount = isoFailingTests.length

      // Update state with the count of "ISO" controls with status "Unhealthy"
      setUnhealthyISOControlsCount(isoUnhealthyControlsCount)

      const socFailingTests = snapshot.docs.filter((doc) => {
        const framework = doc.data().framework
        const status = doc.data().status
        return framework && framework.includes('SOC 2') && status === 'Fail'
      })
      // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
      const socUnhealthyControlsCount = socFailingTests.length

      // Update state with the count of "ISO" controls with status "Unhealthy"
      setUnhealthySOCControlsCount(socUnhealthyControlsCount)
    })

    return () => unsubscribe() // Clean up the subscription when the component unmounts
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || '' // Replace with the desired tenantId
    const tenantRef = doc(db, 'tenants', tenantId)

    const testsCollectionRef = collection(tenantRef, 'tests')

    const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
      const healthyControls = snapshot.docs.filter((doc) => {
        const status = doc.data().status
        return status && status === 'Pass'
      })

      // Count the number of documents with status "Unhealthy"
      const healthyControlsCount = healthyControls.length

      // Update state with the count of "Unhealthy" controls
      setHealthyControlsCount(healthyControlsCount)

      const isohealthyControls = snapshot.docs.filter((doc) => {
        const framework = doc.data().framework
        const status = doc.data().status
        return framework && framework.includes('ISO') && status === 'Pass'
      })

      // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
      const isohealthyControlsCount = isohealthyControls.length

      // Update state with the count of "ISO" controls with status "Unhealthy"
      setHealthyISOControlsCount(isohealthyControlsCount)

      const sochealthyControls = snapshot.docs.filter((doc) => {
        const framework = doc.data().framework
        const status = doc.data().status
        return framework && framework.includes('SOC') && status === 'Pass'
      })
      // Count the number of documents with framework starting with "ISO" and status "Unhealthy"
      const sochealthyControlsCount = sochealthyControls.length

      // Update state with the count of "ISO" controls with status "Unhealthy"
      setHealthySOCControlsCount(sochealthyControlsCount)
    })

    return () => unsubscribe() // Clean up the subscription when the component unmounts
  }, [])

  const renderFrameworkCard = (framework: string | never) => {
    let totalControls, healthyControlsCount, unhealthyControlsCount

    switch (framework) {
      case 'ISO 27001:2022':
        totalControls = totalISO
        healthyControlsCount = healthyISOControlsCount
        unhealthyControlsCount = unhealthyISOControlsCount
        break
      case 'SOC 2':
        totalControls = totalSOC
        healthyControlsCount = healthySOCControlsCount
        unhealthyControlsCount = unhealthySOCControlsCount
        break
      default:
        return null
    }

    return (
      <div className='col-sm-4 mb-5 mb-xl-10'>
        <div className='card card-flush h-lg-80'>
          <div className='card-header '>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-4 text-dark'>{framework}</span>
            </h3>
          </div>

          <div className='card-body pt-0'>
            <div className='card-body d-flex align-items-end pt-0 justify-content-end px-0'>
              <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                <div className='d-flex flex-column flex-sm-row justify-content-between fw-semibold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                  <span className='text-gray-800'>Passing</span>

                  <span className='fw-bold fs-6'>
                    {' '}
                    {totalControls !== 0
                      ? `${((healthyControlsCount / totalControls) * 100).toFixed(0)}%`
                      : '0%'}
                  </span>
                </div>

                <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                  {totalControls !== 0 ? (
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${(healthyControlsCount / totalControls) * 100}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={(healthyControlsCount / totalControls) * 100}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  ) : (
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${0}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex flex-stack'>
              <div className='text-gray-800 fw-semibold fs-6 me-2'>
                <div className='d-inline-block align-middle me-2 mb-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5E6274'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-list-check'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M3.5 5.5l1.5 1.5l2.5 -2.5' />
                    <path d='M3.5 11.5l1.5 1.5l2.5 -2.5' />
                    <path d='M3.5 17.5l1.5 1.5l2.5 -2.5' />
                    <path d='M11 6l9 0' />
                    <path d='M11 12l9 0' />
                    <path d='M11 18l9 0' />
                  </svg>
                </div>
                Total Tests
              </div>

              <div className='d-flex align-items-senter'>
                <span className='text-gray-900 fw-bolder fs-6'>{totalControls}</span>
              </div>
            </div>

            <div className='my-3'></div>

            <div className='d-flex flex-stack'>
              <div className='text-gray-800 fw-semibold fs-6 me-2'>
                <div className='d-inline-block align-middle me-2 mb-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#EE3043'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M12 9v4' />
                    <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                    <path d='M12 16h.01' />
                  </svg>
                </div>
                Needs Attention
              </div>

              <div className='d-flex align-items-senter'>
                <span className='text-gray-900 fw-bolder fs-6'>{unhealthyControlsCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <SignupReferral show={showSignupReferral} onHide={() => setShowSignupReferral(false)} />
      <div className='row gx-4 gx-xl-10'>
        {/*begin::Col*/}
        {activeFrameworks.length !== 0 && (
          <div className='col-sm-4 mb-5 mb-xl-10'>
            {/*begin::List widget 1*/}
            <div className='card card-flush h-lg-80'>
              {/*begin::Header*/}
              <div className='card-header pt-0'>
                {/*begin::Title*/}
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 text-dark'>All Tests</span>
                </h3>
                {/*end::Title*/}
              </div>

              {/*end::Header*/}
              {/*begin::Body*/}
              <div className='card-body pt-0'>
                <div className='card-body d-flex align-items-end pt-0 justify-content-end px-0'>
                  <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                    <div className='d-flex flex-column flex-sm-row justify-content-between fw-semibold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                      <span className='text-gray-800'>Passing</span>

                      <span className='fw-bold fs-6'>
                        {' '}
                        {/* {((passingTests / totalTest) * 100).toFixed(0)}% */}
                        {totalTest !== 0
                          ? `${((passingTests / totalTest) * 100).toFixed(0)}%`
                          : '0%'}
                      </span>
                    </div>

                    <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                      {/* <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${Math.min(passingTests / totalTest) * 100}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={Math.min((passingTests / totalTest) * 100, 100)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div> */}
                      {totalTest !== 0 ? (
                        <div
                          className='rounded h-8px'
                          role='progressbar'
                          style={{
                            width: `${Math.min(passingTests / totalTest) * 100}%`,
                            backgroundColor: '#1CBB8D',
                          }}
                          aria-valuenow={Math.min((passingTests / totalTest) * 100, 100)}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      ) : (
                        <div
                          className='rounded h-8px'
                          role='progressbar'
                          style={{
                            width: `${0}%`,
                            backgroundColor: '#1CBB8D',
                          }}
                          aria-valuenow={0}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
                {/*begin::Item*/}
                <div className='d-flex flex-stack'>
                  {/*begin::Section*/}
                  <div className='text-gray-800 fw-semibold fs-6 me-2'>
                    <div className='d-inline-block align-middle me-2 mb-1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#5E6274'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-list-check'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M3.5 5.5l1.5 1.5l2.5 -2.5' />
                        <path d='M3.5 11.5l1.5 1.5l2.5 -2.5' />
                        <path d='M3.5 17.5l1.5 1.5l2.5 -2.5' />
                        <path d='M11 6l9 0' />
                        <path d='M11 12l9 0' />
                        <path d='M11 18l9 0' />
                      </svg>
                    </div>
                    Total Tests
                  </div>

                  {/*end::Section*/}
                  {/*begin::Statistics*/}
                  <div className='d-flex align-items-senter'>
                    {/*begin::Number*/}
                    <span className='text-gray-900 fw-bolder fs-6'>{totalTest}</span>
                    {/*end::Number*/}
                  </div>
                  {/*end::Statistics*/}
                </div>
                {/*end::Item*/}
                {/*begin::Separator*/}
                <div className='my-3'></div>
                {/*end::Separator*/}
                {/*begin::Item*/}
                <div className='d-flex flex-stack'>
                  {/*begin::Section*/}
                  <div className='text-gray-800 fw-semibold fs-6 me-2'>
                    <div className='d-inline-block align-middle me-2 mb-1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#EE3043'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M12 9v4' />
                        <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                        <path d='M12 16h.01' />
                      </svg>
                    </div>
                    Needs Attention
                  </div>
                  {/*end::Section*/}
                  {/*begin::Statistics*/}
                  <div className='d-flex align-items-senter'>
                    {/*begin::Number*/}
                    <span className='text-gray-900 fw-bolder fs-6'>{failingTests}</span>
                    {/*end::Number*/}
                  </div>
                  {/*end::Statistics*/}
                </div>
              </div>
              {/*end::Body*/}
            </div>
            {/*end::LIst widget 1*/}
          </div>
        )}
        {/*end::Col*/}

        {activeFrameworks.length === 0 ? (
          <div className='col d-flex align-items-stretch mb-5 mb-xl-10'>
            <div
              className='card d-flex text-start p-6 border-dashed'
              style={{
                backgroundColor: '#E8F4F0',
                borderColor: '#50cd89',
              }}
            >
              <span className='d-flex align-items-center'>
                <span className='ms-4'>
                  <span className='d-flex justify-content-between align-items-center fs-5 fw-bold text-gray-900 mb-2'>
                    <span>Get Started with SecureSlate</span>
                    {isLoading && (
                      <span className='fs-7 text-gray-600'>
                        Account Setup in Progress...{' '}
                        <div
                          className='spinner-border spinner-border-sm' 
                          role='status'
                          style={{color: '#44DBAF'}}
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      </span>
                    )}
                  </span>
                  <span className='fw-semibold fs-6 text-gray-700'>
                    It looks like you haven't purchased a SecureSlate plan yet. To unlock powerful
                    security features and expand your capabilities, explore our
                    <a
                      href='https://getsecureslate.com/pricing'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-primary ms-1 fw-semibold'
                    >
                      Pricing Page{' '}
                    </a>
                    and find the best plan for your business. You can also
                    <a
                      href='#'
                      onClick={() => toggleCrispChat()}
                      className='text-primary ms-1 fw-semibold'
                    >
                      contact our sales team{' '}
                    </a>
                    for personalized recommendations.
                  </span>
                </span>
              </span>
            </div>
          </div>
        ) : (
          <>
            {activeFrameworks.includes('ISO 27001:2022') && renderFrameworkCard('ISO 27001:2022')}
            {activeFrameworks.includes('SOC 2') && renderFrameworkCard('SOC 2')}
          </>
        )}

        {/* {activeFrameworks.includes('ISO 27001:2022') && renderFrameworkCard('ISO 27001:2022')}
        {activeFrameworks.includes('SOC 2') && renderFrameworkCard('SOC 2')} */}

        {/* <div className='col-sm-4 mb-5 mb-xl-10'>
          <div className='card card-flush h-lg-80'>
            <div className='card-header '>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 text-dark'>ISO 27001</span>
              </h3>
            </div>

            <div className='card-body pt-0'>
              <div className='card-body d-flex align-items-end pt-0 justify-content-end px-0'>
                <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                  <div className='d-flex flex-column flex-sm-row justify-content-between fw-semibold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                    <span className='text-gray-800'>Passing</span>

                    <span className='fw-bold fs-6'>
                      {' '}
                      {((healthyISOControlsCount / totalISO) * 100).toFixed(0)}%
                    </span>
                  </div>

                  <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${Math.min((healthyISOControlsCount / totalISO) * 100, 100)}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={Math.min((healthyISOControlsCount / totalISO) * 100, 100)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-list-check'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3.5 5.5l1.5 1.5l2.5 -2.5' />
                      <path d='M3.5 11.5l1.5 1.5l2.5 -2.5' />
                      <path d='M3.5 17.5l1.5 1.5l2.5 -2.5' />
                      <path d='M11 6l9 0' />
                      <path d='M11 12l9 0' />
                      <path d='M11 18l9 0' />
                    </svg>
                  </div>
                  Total Tests
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{totalISO}</span>
                </div>
              </div>

              <div className='my-3'></div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#EE3043'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </div>
                  Needs Attention
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{unhealthyISOControlsCount}</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className='col-sm-4 mb-5 mb-xl-10'>
          <div className='card card-flush h-lg-80'>
            <div className='card-header '>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 text-dark'>SOC 2</span>
              </h3>
            </div>

            <div className='card-body pt-0'>
              <div className='card-body d-flex align-items-end pt-0 justify-content-end px-0'>
                <div className='d-flex align-items-center flex-column  w-100 justify-content-end'>
                  <div className='d-flex flex-column flex-sm-row justify-content-between fw-semibold fs-6 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
                    <span className='text-gray-800'>Passing</span>

                    <span className='fw-bold fs-6'>
                      {' '}
                      {((healthySOCControlsCount / totalSOC) * 100).toFixed(2)}%
                    </span>
                  </div>

                  <div className='h-12px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
                    <div
                      className='rounded h-8px'
                      role='progressbar'
                      style={{
                        width: `${Math.min((healthySOCControlsCount / totalSOC) * 100, 100)}%`,
                        backgroundColor: '#1CBB8D',
                      }}
                      aria-valuenow={Math.min((healthySOCControlsCount / totalSOC) * 100, 100)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#5E6274'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-list-check'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M3.5 5.5l1.5 1.5l2.5 -2.5' />
                      <path d='M3.5 11.5l1.5 1.5l2.5 -2.5' />
                      <path d='M3.5 17.5l1.5 1.5l2.5 -2.5' />
                      <path d='M11 6l9 0' />
                      <path d='M11 12l9 0' />
                      <path d='M11 18l9 0' />
                    </svg>
                  </div>
                  Total Tests
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{totalSOC}</span>
                </div>
              </div>

              <div className='my-3'></div>

              <div className='d-flex flex-stack'>
                <div className='text-gray-800 fw-semibold fs-6 me-2'>
                  <div className='d-inline-block align-middle me-2 mb-1'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#EE3043'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      className='icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M12 9v4' />
                      <path d='M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z' />
                      <path d='M12 16h.01' />
                    </svg>
                  </div>
                  Needs Attention
                </div>

                <div className='d-flex align-items-senter'>
                  <span className='text-gray-900 fw-bolder fs-6'>{unhealthySOCControlsCount}</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {activeFrameworks.length < 2 && activeFrameworks.length !== 0 && (
          <div className='col d-flex align-items-stretch mb-5 mb-xl-10'>
            <div className='card flex-fill'>
              <div className='card-body d-flex flex-column justify-content-center align-items-center'>
                <img src='/media/icon/framework.svg' alt='Framework icon' className='mb-4' />
                <h5 className='card-title fw-bold text-center mb-4'>Enable other frameworks</h5>
                <p className='card-text fw-semibold text-center'>
                  <a href='#' onClick={() => toggleCrispChat()} className='text-primary'>
                    Contact sales
                  </a>{' '}
                  to enable other frameworks
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DashboardHeader
