/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../helpers'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {collection, doc, getDocs, onSnapshot, query, updateDoc, where} from 'firebase/firestore'
import db from '../../../../../services/firebase'

const OnSidebarMenuMain = () => {
  const [grandTotal, setGrandTotal] = useState(0)
  const [policyTotal, setPolicyTotal] = useState(0)
  const [completed, setCompleted] = useState(0)
  const {currentUser} = useAuth()
  const [personnelPoliciesLength, setPersonnelPoliciesLength] = useState(0)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }

      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')
      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const employeesData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            fullName: doc.data().full_name,
            group: doc.data().group,
            uid: doc.data().uid,
          }))
          .filter((data) => data.uid === currentUser?.uid)

        if (employeesData.length > 0) {
          const group = employeesData[0].group
          const groupRef = collection(tenantRef, 'personnel_group')
          const unsubscribe = onSnapshot(groupRef, (snapshot) => {
            const groupData = snapshot.docs
              .map((doc) => ({
                ...doc.data(),
                id: doc.id,
                checklist: doc.data().checklist,
                groupName: doc.data().groupName,
                uid: doc.data().uid,
              }))
              .filter((data) => data.groupName === group)

            const checklist = groupData[0].checklist
            const checklistRef = collection(tenantRef, 'personnel_checklist')
            const unsubscribe2 = onSnapshot(checklistRef, (snapshot) => {
              const checklistData = snapshot.docs
                .map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                  checklist: doc.data().checklist_name,
                  policies: doc.data().policies,
                }))
                .filter((data) => data.checklist === checklist)
              if (checklistData.length > 0) {
                setPersonnelPoliciesLength(checklistData[0].policies? checklistData[0].policies.length : '')
              } else {
                setPersonnelPoliciesLength(0)
              }
            })
          })
        }
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const fetchPoliciesData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const userId = currentUser?.uid || ''

        if (!tenantId || !userId) {
          return
        }

        const policiesRef = collection(db, 'tenants', tenantId, 'onboarding', userId, 'policies')
        const unsubscribe = onSnapshot(policiesRef, (snapshot) => {
          const policyDataMap = {}
          let checkedCount = 0

          if (snapshot.empty) {
            setPolicyTotal(0)
            return
          }

          snapshot.docs.forEach((doc) => {
            const policyData = doc.data() || {}
            const isChecked = policyData.isChecked || false

            policyDataMap[doc.id] = policyData

            // Count policies that are checked
            if (isChecked) {
              checkedCount += 1
            }
          })

          const allChecked = checkedCount === personnelPoliciesLength
          setPolicyTotal(allChecked ? 50 : 0)
        })

        return () => {
          unsubscribe()
        }
      } catch (error) {
        console.error('Error fetching policies data:', error)
      }
    }

    fetchPoliciesData()
  }, [currentUser, personnelPoliciesLength])

  useEffect(() => {
    let unsubscribe = () => {}

    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const userId = currentUser?.uid || ''

        if (!tenantId || !userId) {
          return
        }

        let isSATChecked = false
        let isPCISecureChecked = false

        const updateTotalCompleted = () => {
          // if (isSATChecked && isPCISecureChecked) {
          if (isSATChecked) {
            setGrandTotal(50)
          } else {
            setGrandTotal(0)
          }
        }

        // Real-time listener for SAT training data
        const SATDocRef = doc(db, 'tenants', tenantId, 'onboarding', userId, 'training', 'SAT')
        const unsubscribeSAT = onSnapshot(SATDocRef, (doc) => {
          const SATPCISecureData = doc.data() || {}
          isSATChecked = SATPCISecureData.is_checked || false
          updateTotalCompleted()
        })

        // Real-time listener for PCISecure training data
        // const PCISecureDocRef = doc(
        //   db,
        //   'tenants',
        //   tenantId,
        //   'onboarding',
        //   userId,
        //   'training',
        //   'PCISecure'
        // )
        // const unsubscribePCISecure = onSnapshot(PCISecureDocRef, (doc) => {
        //   const PCISecureData = doc.data() || {}
        //   isPCISecureChecked = PCISecureData.is_checked || false
        //   updateTotalCompleted()
        // })

        // Combine the unsubscribe functions
        unsubscribe = () => {
          unsubscribeSAT()
          // unsubscribePCISecure()
        }
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()

    // Cleanup on component unmount
    return () => {
      unsubscribe()
    }
  }, [currentUser])

  useEffect(() => {
    const totalCompleted = grandTotal + policyTotal
    setCompleted(totalCompleted)

    if (totalCompleted === 50 || totalCompleted === 100) {
      updateOnboardingStatus(totalCompleted)
    }
  }, [grandTotal, policyTotal])

  console.log(completed)

  const updateOnboardingStatus = async (totalCompleted) => {
    try {
      const tenantId = currentUser?.tenantId
      const userUid = currentUser?.uid

      if (!tenantId || !userUid) {
        return
      }

      // Reference to the employees collection
      const employeesRef = collection(db, 'tenants', tenantId, 'employees')

      // Query to find the employee document with the matching UID
      const q = query(employeesRef, where('uid', '==', userUid))
      const querySnapshot = await getDocs(q)

      if (querySnapshot.empty) {
        return
      }

      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const day = currentDate.getDate()

      const todaysDate = year + '-' + month + '-' + day

      // Assuming UID is unique, update the first matching document
      const employeeDoc = querySnapshot.docs[0]
      const employeeDocRef = doc(db, 'tenants', tenantId, 'employees', employeeDoc.id)
      if (totalCompleted === 100) {
        await updateDoc(employeeDocRef, {
          onboarding: 'Completed',
          onboardingEnded: todaysDate,
          securityTraining: grandTotal === 50 ? 'Complete' : 'Incomplete',
          acceptedPolicies: policyTotal === 50 ? 'Complete' : 'Incomplete',
        })
      } else {
        await updateDoc(employeeDocRef, {
          onboarding: 'Incomplete',
          securityTraining: grandTotal === 50 ? 'Complete' : 'Incomplete',
          acceptedPolicies: policyTotal === 50 ? 'Complete' : 'Incomplete',
        })
      }
    } catch (error) {
      console.error('Error updating onboarding status:', error)
    }
  }

  return (
    <>
      <div
        className='card-body d-flex align-items-center pt-2 justify-content-center'
        style={{marginTop: '-20px'}}
      >
        <div
          className='d-flex align-items-center flex-column mt-5 w-100 justify-content-start mb-5 p-5 pt-3 '
          style={{backgroundColor: '#19191D', borderRadius: '10px'}}
        >
          <div className='d-flex justify-content-between fw-bold fs-7 text-black opacity-75 w-100 mt-auto mb-2 pt-2'>
            <span className='text-gray-100'>Onboarding</span>

            <span className='text-muted fs-7'>
              {/* {grandTotal === 40 && policyTotal === 40 && onboardingTotal === 20
                ? '3 of 3 complete'
                : (grandTotal === 40 ? 1 : 0) +
                    (policyTotal === 40 ? 1 : 0) +
                    (onboardingTotal === 20 ? 1 : 0) ===
                  2
                ? '2 of 3 complete'
                : grandTotal === 40 || policyTotal === 40 || onboardingTotal === 20
                ? '1 of 3 complete'
                : '0 of 3 complete'} */}
              {grandTotal === 50 && policyTotal === 50
                ? '2 of 2 complete'
                : (grandTotal === 50 ? 1 : 0) + (policyTotal === 50 ? 1 : 0) === 2
                ? '2 of 2 complete'
                : grandTotal === 50 || policyTotal === 50
                ? '1 of 2 complete'
                : '0 of 2 complete'}
            </span>
          </div>
          <div className='h-8px mx-0 w-100 rounded' style={{backgroundColor: '#DAF9EF'}}>
            <div
              className='rounded h-8px'
              role='progressbar'
              style={{
                width: `${Math.min(grandTotal + policyTotal, 100)}%`,
                backgroundColor: '#44DBAF',
              }}
              aria-valuenow={Math.min(grandTotal + policyTotal, 100)}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
          <div className='text-muted mt-2 fs-8'>
            {completed === 100 ? 'Onboarding has been completed' : ''}
          </div>
        </div>
      </div>

      <img src={toAbsoluteUrl(`/media/logos/line 4.svg`)} className='mb-6' alt='line' />
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='home'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}

      <SidebarMenuItem to='/training' icon='copy-success' title='Training' fontIcon='bi-layers' />
      <SidebarMenuItem to='/policies' icon='document' title='Policies' fontIcon='bi-layers' />
      {/* <SidebarMenuItem
        to='/backgroundCheck'
        icon='profile-circle'
        title='Identity Verification'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}

export {OnSidebarMenuMain}
