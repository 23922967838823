import {ChangeEvent, useCallback, useEffect, useState} from 'react'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {collection, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import db from '../../../../services/firebase'
import Drawer from 'react-modern-drawer'
import {getStorage, ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {toast} from 'react-toastify'

interface EditPersonnelChecklistProps {
  isOpen: boolean
  onClose: () => void
  id: string
}

interface Policy {
  id: string
  framework: any
  author?: string
}

const EditPersonnelChecklistModal: React.FC<EditPersonnelChecklistProps> = ({
  isOpen,
  onClose,
  id,
}) => {
  const [form, setForm] = useState<any>({
    policies: [],
    CustomTasks: [],
  })
  const [progress, setProgress] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [selectAll, setSelectAll] = useState(false)
  const [policy, setPolicy] = useState<any[]>([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})

  const getPersonnelChecklist = useCallback(() => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'personnel_checklist')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          policies: doc.data().policies || [],
        }))
        if (id) {
          const filteredData = risksData.find((data) => data.id === id)
          if (filteredData) {
            setForm(filteredData)
          }
        }
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting personnels:', error)
    }
  }, [currentUser, id, policy.length])

  useEffect(() => {
    const unsubscribe = getPersonnelChecklist()
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [getPersonnelChecklist])

  useEffect(() => {
    getPolicy()
  }, [activeFrameworks])

  useEffect(() => {
    getActiveFramworks()
  }, [])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getPolicy = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'policies')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const policyData: Policy[] = snapshot.docs
          .map((doc) => ({
            ...(doc.data() as Policy),
            id: doc.id,
            framework: doc.data().frameworks,
            status: doc.data().status,
          }))
          .filter((item) => item.status === 'Approved')

        const filteredPolicies = policyData.filter((policy) => {
          const policyFrameworks = policy.framework

          if (policy.author === 'Custom policy') return true

          if (!policyFrameworks || !Array.isArray(policyFrameworks)) return false

          // Check if any of the policy's frameworks are included in the active frameworks
          const isFrameworkMatch = policyFrameworks.some((policyFramework) =>
            activeFrameworks.includes(policyFramework)
          )

          return isFrameworkMatch
        })
        setPolicy(filteredPolicies)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const errors: Record<string, string> = {}
    if (!form.checklist_name) {
      errors.checklistName = 'Checklist name is required'
      setFormErrors(errors)
      return
    }

    // Disable the submit button to prevent multiple submissions
    const submitButton = e.currentTarget.querySelector('button[type="submit"]') as HTMLButtonElement // Cast to HTMLButtonElement
    if (submitButton) {
      submitButton.disabled = true
    }

    try {
      setIsLoading(true)

      const storage = getStorage()

      if (selectedFile) {
        const fileName = new Date().getTime() + selectedFile.name
        const storageRef = ref(storage, `ChecklistFile/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, selectedFile)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setProgress(progress)
          },
          (error) => {
            console.error('Error uploading file:', error.message)
            setIsLoading(false)
          },
          async () => {
            const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
            form.fileUrl = downloadUrl

            await updatePersonnelChecklist()
          }
        )
      } else {
        await updatePersonnelChecklist()
        // handleSuccessToast()
      }
    } catch (err: any) {
    } finally {
      setIsLoading(false)
      onClose()
      if (submitButton) {
        submitButton.disabled = false
      }
    }
  }

  const updatePersonnelChecklist = async () => {
    const updatedForm = Object.entries(form).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value
      }
      return acc
    }, {})
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const checklistRef = doc(tenantRef, 'personnel_checklist', id)

    await updateDoc(checklistRef, {
      ...updatedForm,
      updated_at: new Date(),
    })
    setIsLoading(false)
  }

  const handleSuccessToast = () => {
    toast.success('Checklist updated', {
      position: toast.POSITION.TOP_CENTER,
      className: 'bg-white',
      style: {
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e0e0e0',
        color: 'black',
      },
      progressStyle: {
        background: '#1CBB8D',
      },
      icon: ({theme, type}) => (
        <svg
          width='26'
          height='26'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{color: '#1CBB8D'}}
        >
          <path
            d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
            fill='currentColor'
          />
        </svg>
      ),
      autoClose: 3000,
    })
  }

  const getFileName = (fileUrl: string) => {
    if (fileUrl) {
      const decodedUrl = decodeURIComponent(fileUrl)
      const fileNameWithParams = decodedUrl.split('?')[0]
      const fileNameArray = fileNameWithParams.split('/')
      let fileName = fileNameArray[fileNameArray.length - 1]
      fileName = fileName.replace(/\d{13}/, '')
      return fileName
    }
    return ''
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
      setSelectedFileName(file.name)
    }
  }

  const handleRemoveFile = (e: React.MouseEvent) => {
    e.preventDefault()
    setSelectedFile(null)
    setSelectedFileName(null)
    setForm({...form, fileUrl: '', NDA: false})
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.checked})
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setSelectAll(checked)
    setForm({
      ...form,
      policies: checked ? policy.map((p) => p.policy) : [],
    })
  }

  const handlePolicyChange = (e: React.ChangeEvent<HTMLInputElement>, policyName: string) => {
    const checked = e.target.checked
    setForm((prevForm) => {
      const updatedPolicies = checked
        ? [...(prevForm.policies || []), policyName]
        : (prevForm.policies || []).filter((p: string) => p !== policyName)
      return {
        ...prevForm,
        policies: updatedPolicies,
      }
    })
  }
  return (
    <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
      {/* Drawer header */}
      <div
        className=' modal-dialog-centered border-bottom border-gray-300'
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          padding: '14px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingLeft: '15px',
          paddingRight: '12px',
        }}
      >
        <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
          Edit Checklist
        </div>

        <div style={{display: 'flex', gap: '16px'}}>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary '
            onClick={onClose}
            aria-label='Close'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              stroke='#5A5A5A'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-x'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M18 6l-12 12' />
              <path d='M6 6l12 12' />
            </svg>
          </div>
        </div>
      </div>

      {/* Drawer body */}
      <div
        className='scroll-y overflow-auto p-5 mb-4'
        tabIndex={-1}
        style={{height: 'calc(100vh - 10px)'}}
      >
        <div className='modal-body mb-8'>
          <form onSubmit={handleSubmit}>
            <div
              className='d-flex flex-column scroll-y me-n6'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              // data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
              {/* Checklist Name */}
              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2'>Checklist Name</label>
                <input
                  type='text'
                  name='checklist_name'
                  value={form.checklist_name || ''}
                  onChange={handleChange}
                  className={`form-control mb-3 mb-lg-0 w-100 ${
                    formErrors.checklistName ? 'is-invalid' : ''
                  }`}
                />
                {formErrors.checklistName && (
                  <div className='invalid-feedback d-block'>{formErrors.checklistName}</div>
                )}
              </div>

              {/* Onboarding Section */}
              <div className='fv-row mb-10'>
                <label className='fw-bold fs-6 mb-2'>Onboarding</label>

                {/* Policies */}
                <div className='border rounded'>
                  <div className='p-4 fw-bold' style={{borderBottom: '1px solid #ddd'}}>
                    <img src='/media/buttons/badge-1.svg' alt='' className='m-1' /> Policies
                  </div>
                  {policy.length > 0 ? (
                    <>
                      <div
                        className='form-check form-check-custom form-check-solid p-4'
                        style={{borderBottom: '1px dotted #ddd'}}
                      >
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='selectAll'
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                        <label className='form-check-label fw-bold' htmlFor='selectAll'>
                          Select All
                        </label>
                      </div>
                      <div style={{maxHeight: '50vh', overflowY: 'auto'}}>
                        {policy.map((p) => (
                          <div
                            className='form-check form-check-custom form-check-solid p-4 fw-bold'
                            style={{borderBottom: '1px dotted #ddd'}}
                            key={p.id}
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={`policy-${p.id}`}
                              checked={form.policies?.includes(p.policy)}
                              onChange={(e) => handlePolicyChange(e, p.policy)}
                            />
                            <label className='form-check-label' htmlFor={`policy-${p.id}`}>
                              <img src='/media/buttons/text_snippet.png' alt='' className='m-1' />{' '}
                              {p.policy}
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className='p-4 fw-semibold text-center py-5'>
                      Policies has not been added to this account or Policies has not been approved.
                    </div>
                  )}
                </div>

                {/* Security Training */}
                {/* <div className='border rounded mt-5'>
                  <div className='p-4 fw-bold rounded d-flex align-items-center justify-content-between'>
                    <div>
                      <img src='/media/buttons/badge-2.svg' alt='' className='m-1' /> Security
                      Training
                    </div>
                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='security_training'
                        checked={form.security_training || false}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                </div> */}

                {/* OWASP Training */}
                {/* <div className='border rounded mt-5'>
              <div className='p-4 fw-bold rounded d-flex align-items-center justify-content-between'>
                <div>
                  <img src='/media/buttons/badge-3.svg' alt='' className='m-1' /> OWASP Training
                </div>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='owasp_training'
                    checked={form.owasp_training || false}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            </div> */}

                {/* Offboarding Section */}
                <div className='fv-row mb-10 mt-10'>
                  <label className='fw-bold fs-6 mb-2'>Offboarding</label>
                  <div className='border rounded mb-20'>
                    <div
                      className='p-4 fw-bold d-flex align-items-center justify-content-between'
                      style={{borderBottom: '1px solid #ddd'}}
                    >
                      <div>
                        <img src='/media/buttons/badge-1.svg' alt='' className='m-1' /> NDA
                      </div>
                      <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='NDA'
                          checked={form.NDA || false}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                    </div>
                    {form.NDA && (
                      <div className='p-4 fw-bold d-flex align-items-center justify-content-between'>
                        <div>
                          {!selectedFile && !form.fileUrl && (
                            <>
                              <label htmlFor='fileInput'>
                                <img
                                  src='/media/buttons/upload.svg'
                                  alt=''
                                  className='mb-5 mt-2'
                                  style={{cursor: 'pointer'}}
                                />
                              </label>
                              <input
                                type='file'
                                id='fileInput'
                                style={{display: 'none'}}
                                onChange={handleFileChange}
                                accept='application/pdf'
                              />
                              <br />
                              <span className='form-text text-muted'>
                                Max number of files is 1. Supported file type: pdf
                              </span>
                            </>
                          )}
                          {selectedFile && (
                            <p className='bg-gray-100 p-2 w-100'>
                              <span className='mx-5'>Selected File: {selectedFile.name}</span>
                              <button onClick={handleRemoveFile} className='btn p-0'>
                                <i className='bi bi-x fs-1'></i>
                              </button>
                            </p>
                          )}
                          {form.fileUrl && !selectedFile && (
                            <p className='bg-gray-100 p-2 w-100'>
                              <span className='mx-5'>
                                Selected File: {getFileName(form.fileUrl)}
                              </span>
                              <button onClick={handleRemoveFile} className='btn p-0'>
                                <i className='bi bi-x fs-1'></i>
                              </button>
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Custom Tasks Section */}
                <div className='fv-row mb-20'>
                  {/* <div className='border rounded'>
              <div className='p-4 fw-bold ' style={{borderBottom: '1px solid #ddd'}}>
                <img src='/media/buttons/badge-2.svg' alt='' className='m-1' /> Custom Tasks
              </div>
              {(form.CustomTasks || []).map((task: string, index: number) => (
                <div
                  key={index}
                  className='form-check form-check-custom form-check-solid p-4'
                  style={{borderBottom: '1px dotted #ddd'}}
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id={`task-${index}`}
                    checked={true}
                    onChange={() => {
                      setForm((prevForm) => ({
                        ...prevForm,
                        CustomTasks: (prevForm.CustomTasks || []).filter(
                          (_: any, i: number) => i !== index
                        ),
                      }))
                    }}
                  />
                  <label className='form-check-label fw-bold' htmlFor={`task-${index}`}>
                    {task}
                  </label>
                </div>
              ))}
              <div className='form-check form-check-custom form-check-solid p-4 fw-bold mb-10'>
                <img
                  src='/media/buttons/AddTask.svg'
                  alt=''
                  className='m-1'
                  style={{cursor: 'pointer'}}
                  // onClick={() => {
                  //   const newTask = prompt('Enter new task:')
                  //   if (newTask) {
                  //     setForm({
                  //       ...form,
                  //       CustomTasks: [...(form.CustomTasks || []), newTask],
                  //     })
                  //   }
                  // }}
                />
              </div>
            </div> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Drawer footer */}
      <div
        className='offcanvas-footer d-flex justify-content-end border-top py-4 px-6 '
        style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
      >
        <div className='d-flex justify-content-end align-items-center'>
          {/* <div className='m-1' style={{cursor: 'pointer'}} aria-label='Cancel' onClick={onClose}>
            <img src='/media/buttons/Cancel.svg' alt='Cancel' />
          </div> */}
          <button
            className='btn btn-sm btn-light fw-semibold me-4 fs-6'
            onClick={onClose}
            aria-label='Close'
            style={{height: '40px'}}
          >
            Cancel
          </button>
          <div style={{cursor: 'pointer'}} onClick={handleSubmit}>
            {isLoading ? (
              <button
                className='btn btn-sm fw-semibold px-6 fs-6'
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
                disabled
              >
                Updating...<span className='spinner-border spinner-border-sm align-middle'></span>
              </button>
            ) : (
              <button
                className='btn btn-sm fw-semibold px-6 fs-6'
                onClick={handleSubmit}
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export {EditPersonnelChecklistModal}
