import ExcelJS from 'exceljs'
import {
  collection,
  doc,
  getDocs,
  addDoc,
  updateDoc,
  getFirestore,
  Timestamp,
} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL, getMetadata} from 'firebase/storage'
import {storage} from 'services/firebase'
const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}
export const ExportRisk = async (
  currentUser,
  auditFolderName,
  folderId,
  formattedDate,
  auditID
) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId
  const policyRef = collection(doc(db, 'tenants', tenantId!), 'risks')
  const querySnapshot = await getDocs(policyRef)

  const data = querySnapshot.docs.map((doc) => doc.data())

  if (data.length > 0) {
    const fields = [
      {key: 'riskSource', header: 'Risk Name', width: 50},
      {key: 'description', header: 'Description', width: 40},
      {key: 'category', header: 'Category', width: 40},
      {key: 'owner', header: 'Risk Owner', width: 20},
      {key: 'CIA', header: 'CIA', width: 30},
      {key: 'inherent_likelihood', header: 'Inherent Likelihood', width: 15},
      {key: 'inherent_impact', header: 'Inherent Impact', width: 15},
      {key: 'residual_impact', header: 'Residual Impact', width: 15},
      {key: 'residual_likelihood', header: 'Residual Likelihood', width: 15},
      {key: 'treatment', header: 'Treatment', width: 15},
      {key: 'risk_status', header: 'Risk Status', width: 15},
      {key: 'control_title', header: 'Mapped Controls', width: 30},
    ]

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Risks')

    worksheet.columns = fields.map((field) => ({
      header: field.header,
      key: field.key,
      width: field.width,
    }))

    const headerRow = worksheet.getRow(1)
    headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FF000000'},
    }
    headerRow.height = 30
    headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
    worksheet.views = [{state: 'frozen', ySplit: 1}]

    data.forEach((row) => {
      const rowData = fields.reduce((acc, field) => {
        if (field.key === 'CIA') {
          acc[field.key] = (row[field.key] || []).join(', ')
        } else if (field.key === 'category') {
          acc[field.key] = (row[field.key] || []).join(', ')
        } else if (field.key === 'control_title') {
          acc[field.key] = (row.added_controls || [])
            .map((control) => control.control_title)
            .join(', ')
        } else if (field.key === 'updated_at') {
          const timestamp =
            row[field.key] instanceof Timestamp ? row[field.key].toDate() : row[field.key]
          acc[field.key] = timestamp ? formatDate(timestamp) : ''
        } else {
          acc[field.key] = row[field.key] || ''
        }
        return acc
      }, {})

      const addedRow = worksheet.addRow(rowData)
      addedRow.height = 20
      addedRow.font = {size: 10}

      const riskStatusCell = addedRow.getCell('risk_status')
      switch (riskStatusCell.value) {
        case 'Incomplete':
          riskStatusCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
          break
        case 'Needs approval':
          riskStatusCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFF2CC'},
          }
          break
        case 'Approved':
          riskStatusCell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
          break

        default:
          break
      }
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const fileName = `${formattedDate} - risks.xlsx`
    const storageRef = ref(storage, `tenants/${tenantId}/dataroom/${auditFolderName}/${fileName}`)
    await uploadBytes(storageRef, buffer)
    const metadata = await getMetadata(storageRef)
    const downloadURL = await getDownloadURL(storageRef)
    const tenantRef = doc(db, 'tenants', tenantId!)
    const dataRoomRef = collection(tenantRef, 'dataroom')

    const fileDoc = {
      createdAt: new Date().toISOString(),
      name: fileName,
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      downloadUrl: downloadURL,
      fileSize: metadata.size,
      subFolder: storageRef.fullPath,
      time_created: formattedDate,
      tenantId: tenantId,
      uid: currentUser?.uid,
      is_folder: false,
      uploadedBy: currentUser?.userName,
      parentFolder: folderId,
      framework: '',
      fileId: '',
    }
    const docRef = await addDoc(dataRoomRef, fileDoc)
    await updateDoc(docRef, {fileId: docRef.id})

    await updateAuditDocument(currentUser, auditID, data.length)
  }
}

const updateAuditDocument = async (currentUser, auditId, totalLength) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId || ''
  const tenantRef2 = doc(db, 'tenants', tenantId)
  const policyRef = doc(tenantRef2, 'audits', auditId)

  await updateDoc(policyRef, {TotalRisks: totalLength})
}
