import Swal from 'sweetalert2'
import { doc, writeBatch} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import db, {storage} from '../../../../../services/firebase'
import {deleteObject, listAll, ref} from 'firebase/storage'

const DataRoomGrouping = ({selectedItems}) => {
  const {currentUser} = useAuth()

  const deleteSelectedItemsInBatch = async () => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove Selected Items?`,
        text: `Are you sure you want to remove all selected items from the dataroom? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove All',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (!result.isConfirmed) return

      const batch = writeBatch(db)
      const storageDeletePromises = []

      for (const item of selectedItems) {
        if (item.is_folder) {
          const tenantId = currentUser?.tenantId || ''
          const folderRef = ref(storage, `/tenants/${tenantId}/dataroom/${item.folder_name}`)

          const folderItems = await listAll(folderRef)
          folderItems.items.forEach(async (file) => {
            await deleteObject(file)
          })

          const folderDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', item.id)
          batch.delete(folderDocRef)
        } else {
          const fileDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', item.id)
          batch.delete(fileDocRef)
        }
      }

      await Promise.all([batch.commit(), Promise.all(storageDeletePromises)])

      Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: `All selected items have been deleted.`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the selected items.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {selectedItems.length > 0 ? (
        <div className=' pe-4 text-center' style={{color: '#A1A5B7'}}>
          <span>
            <p className='pt-4'>{selectedItems.length} Selected</p>
          </span>
        </div>
      ) : null}

      {/* <button
        type='button'
        // onClick={onClearSelection}
        className='btn btn-sm btn-light fs-6 mx-4 fw-bold'
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        Deselect
      </button> */}

      <div style={{display: 'flex', alignItems: 'center'}}>
        <button
          className='btn btn-sm fs-6'
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#148363')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#1CBB8D')}
          onClick={deleteSelectedItemsInBatch}
          style={{
            backgroundColor: '#1CBB8D',
            color: '#fff',
            width: '140px',
            height: '40px',
            borderRadius: '4px',
            transition: 'background-color 0.3s',
          }}
        >
          Delete Selected
        </button>
      </div>
    </div>
   
  )
}

export {DataRoomGrouping}
