import React, {useState} from 'react'
import {Modal, Button, Form, ProgressBar, Alert} from 'react-bootstrap'

interface FormData {
  referralSource: string
  otherDetails: string
  companySize: string
  industryExperience: number
  primaryConcern: string
  additionalInfo: string
}

interface StepErrors {
  primaryConcern?: string
  referralSource?: string
  otherDetails?: string
  companySize?: string
  industryExperience?: number
  additionalInfo?: string
}

const SignupReferral = ({show, onHide}) => {
  const [step, setStep] = useState(1)
  const [selectedSource, setSelectedSource] = useState<string>('')
  const [formData, setFormData] = useState<FormData>({
    referralSource: '',
    otherDetails: '',
    companySize: '',
    industryExperience: 0,
    primaryConcern: '',
    additionalInfo: '',
  })
  const [errors, setErrors] = useState<StepErrors>({})

  const updateFormData = (field, value) => {
    setFormData((prev) => ({...prev, [field]: value}))
    setErrors((prev) => ({...prev, [field]: ''}))
  }

  const validateStep = () => {
    let stepErrors: StepErrors = {} // Ensure stepErrors is typed as StepErrors

    switch (step) {
      case 1:
        if (!formData.referralSource) {
          stepErrors.referralSource = 'Please select a referral source'
        }
        if (formData.referralSource === 'other' && !formData.otherDetails) {
          stepErrors.otherDetails = 'Please specify the other referral source'
        }
        break
      case 2:
        if (!formData.companySize) {
          stepErrors.companySize = 'Please select your company size'
        }
        break
      case 3:
        // No validation needed for the slider
        break
      case 4:
        if (!formData.primaryConcern) {
          stepErrors.primaryConcern = 'Please select your primary GRC concern'
        }
        break
    }

    setErrors(stepErrors)
    return Object.keys(stepErrors).length === 0
  }

  const handleNext = () => {
    if (validateStep()) {
      setStep((prev) => prev + 1)
    }
  }

  const handleBack = () => {
    setStep((prev) => prev - 1)
  }

  const handleSubmit = () => {
    if (validateStep()) {
      const submissionData = {
        ...formData,
        timestamp: new Date().toISOString(),
      }
      //   onSubmit(submissionData)
      onHide()
    }
  }

  const totalSteps = 4

  const sources = [
    'TV / Streaming (Hulu, NBC, etc.)',
    'Billboard',
    'LinkedIn',
    'TikTok',
    'Friend / Colleague',
    'Search Engine (Google, Bing, etc.)',
    'YouTube',
    'Facebook / Instagram',
    'Software Review Sites',
    'Reddit',
    'Snapchat',
    'Other',
  ]

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Tell Us About Yourself</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='fs-6'>Help us understand your needs better.</p>
        <ProgressBar now={(step / totalSteps) * 100} className='mb-4' />

        {step === 1 && (
          <Form>
            <Form.Group className='mb-3'>
              <h2 className='text-center mb-4 fw-bold'>
                How did you hear
                <br />
                about us?
              </h2>
              {/* <Form.Select
                value={formData.referralSource}
                onChange={(e) => updateFormData('referralSource', e.target.value)}
                isInvalid={!!errors.referralSource}
              >
                <option value=''>Select a source</option>
                <option value='linkedin'>LinkedIn</option>
                <option value='google'>Google Search</option>
                <option value='conference'>Industry Conference</option>
                <option value='colleague'>Colleague Referral</option>
                <option value='advertisement'>Online Advertisement</option>
                <option value='other'>Other</option>
              </Form.Select> */}

              <div className='d-flex flex-wrap gap-2 justify-content-center'>
                {sources.map((source) => (
                  <Button
                    key={source}
                    variant={selectedSource === source ? 'primary' : 'outline-secondary'}
                    className={`
                rounded-3 
                px-3 
                py-2 
                border-dark
                ${selectedSource === source ? 'text-white' : 'text-dark'}
              `}
                    onClick={() => setSelectedSource(source)}
                  >
                    {source}
                  </Button>
                ))}
              </div>
              <Form.Control.Feedback type='invalid'>{errors.referralSource}</Form.Control.Feedback>
            </Form.Group>

            {formData.referralSource === 'other' && (
              <Form.Group className='mb-3'>
                <Form.Label>Please specify:</Form.Label>
                <Form.Control
                  type='text'
                  value={formData.otherDetails}
                  onChange={(e) => updateFormData('otherDetails', e.target.value)}
                  placeholder='Enter referral source'
                  isInvalid={!!errors.otherDetails}
                />
                <Form.Control.Feedback type='invalid'>{errors.otherDetails}</Form.Control.Feedback>
              </Form.Group>
            )}
          </Form>
        )}

        {step === 2 && (
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label className='mb-4'>What's your company size?</Form.Label>
              {['1-50', '51-200', '201-1000', '1000+'].map((size) => (
                <Form.Check
                  key={size}
                  type='radio'
                  id={`company-size-${size}`}
                  label={size}
                  value={size}
                  className='mb-4'
                  checked={formData.companySize === size}
                  onChange={(e) => updateFormData('companySize', e.target.value)}
                />
              ))}
              {errors.companySize && (
                <Alert variant='danger' className='mt-2'>
                  {errors.companySize}
                </Alert>
              )}
            </Form.Group>
          </Form>
        )}

        {step === 3 && (
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label>Years of experience in industry (0-30+)</Form.Label>
              <Form.Range
                min={0}
                max={30}
                value={formData.industryExperience}
                onChange={(e) => updateFormData('industryExperience', parseInt(e.target.value))}
              />
              <div className='text-center'>{formData.industryExperience} years</div>
            </Form.Group>
          </Form>
        )}

        {step === 4 && (
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label>What's your primary concern?</Form.Label>
              <Form.Select
                value={formData.primaryConcern}
                onChange={(e) => updateFormData('primaryConcern', e.target.value)}
                isInvalid={!!errors.primaryConcern}
              >
                <option value=''>Select your primary concern</option>
                <option value='compliance'>Regulatory Compliance</option>
                <option value='risk'>Risk Management</option>
                <option value='audit'>Internal Audit</option>
                <option value='policy'>Policy Management</option>
                <option value='reporting'>GRC Reporting</option>
                <option value='other'>Other</option>
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{errors.primaryConcern}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label>Any additional information?</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={formData.additionalInfo}
                onChange={(e) => updateFormData('additionalInfo', e.target.value)}
                placeholder='Tell us more about your GRC needs...'
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer className='p-2 px-4'>
        {step > 1 && (
          <button className='btn btn-sm bg-secondary fs-6' onClick={handleBack}>
            Back
          </button>
        )}
        {step < totalSteps ? (
          <button className='btn btn-sm bg-primary text-white fs-6' onClick={handleNext}>
            Next
          </button>
        ) : (
          <button className='btn btn-sm bg-primary text-white fs-6' onClick={handleSubmit}>
            Submit
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default SignupReferral
