import ExcelJS from 'exceljs'
import {collection, doc, getDocs, addDoc, updateDoc, getFirestore} from 'firebase/firestore'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getMetadata,
  getStorage,
  StorageReference,
} from 'firebase/storage'
import db from 'services/firebase'

interface Employee {
  full_name?: string
  email?: string
  role?: string
  group?: string
  start_date?: string
  end_date?: string
  onboarding?: string
  onboardingEnded?: string
  [key: string]: any
}

interface ExportResult {
  downloadUrl: string
  fileId: string
}

interface CurrentUser {
  tenantId?: string
  uid?: string
  userName?: string
}

const EXCEL_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const ExportEmployee = async (
  currentUser,
  auditFolderName: string,
  folderId: string,
  formattedDate: string,
  auditID: string
): Promise<ExportResult> => {
  try {
    if (!currentUser?.tenantId) {
      throw new Error('Tenant ID not available')
    }

    const db = getFirestore()
    const storage = getStorage()
    const tenantId = currentUser.tenantId
    // Get employee data
    const employeeData = await fetchEmployeeData(db, tenantId)
    if (!employeeData.length) {
      throw new Error('No employee data available to export')
    }

    const buffer = await generateExcelFile(employeeData)

    const {downloadURL, metadata, storageRef} = await uploadToStorage(
      storage,
      buffer,
      tenantId,
      auditFolderName,
      formattedDate
    )

    const fileDoc = await createFirestoreDocument(
      db,
      tenantId,
      currentUser,
      downloadURL,
      metadata,
      storageRef,
      formattedDate,
      folderId
    )
    if (employeeData && auditID) {
      await updateAuditDocument(currentUser, auditID, employeeData.length)
    }
    return {
      downloadUrl: downloadURL,
      fileId: fileDoc.id,
    }
  } catch (error) {
    throw new Error(
      error instanceof Error ? error.message : 'An error occurred during employee export'
    )
  }
}

async function fetchEmployeeData(db: any, tenantId: string): Promise<Employee[]> {
  const tenantRef = doc(db, 'tenants', tenantId)
  const employeesRef = collection(tenantRef, 'employees')
  const querySnapshot = await getDocs(employeesRef)
  return querySnapshot.docs
    .map((doc) => doc.data() as Employee)
    .filter((data) => data.role !== 'Auditor')
}

async function generateExcelFile(data: Employee[]): Promise<ArrayBuffer> {
  const fields = [
    {key: 'full_name', header: 'Full Name', width: 30},
    {key: 'email', header: 'Email', width: 30},
    {key: 'role', header: 'Role', width: 15},
    {key: 'group', header: 'Group', width: 20},
    {key: 'start_date', header: 'Start Date', width: 20},
    {key: 'end_date', header: 'End Date', width: 20},
    {key: 'onboarding', header: 'Onboarding', width: 20},
    {key: 'onboardingEnded', header: 'Onboarding Completed Date', width: 30},
  ]

  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Employees')
  worksheet.views = [{state: 'frozen', ySplit: 1}] // This should be set directly on the worksheet
  worksheet.columns = fields.map((field) => ({
    header: field.header,
    key: field.key,
    width: field.width,
  }))

  const headerRow = worksheet.getRow(1)
  styleHeaderRow(headerRow)

  data.forEach((row) => {
    const rowData = formatRowData(row, fields)
    const addedRow = worksheet.addRow(rowData)
    styleDataRow(addedRow, row)
  })

  return await workbook.xlsx.writeBuffer()
}

async function updateAuditDocument(currentUser, auditId, totalLength) {
  const tenantId = currentUser?.tenantId || ''
  const tenantRef2 = doc(db, 'tenants', tenantId)

  const policyRef = doc(tenantRef2, 'audits', auditId)

  await updateDoc(policyRef, {TotalEmployees: totalLength})
}

function styleHeaderRow(headerRow: any) {
  headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
  headerRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: 'FF000000'},
  }
  headerRow.height = 30
  headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
}

function formatRowData(row: Employee, fields: {key: string; header: string; width: number}[]) {
  return fields.reduce((acc: {[key: string]: any}, field) => {
    let value = row[field.key] || row[field.key.toLowerCase()] || ''
    if (field.key === 'onboarding') {
      value = value || 'Pending'
    }
    acc[field.key] = value
    return acc
  }, {})
}

function styleDataRow(row: any, data: Employee) {
  row.height = 20
  row.font = {size: 10}

  const onboardingCell = row.getCell('onboarding')
  styleOnboardingCell(onboardingCell)

  if (data['end_date']) {
    styleTerminatedEmployee(row)
  }
}

function styleOnboardingCell(cell: any) {
  const colors = {
    Completed: 'D9EAD3',
    Pending: 'FFF2CC',
    default: 'F4CCCC',
  }

  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: colors[cell.value as keyof typeof colors] || colors.default},
  }
}

function styleTerminatedEmployee(row: any) {
  row.eachCell((cell: any) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'F4CCCC'},
    }
  })
}

async function uploadToStorage(
  storage: any,
  buffer: ArrayBuffer,
  tenantId: string,
  auditFolderName: string,
  formattedDate: string
) {
  const fileName = `${formattedDate} - employees.xlsx`
  const storageRef = ref(storage, `tenants/${tenantId}/dataroom/${auditFolderName}/${fileName}`)

  await uploadBytes(storageRef, new Uint8Array(buffer), {
    contentType: EXCEL_CONTENT_TYPE,
    customMetadata: {
      tenantId,
      exportDate: formattedDate,
    },
  })

  const metadata = await getMetadata(storageRef)
  const downloadURL = await getDownloadURL(storageRef)

  return {downloadURL, metadata, storageRef}
}

async function createFirestoreDocument(
  db: any,
  tenantId: string,
  currentUser: CurrentUser,
  downloadURL: string,
  metadata: any,
  storageRef: StorageReference,
  formattedDate: string,
  folderId: string
) {
  const tenantRef = doc(db, 'tenants', tenantId)
  const dataRoomRef = collection(tenantRef, 'dataroom')

  const fileDoc = {
    createdAt: new Date().toISOString(),
    name: `${formattedDate} - employees.xlsx`,
    contentType: EXCEL_CONTENT_TYPE,
    downloadUrl: downloadURL,
    fileSize: metadata.size,
    subFolder: storageRef.fullPath,
    time_created: formattedDate,
    tenantId: tenantId,
    uid: currentUser?.uid,
    is_folder: false,
    uploadedBy: currentUser?.userName,
    parentFolder: folderId,
    framework: '',
    fileId: '',
  }

  const docRef = await addDoc(dataRoomRef, fileDoc)
  await updateDoc(docRef, {fileId: docRef.id})
  return docRef
}
