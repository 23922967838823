import {useEffect, useRef, useState} from 'react'
import React from 'react'
import {useAuth} from '../../../modules/auth'
import {addDoc, collection, doc, serverTimestamp} from 'firebase/firestore'
import Drawer from 'react-modern-drawer'
import db from '../../../../services/firebase'
import {toast} from 'react-toastify'

export interface addPersonnel {
  fullName: string
  email: string
  role: string
  startDate: string
  group: string
  organization: string
}
const initialState = {
  full_name: '',
  email: '',
  role: 'Auditor',
}

interface AddAuditorProps {
  isOpen: boolean
  onClose: () => void
}

const AddAuditor: React.FC<AddAuditorProps> = ({isOpen, onClose}) => {
  const [form, setForm] = useState(initialState)
  const {full_name, email, role} = form
  const [organization, setOrganization] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const focusRef = useRef<HTMLInputElement | null>(null)


  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (focusRef.current) {
          focusRef.current.focus()
        }
      }, 300)
    }
  }, [isOpen])

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  const todaysDate = month + '/' + day + '/' + year

  const validateForm = () => {
    const errors: Record<string, string> = {}

    if (!full_name.trim()) {
      errors.full_name = 'Full name is required'
    }

    if (!email.trim()) {
      errors.email = 'Email is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateForm()) {
      setIsLoading(false)
      return
    }

    try {
      setIsLoading(true)

      const newPersonnel = {
        full_name,
        email,
        role,
        uid: '',
        tenantId: currentUser?.tenantId,
        availability: true,
        organization:organization
      }

      if (currentUser) {
        const tenantRef = doc(db, 'tenants', currentUser.tenantId!)
        const policyRef = collection(tenantRef, 'employees')

        await addDoc(policyRef, {
          ...newPersonnel,
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        })

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} invited a new Auditor'${full_name}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          // email: email,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          action: 'Auditor added',
          target: 'Employees',
          email: currentUser?.email,
          PersonnelManagement: full_name,
          date: todaysDate,
        })
      }

      toast.success('Employee invited successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      window.scrollTo(0, 0)
    } catch (err: any) {
      window.scrollTo(0, 0)
    } finally {
      setIsLoading(false)
    }
    setTimeout(() => {
      onClose()
      setOrganization('')
      setForm(initialState)
    }, 3000)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setForm({...form, [name]: value})

    // Clear the error for this field
    setFormErrors((prev) => ({...prev, [name]: ''}))

    if (name === 'email') {
      if (!value.trim()) {
        setFormErrors((prev) => ({...prev, email: 'Email is required'}))
      } else if (!validateEmail(value)) {
        setFormErrors((prev) => ({...prev, email: 'Please enter a valid email address'}))
      }
    } else if (name === 'fullName') {
      if (!value.trim()) {
        setFormErrors((prev) => ({...prev, fullName: 'Full name is required'}))
      }
    }
  }

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} direction='right' className='' size='45%'>
        <div
          className=' modal-dialog-centered border-bottom border-gray-300'
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '14px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '12px',
          }}
        >
          <div className='fs-4 fw-bold mx-2' style={{margin: 0}}>
            Add Auditor
          </div>

          <div style={{display: 'flex', gap: '16px'}}>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary '
              onClick={onClose}
              aria-label='Close'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-x'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M18 6l-12 12' />
                <path d='M6 6l12 12' />
              </svg>
            </div>
          </div>
        </div>
        <div
          className='p-6 scroll-y overflow-auto  '
          tabIndex={-1}
          style={{height: 'calc(100vh - 50px)'}}
        >
          <form id='kt_risk_form' className='form' onSubmit={handleSubmit}>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className='mb-20'>
                  <div className='fv-row mb-7 text-grey-800 fw-semibold fs-6'>
                    Provide the details of the auditor you’d like to add to your company. An email
                    invitation will be sent to the auditor upon submission.
                  </div>

                  {/* begin::Input group */}
                  <div className='fv-row mb-4'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2 required'>Full Name</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='Full Name'
                      type='text'
                      className={`form-control ${formErrors.full_name ? 'is-invalid' : ''}`}
                      name='full_name'
                      value={full_name}
                      onChange={handleChange}
                      autoComplete='off'
                      ref={focusRef}
                    />
                    {formErrors.full_name && (
                      <div className='invalid-feedback'>{formErrors.full_name}</div>
                    )}
                    {/* end::Input */}
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='fv-row mb-4'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2 required'>Email</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='auditor@example.com'
                      className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                      type='email'
                      name='email'
                      autoComplete='off'
                      value={email}
                      onChange={handleChange}
                    />

                    {/* end::Input */}

                    {formErrors.email && (
                      <div className='invalid-feedback d-block'>{formErrors.email}</div>
                    )}
                  </div>
                  {/* end::Input group */}

                  {/* begin::Input group */}
                  <div className='fv-row mb-4'>
                    {/* begin::Label */}
                    <label className=' fw-bold fs-6 mb-2'>Organization</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <input
                      placeholder='Full Organization Name'
                      className={`form-control`}
                      type='text'
                      name='organization'
                      autoComplete='off'
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                    {/* end::Input */}
                  </div>
                  {/* end::Input group */}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          className='offcanvas-footer d-flex justify-content-end  border-top py-4 px-6'
          style={{position: 'sticky', bottom: 0, backgroundColor: 'white'}}
        >
          <div className='d-flex justify-content-end align-items-center'>
            {isLoading ? (
              <button
                className='btn btn-sm fw-semibold px-6 fs-6'
                type='submit'
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#148363')
                }
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                  (e.currentTarget.style.backgroundColor = '#1CBB8D')
                }
              >
                Inviting...
                <span className='spinner-border spinner-border-sm align-middle'></span>
              </button>
            ) : (
              <>
                <button
                  className='btn btn-sm btn-light fw-semibold me-4 fs-6'
                  onClick={onClose}
                  aria-label='Close'
                  style={{height: '40px'}}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-sm fw-semibold fs-6'
                  onClick={handleSubmit}
                  style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                  onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#148363')
                  }
                  onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                    (e.currentTarget.style.backgroundColor = '#1CBB8D')
                  }
                >
                  Invite
                </button>
              </>
            )}
          </div>
        </div>
      </Drawer>
    </>
  )
}

export {AddAuditor}
