import ExcelJS from 'exceljs'
import {collection, doc, getDocs, addDoc, updateDoc, getFirestore} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL, getMetadata, getStorage} from 'firebase/storage'
function stripHtmlTags(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, '')
}
export const ExportControl = async (
  currentUser,
  frameworks,
  auditFolderName,
  folderId,
  formattedDate,
  auditID
) => {
  const frameworksArray = typeof frameworks === 'string' ? [frameworks] : frameworks

  const db = getFirestore()
  const storage = getStorage()
  const tenantId = currentUser?.tenantId
  const tenantRef = doc(db, 'tenants', tenantId!)
  const PolicyRef = collection(tenantRef, 'controls')
  const querySnapshot = await getDocs(PolicyRef)

  const data = querySnapshot.docs.map((doc) => doc.data())

  for (const framework of frameworksArray) {
    const filteredControls = data.filter((control) => {
      if (control.author === 'Custom Control') return true
      if (!control.control_framework_mappings) return false
      return (
        control.control_framework_mappings.startsWith(framework) &&
        !control.control_framework_mappings.startsWith('ISO 27001:2013')
      )
    })

    if (filteredControls.length > 0) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Controls')

      worksheet.columns = [
        {header: 'Control', key: 'controlObjective', width: 40},
        {header: 'Control Description', key: 'controlDescription', width: 60},
        {header: 'Category', key: 'clause', width: 30},
        {header: 'Framework', key: 'sec', width: 40},
        {header: 'Author', key: 'author', width: 20},
        {header: 'Platform', key: 'platform', width: 15},
        {header: 'Document', key: 'document', width: 15},
        {header: 'Status', key: 'status', width: 15},
        {header: 'Justification for exclusion', key: 'justification', width: 40},
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center',vertical:'middle'}
      worksheet.views = [{ state: 'frozen', ySplit: 1 }];

      const exportData = filteredControls.map((row) => ({
        controlObjective: row.control_title || '',
        controlDescription: stripHtmlTags(row.control_description) || '',
        clause: row.control_category,
        sec: row.control_framework_mappings || '',
        author: row.author || 'SecureSlate',
        platform: row.control_test || '',
        document: row.control_document || '',
        status: row.control_evidence_status || '',
        justification: row.deactivation_control || '',
      }))

      exportData.forEach((item) => {
        const row = worksheet.addRow(item)
        row.height = 20
        row.font = {size: 11}

        if (item.status === 'Deactivated') {
          row.eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'DDEEFF'},
            }
          })
        }

        if (item.status === 'OK') {
          row.getCell('H').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        } else if (item.status === 'Unhealthy') {
          row.getCell('H').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const fileName = `${formattedDate} - controls: ${framework}.xlsx`
      const storageRef = ref(storage, `tenants/${tenantId}/dataroom/${auditFolderName}/${fileName}`)
      await uploadBytes(storageRef, buffer)
      const metadata = await getMetadata(storageRef)
      const downloadURL = await getDownloadURL(storageRef)

      const dataRoomRef = collection(tenantRef, 'dataroom')

      const fileDoc = {
        createdAt: new Date().toISOString(),
        name: fileName,
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        downloadUrl: downloadURL,
        fileSize: metadata.size,
        subFolder: storageRef.fullPath,
        time_created: formattedDate,
        tenantId: tenantId,
        uid: currentUser?.uid,
        is_folder: false,
        uploadedBy: currentUser?.userName,
        parentFolder: folderId,
        framework: '',
        fileId: '',
      }
      const docRef = await addDoc(dataRoomRef, fileDoc)
      await updateDoc(docRef, {fileId: docRef.id})

      await updateAuditDocument(currentUser, auditID, filteredControls.length)
    }
  }
}

const updateAuditDocument = async (currentUser, auditId, totalLength) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId || ''
  const tenantRef2 = doc(db, 'tenants', tenantId)
  const policyRef = doc(tenantRef2, 'audits', auditId)

  await updateDoc(policyRef, {TotalControls: totalLength})
}
