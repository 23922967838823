import React, {useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import {updateDoc, doc, getDoc} from 'firebase/firestore'
import db, {storage} from '../../../../services/firebase'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

const UploadFile: React.FC<{onFileUpload: (file: File[] | null) => void; fid: any}> = (
  {onFileUpload, fid},
  testId
) => {
  const [files, setFiles] = useState<File[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [dateOneYearFromNow, setDateOneYearFromNow] = useState('')
  const [date, setDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const TestId = searchParams.get('id')
  const {currentUser} = useAuth()

  useEffect(() => {
    const date = new Date()
    setDate(date.toISOString().slice(0, 10))
    date.setFullYear(date.getFullYear() + 1)

    setDateOneYearFromNow(date.toISOString().slice(0, 10))
  }, [])

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('')
      }, 3000)
      return () => clearTimeout(timer) // Cleanup the timer if component unmounts
    }
  }, [errorMessage])

  const calculateTotalSize = (files: File[]): number => {
    return files.reduce((totalSize, file) => totalSize + file.size, 0)
  }

  const handleUpload = (acceptedFiles: File[]) => {
    // Add the accepted files to the component state
    setFiles([...files, ...acceptedFiles])
  }

  const calculateNextReviewDate = (timeframe: string): Date => {
    const today = new Date()
    switch (timeframe.toLowerCase()) {
      case 'weekly':
        return new Date(today.setDate(today.getDate() + 7))
      case 'monthly':
        return new Date(today.setMonth(today.getMonth() + 1))
      case 'quarterly':
        return new Date(today.setMonth(today.getMonth() + 3))
      case 'yearly':
        return new Date(today.setFullYear(today.getFullYear() + 1))
      default:
        return new Date(today.setFullYear(today.getFullYear() + 1)) // Default to yearly if timeframe is not recognized
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const totalSizeLimit = 5 * 1024 * 1024
      const totalSize = calculateTotalSize(files)

      if (totalSize > totalSizeLimit) {
        setErrorMessage(
          'Total file size exceeds 5 MB. Please upload files with a total size less than 5 MB.'
        )
        setIsLoading(false)
        return
      }

      const filteredFiles = files

      if (filteredFiles.length === 0) {
        setErrorMessage('Invalid file type. Please select files with supported formats: docx, pdf')
        setIsLoading(false)
        return
      }

      const fileData: {
        name: string
        url: string
        date: string
        size: number
      }[] = []

      const tenantId = currentUser?.tenantId || ''
      const testId = TestId
      const testDocRef = doc(db, `tenants/${tenantId}/tests/${testId || fid}`)
      const testDocSnapshot = await getDoc(testDocRef)

      let existingFiles = []
      let timeframe = ''
      if (testDocSnapshot.exists()) {
        existingFiles = testDocSnapshot.data().files || []
        timeframe = testDocSnapshot.data().timeframe || ''
      }

      // Calculate the next review date based on the timeframe
      const nextReviewDate = calculateNextReviewDate(timeframe)

      for (const file of filteredFiles) {
        const fileName = file.name
        const fileRef = ref(
          storage,
          `tenants/${currentUser?.tenantId}/tests/test_evidence/${fileName}`
        )

        const uploadTask = uploadBytesResumable(fileRef, file)
        const snapshot = await uploadTask
        const downloadURL = await getDownloadURL(snapshot.ref)

        fileData.push({
          name: fileName,
          url: downloadURL,
          date: nextReviewDate.toISOString(),
          size: file.size,
        })
      }

      // Merge the existing files with the new file data
      const updatedFiles = [...existingFiles, ...fileData]

      // Update the document with the merged files array, status, and next review date
      await updateDoc(testDocRef, {
        files: updatedFiles,
        status: 'Pass',
        next_review_date: nextReviewDate.toISOString(),
        timeframe: 'Annually',
      })

      setIsLoading(false)
      setFiles([])
    } catch (error) {
      setErrorMessage('Error uploading evidence. Please try again.')
      setIsLoading(false)
    }
  }

  const handleClear = (index: number) => {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)
  }
  const handleAllClear = () => {
    setFiles([])
  }

  return (
    <>
      {/* {!uploaded ? ( */}
      <>
        <div className='main-container my-8'>
          <Dropzone
            onDrop={handleUpload}
            minSize={1024}
            maxSize={5 * 1024 * 1024}
            multiple
            accept={{
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
              'text/csv': ['.csv'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/pdf': ['.pdf'],
            }}
            disabled={currentUser?.role === 'Auditor'}
          >
            {({getRootProps, getInputProps}) => (
              <div
                {...getRootProps({
                  className: 'dropzone',
                  style: {
                    backgroundColor: '#fff', 
                    padding: '20px',
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    textAlign: 'center',
                    cursor: 'pointer',
                  },
                })}
              >
                <input {...getInputProps()} />
                <img src='/media/icon/uploadFile.svg' alt='' loading='lazy' className='mb-4 mt-4' />
                <p className='fw-bold fs-6'>
                  Drag and drop or <a href='#'>select</a> a files
                </p>
                <p className='fs-6'>Upload up to 5 MB. Supported formats: jpg, png, csv, xlsx, docx, pdf</p>
              </div>
            )}
          </Dropzone>
        </div>

        {errorMessage && (
          <div className='alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row mt-2'>
            <div className={'d-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'}>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}
        {files.map((file, index) => (
          <>
            <div key={index}>
              <div className='d-flex align-items-center mb-3 mt-8'>
                <i className='ki-duotone ki-file fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <div className='d-flex justify-content-between align-items-center flex-grow-1 '>
                  <div className='mx-2 fw-bold fs-5 '>Document #{index + 1}</div>
                  <i
                    className='ki-duotone ki-trash fs-1 mx-2 cursor-pointer'
                    onClick={() => handleClear(index)}
                  >
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                </div>
              </div>

              <div className='mb-3'>
                <label className='form-label fs-5 fw-bold'>File Name</label>
                <input value={file.name} className='form-control' readOnly />
                <p className='text-muted my-1'>Give your auditor a understandable filename.</p>
              </div>
              <div className='mb-3'>
                <label className='form-label fs-5 fw-bold'>Recurrence start</label>
                <input value={date} className='form-control' readOnly />
                <p className='text-muted my-1'>Next update on: {dateOneYearFromNow}</p>
              </div>
              <div className='separator my-5'></div>
            </div>
          </>
        ))}

        {files.length > 0 && (
          <div className='modal-footer'>
            {isLoading ? (
              <button
                type='submit'
                className='btn btn-sm fs-6'
                style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                // disabled={progress !== null && progress < 100}
              >
                Adding...
                <span className='spinner-border spinner-border-sm align-middle mx-1'></span>
              </button>
            ) : (
              <div className='d-flex justify-content-end align-items-center mx-0'>
                <div
                  className='me-4'
                  style={{cursor: 'pointer'}}
                  aria-label='Cancel'
                  onClick={handleAllClear}
                >
                  <img src='/media/buttons/Cancel.svg' alt='Cancel' />
                </div>
                <div className='' style={{cursor: 'pointer'}} onClick={handleSubmit}>
                  {/* <img src='/media/buttons/Add.svg' alt='Add' />/ */}
                  <button
                    className='btn btn-sm fs-6'
                    style={{backgroundColor: '#1CBB8D', color: '#FFFFFF', height: '40px'}}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    disabled={currentUser?.role === 'Auditor'}
                  >
                    Add
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </>
      {/* ) : (
        <>
          <div className='card m-1'>
        
            <img src='' alt='Fetched File' />
            <div className='mx-4 my-4 d-flex align-items-center'>
              <p className='fw-bold fs-5 mb-0'>Test file.doc.x</p>
              <span className='badge border p-2 rounded-pill ms-auto'>
                <span className='text-center fw-bold text-muted mx-2'>Uploaded</span>
              </span>
            </div>
            <p className='mx-4'>Recurrence start: May 3,2.24 | Unassigned</p>
          </div>
        </>
      )} */}
    </>
  )
}

export default UploadFile
