import React, {useState, useEffect} from 'react'
import {doc, updateDoc, getDoc} from 'firebase/firestore'
import db from 'services/firebase'
import {useAuth} from 'app/modules/auth'
import {toast} from 'react-toastify'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {format, parseISO} from 'date-fns'

interface DisableUserResponse {
  message: string
}

const OffboardingChecklist = ({user}) => {
  const {currentUser} = useAuth()
  const [isOffboardingComplete, setIsOffboardingComplete] = useState(false)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [checklist, setChecklist] = useState({
    hrTasks: [
      {id: 'hrTask1', label: 'Finalize termination paperwork', completed: false},
      {id: 'hrTask2', label: 'Process final paycheck', completed: false},
      {id: 'hrTask3', label: 'Conduct an exit interview', completed: false},
    ],
    itTasks: [
      {
        id: 'itTask1',
        label: 'Revoke the employee’s access to all organization systems, networks, and data',
        completed: false,
      },
      {
        id: 'itTask2',
        label: 'Collect organization-owned assets in the employee’s possession',
        completed: false,
      },
      {id: 'itTask3', label: 'Change shared passwords and encryption keys', completed: false},
    ],
    securityTasks: [
      {
        id: 'securityTask1',
        label: 'Review audit logs for suspicious activity during employee’s tenure',
        completed: false,
      },
      {
        id: 'securityTask2',
        label: 'Review third-party access and remove the employee’s permissions',
        completed: false,
      },
      {
        id: 'securityTask3',
        label: 'Update security systems and disable access badges',
        completed: false,
      },
    ],
  })

  useEffect(() => {
    const fetchChecklist = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const offRef = doc(tenantRef, 'employees', user.id)
        const docSnap = await getDoc(offRef)

        if (docSnap.exists() && docSnap.data().offboardingChecklist) {
          setChecklist(docSnap.data().offboardingChecklist)
          setIsOffboardingComplete(docSnap.data().isOffboardingComplete || false)
        }
      } catch (error) {
        console.error('Error fetching offboarding checklist:', error)
      }
    }

    fetchChecklist()
  }, [user.id])

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      let date
      if (typeof timestamp === 'string') {
        // Handle ISO string format
        date = parseISO(timestamp)
      } else if (timestamp.seconds) {
        // Handle Firestore timestamp
        date = new Date(timestamp.seconds * 1000)
      } else {
        // Handle regular Date object
        date = new Date(timestamp)
      }

      if (isNaN(date.getTime())) {
        return 'Invalid Timestamp'
      }

      return format(date, 'd MMMM, yyyy')
    }
    return '--'
  }

  async function disableUser(userUidToDisable) {
    try {
      if (currentUser?.role !== 'Owner') {
        throw new Error('Must be an admin (Owner) to disable users')
      }

      // Get the cloud function
      const functions = getFunctions()
      const disableUser = httpsCallable(functions, 'disableUser')

      // Call the cloud function
      const result = await disableUser({uid: userUidToDisable})

      return result.data as DisableUserResponse
    } catch (error) {
      throw error
    }
  }

  const handleTaskToggle = async (category, taskId) => {
    const updatedChecklist = {...checklist}
    const taskIndex = updatedChecklist[category].findIndex((task) => task.id === taskId)

    if (taskIndex !== -1) {
      updatedChecklist[category][taskIndex].completed =
        !updatedChecklist[category][taskIndex].completed
      setChecklist(updatedChecklist)

      const allTasksCompleted = Object.values(updatedChecklist).every((category) =>
        category.every((task) => task.completed)
      )

      setIsAllChecked(allTasksCompleted)

      try {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const offRef = doc(tenantRef, 'employees', user.id)
        await updateDoc(offRef, {
          offboardingChecklist: updatedChecklist,
          isOffboardingComplete: allTasksCompleted,
        })
      } catch (error) {
        console.error('Error updating checklist:', error)
      }
    }
  }

  const handleCompletion = () => {
    const allTasksCompleted = Object.values(checklist).every((category) =>
      category.every((task) => task.completed)
    )

    if (allTasksCompleted && isAllChecked) {
      setIsLoading(true)
      try {
        disableUser(user.uid)
        toast.success('Offboarding successfully completed!', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        toast.error('Error completing offboarding', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            color: 'black',
          },
          autoClose: 3000,
        })
      }
      return
    } else {
      toast.error('All checkboxes must be checked', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          color: 'black',
        },
        autoClose: 3000,
      })
    }
  }

  return (
    <div className='container-fluid p-4'>
      <div className='row mb-4'>
        <div className='col-12'>
          <div style={{display: 'flex', alignItems: 'flex-start', gap: '8px'}}>
            <h2 className='fw-bold'>Offboarding Checklist</h2>
            <span
              className='badge fs-8'
              style={{
                color: isOffboardingComplete ? '#1CBB8D' : '#EE3043',
                background: isOffboardingComplete ? '#E8F8F4' : '#FFF5F8',
              }}
            >
              {isOffboardingComplete ? 'Completed' : 'Pending'}
            </span>
          </div>
          <p className='text-muted'>
            Employee Removal Date: {user.end_date ? formatTimestamp(user.end_date) : ''}
          </p>
        </div>
      </div>

      <div className='row'>
        {/* HR Tasks */}
        <div className='mb-4'>
          <div className='card'>
            <div
              style={{
                backgroundColor: '#f8f9fa',
                padding: '1rem',
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
              }}
            >
              <h5 className='card-title mb-0'>HR & Administrative Tasks</h5>
            </div>
            <div className='card-body p-5'>
              {checklist.hrTasks.map((task) => (
                <div key={task.id} className='form-check mb-4'>
                  <input
                    className='form-check-input me-4'
                    type='checkbox'
                    id={task.id}
                    checked={task.completed}
                    onChange={() => handleTaskToggle('hrTasks', task.id)}
                  />
                  <label
                    className={`form-check-label ${task.completed ? 'text-primary' : ''}`}
                    htmlFor={task.id}
                  >
                    {task.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* IT Tasks */}
        <div className='mb-4'>
          <div className='card'>
            {/* <div className='bg-secondary p-4 rounded-top'>
              <h5 className='card-title mb-0'>IT & Technology Assets</h5>
            </div> */}
            <div
              style={{
                backgroundColor: '#f8f9fa',
                padding: '1rem',
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
              }}
            >
              <h5 className='card-title mb-0'>IT & Technology Assets</h5>
            </div>
            <div className='card-body p-5'>
              {checklist.itTasks.map((task) => (
                <div key={task.id} className='form-check  mb-4'>
                  <input
                    className='form-check-input me-4'
                    type='checkbox'
                    id={task.id}
                    checked={task.completed}
                    onChange={() => handleTaskToggle('itTasks', task.id)}
                  />
                  <label
                    className={`form-check-label  ${task.completed ? 'text-primary' : ''}`}
                    htmlFor={task.id}
                  >
                    {task.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Security Tasks */}
        <div className='mb-4'>
          <div className='card'>
            <div
              style={{
                backgroundColor: '#f8f9fa',
                padding: '1rem',
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
              }}
            >
              <h5 className='card-title mb-0'>Security & Access</h5>
            </div>
            <div className='card-body p-5'>
              {checklist.securityTasks.map((task) => (
                <div key={task.id} className='form-check mb-4'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id={task.id}
                    checked={task.completed}
                    onChange={() => handleTaskToggle('securityTasks', task.id)}
                  />
                  <label
                    className={`form-check-label ${task.completed ? 'text-primary' : ''}`}
                    htmlFor={task.id}
                  >
                    {task.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-3 mb-6'>
        <div className='col-12'>
          <p className='fs-8 p-0 m-0' style={{color: '#5A5A5A'}}>
            When clicked, it disables the user's access to SecureSlate, ensuring that all
            offboarding tasks are completed before moving forward.
          </p>
          <button
            className={`btn btn-lg w-100`}
            style={{background: '#1CBB8D', color: '#fff'}}
            disabled={isOffboardingComplete}
            onClick={handleCompletion}
          >
            {isLoading
              ? 'Processing...'
              : isOffboardingComplete
              ? 'Completed'
              : 'Complete All Tasks'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default OffboardingChecklist
