// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import {getStorage} from 'firebase/storage'
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  setPersistence,
} from 'firebase/auth'
import 'firebase/storage'

//production version>>>>

const firebaseConfig = {
  apiKey: 'AIzaSyBzxltHdSHVe7sUKD4lxobJKTtOetJeq6k',
  authDomain: 'slate-134a0.firebaseapp.com',
  projectId: 'slate-134a0',
  storageBucket: 'slate-134a0.appspot.com',
  messagingSenderId: '759171650906',
  appId: '1:759171650906:web:fac324dba2c62dc2234f63',
  measurementId: 'G-7VGVJBNS4T',
}

//development version>>>>
// const firebaseConfig = {
//   apiKey: "AIzaSyBrmeK1nBdFsDURvJcoJMGWz4jiw4WypVs",
//   authDomain: "slate-dev-b7cd9.firebaseapp.com",
//   projectId: "slate-dev-b7cd9",
//   storageBucket: "slate-dev-b7cd9.appspot.com",
//   messagingSenderId: "1050137580521",
//   appId: "1:1050137580521:web:ecfb54e7dc06bf93b3b178",
//   measurementId: "G-1S8E3QEEBL"
// };

// Initialize Firebase
// firebase.initializeApp(firebaseConfig)
// const firebaseApp = firebase.initializeApp(firebaseConfig)
// const db = firebase.firestore()
// export const storage = getStorage(firebaseApp)
// export const auth1 = getAuth(firebaseApp)
// export const googleProvider = new GoogleAuthProvider()
// setPersistence(auth1, browserSessionPersistence)
// export default db

firebase.initializeApp(firebaseConfig)

const firebaseApp = firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

// Enable multi-tab persistence for Firestore
db.enablePersistence({ synchronizeTabs: true })
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a time.
      console.log('Persistence already enabled in another tab');
    } else if (err.code === 'unimplemented') {
      // The current browser doesn't support persistence
      console.log('Persistence not supported');
    }
  });

export const storage = getStorage(firebaseApp)
export const auth1 = getAuth(firebaseApp)
export const googleProvider = new GoogleAuthProvider()

// Set local persistence instead of session persistence for multi-tab support
setPersistence(auth1, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export default db
