import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {collection, doc, getDocs, onSnapshot, query, updateDoc, where} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {toast} from 'react-toastify'

interface DeactivatingProps {
  isOpen: boolean
  onClose: () => void
  id: string
  onComplete: () => void
  audit
}

const RequestAccess: React.FC<DeactivatingProps> = ({isOpen, onClose, id, audit, onComplete}) => {
  const [showModal, setShowModal] = useState(false)
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const [formData, setFormData] = useState({
    accessReason: '',
    // accessDuration: '',
    dataScope: '',
    status: 'Requested',
  })

  const handleChange = (e) => {
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setShowModal(false)
    setFormError('')
    onClose()
  }

  const handleSubmitRequest = async () => {
    setIsLoading(true)
    const tenantId = currentUser?.tenantId || ''
    const tenantRef2 = doc(db, 'tenants', tenantId)
    const policyRef = doc(tenantRef2, 'audits', id)

    await updateDoc(policyRef, {
      request_access: formData,
    })

    setIsLoading(false)
    onClose()
    toast.success('Audit access request sent successfully!', {
      position: toast.POSITION.TOP_CENTER,
      className: 'bg-white',
      style: {
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e0e0e0',
        color: 'black',
      },
      progressStyle: {
        background: '#1CBB8D',
      },
      icon: ({theme, type}) => (
        <svg
          width='26'
          height='26'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{color: '#1CBB8D'}}
        >
          <path
            d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
            fill='currentColor'
          />
        </svg>
      ),
      autoClose: 3000,
    })
  }

  return (
    <Modal show={showModal} onHide={handleClose} backdropClassName='bg-opacity-1' centered>
      <Modal.Header closeButton>
        <Modal.Title>Request Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formError && (
          <div className='alert alert-danger' role='alert'>
            {formError}
          </div>
        )}
        <div className='fs-6' style={{color: '#5A5A5A'}}>
          Please fill out the details below to request access. Your request will be reviewed and
          approved by the appropriate authority.
          <p className='mt-2'>
            <strong>Important Notes:</strong>
          </p>
          <ul>
            <li>Access will only be granted based on the necessity of your request.</li>
            <li>All requests are logged and monitored for compliance purposes.</li>
            <li>Access will be only be provided if you are not engaged in another audit.</li>
          </ul>
        </div>
        <div className='fv-row mb-2 mt-7'>
          {/* Reason for Access */}
          <label className='fw-bold fs-6 mb-2'>Reason for Access</label>
          <textarea
            placeholder='Explain why you need access...'
            className='form-control mb-3 mb-lg-0 w-100'
            name='accessReason'
            value={formData.accessReason}
            onChange={handleChange}
            autoComplete='off'
            required
          />
        </div>
        {/* <div className='fv-row mb-2'>
          
          <label className='fw-bold fs-6 mb-2'>Duration of Access (e.g., 2 hours)</label>
          <input
            type='text'
            placeholder='Enter duration (e.g., 2 hours, 1 day)'
            className='form-control mb-3 mb-lg-0 w-100'
            name='accessDuration'
            value={formData.accessDuration}
            onChange={handleChange}
            autoComplete='off'
            required
          />
        </div> */}
        <div className='fv-row mb-2'>
          {/* Data/System Needed */}
          <label className='fw-bold fs-6 mb-2'>Data/System Needed</label>
          <input
            type='text'
            placeholder='Specify the data or system you need access to...'
            className='form-control mb-3 mb-lg-0 w-100'
            name='dataScope'
            value={audit.auditEvidenceFolderName}
            onChange={handleChange}
            autoComplete='off'
            disabled
          />
        </div>
      </Modal.Body>
      <Modal.Footer className='p-0 px-6 py-4'>
        <button type='button' className='btn btn-light fs-6 mx-4' onClick={handleClose}>
          Cancel
        </button>
        {isLoading ? (
          <button
            className='btn btn-sm fs-6'
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
            disabled
          >
            Submitting... <span className='spinner-border spinner-border-sm align-middle'></span>
          </button>
        ) : (
          <button
            className='btn btn-sm fs-6'
            onClick={handleSubmitRequest}
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Submit Request
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default RequestAccess
