import React, {useEffect, useState} from 'react'
import {OnDashPageTitle} from '../cores/OnDashPageData'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageChangeEvent, Viewer, Worker} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'
import {pageNavigationPlugin} from '@react-pdf-viewer/page-navigation'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import {getDownloadURL, listAll, ref} from 'firebase/storage'
import db, {storage} from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {Link, useNavigate} from 'react-router-dom'
import {doc, getDoc, setDoc} from 'firebase/firestore'

interface CustomFile {
  id?: string
  name: string
  downloadURL: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  SAT?: CustomFile[]
}

const SAT = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const [selectedFileIndex, setSelectedFileIndex] = useState(0)
  const [fileData, setFileData] = useState<CustomFile[]>([])
  const [selectedPDFUrl, setSelectedPDFUrl] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const [completed, setCompleted] = useState(false)
  const navigate = useNavigate()
  const [lastPage, setLastPage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(true)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [filesLoading, setFilesLoading] = useState(true)

  useEffect(() => {
    if (fileData.length > 0) {
      setPdfLoading(true)
      setLoadingProgress(0)

      const loadPDF = async () => {
        try {
          const response = await fetch(fileData[selectedFileIndex].downloadURL)
          const reader = response.body?.getReader()
          const contentLength = Number(response.headers.get('Content-Length'))

          let receivedLength = 0
          while (true && reader) {
            const {done, value} = await reader.read()
            if (done) break

            receivedLength += value.length
            setLoadingProgress(Math.round((receivedLength / contentLength) * 100))
          }

          setSelectedPDFUrl(fileData[selectedFileIndex].downloadURL)
        } catch (error) {
          console.error('Error loading PDF:', error)
        } finally {
          setPdfLoading(false)
        }
      }

      loadPDF()
    }
  }, [fileData, selectedFileIndex])

  useEffect(() => {
    const fetchDataFromFolder = async () => {
      setFilesLoading(true)
      const folderPath = `/SAT_training_pdf/`

      try {
        const folderRef = ref(storage, folderPath)
        const fileList = await listAll(folderRef)

        const filesPromise = fileList.items.map(async (file) => {
          const downloadURL = await getDownloadURL(file)
          return {name: file.name, downloadURL}
        })

        const filesData = await Promise.all(filesPromise)
        setFileData(filesData)
      } catch (error) {
        console.error('Error fetching data from folder:', error)
      } finally {
        setFilesLoading(false)
      }
    }

    fetchDataFromFolder()
  }, [])

  useEffect(() => {
    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const userId = currentUser?.uid

        if (!tenantId || !userId) return

        const userTrainingDocRef = doc(
          db,
          'tenants',
          tenantId,
          'onboarding',
          userId,
          'training',
          'SAT'
        )

        const trainingDoc = await getDoc(userTrainingDocRef)
        const trainingData = trainingDoc.data() || {}

        setCompleted(trainingData.is_checked || false)
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()
  }, [currentUser])

  const handleNameClick = (pdfUrl: string, index: number) => {
    setSelectedPDFUrl(null)
    setSelectedFileIndex(index)
  }

  const handleCheckboxChange = async (index: number) => {
    const selectedFile = fileData[index]

    setIsLoading(true)

    try {
      const tenantId = currentUser?.tenantId || ''
      const userId = currentUser?.uid

      if (!tenantId || !userId) {
        setIsLoading(false)
        return
      }

      const userTrainingDocRef = doc(
        db,
        'tenants',
        tenantId,
        'onboarding',
        userId,
        'training',
        'SAT'
      )

      await setDoc(
        userTrainingDocRef,
        {
          download_url: selectedFile.downloadURL,
          is_checked: true,
          name: selectedFile.name,
        },
        {merge: true}
      )

      await new Promise((resolve) => setTimeout(resolve, 1000))

      setCompleted(true)

      setTimeout(() => {
        navigate('/training')
      }, 500)
    } catch (error) {
      console.error('Error updating training progress:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePageChange = (e: PageChangeEvent) => {
    const currentPage = e.currentPage + 1
    const totalPages = e.doc.numPages
    setLastPage(currentPage === totalPages)
  }

  return (
    <>
      <div className='d-flex align-items-center mb-2 mt-0'>
        <Link to='/training'>
          <img src={toAbsoluteUrl('/media/logos/leftButton.svg')} alt='back' className='p-2' />
        </Link>
        <Link to='/training' style={{textDecoration: 'none', color: 'inherit'}}>
          <label style={{cursor: 'pointer', paddingLeft: '0px'}}>
            <strong>Back To Training</strong>
          </label>
        </Link>
      </div>

      <OnDashPageTitle
        icon='secu.svg'
        title='Security Awareness Training'
        desc='Safeguard your digital workspace.'
      />

      <div className='card'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row m-0'>
              <div className='col-md-3 border-end h-100' style={{minHeight: '500px'}}>
                <h2 className='text-dark mb-1 mt-3 ml-4 p-4'>Contents</h2>
                <hr />

                {filesLoading
                  ? Array(1)
                      .fill(0)
                      .map((_, i) => (
                        <div key={i} className='p-3'>
                          <div className='d-flex align-items-start gap-4'>
                            <div className='placeholder-glow'>
                              <span
                                className='placeholder col-12'
                                style={{width: '24px', height: '24px'}}
                              ></span>
                            </div>
                            <div className='placeholder-glow flex-grow-1'>
                              <span className='placeholder col-12' style={{height: '24px'}}></span>
                            </div>
                          </div>
                        </div>
                      ))
                  : fileData.map((file, index) => (
                      <div
                        className={`d-flex align-items-start mb-2 p-3 ${
                          selectedFileIndex === index ? 'bg-light cursor-pointer' : ''
                        }`}
                        key={index}
                        onClick={() => handleNameClick(file.downloadURL, index)}
                      >
                        <input
                          type='checkbox'
                          className='mt-2 form-check-input p-2'
                          checked={completed || file.isChecked}
                          onChange={() => handleCheckboxChange(index)}
                          disabled={completed || isLoading}
                        />
                        <div className='ms-4 cursor-pointer'>
                          <div className='text-dark fw-bold text-hover-primary fs-5 mt-2'>
                            {file.name}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>

              {/* PDF Viewer */}
              <div className='col-md-9 px-0'>
                <div className='mb-0'>
                  <div className='mb-0'>
                    <div className='overlay'>
                      {pdfLoading ? (
                        <div className='container-fluid'>
                          <div className='row'>
                            <div
                              className='col d-flex justify-content-center align-items-center'
                              style={{height: 600}}
                            >
                              <div className='text-muted fs-3'>{loadingProgress}%</div>
                            </div>
                          </div>
                        </div>
                      ) : selectedPDFUrl ? (
                        <div className='container-fluid'>
                          <div className='row'>
                            <div className='col p-0' style={{height: 600}}>
                              <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                                <Viewer
                                  fileUrl={selectedPDFUrl}
                                  plugins={[
                                    defaultLayoutPluginInstance,
                                    pageNavigationPluginInstance,
                                  ]}
                                  onPageChange={handlePageChange}
                                />
                              </Worker>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='container-fluid'>
                          <div className='row'>
                            <div
                              className='col d-flex justify-content-center align-items-center'
                              style={{height: 600}}
                            >
                              <div className='text-muted'>Select a document to view</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='border p-4 ml-3'>
                  {completed ? (
                    <button
                      type='button'
                      className='btn me-3 btn-sm fs-6'
                      disabled
                      style={{backgroundColor: '#1CBB8D', color: '#fff', height: '40px'}}
                    >
                      Completed
                    </button>
                  ) : lastPage ? (
                    <button
                      type='button'
                      className='btn me-3 btn-sm fs-6'
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        height: '40px',
                        minWidth: '150px',
                      }}
                      onClick={() => handleCheckboxChange(selectedFileIndex)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          Please wait...
                          <span
                            className='spinner-border spinner-border-sm me-2'
                            role='status'
                            aria-hidden='true'
                          ></span>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M5 12l5 5l10 -10' />
                          </svg>
                          Mark as Complete
                        </>
                      )}
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn me-3 btn-sm fs-6'
                      style={{backgroundColor: '#A2E3D0', color: '#fff', height: '40px'}}
                      disabled
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M5 12l5 5l10 -10' />
                      </svg>
                      Mark as Complete
                    </button>
                  )}
                  <span className='p-1 fs-6 text-muted'>
                    Please note that you are required to read all the training material in order to
                    complete the training.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SAT
