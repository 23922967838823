import {collection, doc, onSnapshot} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import AddManual from '../AddManual'
import AddCSV from '../AddCSV'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {ExportControls} from '../utils/ExportControls'
import UpgradePlan from '../submodal/UpgradePlan'

const ControlListToolbar = () => {
  const [controls, setControls] = useState([] as any[])
  const {currentUser} = useAuth()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isCSV, setIsCSV] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    getControls()
  }, [activeFrameworks])

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  const getControls = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }
      const tenantRef = doc(db, 'tenants', tenantId)
      const testsCollectionRef = collection(tenantRef, 'controls')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const controlsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          framework: doc.data().control_framework_mappings,
          status: doc.data().control_evidence_status,
          author: doc.data().author,
        }))

        const filteredControls = controlsData.filter((control) => {
          if (control.author === 'Custom Control') return true

          if (!control.framework) return false

          return activeFrameworks.some(
            (framework) =>
              control.framework.startsWith(framework) &&
              !control.framework.startsWith('ISO 27001:2013')
          )
        })
        setControls(filteredControls)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleMannually = () => {
    setIsCSV(!isCSV)
  }

  const handleOpenModal2 = () => {
    setIsCSV(true)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleExport = () => {
    console.log("clicked")
    ExportControls(activeFrameworks, controls)
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        // onClick={handleExport}
        onClick={() => (currentUser?.account_type === 'Trial' ? setShowModal(true) : handleExport())}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>
      {/* end::Export */}

      <AddManual isOpen={isModalOpen} onClose={handleCloseModal} />
      <AddCSV isOpen={isCSV} onClose={handleMannually} />

      {currentUser?.role !== 'Auditor' && (
        <div className='dropdown'>
          <button
            className='btn btn-sm fs-6 rotate'
            style={{background: '#1CBB8D', height: '40px', color: '#fff', width: '160px'}}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            onClick={() =>
              currentUser?.account_type === 'Trial' ? setShowModal(true) : toggleDropdown()
            }
            data-bs-toggle='dropdown'
            id='dropdownMenu2'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <span className='mx-2'>Add Controls</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down rotate-180'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M6 9l6 6l6 -6' />
            </svg>
          </button>
          {currentUser?.account_type !== 'Trial' && (
            <ul
              className='dropdown-menu mt-2'
              aria-labelledby='dropdownMenu2'
              style={{width: '160px'}}
            >
              <li>
                <button
                  className='dropdown-item text-hover-primary button-with-icon'
                  type='button'
                  onClick={handleOpenModal}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-pencil mb-1 mx-1 '
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4' />
                    <path d='M13.5 6.5l4 4' />
                  </svg>{' '}
                  Add manually
                </button>
              </li>

              <li>
                <button
                  className='dropdown-item text-hover-primary button-with-icon'
                  type='button'
                  onClick={handleOpenModal2}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='18'
                    height='18'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='#5A5A5A'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    className='icon icon-tabler icons-tabler-outline icon-tabler-file-import mb-1 mx-1'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                    <path d='M5 13v-8a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5.5m-9.5 -2h7m-3 -3l3 3l-3 3' />
                  </svg>{' '}
                  Import
                </button>
              </li>
            </ul>
           )} 
        </div>
      )}
      {/* end::Add user */}
      <UpgradePlan show={showModal} onClose={() => setShowModal(false)} />
    </div>
  )
}

export {ControlListToolbar}
