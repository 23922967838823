import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {addDoc, collection, doc, getDocs, query, serverTimestamp, updateDoc, where} from 'firebase/firestore'
import {useAuth} from '../../../../modules/auth'
import db from '../../../../../services/firebase'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom'

interface DeactivatingProps {
  isOpen: boolean
  onClose: () => void
  id: string
  onComplete: () => void 
  audit
}

const CompleteModal: React.FC<DeactivatingProps> = ({isOpen, onClose, id, audit, onComplete}) => {
  const [showModal, setShowModal] = useState(false)
  const {currentUser} = useAuth()
  const [auditComment, setAuditComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    setShowModal(isOpen)
  }, [isOpen])

  const handleClose = () => {
    setShowModal(false)
    setFormError('')
    onClose()
  }

  const handleChange = (e) => {
    setAuditComment(e.target.value)
  }

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`
   const todaysDate = `${month}/${day}/${year}`

  const handleMarkAsComplete = async (auditId, auditorId, audit) => {
    setIsLoading(true)
    const tenantId = currentUser?.tenantId || ''
    const tenantRef2 = doc(db, 'tenants', tenantId)
    const policyRef = doc(tenantRef2, 'audits', auditId)

    if (!audit.audit_documents || audit.audit_documents.length === 0) {
      setFormError('Audit report has not been submitted')
      setIsLoading(false)
      return
    }

    await updateDoc(policyRef, {
      status: 'Completed',
      auditCompleteDate: formattedDate,
      auditInfo: auditComment ? auditComment : '',
      end_date: formattedDate,
    })

    const employeesRef = collection(tenantRef2, 'employees')
    const querySnapshot = await getDocs(query(employeesRef, where('uid', '==', auditorId)))

    if (!querySnapshot.empty) {
      const employeeDocRef = querySnapshot.docs[0].ref
      await updateDoc(employeeDocRef, {availability: true})
    }
    setIsLoading(false)

    const tenantRef = doc(db, 'tenants', tenantId)
    const activitiesRef = collection(tenantRef, 'activities')
    await addDoc(activitiesRef, {
      message: `The auditor ${currentUser?.userName} has marked the audit for the ${audit.framework[0]} framework as completed.`,
      timestamp: serverTimestamp(),
      tenantId: currentUser?.tenantId,
      // email: email,
      uid: currentUser?.uid,
      username: currentUser?.userName,
      action: 'Audit updated',
      target: 'Audits',
      email: currentUser?.email,
      date: todaysDate,
    })

    navigate('/frameworks', { state: { auditCompleted: true } });
    onClose()
    toast.success('Audit marked as Completed', {
      position: toast.POSITION.TOP_CENTER,
      className: 'bg-white',
      style: {
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e0e0e0',
        color: 'black',
      },
      progressStyle: {
        background: '#1CBB8D',
      },
      icon: ({theme, type}) => (
        <svg
          width='26'
          height='26'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          style={{color: '#1CBB8D'}}
        >
          <path
            d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
            fill='currentColor'
          />
        </svg>
      ),
      autoClose: 3000,
    })
  }

  return (
    <Modal show={showModal} onHide={handleClose} backdropClassName='bg-opacity-1' centered>
      <Modal.Header closeButton>
        <Modal.Title>Audit Complete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formError && (
          <div className='alert alert-danger' role='alert'>
            {formError}
          </div>
        )}
        <div className='fs-6' style={{color: '#5A5A5A'}}>
          Marking this audit as complete will finalize all activities and mark the audit as
          successful.
          <p className='mt-2'>
            <strong>Please note:</strong>
          </p>
          <ul>
            <li>This action is irreversible.</li>
            <li>
              After completion, you will no longer have access to the audit data in this account and
              this audit will be marked as completed.
            </li>
          </ul>
          Ensure all necessary steps have been completed before proceeding.
        </div>
        <div className='fv-row mb-2 mt-7'>
          <label className='fw-bold fs-6 mb-2'>
            Additional comments or context for this audit (optional)
          </label>
          <textarea
            placeholder='Provide any additional context or explanation...'
            className='form-control mb-3 mb-lg-0 w-100'
            name='completionDetail'
            value={auditComment}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
      </Modal.Body>

      <Modal.Footer className='p-0 px-6 py-4'>
        <button type='button' className='btn btn-light fs-6 mx-4' onClick={handleClose}>
          Cancel
        </button>
        {/* <div  style={{cursor: 'pointer'}}> */}
        {isLoading ? (
          <button
            className='btn btn-sm fs-6'
            // onClick={handleDeactivateActivate}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            disabled
            // disabled={activities.length === 0}
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',

              transition: 'background-color 0.3s',
            }}
          >
            Updating... <span className='spinner-border spinner-border-sm align-middle'></span>
          </button>
        ) : (
          <button
            className='btn btn-sm fs-6'
            onClick={() => handleMarkAsComplete(audit.id, audit.auditor?.id, audit)}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',

              transition: 'background-color 0.3s',
            }}
          >
            Mark as Complete
          </button>
        )}

        {/* </div> */}
      </Modal.Footer>
    </Modal>
  )
}

export default CompleteModal
