// import {OnSidebarMenuMain} from './OnSidebarMenu'
// import {SidebarMenuMain} from './SidebarMenuMain'
// import {useLocation} from 'react-router-dom'

// const SidebarMenu = () => {
//   const location = useLocation()  
//   return (
//     <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
//       <div
//         id='kt_app_sidebar_menu_wrapper'
//         className='app-sidebar-wrapper hover-scroll-overlay-y mt-4'
//         data-kt-scroll='true'
//         data-kt-scroll-activate='true'
//         data-kt-scroll-height='auto'
//         data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
//         data-kt-scroll-wrappers='#kt_app_sidebar_menu'
//         data-kt-scroll-offset='5px'
//         data-kt-scroll-save-state='true'
//       >
//         <div
//           className='menu menu-column menu-rounded menu-sub-indention px-3'
//           id='#kt_app_sidebar_menu'
//           data-kt-menu='true'
//           data-kt-menu-expand='false'
//         >
//           {location.pathname === '/dashboard' ? (
//             <SidebarMenuMain /> // Render dashboard sidebar
//           ) : location.pathname.startsWith('/training') ||
//             location.pathname === '/policies' ||
//             location.pathname === '/backgroundCheck' ? (
//             <OnSidebarMenuMain /> // Render onDashboard sidebar
//           ) : (
//             <SidebarMenuMain />
//           )}
//         </div>
//       </div>
//     </div>
//   )
// }

// export {SidebarMenu}

import { FC, useEffect } from 'react'
import { OnSidebarMenuMain } from './OnSidebarMenu'
import { SidebarMenuMain } from './SidebarMenuMain'
import { useLocation } from 'react-router-dom'

const SidebarMenu: FC = () => {
  const location = useLocation()

  // Function to calculate and set the height
  const calculateHeight = (): void => {
    const sidebarWrapper = document.getElementById('kt_app_sidebar_menu_wrapper')
    if (sidebarWrapper) {
      // Get viewport height
      const viewportHeight = window.innerHeight
      // Calculate offset height based on dependencies
      const logoElement = document.getElementById('kt_app_sidebar_logo')
      const footerElement = document.getElementById('kt_app_sidebar_footer')
      
      const logoHeight = logoElement?.offsetHeight || 0
      const footerHeight = footerElement?.offsetHeight || 0
      
      // Calculate available height
      const availableHeight = viewportHeight - logoHeight - footerHeight - 20 // 20px buffer
      
      // Set the height
      sidebarWrapper.style.height = `${availableHeight}px`
    }
  }

  useEffect(() => {
    // Initial calculation
    calculateHeight()

    // Recalculate on window resize
    window.addEventListener('resize', calculateHeight)

    // Force recalculation after a small delay to ensure all elements are loaded
    const timeoutId = setTimeout(calculateHeight, 100)

    // Cleanup
    return () => {
      window.removeEventListener('resize', calculateHeight)
      clearTimeout(timeoutId)
    }
  }, []) // Empty dependency array for initial mount

  // Recalculate when route changes
  useEffect(() => {
    calculateHeight()
  }, [location.pathname])

  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper hover-scroll-overlay-y mt-4'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          id='kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
        >
          {location.pathname === '/dashboard' ? (
            <SidebarMenuMain />
          ) : location.pathname.startsWith('/training') ||
            location.pathname === '/policies' ||
            location.pathname === '/backgroundCheck' ? (
            <OnSidebarMenuMain />
          ) : (
            <SidebarMenuMain />
          )}
        </div>
      </div>
    </div>
  )
}

export { SidebarMenu }