import React, { useState, useEffect } from 'react';

// Custom hook for Crisp chat management
export const useCrispChat = () => {
  const [isCrispLoaded, setIsCrispLoaded] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    // Initial Crisp script loading
    if (!window.$crisp) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '61030408-87ac-47eb-979f-e95d108e2d77';
      
      const script = document.createElement('script');
      script.src = 'https://client.crisp.chat/l.js';
      script.async = true;
      
      script.onload = () => {
        setIsCrispLoaded(true);
        initializeChatState();
      };
      
      document.head.appendChild(script);
    } else {
      setIsCrispLoaded(true);
      initializeChatState();
    }
  }, []);

  // Initialize chat state from localStorage
  const initializeChatState = () => {
    const wasChatOpen = localStorage.getItem('isChatOpen') === 'true';
    
    if (wasChatOpen) {
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
      setIsChatOpen(true);
    } else {
      window.$crisp.push(['do', 'chat:hide']);
      setIsChatOpen(false);
    }
  };

  // Watch for chat open/close state
  useEffect(() => {
    if (isCrispLoaded) {
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['do', 'chat:hide']);
          setIsChatOpen(false);
          localStorage.setItem('isChatOpen', 'false');
        },
      ]);
    }
  }, [isCrispLoaded]);

  // Toggle chat visibility
  const toggleCrispChat = () => {
    if (!isCrispLoaded) {
      console.log('Crisp is not loaded yet. Please wait a moment and try again.');
      return;
    }

    if (!isChatOpen) {
      window.$crisp.push(['do', 'chat:show']);
      window.$crisp.push(['do', 'chat:open']);
      setIsChatOpen(true);
      localStorage.setItem('isChatOpen', 'true');
    } else {
      window.$crisp.push(['do', 'chat:hide']);
      window.$crisp.push(['do', 'session:reset']);
      setIsChatOpen(false);
      localStorage.setItem('isChatOpen', 'false');
    }
  };

  return { 
    isCrispLoaded, 
    isChatOpen, 
    toggleCrispChat 
  };
};

export const CrispChatButton = () => {
  const { isChatOpen, toggleCrispChat } = useCrispChat();

  return (
    <button 
      onClick={toggleCrispChat}
      className={`
        ${isChatOpen ? 'bg-red-500' : 'bg-blue-500'} 
        text-white px-4 py-2 rounded
      `}
    >
      {isChatOpen ? 'Close Chat' : 'Open Chat'}
    </button>
  );
};

export default useCrispChat;