/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink} from '../../../../_metronic/layout/core'
import db from '../../../../services/firebase'
import {collection, deleteDoc, doc, onSnapshot, updateDoc} from 'firebase/firestore'
import {VendorPageTitle} from '../../../../_metronic/layout/core/VendorPageData'
import {useAuth} from '../../../modules/auth'
import Swal from 'sweetalert2'

type Props = {}

const Archive: React.FC<Props> = () => {
  const {currentUser} = useAuth()
  const [vendor, setVendor] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchLength, setSearchLength] = useState(false)
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)

  useEffect(() => {
    getVendor()
  }, [])

  useEffect(() => {
    setFilteredItems(vendor)
  }, [vendor])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  const getVendor = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'vendors')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            archived: doc.data().archived,
          }))
          .filter((vendor) => vendor.archived) // Filter out archived vendors
        setVendor(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(vendor)
      setSearchLength(false)
    } else {
      const filteredItems = vendor.filter((user) =>
        user.vendorName.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleArchive = async (id) => {
    try {
      // Update the document in Firestore to mark it as archived
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const vendorsRef = collection(tenantRef, 'vendors')
      const docRef = doc(vendorsRef, id)

      await updateDoc(docRef, {
        archived: false,
      })

      // Update local state to reflect the change
      setVendor((prevVendors) => prevVendors.map((v) => (v.id === id ? {...v, archived: true} : v)))

      // Show a success message
      Swal.fire({
        icon: 'success',
        title: 'Archived!',
        text: 'The vendor has been removed from archived.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      console.error('Error archiving vendor:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while  unarchiving the vendor.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const deleteHandler = async (Data) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${Data.vendorName}`,
        text: `Are you sure you want to remove ${Data.vendorName} risk ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // await riskServices.deleteRisk(riskData.id)
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'vendors')
        const docRef = doc(policyRef, Data.id)
        await deleteDoc(docRef)
        getVendor()
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${Data.vendorName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the vendor.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked
    setSelectAll(isChecked)
    setCheckedItems(isChecked ? filteredItems.map((item) => item.id) : [])
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => {
      const newCheckedItems = checked ? [...prev, value] : prev.filter((item) => item !== value)

      // Update selectAll state based on whether all items are checked
      setSelectAll(newCheckedItems.length === filteredItems.length)

      return newCheckedItems
    })
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleMultipleDelete = async () => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove Selected Vendors`,
        text: `Are you sure you want to remove ${checkedItems.length} selected vendors? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'vendors')

        // Delete all selected vendors
        const deletePromises = checkedItems.map(async (vendorId) => {
          const docRef = doc(policyRef, vendorId)
          await deleteDoc(docRef)
        })

        await Promise.all(deletePromises)

        // Refresh the vendor list
        getVendor()

        // Clear the selection
        setCheckedItems([])
        setSelectAll(false)

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `${checkedItems.length} vendors have been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the vendors.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <div>
      <VendorPageTitle
        icon='VendorIcon.svg'
        title='Vendor Risk Management'
        desc={
          'Monitor risks associated with third party services to enhance your security posture. '
        }
        breadcrumbs={usersBreadcrumbs}
      />

      <div className='card card-custom '>
        <div className='card-header border-0 pt-6 h-100'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control  w-250px ps-14'
                placeholder='Search..'
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {checkedItems.length > 0 ? (
                <>
                  {checkedItems.length > 0 ? (
                    <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                      <span>
                        <p className='pt-4'>{checkedItems.length} Selected</p>
                      </span>
                    </div>
                  ) : null}
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                      <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                    </div>

                    <button
                      className='btn btn-sm fs-6'
                      onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#148363')
                      }
                      onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                        (e.currentTarget.style.backgroundColor = '#1CBB8D')
                      }
                      onClick={handleMultipleDelete}
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        width: '140px',
                        height: '40px',
                        borderRadius: '4px',
                        left: '615px',
                        top: '20px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      {' '}
                      Delete vendors
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className='table-responsive py-3 px-10' style={{minHeight: '50vh'}}>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      onChange={handleSelectAllChange}
                    />
                  </div>
                </th>
                <th className='min-w-300px'>VENDOR NAME</th>
                <th className='min-w-150px'>RISK LEVEL</th>
                <th className='min-w-150px'>DUE DILIGENCE</th>
                <th className='min-w-150px'>CATEGORY</th>
                <th className='min-w-150px'>OWNER</th>
                <th className='min-w-50px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredItems && filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <tr>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value={item.id}
                          checked={checkedItems.includes(item.id)}
                          onChange={handleCheckChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-30px me-3'>
                          {/* <img src={toAbsoluteUrl(item.logo[0].url)} alt='' loading='lazy' /> */}
                          {item.logo && item.logo.length > 0 ? (
                            <img src={toAbsoluteUrl(item.logo[0]?.url)} alt='' loading='lazy' />
                          ) : (
                            <img src={toAbsoluteUrl(item.logoUrl)} alt='' loading='lazy' />
                          )}
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a
                            href={`/vendor-risk/details/${item.id}`}
                            className='text-dark fw-bold text-hover-primary fs-6'
                          >
                            {item.vendor_name}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td>
                      {item.risk_level ? (
                        <a
                          href='#'
                          className={`badge fs-7 ${
                            item.risk_level === 'High'
                              ? 'badge-danger'
                              : item.risk_level === 'Medium'
                              ? 'badge-warning'
                              : 'badge-primary'
                          }`}
                          style={{
                            color:
                              item.risk_level === 'High'
                                ? '#EE3043'
                                : item.risk_level === 'Medium'
                                ? '#FFA800'
                                : '#1CBB8D',
                            background:
                              item.risk_level === 'High'
                                ? '#FFF5F8'
                                : item.risk_level === 'Medium'
                                ? '#FFF8DD'
                                : '#E8F8F4',
                          }}
                        >
                          {item.risk_level}
                        </a>
                      ) : (
                        '--'
                      )}
                    </td>

                    <td>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <div className='clearfix bshadow0 pbs fw-semibold'>
                            <span
                              className={`badge fs-7 ${
                                !item.due_diligence_status ||
                                item.due_diligence_status === 'Incomplete'
                                  ? 'badge-danger'
                                  : 'badge-primary'
                              }`}
                              style={{
                                color:
                                  !item.due_diligence_status ||
                                  item.due_diligence_status === 'Incomplete'
                                    ? '#EE3043'
                                    : '#1CBB8D',
                                background:
                                  !item.due_diligence_status ||
                                  item.due_diligence_status === 'Incomplete'
                                    ? '#FFF5F8'
                                    : '#E8F8F4',
                              }}
                            >
                              {item.due_diligence_status ? item.due_diligence_status : 'Incomplete'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          {item.category ? (
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {item.category}
                            </a>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className='d-flex align-items-center'>
                        {item.owner ? (
                          <>
                            <div className='symbol symbol-35px me-2'>
                              <span
                                className='symbol-label fs-7 fw-bold'
                                style={{backgroundColor: '#DAF9EF'}}
                              >
                                {(() => {
                                  const nameParts = item.owner.split(' ')
                                  if (nameParts.length > 1) {
                                    return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1]
                                      .charAt(0)
                                      .toUpperCase()}`
                                  } else {
                                    return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[0]
                                      .charAt(1)
                                      .toUpperCase()}`
                                  }
                                })()}
                              </span>
                            </div>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {item.owner}
                            </a>
                          </>
                        ) : (
                          <span>--</span>
                        )}
                      </div>
                    </td>
                    <td className=''>
                      <div className='dropdown mx-2'>
                        <button
                          className='btn btn-secondary border-0 p-0 m-2'
                          type='button'
                          id='dropdownMenu2'
                          data-bs-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <img
                            src='/media/buttons/Options.svg'
                            alt='Options'
                            onClick={toggleDropdown}
                          />
                        </button>
                        <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                          {/* <li>
                          <button
                            className='dropdown-item text-muted text-hover-primary'
                            type='button'
                            onClick={() => handleOpenModal(item.id)}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                              <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                              <path d='M16 5l3 3' />
                            </svg>{' '}
                            Edit Vendor
                          </button>
                        </li> */}

                          <li>
                            <button
                              className='dropdown-item text-muted text-hover-primary'
                              type='button'
                              onClick={() => handleArchive(item.id)}
                              disabled={currentUser?.role === 'Auditor'}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-archive  mx-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z' />
                                <path d='M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10' />
                                <path d='M10 12l4 0' />
                              </svg>{' '}
                              Unarchive Vendor
                            </button>
                          </li>

                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              style={{color: '#F15363'}}
                              onClick={(e) => deleteHandler(item)}
                              disabled={currentUser?.role === 'Auditor'}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#F15363'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M4 7l16 0' />
                                <path d='M10 11l0 6' />
                                <path d='M14 11l0 6' />
                                <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                              </svg>{' '}
                              Delete Vendor
                            </button>
                          </li>
                        </ul>
                      </div>
                      {/* <PolicyListToolbar policyDataId={policyData.id} /> */}
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr style={{height: '100%'}}>
                    {searchLength ? (
                      <td colSpan={10}>
                        <div
                          className='d-flex justify-content-center align-items-center text-center'
                          style={{height: '100%'}}
                        >
                          <div className='m-20'>
                            <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No vendor found!</p>
                            </div>
                            <p>
                              We couldn't find any vendor that match your search. Maybe try a
                              different search!
                            </p>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td colSpan={10}>
                        <div
                          className='d-flex justify-content-center align-items-center text-center'
                          style={{height: '100%'}}
                        >
                          {!showEmptyState ? (
                            <div className='m-20'>{/* <RiskListLoading /> */}</div>
                          ) : (
                            <div className='m-20'>
                              <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                              <div className='m-4 fs-5'>
                                <p style={{fontWeight: 'bold'}}>No vendors archived</p>
                              </div>
                              <p>We couldn't find any vendors that has been archived.</p>
                            </div>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
      {/* begin::Table container */}

      {/* begin::Body */}
    </div>
  )
}

export {Archive}
