
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'

const routeHeadings = {
  '/dashboard': {heading: 'Dashboard'},
  '/controls': {heading: 'Controls'},
  '/tests': {heading: 'Tests'},
  '/reports': {heading: 'Reports'},
  '/frameworks': {heading: 'Frameworks'},
  '/policy-library/lists': {heading: 'Policy Library'},
  '/employees': {heading: 'Employees'},
  '/asset-management': {heading: 'Asset Management'},
  '/data-room': {heading: 'Data Room'},
  '/risk-management': {heading: 'Risk Management'},
  '/vendor-risk': {heading: 'Vendor Risk'},
  '/clouds': {heading: 'Clouds'},
  '/domains': {heading: 'Domains'},
  '/domains/checks': {heading: 'Domains'},
  '/policy-library/PolicyTemplates': {heading: 'Policy Library'},
  '/employees/group': {heading: 'Employees'},
  '/employees/checklist': {heading: 'Employees'},
  '/risk-management/library': {heading: 'Risk Management'},
  '/risk-management/overview': {heading: 'Risk Management'},
  '/vendor-risk/archive': {heading: 'Vendor Risk'},
  '/vendor-risk/details/': {heading: 'Vendor Risk'},
  '/training': {heading: 'Training'},
  '/training/SAT': {heading: 'Training'},
  '/training/PCISecure': {heading: 'Training'},
  '/policies': {heading: 'Policies'},
  '/backgroundCheck': {heading: 'Identity Verification'},
  '/controls/details/': {heading: 'Controls'},
  '/company-settings': {heading: 'Company Settings'},
  '/company-settings/advanced': {heading: 'Company Settings'},
  '/company-settings/framework': {heading: 'Company Settings'},
  '/company-settings/users': {heading: 'Company Settings'},
  '/integrations': {heading: 'Integrations'},
  '/integrations/available': {heading: 'Integrations'},
  '/tasks': {heading: 'Tasks'},
  '/audits': {heading: 'Audits'},
}

const RouteHeading = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const findMatchingRoute = (path) => {
    // Check for exact match first
    if (routeHeadings[path]) {
      return {...routeHeadings[path], path}
    }

    // If no exact match, look for the closest parent path
    const pathParts = path.split('/').filter(Boolean)
    while (pathParts.length) {
      const partialPath = '/' + pathParts.join('/')
      if (routeHeadings[partialPath]) {
        return {...routeHeadings[partialPath], path: partialPath}
      }
      pathParts.pop()
    }

    return null
  }

  const matchedRoute = findMatchingRoute(location.pathname)

  if (!matchedRoute) return null

  const {heading, subHeading, path} = matchedRoute

  const handleClick = (e) => {
    e.preventDefault()
    navigate(path)
  }

  const renderHeading = () => {
    return (
      <h4 className={`mt-8 ${subHeading ? 'fw-semibold' : 'fw-bold'}`}>
        <a href={path} onClick={handleClick} className='text-black hover:underline'>
          {heading}
        </a>
      </h4>
    )
  }

  const renderSubHeading = () => {
    if (!subHeading) return null

    return (
      <h4 className='mt-8'>
        &nbsp; &nbsp;
        <span className='text-black'>{subHeading}</span>
      </h4>
    )
  }

  return (
    <div className='flex items-center'>
      {currentUser?.role !== 'Auditor' && (
        <span className='fw-bold'>
          {renderHeading()} {renderSubHeading()}
        </span>
      )}
    </div>
  )
}

export default RouteHeading
