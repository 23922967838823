import React from 'react'
import {AuditPageTitle} from '../../../_metronic/layout/core/AuditPageData'
import {KTCard} from '../../../_metronic/helpers'
import Audit from './Audit'

const AuditWrapper = () => {
  return(
  <>
    <AuditPageTitle
      icon='Icon-2.svg'
      title='Audits'
      // desc={totalVendor.toString() + ' Vendors'}
      desc={'Track and manage your security audits in one centralized view'}
    />
    {/* <KTCard> */}
        <Audit/>
    {/* </KTCard> */}
  </>
  )
}

export default AuditWrapper
