import React, {useEffect, useRef, useState} from 'react'
import {OnDashPageTitle} from '../cores/OnDashPageData'
import {PageChangeEvent, Viewer} from '@react-pdf-viewer/core'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import {Worker} from '@react-pdf-viewer/core'
import {collection, onSnapshot, doc, setDoc} from '@firebase/firestore'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {Helmet} from 'react-helmet'
import {useNavigate} from 'react-router-dom'
import {renderAsync} from 'docx-preview'

interface PolicyData {
  policy: string
  fileUrl: string
  isChecked: boolean
  owner: string
  framework: string
}

interface PolicyDocument extends PolicyData {
  id: string
}
interface CustomFile extends PolicyDocument {
  tenantId: string | null | undefined
  finished?: boolean
  fileName: string
  customfile
}

const Policies: React.FC = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const [policies, setPolicies] = useState<CustomFile[]>([])
  const [selectedFile, setSelectedFile] = useState<CustomFile | null>(null)
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(null)
  const {currentUser} = useAuth()
  const [lastPage, setLastPage] = useState(false)
  const [policyData, setPolicyData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [personnelPolicies, setPersonnelPolicies] = useState('')
  const [filteredPolicies, setFilteredPolicies] = useState<CustomFile[]>([])
  const [fileType, setFileType] = useState<'pdf' | 'docx' | null>(null)
  const [reachedEnd, setReachedEnd] = useState(false)
  const docxContainerRef = useRef<HTMLDivElement>(null)
  const [pdfKey, setPdfKey] = useState(0)
  const [isDocumentLoading, setIsDocumentLoading] = useState(true)

  console.log(selectedFile)
  console.log("File",selectedFile?.customfile)

  useEffect(() => {
    if (selectedFile) {
      setIsDocumentLoading(true)
    }
  }, [selectedFile])

  const renderDocx = async (url: string) => {
    try {
      setIsDocumentLoading(true)
      if (docxContainerRef.current) {
        docxContainerRef.current.innerHTML = ''
      }
      const response = await fetch(url)
      const blob = await response.blob()
      if (docxContainerRef.current) {
        await renderAsync(blob, docxContainerRef.current, docxContainerRef.current, {
          inWrapper: false,
        })
        scrollDocxToTop()
      }
    } catch (error) {
      console.error('Error rendering DOCX:', error)
    } finally {
      setIsDocumentLoading(false)
    }
  }

  useEffect(() => {
    // Set the selectedFile state to the first item in the policies array
    if (filteredPolicies.length > 0) {
      setSelectedFile(filteredPolicies[0])
      setSelectedFileIndex(0)
    }
  }, [policies])

  useEffect(() => {
    scrollDocxToTop()
  }, [selectedFile])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'policies')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const policyData = snapshot.docs.map((doc) => ({
        ...(doc.data() as CustomFile),
        id: doc.id,
        policy: doc.data().policy,
      }))

      const filtered = policyData.filter((policy) => personnelPolicies ? personnelPolicies.includes(policy.policy) : false)

      setFilteredPolicies(filtered)
      setPolicies(policyData)
    })

    return unsubscribe
  }, [currentUser, personnelPolicies])

  // useEffect(() => {
  //   if (selectedFile && selectedFile.fileUrl) {
  //     const fileExtension = selectedFile.fileName.split('.').pop()?.toLowerCase()
  //     setFileType(fileExtension === 'pdf' ? 'pdf' : 'docx')
  //   }
  // }, [selectedFile])

  useEffect(() => {
    if (selectedFile && selectedFile.customfile) {
      const sortedFiles = selectedFile.customfile.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      const fileExtension = sortedFiles[0].name.split('.').pop()?.toLowerCase();
      setFileType(fileExtension === 'pdf' ? 'pdf' : 'docx');
    }
  }, [selectedFile]);

  // useEffect(() => {
  //   if (selectedFile && fileType === 'docx') {
  //     renderDocx(selectedFile.customfile[0].url)
  //   }
  // }, [selectedFile, fileType])

  useEffect(() => {
    if (selectedFile && fileType === 'docx' && selectedFile.customfile) {
      const sortedFiles = selectedFile.customfile.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      renderDocx(sortedFiles[0].url);
    }
  }, [selectedFile, fileType]);

  useEffect(() => {
    if (fileType === 'docx' && docxContainerRef.current) {
      const container = docxContainerRef.current
      const handleScroll = () => {
        if (container.scrollHeight - container.scrollTop <= container.clientHeight + 50) {
          setReachedEnd(true)
        } else {
          setReachedEnd(false)
        }
      }

      container.addEventListener('scroll', handleScroll)
      return () => container.removeEventListener('scroll', handleScroll)
    }
  }, [fileType])

  const scrollDocxToTop = () => {
    if (docxContainerRef.current) {
      docxContainerRef.current.scrollTop = 0
    }
  }

  const isAcceptButtonDisabled = () => {
    if (selectedFile && policyData[selectedFile?.id]?.isChecked) {
      return true
    }
    if (fileType === 'pdf') {
      return !lastPage
    }
    if (fileType === 'docx') {
      return !reachedEnd
    }
    return true
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      if (!tenantId) {
        return
      }

      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'employees')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const employeesData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            fullName: doc.data().full_name,
            uid: doc.data().uid,
            group: doc.data().group,
          }))
          .filter((data) => data.uid === currentUser?.uid)

        if (employeesData.length > 0) {
          const group = employeesData[0].group
          const groupRef = collection(tenantRef, 'personnel_group')

          const unsubscribe = onSnapshot(groupRef, (snapshot) => {
            const groupData = snapshot.docs
              .map((doc) => ({
                ...doc.data(),
                id: doc.id,
                checklist: doc.data().checklist,
                groupName: doc.data().groupName,
              }))
              .filter((data) => data.groupName === group)

            const checklist = groupData[0].checklist
            const checklistRef = collection(tenantRef, 'personnel_checklist')

            const unsubscribe2 = onSnapshot(checklistRef, (snapshot) => {
              const checklistData = snapshot.docs
                .map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                  checklist: doc.data().checklist_name,
                  customTasks: doc.data().CustomTasks || [],
                  policies: doc.data().policies,
                }))
                .filter((data) => data.checklist === checklist)

              const policies = checklistData[0].policies

              setPersonnelPolicies(policies)
            })
          })
        }
      })
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const fetchAllPoliciesData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const userId = currentUser?.uid || ''
        if (!tenantId || !userId) {
          return
        }

        const policyDataMap = {}
        const unsubscribeArray: (() => void)[] = []

        // Iterate through each filtered policy and set up a listener
        filteredPolicies.forEach((item) => {
          const policyDocRef = doc(
            db,
            'tenants',
            tenantId,
            'onboarding',
            userId,
            'policies',
            item.id
          )

          const unsubscribe = onSnapshot(policyDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              policyDataMap[item.id] = docSnapshot.data()
            } else {
              policyDataMap[item.id] = {} // Handle cases where the document doesn't exist
            }

            // Update state with the new data
            setPolicyData((prevData) => ({
              ...prevData,
              ...policyDataMap,
            }))
          })

          unsubscribeArray.push(unsubscribe)
        })

        // Cleanup function to unsubscribe from all listeners
        return () => {
          unsubscribeArray.forEach((unsubscribe) => unsubscribe())
        }
      } catch (error) {
        console.error('Error fetching policies data:', error)
      }
    }

    if (filteredPolicies.length > 0) {
      fetchAllPoliciesData()
    }
  }, [filteredPolicies, currentUser])

  const handleFileChange = (file: CustomFile, index: number) => {
    setSelectedFile(file)
    setSelectedFileIndex(index)
    setLastPage(false)
    setPdfKey((prevKey) => prevKey + 1)

    setReachedEnd(false)
    if (docxContainerRef.current) {
      docxContainerRef.current.innerHTML = ''
    }
    scrollDocxToTop()
  }

  const handleAcceptClick = async () => {
    if (selectedFileIndex !== null) {
      const updatedFileList = [...filteredPolicies]
      const selectedPolicy = updatedFileList[selectedFileIndex]
      updatedFileList[selectedFileIndex] = {
        ...selectedPolicy,
        isChecked: true,
        finished: true,
      }

      const {id, policy} = selectedPolicy
      const tenantId = currentUser?.tenantId || ''
      const userId = currentUser?.uid || ''

      if (!tenantId || !userId) {
        setIsLoading(false)
        return
      }

      // Use index as the document ID
      const policyDocId = selectedFileIndex.toString()
      const policyDocRef = doc(db, 'tenants', tenantId, 'onboarding', userId, 'policies', id)

      try {
        setIsLoading(true)

        // Update the policy data
        const updatedPolicyData = {
          isChecked: true,
          finished: true,
          completedByUid: currentUser?.uid,
          completedBy: currentUser?.userName,
          policy,
        }

        // Set or update the specific policy document
        await setDoc(policyDocRef, updatedPolicyData)

        // Update local state
        setPolicyData((prevData) => ({
          ...prevData,
          [policyDocId]: updatedPolicyData,
        }))
        setFilteredPolicies(updatedFileList)

        setIsLoading(false)

        // Check if all policies are now checked
        const areAllPoliciesChecked = updatedFileList.every((policy) => policy.isChecked)

        // Move to the next file or navigate if all are checked
        if (areAllPoliciesChecked) {
          setTimeout(() => {
            navigate('/training')
          }, 1000)
        } else {
          const nextFileIndex = selectedFileIndex + 1
          if (nextFileIndex < updatedFileList.length) {
            setSelectedFile(updatedFileList[nextFileIndex])
            setSelectedFileIndex(nextFileIndex)
            setLastPage(false)
            setPdfKey((prevKey) => prevKey + 1)
            scrollDocxToTop()
            setReachedEnd(false)
          }
        }
      } catch (error) {
        console.error('Error updating policy in Firestore:', error)
        setIsLoading(false)
      }
    }
  }

  const handlePageChange = (e: PageChangeEvent) => {
    // Get the current page number
    const currentPage = e.currentPage + 1
    // Get the total number of pages
    const totalPages = e.doc.numPages

    // Check if the current page number equals the total number of pages
    if (currentPage === totalPages) {
      setLastPage(true)
    } else {
      setLastPage(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Policies</title>
      </Helmet>
      <OnDashPageTitle
        icon='policies1.svg'
        title='Policies'
        desc="Read and accept your company's policies."
      />
      <div className='card'>
        <div className='row '>
          <div className='col-md-12'>
            <div className='row m-0 '>
              <div className='col-md-3 border-end overflow-auto' style={{maxHeight: '600px'}}>
                <h2 className='text-dark  mt-3 ml-4 px-4 pt-4 pb-3'>Policies</h2>

                <hr />
                {filteredPolicies.map((item, index) => (
                  <div
                    className={`d-flex align-items-start mb-2 p-3  ${
                      selectedFileIndex === index ? 'bg-light cursor-pointer' : ''
                    }`}
                    key={item.id}
                    onClick={() => handleFileChange(item, index)}
                  >
                    <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={policyData[item.id]?.isChecked || item.isChecked || false}
                      />
                    </div>
                    {/* <input
                        type='checkbox'
                        className='mt-1 form-check-input p-3'
                        checked={policyData[item.id]?.isChecked || item.isChecked || false}
                      /> */}
                    <div className='ms-4 cursor-pointer '>
                      <div className='text-dark fw-bold text-hover-primary fs-4 '>
                        {item.policy}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Right Column (Pdf/docx viewer) */}
              <div className='col-md-9 px-0'>
                <div className='mb-0'>
                  <div className='mb-0'>
                    <div className='overlay '>
                      {selectedFile ? (
                        <div className='container-fluid'>
                          <div className='row'>
                            <div className='col p-0' style={{height: 600}}>
                              {isDocumentLoading && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    zIndex: 1000,
                                  }}
                                >
                                  <div className='d-flex flex-column align-items-center'>
                                    <div className='spinner-border text-primary mb-3' role='status'>
                                      <span className='visually-hidden'>Loading...</span>
                                    </div>
                                    <div className='fs-6'>Loading document...</div>
                                  </div>
                                </div>
                              )}
                              {fileType === 'pdf' ? (
                                <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
                                  <Viewer
                                    fileUrl={selectedFile.customfile[0].url}
                                    plugins={[defaultLayoutPluginInstance]}
                                    key={pdfKey}
                                    onPageChange={handlePageChange}
                                  />
                                </Worker>
                              ) : (
                                <div
                                  ref={docxContainerRef}
                                  id='docx-container'
                                  style={{height: '100%', overflow: 'auto'}}
                                ></div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {' '}
                          <div className='container-fluid'>
                            <div className='row'>
                              <div className='col ' style={{height: 600}}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                  }}
                                >
                                  {/* <div className='spinner-border text-primary mb-6' role='status'>
                                    <span className='visually-hidden'>Loading...</span>
                                  </div> */}
                                  <div className='mb-2 mx-10 fs-6'>
                                    No checklist policies have been assigned to this group. Please
                                    ask the admin or owner to update the checklist to complete the
                                    onboarding process for the employees in this group.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className='border p-4 ml-3 '>
                  {selectedFile && policyData[selectedFile?.id]?.isChecked ? (
                    <button
                      type='button'
                      className='btn me-3 '
                      disabled
                      style={{backgroundColor: '#1CBB8D', color: '#fff'}}
                    >
                      Accepted
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn me-3 '
                      onClick={handleAcceptClick}
                      style={{backgroundColor: '#1CBB8D', color: '#fff'}}
                      disabled={isAcceptButtonDisabled()}
                    >
                      {isLoading ? (
                        <>
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </>
                      ) : (
                        <>Accept</>
                      )}
                    </button>
                  )}
                  <span className='p-1'>
                    {' '}
                    By clicking accept you agree to the current document{' '}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Policies
