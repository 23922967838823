import {useState} from 'react'
import {ListViewProvider} from '../vendor/core/ListViewProvider'
import {QueryRequestProvider} from '../vendor/core/QueryRequestProvider'
import {QueryResponseProvider} from '../vendor/core/QueryResponseProvider'
import {PolicyLibraryTable} from './table/PolicyLibraryTable'
import {EditPolicyModal} from './components/EditPolicyModal'

const PolicyList = () => {
  const [policyID, setPolicyID] = useState('')

  const getPolicyIdHandler = (id) => {
    setPolicyID(id)
  }

  return (
    <>
      {/* <KTCard> */}
        {/* <PolicyListHeader /> */}
        <PolicyLibraryTable className='' getPolicyID={getPolicyIdHandler} />
        {policyID !== undefined && <EditPolicyModal id={policyID} setPolicyID={setPolicyID} />}
      {/* </KTCard> */}
      {/* {itemIdForUpdate !== undefined && <PolicyEditModal />} */}
    </>
  )
}

const PolicyListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PolicyList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PolicyListWrapper}
