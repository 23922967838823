import React from 'react'
import {Modal} from 'react-bootstrap'
import useCrispChat from 'app/Hooks/useCrispChat'

interface UpgradeModalProps {
  show: boolean
  onClose: () => void
}

const UpgradePlanTest: React.FC<UpgradeModalProps> = ({show, onClose}) => {
  const {toggleCrispChat} = useCrispChat()
  if (!show) {
    return null
  }

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title as='h5'>Upgrade to paid plan</Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <h2 className='fs-4 mb-3'>Unlock this Feature with a Paid Plan</h2>{' '}
        <p className='mb-6 fs-6'>
          {' '}
          Upgrade to any paid plan to continue enjoying this feature. Check out the{' '}
          <a
            href='https://getsecureslate.com/pricing'
            className='text-primary text-decoration-none'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            pricing details{' '}
          </a>{' '}
          for more information.{' '}
        </p>
        <p className='mb-6 fs-6'>
          If you are on a paid plan, this feature is mapped to the corresponding test, providing an
          overview of the test status and allowing you to easily navigate to the associated test.
        </p>
        <div className='d-flex gap-3'>
          <button
            onClick={toggleCrispChat}
            className='btn btn-sm fs-6'
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            style={{
              backgroundColor: '#1CBB8D',
              color: '#fff',
              height: '40px',
              borderRadius: '4px',
              transition: 'background-color 0.3s',
            }}
          >
            Contact sales
          </button>
          {/* <Button variant="outline-secondary" className="flex-grow-1">
            Talk To A Human
          </Button> */}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UpgradePlanTest
