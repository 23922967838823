import ExcelJS from 'exceljs'
import {collection, doc, getDocs, addDoc, updateDoc, getFirestore} from 'firebase/firestore'
import {ref, uploadBytes, getDownloadURL, getMetadata} from 'firebase/storage'
import {storage} from 'services/firebase'

export const ExportAsset = async (currentUser, auditFolderName, folderId, formattedDate,auditID) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId
  const tenantRef = doc(db, 'tenants', tenantId!)
  const PolicyRef = collection(tenantRef, 'assets')
  const querySnapshot = await getDocs(PolicyRef)

  const data = querySnapshot.docs.map((doc) => doc.data()).filter((doc) => !doc.is_repo)

  if (data.length > 0) {
    const fields = [
      {key: 'serial_number', header: 'Serial Number', width: 20},
      {key: 'name', header: 'Name', width: 15},
      {key: 'model', header: 'Model', width: 15},
      {key: 'os_version', header: 'OS Version', width: 20},
      {key: 'owner', header: 'Owner', width: 20},
      {key: 'hd_encryption', header: 'HD Encryption', width: 15},
      {key: 'anti_virus', header: 'Anti Virus', width: 15},
      {key: 'password_policy', header: 'Password Policy', width: 15},
      {key: 'screen_policy', header: 'Screen Policy', width: 15},
      {key: 'firewall', header: 'Firewall', width: 15},
      {key: 'audit_scope', header: 'Audit Scope', width: 15},
    ]

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Assets')

    worksheet.columns = fields.map((field) => ({
      header: field.header,
      key: field.key,
      width: field.width,
    }))

    const headerRow = worksheet.getRow(1)
    headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FF000000'},
    }
    headerRow.height = 30
    headerRow.alignment = {horizontal: 'center',vertical:'middle'}
    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    for (let i = 1; i <= 11; i++) {
      const cell = headerRow.getCell(i)
      cell.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 11}
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      cell.alignment = {horizontal: 'center', vertical: 'middle'}
    }

    data.forEach((row) => {
      const rowData = fields.reduce((acc, field) => {
        let value = row[field.key] || row[field.key.toLowerCase()] || ''

        if (field.key === 'last_checkedin' && value) {
          const date = new Date(value)
          acc[field.key] = date.toLocaleDateString()
        } else {
          acc[field.key] = value
        }

        return acc
      }, {})

      const addedRow = worksheet.addRow(rowData)
      addedRow.height = 20
      addedRow.font = {size: 11}

      fields.forEach((field) => {
        const cell = addedRow.getCell(field.key)
        if (
          [
            'anti_virus',
            'audit_scope',
            'firewall',
            'hd_encryption',
            'password_policy',
            'screen_policy',
          ].includes(field.key)
        ) {
          if (cell.value === 'No') {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'F4CCCC'},
            }
          } else if (cell.value === 'Yes') {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'D9EAD3'},
            }
          }
        }
      })
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const fileName = `${formattedDate} - asset.xlsx`
    const storageRef = ref(storage, `tenants/${tenantId}/dataroom/${auditFolderName}/${fileName}`)
    await uploadBytes(storageRef, buffer)
    const metadata = await getMetadata(storageRef)
    const downloadURL = await getDownloadURL(storageRef)
    const tenantRef = doc(db, 'tenants', tenantId!)
    const dataRoomRef = collection(tenantRef, 'dataroom')

    const fileDoc = {
      createdAt: new Date().toISOString(),
      name: fileName,
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      downloadUrl: downloadURL,
      fileSize: metadata.size,
      subFolder: storageRef.fullPath,
      time_created: formattedDate,
      tenantId: tenantId,
      uid: currentUser?.uid,
      is_folder: false,
      uploadedBy: currentUser?.userName,
      parentFolder: folderId,
      framework: '',
      fileId: '',
    }
    const docRef = await addDoc(dataRoomRef, fileDoc)
    await updateDoc(docRef, {fileId: docRef.id})

    await updateAuditDocument(currentUser, auditID, data.length)
  } else {
    console.log('No data to export')
  }
}


const updateAuditDocument = async (currentUser, auditId, totalLength) => {
  const db = getFirestore()
  const tenantId = currentUser?.tenantId || ''
  const tenantRef2 = doc(db, 'tenants', tenantId)
  const policyRef = doc(tenantRef2, 'audits', auditId)

  await updateDoc(policyRef, { TotalAssets: totalLength })
}