import React, {FC} from 'react'
import {PageLink} from '../../../_metronic/layout/core'
import CompanySettings from './table/CompanySettings'
import {SettingPageTitle} from './components/header/SettingPageTitle'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingPage: FC = () => {

  return (
    <>
      <SettingPageTitle
        icon='Settings.svg'
        title='Settings'
        desc=' Manage your settings'
        breadcrumbs={usersBreadcrumbs}
      >
        Personnel Management
      </SettingPageTitle>
      {/* <KTCard> */}
        <CompanySettings />
      {/* </KTCard> */}
    </>
  )
}

export default SettingPage
